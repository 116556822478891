import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';

interface AuthInterface {
  email_mobile: string | number | readonly string[] | undefined;
  password: string | number | readonly string[] | undefined;
}

const LoginEmail: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;

  const [initialValues, setInitialValues] = useState<AuthInterface>(globalValues);
  const [errors, setErrors] = useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'login',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(error);

          const token = data.result.access_token;

          localStorage.setItem('@token', token);
          window.location.reload();
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="flex-1">
      <div className="max-w-screen-sm p-8 m-auto space-y-6 bg-white rounded-lg">
        <p className="text-xl text-black font-medium text-center">{t('welcome')} 👋</p>

        <form
          className="space-y-3"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <label
              htmlFor="email_mobile"
              className="form-label"
            >
              {t('email')}
            </label>
            <input
              type="email_mobile"
              name="email_mobile"
              id="email_mobile"
              placeholder="example@example.com"
              className="form-input form-outline"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              value={values.email_mobile}
              required
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('email_mobile', target.value)
              }
            />
            {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
          </div>

          <div className="form-group">
            <div className="flex items-center justify-between gap-3">
              <p className="form-label">{t('password')}</p>
              <Link
                to="/account/forgot-password"
                className="text-primary text-sm font-medium underline hover:no-underline transition"
              >
                <span>{t('forgot-password')}</span>
              </Link>
            </div>

            <input
              type="password"
              name="password"
              id="password"
              className="form-input form-outline"
              placeholder="********"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              value={values.password}
              required
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('password', target.value)
              }
            />
            {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
          </div>

          <div className="!mt-8 space-y-2">
            <button
              className="btn-with-icon bg-primary !text-white w-full !p-4"
              type="submit"
              disabled={disabled}
            >
              {disabled ? (
                <Icon
                  icon="svg-spinners:3-dots-fade"
                  width={20}
                />
              ) : (
                <span>{t('login')}</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginEmail;

