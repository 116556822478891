import dayGridPlugin from '@fullcalendar/daygrid'; // a p
import FullCalendar from '@fullcalendar/react';
import { ComponentProps } from 'react';

export default function CalendarEvents(props: ComponentProps<typeof FullCalendar>) {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev',
        center: 'title',
        right: 'next'
      }}
      {...props}
    />
  );
}
