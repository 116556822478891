import React from 'react';
import { motion } from 'framer-motion';
import { MoveToBottom, MoveToTop } from 'src/animations';
import { Icon } from '@iconify/react';
import axiosInstance from 'src/helper/AxiosInstance';
import { pick } from 'lodash';
import Alert from 'src/components/shared/Alert';
import SharedTime from 'src/components/shared/SharedTime';

const Notifications: React.FC = () => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('notifications', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.notifications;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="flex items-center justify-between flex-wrap">
          <div className="inline-flex gap-2">
            {isUpdating || isLoading ? (
              <Icon
                icon="svg-spinners:3-dots-fade"
                width={18}
              />
            ) : null}
            <p className="text-lg font-semibold text-gray-500 shrink-0">Notifications</p>
          </div>
          {/* <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button className="btn-with-icon !text-gray-500 shrink-0">
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>Push notification</span>
            </button>
            <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button>
          </div> */}
        </div>

        {isLoading ? (
          <ul className="space-y-3">
            {Array.from({ length: 3 })
              .fill(0)
              .map((_, index: string | number) => (
                <li key={index}>
                  <a
                    href="#"
                    className="p-6 rounded-xl bg-white ring-1 ring-gray-200 s block"
                  >
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToTop}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-3 mb-4"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-full  bg-gray-200 animate-pulse rounded p-3 mb-2"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/3 bg-gray-200 animate-pulse rounded p-3"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-2 mt-4"
                    ></motion.div>
                  </a>
                </li>
              ))}
          </ul>
        ) : (
          <ul className="space-y-3">
            {responses?.length ? (
              responses.map((item: any, index: string | number) => (
                <li key={index}>
                  <a
                    href="#"
                    className="p-6 rounded-xl bg-white ring-1 ring-gray-200 space-y-1 block"
                  >
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToTop}
                      className="text-lg font-semibold text-gray-800"
                    >
                      {item?.title || '-'}
                    </motion.p>
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="text-sm font-medium text-gray-500"
                    >
                      {item?.body || '-'}
                    </motion.p>
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="text-xs font-medium text-gray-500 !mt-4"
                    >
                      <SharedTime date={item?.created_at} />
                    </motion.p>
                  </a>
                </li>
              ))
            ) : (
              <Alert
                type="default"
                title="Empty result"
                content="It seems that there are no notifications available yet"
              />
            )}
          </ul>
        )}

        <div className="p-4 flex items-center gap-4 flex-wrap">
          <p className="text-gray-500 flex-1 shrink-0 text-sm">
            Showing {pagination?.page || 0} to {pagination?.total_pages || 0} of{' '}
            {pagination?.total || 0} results
          </p>
          <div className="inline-flex flex-wrap gap-2">
            <button
              className="btn-with-icon outline-btn shrink-0"
              disabled={pagination?.current_page === 1}
              onClick={() => GetItems({ page: pagination.page + 1 })}
            >
              <span>Previous</span>
            </button>

            <button
              className="btn-with-icon outline-btn shrink-0"
              disabled={pagination?.current_page === pagination?.total_pages}
              onClick={() => GetItems({ page: pagination.page - 1 })}
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
