import { serialize } from 'object-to-formdata';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import ItemList from 'src/components/shared/ItemList';
import Switcher from 'src/components/shared/Switcher';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';
import InputFile from 'src/components/shared/InputFile';
import Receipt from './Receipt';

const features = [
  {
    title: 'show-logo',
    key: 'show_logo'
  },
  {
    title: 'show-commercial-number',
    key: 'commercial_number'
  },
  {
    title: 'center-name',
    key: 'center_name'
  },
  {
    title: 'center-location',
    key: 'center_location'
  },
  {
    title: 'invoice-number',
    key: 'invoice_number'
  },
  {
    title: 'client-name',
    key: 'client_name'
  },
  {
    title: 'client-number',
    key: 'client_number'
  },
  {
    title: 'item-price',
    key: 'item_price'
  },
  {
    title: 'item-qty',
    key: 'item_qty'
  },
  {
    title: 'discount',
    key: 'discount'
  },
  {
    title: 'vat',
    key: 'vat'
  },
  {
    title: 'total-include-vat',
    key: 'total_include_vat'
  },
  {
    title: 'total-exclude-vat',
    key: 'total_exclude_vat'
  },
  {
    title: 'qr-code',
    key: 'qr_code'
  },
  {
    title: 'comment',
    key: 'show_comment'
  },

  {
    title: 'notes',
    key: 'show_notes'
  }
];

export default function DesignReceipt() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [logo, setLogo] = useState<string>('');
  const [itemContent, setItemContent] = useState<any>({
    font_size: 14,
    show_logo: 1,
    commercial_number: 0,
    center_name: 1,
    center_location: 1,
    invoice_number: 1,
    client_name: 1,
    client_number: 1,
    item_price: 1,
    item_qty: 1,
    discount: 1,
    vat: 1,
    total_include_vat: 1,
    total_exclude_vat: 1,
    qr_code: 0,
    show_comment: 0,
    show_notes: 0
  });

  const [initialValues, setInitialValues] = useState<any>({
    notes: '',
    comment: '',
    commercial_register_num: '',
    tax_num: '',

    'item_content[font_size]': 14,
    'item_content[show_logo]': 1,
    'item_content[commercial_number]': 0,
    'item_content[center_name]': 1,
    'item_content[center_location]': 1,
    'item_content[invoice_number]': 1,
    'item_content[client_name]': 1,
    'item_content[client_number]': 1,
    'item_content[item_price]': 1,
    'item_content[item_qty]': 1,
    'item_content[discount]': 1,
    'item_content[vat]': 1,
    'item_content[total_include_vat]': 1,
    'item_content[total_exclude_vat]': 1,
    'item_content[qr_code]': 0,
    'item_content[show_comment]': 0,
    'item_content[show_notes]': 0
  });
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'invoice_settings/update',
          method: 'post',
          data: serialize(values)
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  function getReceiptSettings() {
    prepareRequest(
      {
        url: '/invoice_settings'
      },
      (data) => {
        setInitialValues({
          ...initialValues,
          comment: data?.result?.comment,
          notes: data?.result?.notes,
          commercial_register_num: data?.result?.commercial_register_num,
          tax_num: data?.result?.tax_num
        });

        setLogo(data.result.logo);

        if (Object.keys(data?.result?.item_content).length > 0) {
          setItemContent(data?.result?.item_content);
        }
      }
    );
  }

  useEffect(() => {
    getReceiptSettings();
  }, []);

  return (
    <div className="p-6 flex items-start gap-x-4 gap-y-6 flex-col-reverse lg:flex-row">
      <div className="flex-1 w-full lg:w-auto">
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <Card className="!p-6">
            <div className="space-y-4">
              <div className="form-group col-span-full">
                <label
                  htmlFor="logo"
                  className="form-label"
                >
                  {t('logo')}
                </label>

                <InputFile
                  defaultValue={values.logo}
                  onValueChange={function (e: any): void {
                    if (e.target.files.length > 0) {
                      setFieldValue('logo', e);
                    }
                  }}
                  accept="image/*"
                />
                <span className="form-error">{errors?.logo}</span>
              </div>

              <div className="form-group">
                <label
                  htmlFor="commercial_register_num"
                  className="form-label"
                >
                  {t('commercial-number')}
                </label>
                <input
                  type="text"
                  id="commercial_register_num"
                  className="form-input form-outline"
                  value={values.commercial_register_num}
                  onChange={handleChange}
                  name="commercial_register_num"
                  required
                />

                <p className="form-error">{errors?.['commercial_register_num']}</p>
              </div>

              <div className="form-group">
                <label
                  htmlFor="tax_num"
                  className="form-label"
                >
                  {t('tax-number')}
                </label>
                <input
                  type="text"
                  id="tax_num"
                  className="form-input form-outline"
                  value={values.tax_num}
                  onChange={handleChange}
                  name="tax_num"
                  required
                />

                <p className="form-error">{errors?.['tax_num']}</p>
              </div>

              <div className="form-group">
                <label
                  htmlFor="notes"
                  className="form-label"
                >
                  {t('notes')}
                </label>
                <textarea
                  id="notes"
                  className="form-textarea form-outline"
                  value={values.notes}
                  onChange={handleChange}
                  name="notes"
                ></textarea>

                <p className="form-error">{errors?.['notes']}</p>
              </div>

              <div className="form-group">
                <label
                  htmlFor="comment"
                  className="form-label"
                >
                  {t('comment')}
                </label>
                <textarea
                  id="comment"
                  className="form-textarea form-outline"
                  value={values.comment}
                  onChange={handleChange}
                  name="comment"
                ></textarea>

                <p className="form-error">{errors?.['comment']}</p>
              </div>

              <div className="form-group">
                <label
                  htmlFor="font_size"
                  className="form-label"
                >
                  {t('font-size')}
                </label>
                <input
                  type="number"
                  max={20}
                  min={14}
                  step="2"
                  id="font_size"
                  className="form-input form-outline"
                  value={Number(itemContent.font_size)}
                  onChange={(e) => {
                    setFieldValue('item_content[font_size]', e.target.value);

                    setItemContent({
                      ...itemContent,
                      font_size: e.target.value
                    });
                  }}
                  name="font_size"
                  required
                />

                <p className="form-error">{errors?.['font_size']}</p>
              </div>

              <div className="w-full">
                <ul className="divide-y divide-gray-100">
                  {features.map((item, index) => (
                    <ItemList
                      key={index}
                      className="py-3"
                    >
                      <div className="inline-flex gap-3">
                        <Switcher
                          checked={Number(itemContent[item.key]) === 1}
                          onChange={(checked: boolean) => {
                            setFieldValue(`item_content[${item.key}]`, checked ? 1 : 0);
                            setItemContent({
                              ...itemContent,
                              [item.key]: checked ? 1 : 0
                            });
                          }}
                        />
                        <p>{t(item.title)}</p>
                      </div>
                    </ItemList>
                  ))}
                </ul>
              </div>
            </div>
          </Card>
          <div className="inline-flex items-center gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary shrink-0"
              type="submit"
              disabled={disabled}
            >
              <span>{t('save-changes')}</span>
            </button>
          </div>
        </form>
      </div>

      <Receipt
        itemContent={itemContent}
        logo={logo}
      />
    </div>
  );
}

