import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import prepareRequest from 'src/helper/prepareRequest';

const initialValues = {
  email: 'demo@demo.com'
};

const DemoAccount = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'login',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(error);

          const token = data.result.access_token;

          localStorage.setItem('@token', token);
          window.location.reload();
        }
      ).finally(() => setDisabled(false));
    }
  });

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <button
        type="submit"
        className="flex gap-1 items-center text-lg font-medium text-gray-600"
        disabled={disabled}
      >
        <Icon
          icon="mdi:user-help-outline"
          className="w-6 h-6"
        />

        <span>{t('demo-account')}</span>
      </button>
    </form>
  );
};

export default DemoAccount;


