import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import Card from '../shared/Card';
import Switcher from '../shared/Switcher';
import UpdateFAQS from './UpdateFAQS';
import ButtonWithCallback from '../shared/ButtonWithCallback';


function FAQSQuestionCard({ item, refetching }: { item: any; refetching: any }) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  function deleteItem() {
    setDisabled(true);
    prepareRequest({ url: 'faqs/delete/' + item.id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      refetching();
    }).finally(() => {
      setDisabled(false);
    });
  }

  function updateStatus() {
    setDisabled(true);
    prepareRequest({ url: 'faqs/update_is_active/' + item.id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      refetching();
    }).finally(() => {
      setDisabled(false);
    });
  }

  return (
    <Card className="min-h-full !p-6 flex flex-col gap-3">
      <div className="space-y-2 text-start flex-1">
        <div className="flex items-start gap-2">
          <p className="text-lg font-semibold flex-1">{item.question}</p>
          <Switcher
            checked={!!item.is_active}
            onChange={updateStatus}
          />
        </div>
        <p className="text-base text-gray-500 line-clamp-2">{item.answer}</p>
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        <UpdateFAQS
          item={item}
          refetching={refetching}
        />

        <ButtonWithCallback
          options={{
            title: t('are-you-sure'),
            showCancelButton: true,
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            icon: 'question'
          }}
          callback={deleteItem}
          className="btn-with-icon !bg-red-100 !text-red-600"
          disabled={disabled}
        >
          <span>{t('delete')}</span>
        </ButtonWithCallback>
      </div>
    </Card>
  );
}



export default FAQSQuestionCard