import React, { FC } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/Table';
import SharedTime from 'src/components/shared/SharedTime';
import { Link } from 'react-router-dom';
import WebsiteTabs from 'src/components/shared/WebsiteTab';
import Select from 'src/components/shared/Select';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';

const faqs: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [faqTypes, setFaqTypes] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems(), GetTypes()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetTypes = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('faq_types', { params: { is_active: 1 } });
      setFaqTypes(data?.result?.faq_types);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('faqs', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.faqs;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ChangeStatus = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('faqs/update_is_active/' + id);
      await GetItems();
      console.log(data);
      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DeleteItem = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('faqs/delete/' + id);
      await GetItems();

      console.log(data);

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr className=" bg-gray-100 border-b border-b-gray-200 ">
              <th className=" font-medium p-4 text-slate-600 text-start">Question | answer</th>
              <th className=" font-medium p-4  text-slate-600 text-start">FAQs type </th>
              <th className=" font-medium p-4 text-slate-600 text-start">
                <select
                  name="status-filter"
                  id="status-filter"
                  className="form-select !py-0 !w-fit"
                  value={pagination.is_active}
                  onChange={(e) => {
                    GetItems({ is_active: (e.target as HTMLSelectElement).value });
                  }}
                >
                  <option
                    disabled
                    defaultValue={''}
                  >
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </th>

              <th className=" font-medium p-4  text-slate-600 text-start">Latest update</th>
              <th className=" font-medium p-4  text-slate-600 text-start">Actions </th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr
                    className="divide-x divide-gray-200"
                    key={index}
                  >
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <div className="space-y-1">
                        <p className="text-base font-semibold">{item.question || '-'} </p>
                        <p className="text-sm text-gray-500 line-clamp-2">{item.answer || '-'}</p>
                      </div>
                    </td>

                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      {item?.faqType?.name}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <DotStatus active={!!item.is_active} />
                    </td>

                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <div className="inline-flex gap-2">
                        <button
                          className="action-btn text-red-500"
                          onClick={() => DeleteItem(item.id)}
                        >
                          Remove
                        </button>
                        <button
                          className="action-btn text-blue-600"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="action-btn text-gray-600"
                          onClick={() => ChangeStatus(item.id)}
                        >
                          Change status
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="FAQs table"
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <Breadcrumbs title="Settings" />
        <div className="flex items-center justify-between flex-wrap">
          <WebsiteTabs />
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button
              className="btn-with-icon !text-gray-600 shrink-0"
              onClick={() => setVisible(true)}
            >
              <span>
                <Icon
                  icon="majesticons:plus"
                  width={18}
                />
              </span>
              <span>New question</span>
            </button>
            <Link
              to="/settings/website/faqs/types"
              className="btn-with-icon !text-gray-600 shrink-0"
            >
              <span>
                <Icon
                  icon="fe:layer"
                  width={18}
                />
              </span>
              <span>FAQ types</span>
            </Link>

            {/* <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon
                  icon="ri:file-excel-2-line"
                  width={18}
                />
              </span>
              <span>Export EXCEL</span>
            </button> */}
          </div>
        </div>
        {MEMO_TABLE}
      </div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title="New question"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
          faqTypes={faqTypes}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title="Update question"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          faqTypes={faqTypes}
          schema={{
            ...editItem,
            faq_type_id: editItem?.faqType?.id
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  question: string | undefined;
  alt_question: string | undefined;
  answer: string | undefined;
  alt_answer: string | undefined;
  faq_type_id: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching,
  faqTypes
}: {
  closeModal: any;
  reFetching: any;
  faqTypes: any[];
}): JSX.Element => {
  const globalValues = {
    question: undefined,
    alt_question: undefined,
    answer: undefined,
    alt_answer: undefined,
    faq_type_id: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('faqs/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}
      <div className="form-group">
        <label className="form-label">Question</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.question}
          onChange={(e) => handleChange('question', e)}
        />
        {errors?.question ? <span className="form-error">{errors?.question}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt question</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_question}
          onChange={(e) => handleChange('alt_question', e)}
        />
        {errors?.alt_question ? <span className="form-error">{errors?.alt_question}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Answer</label>
        <textarea
          name="faq-answer"
          id="faq-answer"
          placeholder=".."
          onChange={(e) => handleChange('answer', e)}
          value={formik.values.answer}
          className="form-textarea form-outline"
        ></textarea>
        {errors?.answer ? <span className="form-error">{errors?.answer}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt answer</label>
        <textarea
          name="faq-alt_answer"
          id="faq-alt_answer"
          placeholder=".."
          onChange={(e) => handleChange('alt_answer', e)}
          value={formik.values.alt_answer}
          className="form-textarea form-outline"
        ></textarea>
        {errors?.alt_answer ? <span className="form-error">{errors?.alt_answer}</span> : null}
      </div>{' '}
      <div className="form-group">
        <label className="form-label">FAQ type</label>
        <Select
          type={'single'}
          options={faqTypes}
          value={formik.values.faq_type_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('faq_type_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />

        {errors?.faq_type_id ? <span className="form-error">{errors?.faq_type_id}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema,
  faqTypes
}: {
  closeModal: any;
  reFetching: any;
  faqTypes: any[];
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('faqs/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}
      <div className="form-group">
        <label className="form-label">Question</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.question}
          onChange={(e) => handleChange('question', e)}
        />
        {errors?.question ? <span className="form-error">{errors?.question}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt question</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_question}
          onChange={(e) => handleChange('alt_question', e)}
        />
        {errors?.alt_question ? <span className="form-error">{errors?.alt_question}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Answer</label>
        <textarea
          name="faq-answer"
          id="faq-answer"
          placeholder=".."
          onChange={(e) => handleChange('answer', e)}
          value={formik.values.answer}
          className="form-textarea form-outline"
        ></textarea>
        {errors?.answer ? <span className="form-error">{errors?.answer}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">Alt answer</label>
        <textarea
          name="faq-alt_answer"
          id="faq-alt_answer"
          placeholder=".."
          onChange={(e) => handleChange('alt_answer', e)}
          value={formik.values.alt_answer}
          className="form-textarea form-outline"
        ></textarea>
        {errors?.alt_answer ? <span className="form-error">{errors?.alt_answer}</span> : null}
      </div>{' '}
      <div className="form-group">
        <label className="form-label">FAQ type</label>
        <Select
          type={'single'}
          options={faqTypes}
          value={formik.values.faq_type_id}
          onSelect={function (value: any): void {
            formik.setFieldValue('faq_type_id', value);
          }}
          optionTxt={'name'}
          optionValue={'id'}
        />

        {errors?.faq_type_id ? <span className="form-error">{errors?.faq_type_id}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>Submit</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  );
};

export default faqs;

