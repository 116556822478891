import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';
import InputFile from 'src/components/shared/InputFile';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Mobile from 'src/components/shared/Mobile';
import Select from 'src/components/shared/Select';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';
// import GoogleMap from '../../components/shared/GoogleMap';

export default function Update() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <AppBar
        center
        title={t('center-settings')}
      />
      <div className="xl:container mx-auto">
        <UpdateCenterForm id={id} />
      </div>
    </>
  );
}

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  info: string | undefined;
  alt_info: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  region_id: string | undefined;
  city_id: string | undefined;
  district_id: string | undefined;
  owner_id: string | undefined;
  package_id: string | undefined;
  address: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
}

export function UpdateCenterForm({ id }: { id: string | undefined }) {
  const { t } = useTranslation();
  let rerender = true;
  const navigate = useNavigate();
  const [center, setCenter] = React.useState<any>({});
  const [initialValues, setInitialValues] = React.useState(center);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetCenter(), , GetRegions(), GetCities(), GetDistricts()]).finally(() =>
        setIsLoading(false)
      );
    }
    rerender = false;
  }, []);

  const GetCenter = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('centers/' + id);
      const item = data?.result?.center;
      setInitialValues({
        ...item,
        region_id: item?.region?.id,
        city_id: item?.city?.id,
        district_id: item?.district?.id,
        feature_ids: item?.features?.map((ev: any) => ev.id)
      });
    } catch (error) {
      console.log(error);
      navigate(-1);
    }
  }, []);

  const GetRegions = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('regions', { params: { is_active: 1 } });
      setRegions(data?.result?.regions);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetCities = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('cities', { params: { is_active: 1 } });
      setCities(data?.result?.cities);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetDistricts = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('districts', { params: { is_active: 1 } });
      setDistricts(data?.result?.districts);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const fd = serialize(values);

      if (!(values.image instanceof File)) fd.delete('image');

      const { data } = await axiosInstance.post('centers/update', fd);
      generateAlert(data.message, 'success');

      helper.resetForm();
      window.location.reload();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          generateAlert(err?.message, 'error');
        }
        return;
      }
      generateAlert(error?.message, 'error');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const filterCities = React.useMemo(() => {
    formik.setFieldValue('city_id', undefined);
    formik.setFieldValue('district_id', undefined);
    return cities.filter((e: any) =>
      formik.values.region_id ? formik.values.region_id == e?.region?.id : e
    );
  }, [formik.values.region_id, cities]);

  const filterDistricts = React.useMemo(() => {
    formik.setFieldValue('district_id', undefined);
    return districts.filter((e: any) =>
      formik.values.city_id ? formik.values.city_id == e?.city?.id : e
    );
  }, [formik.values.city_id, districts]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="p-6">
            <form
              className="grid grid-cols-1 gap-4 items-start"
              onSubmit={formik.handleSubmit}
            >
              <div className="space-y-4">
                {/* <p className="text-sm font-semibold text-gray-500">{t('basic-information')}</p> */}
                <Card>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-group col-span-full">
                      <label className="form-label">{t('image')}</label>
                      <InputFile
                        defaultValue={formik.values.image}
                        onValueChange={function (e: any): void {
                          formik.setFieldValue('image', e);
                        }}
                        accept="image/*"
                      />
                      {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('name')}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.name}
                        onChange={(e) => handleChange('name', e)}
                      />
                      {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('alt-name')}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.alt_name}
                        onChange={(e) => handleChange('alt_name', e)}
                      />
                      {errors?.alt_name ? (
                        <span className="form-error">{errors?.alt_name}</span>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">{t('email')}</label>
                      <input
                        type="email"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.email}
                        onChange={(e) => handleChange('email', e)}
                      />
                      {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">{t('mobile')}</label>
                      <Mobile
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.mobile}
                        onChange={(e) => handleChange('mobile', e)}
                      />
                      {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
                    </div>

                    <div className="form-group col-span-full">
                      <label className="form-label">{t('description')}</label>

                      <Editor
                        onChange={(value) => formik.setFieldValue('info', value)}
                        value={formik.values.info}
                      />
                      {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
                    </div>
                    <div className="form-group col-span-full">
                      <label className="form-label">{t('alt-description')}</label>
                      <Editor
                        onChange={(value) => formik.setFieldValue('alt_info', value)}
                        value={formik.values.alt_info}
                      />
                      {errors?.alt_info ? (
                        <span className="form-error">{errors?.alt_info}</span>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="space-y-4">
                {/* <p className="text-sm font-semibold text-gray-500">{t('location')}</p> */}
                <Card>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-group col-span-full">
                      <label className="form-label">{t('region')}</label>
                      <Select
                        type={'single'}
                        options={regions}
                        value={formik.values.region_id}
                        onSelect={function (value: any): void {
                          formik.setFieldValue('region_id', value);
                        }}
                        optionTxt={'name'}
                        optionValue={'id'}
                      />

                      {errors?.region_id ? (
                        <span className="form-error">{errors?.region_id}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('city')}</label>
                      <Select
                        type={'single'}
                        options={filterCities}
                        value={formik.values.city_id}
                        onSelect={function (value: any): void {
                          formik.setFieldValue('city_id', value);
                        }}
                        optionTxt={'name'}
                        optionValue={'id'}
                      />

                      {errors?.city_id ? (
                        <span className="form-error">{errors?.city_id}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('district')}</label>
                      <Select
                        type={'single'}
                        options={filterDistricts}
                        value={formik.values.district_id}
                        onSelect={function (value: any): void {
                          formik.setFieldValue('district_id', value);
                        }}
                        optionTxt={'name'}
                        optionValue={'id'}
                      />

                      {errors?.district_id ? (
                        <span className="form-error">{errors?.district_id}</span>
                      ) : null}
                    </div>
                  </div>
                </Card>
                {/* <p className="text-sm font-semibold text-gray-500">{t('center-settings')}</p> */}
                <Card>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-group">
                      <label className="form-label">{t('cr-no')}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.cr_no}
                        onChange={(e) => handleChange('cr_no', e)}
                      />
                      {errors?.cr_no ? <span className="form-error">{errors?.cr_no}</span> : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('vat-no')}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.vat_no}
                        onChange={(e) => handleChange('vat_no', e)}
                      />
                      {errors?.vat_no ? <span className="form-error">{errors?.vat_no}</span> : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('bank-name')}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.bank_name}
                        onChange={(e) => handleChange('bank_name', e)}
                      />
                      {errors?.bank_name ? (
                        <span className="form-error">{errors?.bank_name}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">IBAN</label>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="..."
                        className="form-input form-outline"
                        value={formik.values.iban}
                        onChange={(e) => handleChange('iban', e)}
                      />
                      {errors?.iban ? <span className="form-error">{errors?.iban}</span> : null}
                    </div>
                    <div className="form-group">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="form-checkbox form-outline rounded-full"
                          true-value={1}
                          false-value={0}
                          id="accept_partial_payments"
                          value={formik.values.accept_partial_payments}
                          onChange={(e) => handleChange('accept_partial_payments', e)}
                        />
                        <label
                          className="form-label"
                          htmlFor="accept_partial_payments"
                        >
                          {t('accept-partial-payments')}
                        </label>
                      </div>
                      {errors?.accept_partial_payments ? (
                        <span className="form-error">{errors?.accept_partial_payments}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="form-checkbox form-outline rounded-full"
                          true-value={1}
                          false-value={0}
                          id="show_item_image"
                          value={formik.values.show_item_image}
                          onChange={(e) => handleChange('show_item_image', e)}
                        />
                        <label
                          className="form-label"
                          htmlFor="show_item_image"
                        >
                          {t('show-images-in-pos')}
                        </label>
                      </div>
                      {errors?.show_item_image ? (
                        <span className="form-error">{errors?.show_item_image}</span>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-span-full">
                <div className="flex gap-3 flex-wrap">
                  <button
                    className="btn-with-icon bg-primary"
                    type="submit"
                    disabled={disabled}
                  >
                    {disabled ? (
                      <Icon
                        icon="svg-spinners:3-dots-fade"
                        width={20}
                      />
                    ) : (
                      <span>{t('save-changes')}</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
