import { Icon } from '@iconify/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddAboutUs from 'src/components/account/setup/AddAboutUs';
import AddCenter from 'src/components/account/setup/AddCenter';
import AddFAQS from 'src/components/account/setup/AddFAQS';
import AddPage from 'src/components/account/setup/AddPage';
import AddProduct from 'src/components/account/setup/AddProduct';
import AddService from 'src/components/account/setup/AddService';
import ContactInfo from 'src/components/account/setup/ContactInfo';
import Subdomain from 'src/components/account/setup/Subdomain';
import CompleteAccountStepsBanner from 'src/components/shared/CompleteAccountStepsBanner';
import ItemList from 'src/components/shared/ItemList';
import Switcher from 'src/components/shared/Switcher';
import FullScreenHeader from 'src/layouts/FullScreenHeader';
import Header from 'src/layouts/Header';
import { RootState } from 'src/store';

export interface StepItemProps {
  callback?: () => void;
}

const steps = [
  {
    title: 'subdomain',
    key: 'subdomain',
    content: 'subdomain-content',
    icon: 'iconoir:internet'
  },
  {
    title: 'add-center-info',
    key: 'add-center',
    content: 'add-center-info-content',
    icon: 'fluent:data-usage-edit-24-regular'
  },
  {
    title: 'center-contact-info',
    key: 'contact-info',
    content: 'center-contact-info-content',
    icon: 'system-uicons:contacts'
  },
  // {
  //   title: 'services',
  //   key: 'services',
  //   content: 'services-content',
  //   icon: 'carbon:cloud-services'
  // },
  // {
  //   title: 'products',
  //   key: 'products',
  //   content: 'products-content',
  //   icon: 'fluent-mdl2:product-release'
  // },
  {
    title: 'pages',
    key: 'pages',
    content: 'pages-content',
    icon: 'fluent-mdl2:reopen-pages'
  },
  // {
  //   title: 'about-us',
  //   key: 'about-us',
  //   content: 'about-us-content',
  //   icon: 'mdi:card-account-details-star-outline'
  // },
  {
    title: 'faqs',
    key: 'faqs',
    content: 'faqs-content',
    icon: 'fluent:question-circle-24-regular'
  }
];

export default function Setup() {
  const { isSingleApp } = useSelector((state: RootState) => state.auth);

  const [activeTab, setActiveTab] = useState<string>(isSingleApp ? 'add-center' : 'subdomain');

  const { t } = useTranslation();

  const component = useMemo(() => {
    switch (activeTab) {
      case 'add-center':
        return <AddCenter />;
      case 'services':
        return <AddService />;
      case 'contact-info':
        return <ContactInfo />;
      case 'products':
        return <AddProduct />;
      case 'about-us':
        return <AddAboutUs />;
      case 'pages':
        return <AddPage />;
      case 'faqs':
        return <AddFAQS />;

      default:
        return <Subdomain />;
    }
  }, [activeTab]);

  return (
    <div>
      <FullScreenHeader title={t('website-settings')} />

      <div className="px-6">
        <div className="xl:container space-y-6">
          <div className="flex flex-col items-start gap-y-6 lg:flex-row lg:h-[calc(100vh-5rem)]">
            <div
              className={`
                flex-1 w-full order-2 border-gray-100 py-6 overflow-auto no-scrollbar
                lg:max-w-[250px] lg:h-full lg:order-1 lg:border-e 
              `}
            >
              <ul>
                {steps.map((step, index) =>
                  isSingleApp && ['subdomain'].includes(step.key) ? null : (
                    <ItemList
                      className="relative py-2"
                      key={index}
                    >
                      <button
                        className="flex items-center gap-4"
                        onClick={() => setActiveTab(() => step.key)}
                      >
                        <div
                          className={`w-10 h-10 rounded-full flex items-center justify-center ${
                            activeTab === step.key ? 'bg-primary' : 'bg-gray-100'
                          }`}
                        >
                          <Icon
                            icon={step.icon}
                            className="text-2xl text-gray-600"
                          />
                        </div>

                        <h2
                          className={`text-base font-semibold ${
                            activeTab === step.key ? 'text-gray-800' : 'text-gray-500'
                          }`}
                        >
                          {t(step.title)}
                        </h2>
                      </button>
                    </ItemList>
                  )
                )}

                <ItemList className="mt-2">
                  <div className="flex items-center gap-4">
                    <div>
                      <Switcher
                        // checked={false}
                        defaultChecked={false}
                        onChange={(checked) => {
                          // setParams((param) => {
                          //   param.set('has_notes', checked ? '1' : '0');

                          //   return param;
                          // });

                          console.log('checked', checked);
                        }}
                      />
                    </div>

                    <h2 className={`text-base font-semibold text-gray-500`}>
                      {t('disable-website')}
                    </h2>
                  </div>
                </ItemList>
              </ul>
            </div>

            <div className="flex-1 w-full order-1 lg:order-2 space-y-6 py-6  h-full overflow-auto no-scrollbar lg:ps-8">
              <CompleteAccountStepsBanner />
              {component}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

