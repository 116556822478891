import { Icon } from '@iconify/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import SharedTime from 'src/components/shared/SharedTime';
import Switcher from 'src/components/shared/Switcher';
import SlideUpDown from 'src/components/shared/animations/SlideUpDown';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function PaymentGateway() {
  const { t } = useTranslation();
  return (
    <div className="p-6 space-y-4">
      <UpdateSmsForm />
    </div>
  );
}

function UpdateSmsForm() {
  let render = true;
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customVisible, setCustomVisible] = useState<boolean>(false);
  const [paymentGateway, setPaymentGateway] = useState<any>({});
  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  useEffect(() => {
    if (!render) return;
    getUpdates();
    render = false;
  }, []);

  function getUpdates() {
    setIsLoading(true);
    prepareRequest({ url: 'settings/payment_gateway' }, (data) => {
      const result = data.result;

      setInitialValues(() => ({
        gateway: result.gateway,
        ...result.hyperpay_config
      }));
      setPaymentGateway(() => result);
      setCustomVisible(() => !!result.gateway);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function submitHandler(values: any, helper: any) {
    setErrors({});
    setDisabled(true);
    prepareRequest(
      {
        url: 'settings/payment_gateway/update',
        method: 'post',
        data: {
          ...(values.gateway && {
            ...values
          }),
          gateway: values.gateway ? values.gateway : null
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        await getUpdates();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  if (isLoading) return <LoadingComponent />;
  return (
    <>
      <Card className="!p-6">
        <div className="flex gap-4 justify-between">
          <div className="space-y-1">
            <p className="text-lg font-semibold">{t('custom-payment-form-content')}</p>
            <p className="text-sm text-gray-500">{t('payment-settings-content')}</p>
          </div>
          <Switcher
            checked={customVisible}
            onChange={(ev) => {
              setCustomVisible(() => ev);
              if (!ev && !!paymentGateway.gateway) {
                formik.setFieldValue('gateway', null);
                formik.handleSubmit();
              }
            }}
          />
        </div>
      </Card>

      {customVisible ? (
        <Card className="!p-6">
          <form
            className="space-y-4"
            onSubmit={formik.handleSubmit}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group col-span-full">
                <label className="form-label">{t('payment-type')}</label>
                <select
                  name="gateway"
                  id="gateway"
                  className="form-select form-outline"
                  defaultValue=""
                  value={formik.values.gateway}
                  onChange={formik.handleChange}
                >
                  <option value="">{t('select')}</option>
                  <option value="hyperpay">hyperpay</option>
                </select>
                {errors?.gateway ? <span className="form-error">{errors?.gateway}</span> : null}
              </div>
              <div className="form-group col-span-full">
                <label className="form-label">Access token</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.access_token}
                  onChange={(e) => handleChange('access_token', e)}
                />
                {errors?.access_token ? (
                  <span className="form-error">{errors?.access_token}</span>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">Mada entity</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.mada_entity}
                  onChange={(e) => handleChange('mada_entity', e)}
                />
                {errors?.mada_entity ? (
                  <span className="form-error">{errors?.mada_entity}</span>
                ) : null}
              </div>

              <div className="form-group">
                <label className="form-label">Visa entity</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.visa_entity}
                  onChange={(e) => handleChange('visa_entity', e)}
                />
                {errors?.visa_entity ? (
                  <span className="form-error">{errors?.visa_entity}</span>
                ) : null}
              </div>

              <div className="form-group">
                <label className="form-label">Apple pay entity</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.apple_pay_entity}
                  onChange={(e) => handleChange('apple_pay_entity', e)}
                />
                {errors?.apple_pay_entity ? (
                  <span className="form-error">{errors?.apple_pay_entity}</span>
                ) : null}
              </div>

              <div className="form-group">
                <label className="form-label">STC entity</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.stc_entity}
                  onChange={(e) => handleChange('stc_entity', e)}
                />
                {errors?.stc_entity ? (
                  <span className="form-error">{errors?.stc_entity}</span>
                ) : null}
              </div>
            </div>

            <div className="inline-flex gap-3 flex-wrap">
              <button
                className="btn-with-icon bg-primary text-white"
                type="submit"
                disabled={disabled}
              >
                <span>{t('save-changes')}</span>
              </button>
            </div>
          </form>
        </Card>
      ) : (
        <PaymentRules
          accept_payment_rules={paymentGateway.accept_payment_gateway_rules}
          rules={paymentGateway.rules}
        />
      )}
      <PaymentLogs />
    </>
  );
}

let time: Date = new Date();

function PaymentRules({
  accept_payment_rules,
  rules
}: {
  accept_payment_rules: number;
  rules: string;
}) {
  const { t } = useTranslation();
  const submitButton = useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] = useState({
    verification_code: undefined,
    accept_payment_rules
  });
  const [result, setResult] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { seconds, restart, totalSeconds } = useTimer({ expiryTimestamp: time });

  const { formik } = useForm({
    initialValues,
    submitHandler: (values, helpers) => {
      setDisabled(true);
      prepareRequest(
        { url: 'settings/payment_gateway/accept_rules', method: 'post', data: values },
        (data, error) => {
          generateAlert(data.message, 'success');
          setResult(() => data.result);
          // time = data.result?.remaining_time || 0;
          time = new Date();
          time.setSeconds(time.getSeconds() + data.result.remaining_time || 0);
          restart(time);
          // console.log(data);
        }
      ).finally(() => setDisabled(false));
    }
  });

  function onInputChange(ev: ChangeEvent<HTMLInputElement>) {
    const { checked } = ev.target;
    formik.setFieldValue('accept_payment_rules', checked ? 1 : 0);
    submitButton.current?.click();
  }

  return (
    <Card className="!p-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-4">
          <div className="flex items-center justify-center w-14 h-14 rounded-full bg-gray-100 text-gray-600 mx-auto">
            <Icon
              icon="solar:list-check-broken"
              width="32"
              height="32"
            />
          </div>
          <div className="space-y-2 text-center">
            <p className="text-lg font-semibold">{t('payment-rules')}</p>
            <div
              className="text-gray-500"
              dangerouslySetInnerHTML={{ __html: rules }}
            ></div>
            <SlideUpDown visible={!!result}>
              <div className="flex items-stretch w-full mx-auto !my-4">
                <input
                  type="text"
                  name="verification_code"
                  id="verification_code"
                  className="form-input form-outline !rounded-e-none"
                  value={formik.values.verification_code}
                  onChange={formik.handleChange}
                  placeholder={t('verification-code')}
                />
                <button
                  type="submit"
                  className="btn-with-icon bg-primary !rounded-s-none"
                  disabled={disabled}
                >
                  <span>{t('submit')}</span>
                </button>
              </div>
              <button
                type="submit"
                className="text-xs font-gray-500 max-w-fit self-start"
                onClick={() => {
                  formik.setFieldValue('verification_code', undefined);
                }}
                disabled={disabled || !!seconds}
              >
                {t('resend-content')} <span>({seconds}s)</span>
              </button>
            </SlideUpDown>
            <div className="pt-4 !mt-4 border-t border-t-gray-200">
              <div className="flex items-center gap-3 justify-center">
                <input
                  type="checkbox"
                  name="accept_payment_rules"
                  id="accept_payment_rules"
                  className="form-checkbox"
                  true-value={1}
                  false-value={0}
                  value={formik.values.accept_payment_rules}
                  onChange={onInputChange}
                  disabled={!!accept_payment_rules || disabled || result}
                />
                <label
                  htmlFor="accept_payment_rules"
                  className="form-label cursor-pointer"
                >
                  {t('accept_payment_rules')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <input
          type="submit"
          hidden
          ref={submitButton}
          className="hidden"
        />
      </form>
    </Card>
  );
}

function PaymentLogs() {
  let render = true;
  const { t } = useTranslation();
  const [logs, setLogs] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!render) return;
    getLogs();
    render = false;
  }, []);

  function getLogs(params?: any) {
    setIsLoading(true);
    prepareRequest(
      { url: 'settings/payment_gateway/logs', params: { page: 1, ...params } },
      (data) => {
        const result = data.result;
        setLogs(() => result.payment_gateway_logs?.data || []);
        setPagination(() => result.payment_gateway_logs?.pagination || {});
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      <Table
        title={t('payment-logs')}
        isEmpty={!logs.length}
        RenderHead={() => (
          <tr>
            <th>{t('payment-type')}</th>
            <th>{t('amount')}</th>
            <th>{t('payments')}</th>
            <th>{t('details')}</th>
            <th>{t('date')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{log.gateway || '-'}</td>
                <td>{CurrencyFormatter(log.amount || 0)}</td>
                <td>{log.payment_option || '-'}</td>
                <td>{log.extra || '-'}</td>
                <td>
                  <SharedTime date={log.created_at} />
                </td>
              </tr>
            ))}
          </>
        )}
        pagination={pagination}
        loading={isLoading}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            getLogs({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => getLogs({ page: pagination.page + 1 })}
        onPreviousClick={() => getLogs({ page: pagination.page - 1 })}
      />
    </>
  );
}

