import { Icon } from '@iconify/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';
import { JournalEntry, Transaction } from './new';

export default function UpdateSelectedJournalEntry() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<JournalEntry | any>({});
  const [centers, setCenters] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<JournalEntry | any>({});
  const navigate = useNavigate();

  useEffect(() => {
    getSelectedJournalEntry();
    getCenters();
  }, []);

  function getSelectedJournalEntry() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'journal_entries/' + id
      },
      (data) => {
        const result = data.result?.journal_entry || {};
        setInitialValues(() => ({
          ...result,
          center_id: result?.center?.id,
          transactions: result?.transactions?.map((transaction: Transaction) => ({
            ...transaction,
            account_id: transaction.account?.id
          }))
        }));
      }
    ).finally(() => setIsLoading(false));
  }

  function getCenters(q?: string) {
    prepareRequest(
      {
        url: 'centers',
        params: {
          is_active: 1,
          page: 1,
          search_key: q
        }
      },
      (data) => {
        setCenters(() => data.result?.centers?.data);
      }
    );
  }

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm<JournalEntry>({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        { url: 'journal_entries/update', method: 'post', data: values },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          navigate('/settings/journal-entries', { replace: true });
        }
      ).finally(() => {
        setDisabled(false);
      });
    }
  });

  const pricesDontMatch = useMemo(() => {
    let debits = 0,
      credits = 0;

    for (const transaction of values?.transactions || []) {
      debits += transaction.debit || 0;
      credits += transaction.credit || 0;
    }

    return debits !== credits;
  }, [values.transactions]);

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <AppBar
        title={initialValues?.date as string}
        center
      />
      <div className="flex-1 min-h-screen">
        <div className="p-6">
          <div className="container">
            <form
              className="space-y-4"
              onSubmit={handleSubmit}
            >
              <Card className="!p-6 space-y-4">
                <div className="form-group">
                  <p className="form-label">{t('date')}</p>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={values.date}
                    onChange={handleChange}
                    className="form-input form-outline"
                  />
                  <p className="form-error">{errors.date}</p>
                </div>
                <div className="form-group">
                  <p className="form-label">{t('center')}</p>
                  <Select
                    type="single"
                    options={centers}
                    optionTxt="name"
                    optionValue="id"
                    onSelect={(value) => setFieldValue('center_id', value)}
                    onSearchChange={(ev) => getCenters(ev.target.value)}
                    value={values.center_id}
                  />
                  <p className="form-error">{errors.center_id}</p>
                </div>
                <div className="form-group">
                  <p className="form-label">{t('notes')}</p>
                  <textarea
                    name="notes"
                    id="notes"
                    value={values.notes}
                    onChange={handleChange}
                    className="form-textarea form-outline"
                  ></textarea>
                  <p className="form-error">{errors.notes}</p>
                </div>
              </Card>
              <button
                type="button"
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                {t('add-new')}
              </button>
              <Card className="!p-0 overflow-hidden">
                <div className="grid">
                  <div className="overflow-x-auto">
                    <table className="styled-table">
                      <thead className="bg-gray-100">
                        <tr>
                          <th>{t('account')}</th>
                          <th>{t('debit')}</th>
                          <th>{t('credit')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values?.transactions?.map((transaction: Transaction, index: number) => (
                          <tr key={transaction.account_id}>
                            <td>
                              <p>{transaction.account?.name}</p>
                              {!!transaction.notes && (
                                <p className="text-gray-600 !max-w-sm !whitespace-normal">
                                  {transaction.notes}
                                </p>
                              )}
                            </td>
                            <td>{CurrencyFormatter(transaction.debit)}</td>
                            <td>{CurrencyFormatter(transaction.credit)}</td>
                            <td>
                              <button
                                type="button"
                                className="btn-with-icon bg-red-100 !text-red-600"
                                onClick={() => {
                                  setFieldValue(
                                    'transactions',
                                    values.transactions.filter((_e: any, idx: any) => index !== idx)
                                  );
                                }}
                              >
                                <Icon
                                  icon="solar:trash-bin-minimalistic-outline"
                                  width="20"
                                  height="20"
                                />
                                <span>{t('delete')}</span>
                              </button>
                            </td>
                          </tr>
                        ))}

                        {!values?.transactions?.length && (
                          <tr>
                            <td colSpan={100}>
                              <p className="text-center text-gray-600">{t('no-data')}</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
              <button
                type="submit"
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                disabled={pricesDontMatch || disabled}
              >
                {t('save-changes')}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        visible={visible}
        title={t('new-transaction')}
        handleClose={() => setVisible(false)}
      >
        <NewTransactionForm
          onSubmit={(transaction) => {
            setFieldValue('transactions', [...values.transactions, transaction]);
          }}
          handleClose={setVisible}
        />
      </Modal>
    </>
  );
}

function NewTransactionForm({
  onSubmit,
  handleClose
}: {
  onSubmit: (values: any) => void;
  handleClose: (value: boolean) => void;
}) {
  const [accounts, setAccounts] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAccounts();
  }, []);

  function getAccounts() {
    prepareRequest(
      {
        url: 'accounts',
        params: {
          is_active: 1,
          page: 1,
          is_details: 1
        }
      },
      (data, error) => {
        setAccounts(() => data.result?.accounts?.data || []);
      }
    );
  }

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: {
      account_id: undefined,
      debit: undefined,
      credit: undefined
    },
    submitHandler(values, formikHelpers) {
      const account = accounts.find((acc: any) => acc.id == values.account_id);
      const data = { ...values, account };

      if (!values.account_id && !(values.debit || values.credit)) {
        generateAlert('Debit/Credit value or account should be selected.', 'error');
        return;
      }
      onSubmit?.(data);
      handleClose(false);
    }
  });

  const disabled = useMemo(() => {
    return !(!!values.debit || !!values.credit) && !values.account_id;
  }, [values]);
  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <p className="form-label">{t('account')}</p>
        <Select
          type="single"
          options={accounts}
          optionTxt="name"
          optionValue="id"
          onSelect={(value) => {
            setFieldValue('account_id', value);
          }}
          value={values.account_id}
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('debit')}</p>
        <input
          type="number"
          name="debit"
          id="debit"
          step="any"
          value={values.debit}
          onChange={handleChange}
          disabled={!!values.credit}
          className="form-input form-outline"
          required={!values.credit}
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('credit')}</p>
        <input
          type="number"
          name="credit"
          id="credit"
          step="any"
          value={values.credit}
          disabled={!!values.debit}
          required={!values.debit}
          onChange={handleChange}
          className="form-input form-outline"
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('notes')}</p>
        <textarea
          name="notes"
          id="notes"
          value={values.notes}
          onChange={handleChange}
          className="form-textarea form-outline"
        ></textarea>
      </div>
      <button
        type="submit"
        className="btn-with-icon bg-blue-600 !text-white shrink-0"
        disabled={disabled}
      >
        {t('submit')}
      </button>
    </form>
  );
}
