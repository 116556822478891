import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

const WastagesManagement: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('bookings/wastage_tracks', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.wastage_tracks;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('product')}</th>
              <th>{t('wastage')}</th>
              <th>{t('wastage-value')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.item?.name}</td>
                    <td>{item.wastage?.name}</td>
                    <td>{item.value || 0}</td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </button>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  value: string | undefined;
  product_id: string | undefined;
  wastage_id: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    value: undefined,
    product_id: undefined,
    wastage_id: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<any[]>([]);
  const [wastages, setWastages] = React.useState<any[]>([]);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('items/add_wastage', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    getProducts();
    getWastages();
  }, []);

  function getProducts(q?: string) {
    prepareRequest(
      {
        url: 'items',
        params: {
          is_active: 1,
          page: 1,
          item_type: 'product',
          search_key: q
        }
      },
      (data) => {
        setProducts(() => data.result?.items?.data);
      }
    );
  }

  function getWastages(q?: string) {
    prepareRequest(
      {
        url: 'wastages',
        params: {
          is_active: 1,
          page: 1,
          search_key: q
        }
      },
      (data) => {
        setWastages(() => data.result?.wastages?.data);
      }
    );
  }

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('product')}</label>
        <Select
          type="single"
          options={products}
          optionTxt="name"
          optionValue="id"
          onSelect={(value) => setFieldValue('product_id', value)}
          onSearchChange={(ev) => getProducts(ev.target.value)}
          value={values.product_id}
        />
        {errors?.product_id ? <span className="form-error">{errors?.product_id}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('wastage')}</label>
        <Select
          type="single"
          options={wastages}
          optionTxt="name"
          optionValue="id"
          onSelect={(value) => setFieldValue('wastage_id', value)}
          onSearchChange={(ev) => getWastages(ev.target.value)}
          value={values.wastage_id}
        />
        {errors?.wastage_id ? <span className="form-error">{errors?.wastage_id}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('wastage-value')}</label>
        <input
          type="number"
          placeholder="..."
          min={0}
          className="form-input form-outline"
          value={values.value}
          name="value"
          onChange={handleChange}
        />
        {errors?.value ? <span className="form-error">{errors?.value}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

export default WastagesManagement;

