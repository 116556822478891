import { useTranslation } from 'react-i18next';

interface CompleteCardProps {
  count: number;
  total: number;
}

const CompleteCard = ({ count, total }: CompleteCardProps) => {
  const circumference = 2 * Math.PI * 25;
  const current = count ? (count / total) * 100 : 0;

  const { t } = useTranslation();

  return (
    <div className="flex gap-3 bg-white p-3 rounded-lg text-[#9c9ab2]">
      <div className="relative flex items-center justify-center">
        <svg
          className="transform -rotate-90"
          width={60}
          height={60}
        >
          <circle
            cx="30"
            cy="29.23"
            r="25"
            stroke="#E9EBF3"
            strokeWidth="4"
            fill="transparent"
            className="text-gray-700"
          />

          <circle
            cx="30"
            cy="29.23"
            r="25"
            stroke="#F6C2BA"
            strokeWidth="4"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - (count / total) * circumference}
          />
        </svg>

        <span className="absolute text-sm">
          {count}/{total}
        </span>
      </div>

      <div className="flex flex-1 flex-col gap-3">
        <p>{t('home-complete-card')}</p>
        <div className="bg-gray-200 w-[70%] h-[8px] overflow-hidden">
          <hr
            className="bg-[#F6C2BA] h-full transition-all duration-300 ease-in-out"
            style={{ width: `${current}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteCard;

