import { serialize } from 'object-to-formdata';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import InputFile from 'src/components/shared/InputFile';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

export default function AddProduct() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({
    item_type: 'product'
  });
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'items/add',
          method: 'post',
          data: serialize(values)
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group col-span-full">
                <label
                  htmlFor="image"
                  className="form-label"
                >
                  {t('image')}
                </label>
                <InputFile
                  defaultValue={values.image}
                  onValueChange={function (e: any): void {
                    setFieldValue('image', e);
                  }}
                  accept="image/*"
                />
                <span className="form-error">{errors?.image}</span>
              </div>
              <div className="form-group col-span-full">
                <label
                  htmlFor="name"
                  className="form-label"
                >
                  {t('name')}
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-input form-outline"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  required
                />

                <p className="form-error">{errors?.['name']}</p>
              </div>
              <div className="form-group">
                <label
                  htmlFor="price"
                  className="form-label"
                >
                  {t('price')}
                </label>
                <input
                  type="number"
                  id="price"
                  className="form-input form-outline"
                  value={values.price}
                  onChange={handleChange}
                  name="price"
                  required
                />

                <p className="form-error">{errors?.['price']}</p>
              </div>
              <div className="form-group">
                <label
                  htmlFor="price"
                  className="form-label"
                >
                  {t('cost')}
                </label>
                <input
                  type="number"
                  id="cost"
                  className="form-input form-outline"
                  value={values.cost}
                  onChange={handleChange}
                  name="cost"
                  required
                />

                <p className="form-error">{errors?.['cost']}</p>
              </div>
            </div>
          </div>
        </Card>
        <div className="inline-flex items-center gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary shrink-0"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </div>
      </form>
    </div>
  );
}

