import { pick } from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExportOptions, { downloadFile } from 'src/components/shared/ExportOptions';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import ReportsWrapper, { defaultFilterDate } from '../../../components/reports/ReportsWrapper';

const index: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });
  const [filter, setFilter] = React.useState<any>({});

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const paginate = pick(paginates, ['page', 'search_key', 'from', 'to']);

      const { data } = await axiosInstance.get('reports/incomes', {
        params: { ...defaultFilterDate, ...paginate }
      });
      // const { data: items, pagination: responsePaginate } = data?.result?.incomes;
      // console.log(data);
      // setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(data.result?.incomes || []);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('date')}</th>
              <th>{t('services')}</th>
              <th>{t('products')}</th>
              <th>{t('packages-offers')}</th>
              <th>{t('center-commissions')}</th>
              <th>{t('employee-commissions')}</th>
              <th>{t('payments')}</th>
              <th>{t('vat')}</th>
              <th>{t('extra-discount')}</th>
              <th>{t('total')}</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <SharedTime
                        date={item.date}
                        format="dddd, ll"
                      />
                    </td>

                    <td>
                      {CurrencyFormatter(item.services?.total || 0)} - ({item.services?.count || 0})
                    </td>
                    <td>
                      {CurrencyFormatter(item.products?.total || 0)} - ({item.products?.count || 0})
                    </td>
                    <td>
                      {CurrencyFormatter(item.packages_offers?.total || 0)} - (
                      {item.packages_offers?.count || 0})
                    </td>
                    <td>{CurrencyFormatter(item.total_center_commissions || 0)}</td>
                    <td>{CurrencyFormatter(item.total_employee_commissions || 0)}</td>
                    <td>{CurrencyFormatter(item.total_payments || 0)}</td>
                    <td>{CurrencyFormatter(item.total_vat || 0)}</td>
                    <td>{CurrencyFormatter(item.total_extra_discount || 0)}</td>
                    <td>{CurrencyFormatter(item.total_total || 0)}</td>
                    <td>
                      <CloseSession date={item.date} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="reports/incomes/export_excel"
                cvsPathname="reports/incomes/export_csv"
                pathname="reports/incomes/send_excel"
                pdfPathname="reports/incomes/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="monthly"
          onChange={({ from, to }) => {
            GetItems({
              from: moment(from).format('yyyy-MM-DD'),
              to: moment(to).format('yyyy-MM-DD')
            });
          }}
        />
      </div>
      <div className="grid">{MEMO_TABLE}</div>
    </ReportsWrapper>
  );
};

function CloseSession({ date }: { date: string }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({
    initialValues: {
      with_booking_items: ''
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'bookings/close_session',
          method: 'post',
          responseType: 'blob',
          params: {
            ...values,
            date
          }
        },
        (data, error) => {
          downloadFile('pdf', data);
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <>
      <button
        className="btn-with-icon !text-gray-600 !bg-gray-100"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('close-session')}</span>
      </button>
      <Modal
        title={t('close-session')}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <select
              className="form-select form-outline"
              defaultValue=""
              name="with_booking_items"
              onChange={handleChange}
              value={values.with_booking_items}
            >
              <option
                value=""
                disabled
              >
                {t('select')}
              </option>
              <option value="1">{t('with-items')}</option>
              <option value="0">{t('without-items')}</option>
            </select>
          </div>
          <button
            className="btn-with-icon  !bg-primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </form>
      </Modal>
    </>
  );
}

export default index;

