import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FAQSQuestionCard from 'src/components/FAQS/FAQSQuestionCard';
import Card from 'src/components/shared/Card';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

const initialValues = {
  question: '',
  alt_question: '',
  answer: '',
  alt_answer: ''
};

const AddFAQS = () => {
  const [faqs, setFaqs] = useState<any[]>([]);
  const { t } = useTranslation();

  function getFaqs() {
    prepareRequest(
      {
        url: '/faqs'
      },
      (data) => {
        setFaqs(() => data.result.faqs);
      }
    );
  }

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        {faqs.map((item, index) => (
          <FAQSQuestionCard
            item={item}
            key={index}
            refetching={getFaqs}
          />
        ))}
      </div>

      <AddFAQSForm refetching={getFaqs} />
    </div>
  );
};

export default AddFAQS;

function AddFAQSForm({ refetching }: { refetching: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: '/faqs/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetching();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="form-label">{t('question')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.question}
                  name={'question'}
                  onChange={handleChange}
                />
                <span className="form-error">{errors['question']}</span>
              </div>

              <div className="form-group">
                <label className="form-label">{t('alt_question')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.alt_question}
                  name={'alt_question'}
                  onChange={handleChange}
                />
                <span className="form-error">{errors['alt_question']}</span>
              </div>

              <div className="form-group">
                <label className="form-label">{t('answer')}</label>

                <textarea
                  autoComplete="off"
                  placeholder="..."
                  className="form-textarea form-outline"
                  value={values.answer}
                  onChange={handleChange}
                ></textarea>

                {errors?.answer ? <span className="form-error">{errors?.answer}</span> : null}
              </div>

              <div className="form-group">
                <label className="form-label">{t('alt_answer')}</label>

                <textarea
                  autoComplete="off"
                  placeholder="..."
                  className="form-textarea form-outline"
                  value={values.alt_answer}
                  onChange={handleChange}
                ></textarea>

                {errors?.alt_answer ? (
                  <span className="form-error">{errors?.alt_answer}</span>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
        <div className="inline-flex items-center gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary shrink-0"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </div>
      </form>
    </div>
  );
}

