import React, { FC, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import InputFile from 'src/components/shared/InputFile';
import useForm from 'src/hooks/useForm';
import Alert from 'src/components/shared/Alert';
import axiosInstance from 'src/helper/AxiosInstance';
import { AxiosError } from 'axios';
import { pick, values } from 'lodash';
import Image from 'src/components/shared/Image';
import DotStatus from 'src/components/shared/DotStatus';
import Table from 'src/components/shared/tables/Table';
import SharedTime from 'src/components/shared/SharedTime';
import { Link, useSearchParams } from 'react-router-dom';
import TomComponent from 'src/components/shared/TomComponent';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import Breadcrumbs from 'src/components/shared/Breadcrumbs';
import Select from 'src/components/shared/Select';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Delete from 'src/components/shared/Delete';
import prepareRequest from 'src/helper/prepareRequest';
import FilterTable from 'src/components/shared/FilterTable';
import { filterProps } from 'framer-motion';
import AddFormBody from 'src/components/shared/items/AddForm';
import UpdateFormBody from 'src/components/shared/items/UpdateForm';
import { useTranslation } from 'react-i18next';
import ExportOptions from 'src/components/shared/ExportOptions';

export default function index() {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const [filter, setFilter] = React.useState<any>({});

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
      console.log(searchParams.values().next(), searchParams.entries().next());
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { ...paginate } = pick(paginates, ['page', 'search_key']);
      const type = searchParams.get('type');
      const order_type = ['refund', 'purchase'].includes(type as string) ? type : 'purchase';

      const { data } = await axiosInstance.get('orders', {
        params: { ...paginate, order_type }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.orders;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('address')}</th>
              <th>{t('products')}</th>
              <th>{t('vat')}</th>
              <th>{t('subtotal')}</th>
              <th>{t('total')}</th>
              <th>{t('status')}</th>
              <th>{t('payment-status')}</th>
              <th>{t('created-date')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span>{item.supplier?.name || item.supplier_name || '-'}</span>
                      <br />
                      <span>{item.supplier?.mobile || item.supplier_mobile || '-'}</span>
                    </td>
                    <td>{item.center?.name || '-'}</td>
                    <td>{item.items?.length || 0}</td>
                    <td>{CurrencyFormatter(item.vat || 0)}</td>
                    <td>{CurrencyFormatter(item.sub_total || 0)}</td>
                    <td>{CurrencyFormatter(item.total || 0)}</td>

                    <td>{t('statuses.' + item.status)}</td>
                    <td>{item.is_paid ? t('paid') : t('unpaid')}</td>
                    <td>
                      <SharedTime date={item.created_at} />
                    </td>
                    <td>
                      <Link
                        to={'/settings/inventory/purchase-orders/' + item.id}
                        className="btn-with-icon max-w-fit bg-blue-500 "
                      >
                        <Icon
                          icon="gg:eye"
                          width="16"
                        />
                        <span>{t('view-details')}</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <Link
                to="/settings/inventory/purchase-orders/new"
                className="btn-with-icon !text-gray-600"
              >
                <Icon
                  icon="ic:baseline-plus"
                  width="16"
                />
                <span>{t('add-new')}</span>
              </Link>
              <ExportOptions
                excelPathname="orders/export_excel"
                cvsPathname="orders/export_csv"
                pathname="orders/send_excel"
                pdfPathname="orders/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </React.Fragment>
  );
}

