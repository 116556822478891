import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PluginChartOptions,
  ChartData
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CoreChartOptions } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { PluginOptionsByType } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, ChartDataLabels);

interface IProps {
  data: ChartData<'bar'>;
  options?: any;
  optionsPlugins?: _DeepPartialObject<PluginOptionsByType<'bar'>>;
}

export default function SimpleBar({ data, options, optionsPlugins }: IProps) {
  return (
    <Bar
      options={{
        responsive: true,

        plugins: {
          datalabels: {
            align: 'center',
            anchor: 'center',
            rotation: 90,
            offset: 15,
            clamp: true,
            color: '#000',
            display: 'auto'
          },

          ...optionsPlugins
        },
        ...options
      }}
      data={data}
      className="max-h-96 !w-full"
    />
  );
}
