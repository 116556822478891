import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportsWrapper, { defaultFilterDate } from 'src/components/reports/ReportsWrapper';
import Card from 'src/components/shared/Card';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import ItemList from 'src/components/shared/ItemList';
import Title from 'src/components/shared/Title';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';

function useHooks() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<any>({});
  const [charts, setCharts] = useState<any>({});

  const getSummary = (params?: any) =>
    prepareRequest(
      {
        url: 'reports/summary',
        params: {
          ...defaultFilterDate,
          ...params
        }
      },
      (data) => {
        setSummary(data.result);
      }
    );
  const getCharts = (params?: any) =>
    prepareRequest(
      {
        url: 'reports/charts',
        params: {
          ...defaultFilterDate,
          ...params
        }
      },
      (data) => {
        setCharts(data.result);
      }
    );

  return { isLoading, summary, charts, getSummary, getCharts };
}

export default function index() {
  const { summary, charts, getSummary, getCharts } = useHooks();
  const { t } = useTranslation();

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="monthly"
          onChange={({ from, to }) => {
            getSummary({
              from: moment(from).format('yyyy-MM-DD'),
              to: moment(to).format('yyyy-MM-DD')
            });
            getCharts({
              from: moment(from).format('yyyy-MM-DD'),
              to: moment(to).format('yyyy-MM-DD')
            });
          }}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 whitespace-normal">
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <IncomesCard item={summary.income_profits || {}} />
        </div>
        <PaymentsCard item={charts.cashier || {}} />
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <CallCentersCard data={summary.marketers || []} />
          <ExpertsCard data={charts.experts || []} />
        </div>
        <BookingsCard item={summary.bookings || {}} />
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <TopServicesCard data={charts.top_services || []} />
          <LowestServicesCard data={charts.lowest_services || []} />
        </div>
        <ServicesCard item={summary.services || {}} />

        <div className="sm:col-span-2">
          <ClientsCard data={charts.top_clients || []} />
        </div>
        <EmployeesCard item={summary.employees || {}} />
      </div>
    </ReportsWrapper>
  );
}

function BookingsCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('reservations')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-reservations')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('incomplete-reservations')} />
            <Title title={item.incompleted?.count || 0} />
            <Title
              title={CurrencyFormatter(item.incompleted?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-reservations')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function ServicesCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-services')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('incomplete-services')} />
            <Title title={item.incompleted?.count || 0} />
            <Title
              title={CurrencyFormatter(item.incompleted?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-services')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function InventoryCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('inventory')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('available')} />

            <Title title={item.available?.count || 0} />
            <Title
              title={CurrencyFormatter(item.available?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('low-stock')} />

            <Title title={item.lowest_stock?.count || 0} />
            <Title
              title={CurrencyFormatter(item.lowest_stock?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('out-off-stock')} />

            <Title title={item.out_off_stock?.count || 0} />
            <Title
              title={CurrencyFormatter(item.out_off_stock?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function PackagesOffers({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('upcoming-packages-offers')} />
            <Title title={item.upcoming?.count || 0} />
            <Title
              title={CurrencyFormatter(item.upcoming?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('opened-packages-offers')} />
            <Title title={item.opened?.count || 0} />
            <Title
              title={CurrencyFormatter(item.opened?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-packages-offers')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('closed-packages-offers')} />
            <Title title={item.invoiced?.count || 0} />
            <Title
              title={CurrencyFormatter(item.invoiced?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-packages-offers')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function EmployeesCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <Title
        className="border-b border-b-gray-200 pb-4 font-bold !text-gray-800 -mx-4 px-4"
        title={t('employees')}
      />
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('management')} />
            <Title
              title={item.admins_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('cashiers')} />
            <Title
              title={item.cashiers_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('experts')} />
            <Title
              title={item.experts_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('call-centers')} />
            <Title
              title={item.marketers_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function CashiersTable({ data }: any) {
  const { t } = useTranslation();

  return (
    <Table
      RenderHead={() => (
        <tr>
          <th>{t('name')}</th>
          <th>{t('code')}</th>
          <th>{t('reservations')}</th>
          <th>{t('pay-average')}</th>
          <th>{t('card-amount')}</th>
          <th>{t('cash-amount')}</th>
          <th>{t('bank-transfer-amount')}</th>
        </tr>
      )}
      RenderBody={() =>
        data?.map((item: any) => (
          <tr key={item.id}>
            <td>
              {item.name || '-'}
              <br />
              {item.mobile || '-'}
            </td>
            <td>{item.code || '-'}</td>
            <td>
              {CurrencyFormatter(item.bookings?.total || 0)} - ({item.bookings?.count || 0})
            </td>
            <td>{CurrencyFormatter(item.average || 0)}</td>
            <td>{CurrencyFormatter((item.payments?.mada || 0) + (item.payments?.visa || 0))}</td>
            <td>{CurrencyFormatter(item.payments?.cash || 0)}</td>
            <td>{CurrencyFormatter(item.payments?.bank_transfer || 0)}</td>
          </tr>
        ))
      }
    />
  );
}

function PaymentsCard({ item }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('payments')}
            />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('cash')} />
            <Title
              title={CurrencyFormatter(item.cash || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('mada')} />
            <Title
              title={CurrencyFormatter(item.mada || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('visa')} />
            <Title
              title={CurrencyFormatter(item.visa || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('bank_transfer')} />
            <Title
              title={CurrencyFormatter(item.bank_transfer || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function IncomesCard({ item }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('sales')}
            />

            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('services')} />
            <Title
              title={CurrencyFormatter(item.services?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('products')} />
            <Title
              title={CurrencyFormatter(item.products?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('total')} />
            <Title
              title={CurrencyFormatter(item.profit || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function ClientsCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-clients')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function TopServicesCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function LowestServicesCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('low-services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function TopPackagesOffersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function LowestPackagesOffersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('low-packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function CallCentersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('call-centers')}
            />
            <Title
              title={t('commission')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-2 gap-2">
              <Title title={item.name || '-'} />
              <Title
                title={CurrencyFormatter(item.total_commission || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function CashiersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('cashiers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.bookings?.count || 0} />
              <Title
                title={CurrencyFormatter(item.bookings?.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function ExpertsCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('experts')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}
