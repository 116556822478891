import { Disclosure } from '@headlessui/react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PrintPDF from 'src/components/invoices/PrintPDF';
import Alert from 'src/components/shared/Alert';
import Card from 'src/components/shared/Card';
import Controllable from 'src/components/shared/Controllable';
import DisplayPayments from 'src/components/shared/DisplayPaymentsForm';
import { downloadFile } from 'src/components/shared/ExportOptions';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import NotConfigureCard from 'src/components/shared/NotConfigureCard';
import Pagination from 'src/components/shared/Pagination';
import SearchBox from 'src/components/shared/SearchBox';
import Select from 'src/components/shared/Select';
import Title from 'src/components/shared/Title';
import SlideUpDown from 'src/components/shared/animations/SlideUpDown';
import { ItemType, itemTypes } from 'src/components/shared/items/AddForm';
import AddForm from 'src/components/shared/users/AddForm';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

type PaymentOption = 'cash' | 'mada' | 'bank_transfer';

type PaymentItem = {
  payment_option: PaymentOption;
  amount: number | undefined;
  ref_no: string | undefined;
};
interface AddForm {
  id?: string | undefined;
  client_id: string | undefined;
  center_id: string | undefined;
  items: any[];
  item_ids: string[];
  payments: Array<PaymentItem>;
  extra_discount_type: string;
  extra_discount_value: string | undefined;
  booking_time: Date | string | undefined;
  online_payment?: number;
  payment_option?: PaymentOption | 'visa';
}

function useHooks() {
  let render = true;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [centers, setCenters] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);
  const [marketers, setMarketers] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ item_type: 'service', page: '1', is_active: '1' });

  useEffect(() => {
    if (!render) return;
    Promise.all([
      getItems(),
      getCategories(),
      getEmployees(),
      getClients(),
      getMarketers(),
      getCenters()
    ]).finally(() => {
      setIsLoading(false);
      render = false;
    });
  }, []);

  async function getItems() {
    try {
      const { data } = await axiosInstance.get('items', {
        params
      });
      const result = data.result?.items?.data;
      const paginate = data.result?.items?.pagination;

      setItems(
        result.map((e: any) => ({
          ...e,
          service: e
        }))
      );
      setPagination(paginate);
      setHasMore(paginate?.count < paginate?.total);
    } catch (error) {}
  }

  async function getCategories() {
    try {
      const { data } = await axiosInstance.get('categories', { params: { is_active: 1 } });
      const result = data.result?.categories;

      setCategories(result);
    } catch (error) {}
  }
  async function getCenters() {
    try {
      const { data } = await axiosInstance.get('centers', { params: { is_active: 1 } });
      const result = data.result?.centers;

      setCenters(result);
    } catch (error) {}
  }
  async function getEmployees() {
    try {
      const { data } = await axiosInstance.get('users', {
        params: { user_type: 'employee', is_active: 1, page: 1 }
      });
      const users = data.result?.users?.data || [];

      setEmployees(users);
    } catch (error) {}
  }
  async function getClients(search?: string) {
    try {
      const { data } = await axiosInstance.get('users', {
        params: { user_type: 'client', is_active: 1, page: 1, search_key: search }
      });
      const users = data.result?.users?.data || [];

      setClients(
        users.map((e: any) => ({
          ...e,
          label: [e.name, '( ' + e.mobile + ' )'].join(' ')
        }))
      );
    } catch (error) {}
  }

  async function getMarketers(search?: string) {
    try {
      const { data } = await axiosInstance.get('users', {
        params: { user_type: 'marketer', is_active: 1, page: 1, search_key: search }
      });
      const users = data.result?.users?.data || [];

      setMarketers(
        users.map((e: any) => ({
          ...e,
          label: [e.name, '( ' + e.code + ' )'].join(' ')
        }))
      );
    } catch (error) {}
  }

  return {
    isLoading,
    items,
    getItems,
    hasMore,
    categories,
    centers,
    clients,
    employees,
    marketers,
    getClients,
    getMarketers,
    pagination,
    params,
    setParams
  };
}

export default function index() {
  const {
    isLoading,
    items,
    getItems,
    hasMore,
    categories,
    centers,
    clients,
    employees,
    marketers,
    getClients,
    getMarketers,
    pagination,
    params,

    setParams
  } = useHooks();

  const [initialValues, setInitialValues] = useState<AddForm>({
    client_id: undefined,
    center_id: undefined,
    payments: [],
    items: [],
    item_ids: [],
    extra_discount_type: 'fixed',
    extra_discount_value: undefined,
    booking_time: undefined,
    online_payment: 0,
    payment_option: 'visa'
  });

  const [disabled, setDisabled] = useState<boolean>(false);
  const [newClient, setNewClient] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<boolean>(false);
  const [todayBookingsVisible, setTodayBookingsVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const { user, isSingleApp } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [paymentForm, setPaymentForm] = useState<any | null>(null);

  const QRRef = useRef<HTMLButtonElement | null>(null);
  const [result, setResult] = useState<any>();

  const placeholders: Record<ItemType, string> = {
    service: t('searches.services'),
    product: t('searches.products'),
    package_offer: t('searches.packages-offers')
  };

  async function submitHandler(form: any, helper: any) {
    setDisabled(true);
    setErrors({});

    const items = new Array().concat(
      ...form.items?.map((item: any) => {
        const { qty, id, object_id, employee_id, variant, option_ids } = item;

        if (qty > 1) {
          return Array.from({ length: qty }).fill({
            id: object_id,
            item_id: id,
            employee_id,
            variant,
            option_ids
          });
        }

        return {
          id: object_id,
          item_id: id,
          employee_id,
          variant,
          option_ids
        };
      })
    );

    const data = {
      ...form,
      items,
      ...(form.booking_time && {
        booking_time: moment(new Date(form.booking_time)).format('yyyy-MM-DD HH:mm')
      }),
      center_id: user.center?.id,
      ...(form.online_payment
        ? {
            payment_option: form.payment_option,
            payment_amount: form.payment_amount
          }
        : { payment_option: undefined, payment_amount: undefined })
    };

    prepareRequest(
      {
        url: 'bookings/new',
        data: data,
        method: 'post'
      },
      (data, error) => {
        if (error) {
          if (error['payments.0.amount']) {
            generateAlert(t('payment-not-complete-error'), 'error');
          }

          setErrors(error);
          return;
        }

        const { booking, checkout_id, checkout_url, payment_option } = data?.result || {};

        if (checkout_url) {
          setPaymentForm({
            checkout_id,
            checkout_url,
            payment_option
          });

          //  display payment form iframe in current page;
          const script = document.createElement('script');
          const body = document.querySelector('body');
          script.src = checkout_url;
          body?.append(script);

          return;
        }

        generateAlert(data.message, 'success');
        helper.resetForm();
        setNewClient(false);
        setExtraData(false);
        setTodayBookingsVisible(false);
        setErrors({});

        if (!!user?.global_settings?.enable_cashier_print) {
          setResult(() => booking);

          setTimeout(() => {
            QRRef.current?.click();
            setResult({});
          }, 250);
        }
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const {
    formik: { values, handleChange, setFieldValue, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  const getTotal = useMemo(() => {
    let total = 0;
    const allItems = values.items;

    for (const item of allItems) {
      const selectedOption = item?.option_ids
        ? item.service?.options?.filter((e: any) => item?.option_ids?.includes(e.id))
        : [];

      const selectedOptionPrice = selectedOption?.reduce((acc: number, e: any) => acc + e.price, 0);

      total += Number(item?.service?.price + (selectedOptionPrice || 0)) * Number(item?.qty || 0);
    }

    return total;
  }, [values.items]);

  const getDiscountTotal = useMemo(() => {
    let total = 0;
    const { extra_discount_type = 'fixed', extra_discount_value } = values;
    const amount = getTotal;
    total =
      extra_discount_type === 'fixed'
        ? amount - Number(extra_discount_value || 0)
        : amount - amount * Number((extra_discount_value || 0) / 100);
    return total;
  }, [values.extra_discount_type, values.extra_discount_value, getTotal]);

  const onItemsChange = useMemo(() => {
    const mapItems = values.item_ids.map((id: any) => {
      const service = items.find((e: any) => e.id == id);
      const findService = values.items.find((e: any) => e.id == id);
      if (findService) return findService;
      else
        return {
          id,
          marketer_code: undefined,
          employee_id: undefined,
          service,
          qty: 1
        };
    });

    setFieldValue('items', mapItems);
  }, [values.item_ids, items]);

  const DISPLAY_SERVICES = useMemo(() => {
    return (
      <>
        {items?.map((item: any) => (
          <DisplayItemsCard
            key={item.id}
            item={item}
            onChange={setFieldValue}
            selectedValue={values.item_ids}
            items={values.items}
            employees={employees}
            errors={errors}
          />
        ))}
      </>
    );
  }, [items, values.items]);

  if (isLoading) return <LoadingComponent />;

  if (
    // !(
    //   user.summary?.services_count &&
    //   (user.summary?.products_count || user.summary?.package_offers_count)
    // )

    !(
      user.summary?.services_count ||
      user.summary?.products_count ||
      user.summary?.package_offers_count
    )
  )
    return (
      <div>
        <NotConfigureCard
          title={t('pos-not-ready')}
          desc={t('pos-not-ready-content')}
        />
      </div>
    );

  return (
    <>
      <div className="hidden lg:flex  w-full">
        <div className="flex-1 space-y-4 p-6 flex flex-col">
          <div className="space-y-2">
            <ul className="flex items-center overflow-x-auto justify-center gap-2 pb-4">
              {itemTypes
                .filter((type) => {
                  switch (type.key) {
                    case 'product':
                      return user?.summary?.products_count > 0;
                    case 'service':
                      return user?.summary?.services_count > 0;
                    case 'package_offer':
                      return user?.summary?.package_offers_count > 0;
                    default:
                      return false;
                  }
                })
                .map((type: any, index: number) => (
                  <li key={index}>
                    <input
                      type="radio"
                      hidden
                      className="peer"
                      name="types"
                      id={type.key}
                      value={type.key}
                      checked={type.key === params.get('item_type')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setParams((param) => {
                          param.set('item_type', (e.target as any).value);
                          param.set('page', '1');
                          return param;
                        });
                        getItems();
                      }}
                    />
                    <label
                      htmlFor={type.key}
                      className="btn-with-icon bg-gray-100 !text-gray-500 peer-checked:bg-primary peer-checked:!text-white !rounded-full transition-all !px-4 cursor-pointer capitalize"
                    >
                      {t(type.label)}
                    </label>
                  </li>
                ))}
            </ul>
            <SearchBox
              value={params.get('search_key') as string}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setParams((param) => {
                  params.set('search_key', (e.target as any).value);
                  param.set('page', '1');
                  return param;
                });

                getItems();
              }}
              className="w-full"
              placeholder={placeholders[params.get('item_type') as ItemType]}
            >
              <button
                type="button"
                className="btn-with-icon !p-0 !text-blue-600 !text-xs !font-semibold"
                onClick={() => setTodayBookingsVisible(true)}
              >
                <span>{t('today-reservations')}</span>
              </button>
            </SearchBox>
            <div className="flex gap-3 items-center">
              <select
                name="category_id"
                id="category_id"
                className="form-select form-outline flex-1 shrink-0"
                defaultValue=""
                value={params.get('category_id') as string}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setParams((param) => {
                    params.set('category_id', (e.target as any).value);
                    param.set('page', '1');
                    return param;
                  });

                  getItems();
                }}
              >
                <option
                  value=""
                  disabled
                >
                  {t('select')}
                </option>
                {categories.map((category: any) => (
                  <option value={category.id}>{category.name}</option>
                ))}
              </select>
              <button
                className="shrink-0 btn-with-icon !p-0 !text-gray-600 hover:underline focus:underline"
                type="button"
                onClick={() => {
                  setParams((param) => {
                    param.set('search_key', '');
                    param.set('item_type', 'service');
                    param.set('category_id', '');
                    param.set('page', '1');
                    return param;
                  });
                  getItems();
                }}
              >
                <span>{t('clear')}</span>
              </button>
            </div>
          </div>

          {errors.items && <p className="form-error">{errors.items}</p>}

          <div className="grid grid-cols-[repeat(auto-fill,minmax(15em,1fr))] gap-3">
            {DISPLAY_SERVICES}

            {!items?.length && (
              <p className="text-sm text-center text-gray-500 col-span-full">{t('no-data')}</p>
            )}
          </div>

          <div>
            <Pagination
              pagination={pagination}
              onNextClick={
                () => {
                  setParams((param) => {
                    params.set(
                      'page',
                      (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
                    );

                    return param;
                  });

                  getItems();
                }
                // getItems(pagination?.current_page + 1 || 1, search, categoryId, resultType)
              }
              onPreviousClick={
                () => {
                  setParams((param) => {
                    params.set(
                      'page',
                      (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
                    );

                    return param;
                  });

                  getItems();
                }
                // getItems(pagination?.current_page - 1 || 1, search, categoryId, resultType)
              }
            />
          </div>
        </div>

        <div className="flex-1 max-w-sm  border-s border-s-gray-200 relative">
          <div className="w-full space-y-4 sticky top-20 flex flex-col max-h-[calc(100vh-5rem)] p-6 overflow-y-auto">
            <button
              type="button"
              className="btn-with-icon !bg-gray-100 !rounded-full !text-gray-500 !max-w-fit ms-auto"
              onClick={() => window.location.reload()}
            >
              <Icon
                icon="ci:arrow-reload-02"
                width="18"
              />
              <span>{t('refresh')}</span>
            </button>

            <Card className="!bg-gray-50 p-6 space-y-4">
              <p className="text-xl font-bold text-gray-800">{t('client-details')}</p>
              <div className="space-y-2">
                {values.client ? (
                  <Card className="space-y-1">
                    <Title
                      title={values.client?.name || 'N/A'}
                      className="text-gray-800 font-semibold"
                    />
                    <Title title={values.client?.mobile || 'N/A'} />
                  </Card>
                ) : (
                  <div className="form-group">
                    <div className="flex items-center justify-between gap-2">
                      <p className="form-label">{t('client')}</p>
                      <button
                        type="button"
                        className="inline-flex gap-1 items-center  text-teal-600 text-xs"
                        onClick={() => setNewClient(!newClient)}
                      >
                        <Icon
                          icon="majesticons:plus"
                          width="15"
                        />
                        <span>{t('new-client')}</span>
                      </button>
                    </div>

                    <Select
                      options={clients}
                      value={values.client_id}
                      onSelect={(value) => setFieldValue('client_id', value)}
                      optionTxt="label"
                      optionValue="id"
                      onSearchChange={(ev) => getClients(ev.target.value)}
                    />
                    {errors.client_id && <p className="form-error">{errors.client_id}</p>}
                  </div>
                )}

                {/* {!user?.subscription?.package?.is_default && ( */}
                {isSingleApp && (
                  <div className="form-group">
                    <label className="form-label">{t('call-center')}</label>
                    <Select
                      options={marketers}
                      value={values.marketer_id}
                      onSelect={(value) => setFieldValue('marketer_id', value)}
                      optionTxt="label"
                      optionValue="id"
                      onSearchChange={(ev) => getMarketers(ev.target.value)}
                    />
                    {errors.marketer_id && <p className="form-error">{errors.marketer_id}</p>}
                  </div>
                )}

                <div className="form-group">
                  <div className="flex items-center justify-between gap-2">
                    <label className="form-label">{t('booking-time')}</label>
                    {!values.booking_time ? (
                      <p className="text-xs font-semibold text-gray-600">({t('asap')})</p>
                    ) : null}
                  </div>
                  <input
                    type="datetime-local"
                    className="form-input form-outline"
                    onChange={handleChange}
                    name="booking_time"
                    value={values.booking_time}
                    min={new Date().toISOString().substring(0, 19)}
                  />
                  {errors.booking_time && <p className="form-error">{errors.booking_time}</p>}
                </div>

                <div className="form-group">
                  <label className="form-label">{t('notes')}</label>

                  <textarea
                    className="form-input form-outline"
                    onChange={handleChange}
                    name="notes"
                    value={values.notes}
                    placeholder="..."
                  ></textarea>
                  {errors.notes && <p className="form-error">{errors.notes}</p>}
                </div>

                <Modal
                  title={t('new-client')}
                  visible={newClient}
                  handleClose={setNewClient}
                >
                  <AddForm
                    user_type="client"
                    reFetching={getClients}
                    closeModal={setNewClient}
                  />
                </Modal>
              </div>
            </Card>

            <SlideUpDown visible={!!values.items.length}>
              <div className="grid flex-1">
                <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
                  {values.items?.map((item: any) => (
                    <ItemList
                      className="py-3"
                      key={item?.id}
                    >
                      <ServiceSide
                        item={item}
                        items={values.items}
                        onChange={setFieldValue}
                        employees={employees}
                        onClick={(value: any) => {
                          setParams((param) => {
                            param.set('search_key', value);
                            param.set('page', '1');
                            return param;
                          });
                          getItems();
                        }}
                      />
                    </ItemList>
                  ))}
                </ul>
              </div>
            </SlideUpDown>

            {user.user_type === 'marketer' ? (
              <button
                type="button"
                className="btn-with-icon bg-primary !rounded-full w-full !p-3"
                onClick={() => handleSubmit()}
                disabled={!(values.client_id && values.items?.length)}
              >
                <Icon
                  icon="fluent:payment-20-filled"
                  width="22"
                />

                <span>{t('complete-reservation')}</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn-with-icon bg-primary !rounded-full w-full !p-3"
                onClick={() => setExtraData(true)}
                disabled={!(values.client_id && values.items?.length)}
              >
                <Icon
                  icon="fluent:payment-20-filled"
                  width="22"
                />

                <span>{t('complete-reservation')}</span>
              </button>
            )}

            <p className="text-sm text-center text-gray-500 font-semibold">
              {t('total')} ( {CurrencyFormatter(getTotal)} )
            </p>
          </div>
        </div>

        <Modal
          visible={extraData}
          // visible
          handleClose={() => {
            setExtraData(false);
            setFieldValue('payments', []);
            setFieldValue('extra_discount_value', undefined);
            setFieldValue('extra_discount_type', 'fixed');
          }}
          title={[t('total-amount'), CurrencyFormatter(getTotal)].join(' ')}
          size="!max-w-screen-sm"
        >
          {!!paymentForm?.checkout_id ? (
            <form
              action={
                '/checkout/?payment=' +
                paymentForm?.payment_option +
                '&payment_option=' +
                paymentForm?.payment_option
              }
              className="paymentWidgets"
              data-brands={
                paymentForm?.payment_option === 'visa'
                  ? 'VISA MASTER'
                  : paymentForm?.payment_option === 'mada'
                  ? 'MADA'
                  : ''
              }
            ></form>
          ) : (
            <div className="space-y-4">
              <div className="form-group">
                <p className="form-label">
                  {t('extra-discount')}{' '}
                  {values.extra_discount_type === 'percentage' ? '(%)' : '( ' + t('sar') + ' )'}
                </p>
                <input
                  type="number"
                  placeholder="0.00"
                  step="0.01"
                  name="extra_discount_value"
                  onChange={handleChange}
                  className="form-input form-outline"
                  value={values.extra_discount_value}
                  max={user?.max_discount}
                />
                <div className="flex items-center gap-4 flex-wrap">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      className="form-radio form-outline"
                      value="fixed"
                      id="fixed"
                      name="extra_discount_type"
                      checked={values.extra_discount_type === 'fixed'}
                      onChange={handleChange}
                    />
                    <label
                      className="form-label"
                      htmlFor="fixed"
                    >
                      {t('fixed')}
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      className="form-radio form-outline"
                      value="percentage"
                      id="percentage"
                      name="extra_discount_type"
                      checked={values.extra_discount_type === 'percentage'}
                      onChange={handleChange}
                    />
                    <label
                      className="form-label"
                      htmlFor="percentage"
                    >
                      {t('percentage')}
                    </label>
                  </div>
                </div>
                {errors.extra_discount_value && (
                  <p className="form-error">{errors.extra_discount_value}</p>
                )}
              </div>

              <div className="space-y-4">
                <div className="p-4 rounded-lg bg-white border border-gray-200 space-y-4">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="form-checkbox form-outline"
                      id="online_payment"
                      name="online_payment"
                      checked={values.online_payment === 1}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('online_payment', event.target.checked ? 1 : 0);
                      }}
                    />
                    <label
                      className="form-label"
                      htmlFor="online_payment"
                    >
                      {t('online-pay')}
                    </label>
                  </div>
                </div>
                {!!values.online_payment ? (
                  <>
                    <div className="form-group">
                      <label
                        htmlFor="payment_option"
                        className="form-label"
                      >
                        {t('payment-option')}
                      </label>
                      <div className="flex items-center gap-4 flex-wrap">
                        <div className="flex items-center gap-2">
                          <input
                            type="radio"
                            className="form-radio form-outline"
                            value="visa"
                            id="visa"
                            name="payment_option"
                            checked={values.payment_option === 'visa'}
                            onChange={handleChange}
                          />
                          <label
                            className="form-label"
                            htmlFor="visa"
                          >
                            {t('visa')}
                          </label>
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            type="radio"
                            className="form-radio form-outline"
                            value="mada"
                            id="mada"
                            name="payment_option"
                            checked={values.payment_option === 'mada'}
                            onChange={handleChange}
                          />
                          <label
                            className="form-label"
                            htmlFor="mada"
                          >
                            {t('mada')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="payment_amount"
                        className="form-label"
                      >
                        {t('amount')}
                      </label>
                      <input
                        type="number"
                        name="payment_amount"
                        id="payment_amount"
                        className="form-input form-outline"
                        step="any"
                        placeholder="0.00"
                        onChange={handleChange}
                        value={values.payment_amount}
                      />
                    </div>
                    {errors.payment_amount && <p className="form-error">{errors.payment_amount}</p>}
                    {errors.payment_option && <p className="form-error">{errors.payment_option}</p>}
                  </>
                ) : (
                  <>
                    <DisplayPayments
                      payments={values.payments}
                      onChange={setFieldValue}
                      errors={errors}
                      total={getDiscountTotal}
                    />
                    {errors.payments && <p className="form-error">{errors.payments}</p>}
                  </>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <button
                  className="btn-with-icon bg-primary text-white w-full !rounded-full !p-4"
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={disabled}
                >
                  {disabled ? (
                    <Icon
                      icon="svg-spinners:3-dots-fade"
                      width={20}
                    />
                  ) : (
                    <span>{t('submit')}</span>
                  )}
                </button>

                {/*    <button
                className="btn-with-icon outline-btn w-full !rounded-full"
                type="reset"
                onClick={() => setExtraData(false)}
              >
                <span>{t('cancel')}</span>
              </button> */}
              </div>
            </div>
          )}
        </Modal>

        <Modal
          visible={todayBookingsVisible}
          handleClose={setTodayBookingsVisible}
          size="max-w-screen-xl"
          title={t('today-reservations')}
        >
          <TodayBookings
            handleClose={setTodayBookingsVisible}
            onUpdate={setInitialValues}
          />
        </Modal>
      </div>

      <div className="p-6 block lg:hidden">
        <Alert
          type="info"
          content="You cannot open this page on mobile screens or less than the allowed size of 1024 pixels. If you want to continue, please open that page on a screen larger than the specified size, thank you"
        />
      </div>

      {result ? (
        <div className="fixed bottom-[calc(200vh)]">
          <PrintPDF
            item={result || {}}
            trigger={
              <button
                type="button"
                ref={QRRef}
              ></button>
            }
          />
        </div>
      ) : null}

      <p>{JSON.stringify(result, null, 4)}</p>
    </>
  );
}

function DisplayItemsCard({ item, onChange, selectedValue, items, employees, errors }: any) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const index = useMemo(() => {
    return items.findIndex((e: any) => e.id == item.id);
  }, [items]);

  const onRemoveService = useCallback(() => {
    const filterItems = items.filter((e: any) => e.id !== item.id);
    const filterServiceIds = selectedValue.filter((id: any) => id !== item.id);
    onChange('items', filterItems);
    onChange('item_ids', filterServiceIds);
  }, [items]);

  const DisplayError = useCallback(
    (key: string) => {
      return errors['items.' + index + '.' + key];
    },
    [errors]
  );

  const isSelected = useMemo(() => {
    return items.some((e: any) => e.id == item.id);
  }, [items, selectedValue]);

  const onServiceAdd = useCallback(() => {
    const object = {
      id: item.id,
      variant: undefined,
      options: []
    };

    const concat = [...items, object].map((e: any) => e.id);
    onChange('item_ids', concat);
    generateAlert('( ' + item.service?.name + ' added successfully)', 'success');
  }, [items, selectedValue]);

  const onValueChange = useCallback(
    (value: any) => {
      onChange('items[' + index + '].qty', value);
    },
    [items]
  );

  const selectedItem = useMemo(() => {
    const findItem = items.find((e: any) => e.id == item.id);
    return findItem;
  }, [items]);

  const selectOptionHandler = ({ id }: { id: number }) => {
    if (selectedOption.includes(id)) {
      const idIndex = selectedOption.findIndex((e: any) => e === id);
      setSelectedOption(selectedOption.filter((e: any) => e !== id));

      onChange(`items[${index}].option_ids[${idIndex}]`, null);
    } else {
      // onChange(`items[${index}].option_ids[${}]`, id);
      setSelectedOption([...selectedOption, id]);
      onChange(`items[${index}].option_ids[${selectedOption.length}]`, id);
    }
  };

  return (
    <Card className="!p-0 ">
      <div className="flex flex-col min-h-full">
        {user.center?.show_item_image ? (
          <Image
            className="w-full h-full min-h-min rounded-t-lg object-cover shrink-0"
            src={item.service?.image}
          />
        ) : null}

        <div className="space-y-0.5 flex-1 p-3">
          {!!(item.service?.item_type === 'package_offer' && item.service?.end_date) && (
            <span className="py-1 px-3 rounded-full text-white bg-red-500 font-medium text-xs block max-w-fit">
              {t('expire-time')}: {moment(item.service?.end_date).fromNow()}
            </span>
          )}

          <p className="text-sm font-bold text-gray-800 line-clamp-2">
            {item.service?.name || item.service?.alt_name || 'N/A'}
          </p>

          {!!item?.service?.items?.length ? (
            <>
              <p className="text-sm text-gray-600">
                [ {item?.service?.items?.map((e: any) => e.name).join(' + ')} ]
              </p>
            </>
          ) : null}

          <p className="text-base font-bold text-gray-800">
            <span>{CurrencyFormatter(item.service?.price || 0)}</span>{' '}
          </p>

          {isSelected && item?.service?.options?.length ? (
            <Disclosure>
              <Disclosure.Button className="text-sm text-blue-600 underline hover:no-underline">
                <span>{t('options')}</span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="space-y-2">
                  {/* {item?.service?.variants?.length ? (
                    <>
                      <p className="form-label">{t('variants')}</p>
                      <div className="flex items-center gap-2 flex-wrap">
                        {item?.service?.variants?.map((variant: any) => (
                          <div key={variant.id}>
                            <input
                              type="radio"
                              value={items[index]?.variant}
                              checked={items[index]?.variant == variant.id}
                              onChange={() => onChange('items[' + index + '].variant', variant.id)}
                              hidden
                              className="peer"
                              id={'variant-for-' + variant.id}
                              name={'variants-group-' + item?.service?.id}
                            />
                            <label
                              htmlFor={'variant-for-' + variant.id}
                              className="btn-with-icon bg-gray-100 !text-gray-600 cursor-pointer peer-checked:!text-white peer-checked:bg-primary !text-xs transition-all"
                            >
                              <span>
                                {variant.name} - {CurrencyFormatter(variant.price)}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null} */}

                  <div className="flex items-center gap-2 flex-wrap">
                    {item?.service?.options?.map((option: any, optionIndex: number) => (
                      <div key={option.id}>
                        <input
                          type="checkbox"
                          value={option.id}
                          checked={selectedOption.includes(option.id)}
                          onChange={() =>
                            selectOptionHandler({
                              id: option.id
                            })
                          }
                          hidden
                          className="peer"
                          id={'option-for-' + option.id}
                        />

                        <label
                          htmlFor={'option-for-' + option.id}
                          className="btn-with-icon bg-gray-100 !text-gray-600 cursor-pointer peer-checked:!text-white peer-checked:bg-primary !text-xs transition-all"
                        >
                          <span>
                            {option.name} - {CurrencyFormatter(option.price)}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </Disclosure>
          ) : null}
        </div>

        <div className="flex items-center gap-3 px-3 pb-3">
          <div className="flex-1 shrink-0">
            {isSelected ? (
              <button
                className="btn-with-icon max-w-fit !rounded-full !text-xs bg-red-500 !p-2"
                type="button"
                onClick={onRemoveService}
              >
                <Icon
                  icon="fluent:delete-12-regular"
                  width="18"
                />
              </button>
            ) : (
              <button
                className="btn-with-icon w-full !rounded-full !text-xs bg-blue-600 !p-2 !text-white"
                type="button"
                onClick={onServiceAdd}
              >
                <Icon
                  icon="majesticons:plus"
                  width="18"
                />
                <span>{t('add-to-list')}</span>
              </button>
            )}
          </div>
          {isSelected ? (
            <div className="shrink-0">
              <Controllable
                value={selectedItem?.qty}
                onChange={onValueChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
}

function ServiceSide({ item, items, onChange, employees, onClick }: any) {
  const onDelete = useCallback(() => {
    const filterItems = items.filter((e: any) => e.id !== item.id);
    const ids = filterItems.map((e: any) => e.id);

    onChange('items', filterItems);
    onChange('item_ids', ids);
  }, [items]);

  const employee = useMemo(() => {
    const findEmployee = employees.find((e: any) => e.id == item.employee_id);
    return findEmployee;
  }, [items]);

  const selectedOption = item?.option_ids
    ? item.service?.options?.filter((e: any) => item?.option_ids?.includes(e.id))
    : [];

  const selectedOptionNames = selectedOption?.map((e: any) => e.name).join(', ');

  const selectedOptionPrice = selectedOption?.reduce((acc: number, e: any) => acc + e.price, 0);

  return (
    <div className="flex items-center gap-4">
      <a
        className="flex-1 space-y-0.5 block"
        href="javascript:void(0)"
        onClick={() => onClick(item.service?.name)}
      >
        <p className="text-gray-800 font-semibold text-start">
          {item.service?.name || 'N/A'}
          <span className="text-gray-600">
            {selectedOptionNames && ' ( ' + selectedOptionNames + ' )'}
          </span>
        </p>

        {/* <SlideUpDown visible={!!item?.service?.items?.length}>
          <Title title={'[ ' + item?.service?.items?.map((e: any) => e.name).join(', ') + ' ]'} />
        </SlideUpDown> */}

        <p className="font-medium text-sm">
          {item?.qty > 1 ? <span className="text-gray-600">{item?.qty} * </span> : null}
          <span className="text-gray-600">
            {CurrencyFormatter(
              selectedOption
                ? item.service?.price + selectedOptionPrice
                : item.service?.price
                ? item.service?.price
                : 0
            )}
          </span>{' '}
        </p>

        <SlideUpDown visible={item?.qty > 1}>
          <Title
            title={CurrencyFormatter(
              (selectedOption ? item.service?.price + selectedOptionPrice : item.service?.price) *
                (item.qty || 1)
            )}
          />
        </SlideUpDown>
      </a>
      <button
        className="text-red-500"
        type="button"
        onClick={onDelete}
      >
        <Icon
          icon="fluent:delete-12-regular"
          width="18"
        />
      </button>
    </div>
  );
}

function TodayBookings({ handleClose, onUpdate }: any) {
  const [bookings, setBookings] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>();
  useEffect(() => {
    getBookings();
  }, []);

  const getBookings = (search_key?: string, page: number = 1) => {
    setIsLoading(true);
    setBookings([]);
    prepareRequest(
      {
        url: 'bookings',
        params: {
          page,
          search_key,
          // ...(!search_key && {
          // }),
          booking_time: moment(new Date()).add(-1, 'day').format('yyyy-MM-DD'),
          from: moment(new Date()).add(-1, 'day').format('yyyy-MM-DD'),
          to: moment(new Date()).add(-1, 'day').format('yyyy-MM-DD'),
          status: ['created']
        }
      },
      (data) => {
        const { data: result, pagination: paginate } = data.result?.bookings;
        setBookings(result || []);
        setPagination(paginate);
      }
    ).finally(() => setIsLoading(false));
  };

  const closeSession = (ev: ChangeEvent<HTMLSelectElement>) => {
    setDisabled(true);
    const { value } = ev.target;
    prepareRequest(
      {
        url: 'bookings/close_session',
        params: {
          with_booking_items: value
        },
        responseType: 'blob',
        method: 'post'
      },
      (data) => {
        downloadFile('pdf', data);
      }
    ).finally(() => setDisabled(false));
  };

  const onBookingModify = useCallback(
    (id: string | number) => {
      let result: AddForm;
      let mapItems: any[] = [];
      const booking = bookings.find((e: any) => e.id == id);
      const items = booking.items.filter((e: any) => ['pending', 'created'].includes(e.status));
      // const mapItems = items.map((e: any) => ({
      //   id: e.item_id,
      //   employee_id: e.employee?.id,
      //   marketer_code: e.marketer?.code,
      //   service: e,
      //   object_id: e.id
      // }));

      items.forEach((item: any, _index: number, arr: any[]) => {
        const count = arr.filter((e: any) => e.item_id == item.item_id)?.length;
        const existedItem = mapItems.find((e: any) => e.item_id == item.item_id);

        if (!existedItem) {
          mapItems.push({
            ...item,
            qty: count,
            service: item
          });
        } else {
          mapItems = mapItems.map((e: any) => {
            if (e.item_id == item.item_id) {
              return { ...e, qty: count };
            } else return e;
          });
        }
      });

      result = {
        id: booking.id,
        items: mapItems,
        client_id: booking.client?.id,
        client: booking.client,
        item_ids: mapItems.map((e: any) => e.id),
        booking_time: booking.booking_time,
        center_id: booking.center?.id,
        payments: booking.payments,
        ...(booking.extra_discount && {
          extra_discount_value: booking.extra_discount,
          extra_discount_type: 'fixed'
        })
      };
      handleClose(false);
      onUpdate(result);
      generateAlert('Reservation added to modify', 'success');
    },
    [bookings]
  );

  return (
    <div className="space-y-4">
      <div className="flex gap-3">
        <SearchBox
          placeholder={t('mobile')}
          className="shrink-0 flex-1"
          defaultValue={search}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            setSearch(ev.target.value);
            getBookings(ev.target.value, 1);
          }}
        />
        <select
          className="form-select form-outline max-w-fit"
          defaultValue=""
          onChange={closeSession}
          disabled={disabled}
        >
          <option
            value=""
            disabled
          >
            {t('close-session')}
          </option>
          <option value="1">{t('with-items')}</option>
          <option value="0">{t('without-items')}</option>
        </select>
      </div>
      <ul className="grid grid-wrapper gap-3">
        {isLoading
          ? Array.from({ length: 4 }).map((_, index: number) => <CardPlaceholder key={index} />)
          : null}
        {bookings.map((booking: any) => (
          <li key={booking.id}>
            <Card>
              <div className="space-y-1">
                <div className="space-y-0.5">
                  <p className="text-gray-400 text-xs">#{booking.id}</p>
                  <p className="text-gray-600 text-sm font-semibold">
                    - {booking.client?.name}{' '}
                    {booking.client?.mobile ? '( ' + booking.client?.mobile + ' )' : null}
                  </p>
                </div>
                <div>
                  <p className="text-gray-400 text-sm inline-flex gap-2 items-center">
                    <Icon
                      icon="iconamoon:clock"
                      width="16"
                    />
                    <span>{moment(booking.booking_time).format('lll')}</span>
                  </p>
                </div>
                <div className="!mt-3 pt-3 border-t border-t-gray-200">
                  <div className="flex items-center justify-end gap-2">
                    <button
                      type="button"
                      className="btn-with-icon !text-xs bg-blue-500"
                      onClick={() => onBookingModify(booking.id)}
                    >
                      {t('process')}
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </li>
        ))}
        {!bookings.length && !isLoading ? (
          <p className="text-center text-gray-600 col-span-full">{t('no-data')}</p>
        ) : null}
      </ul>
      <div>
        <Pagination
          pagination={{ ...pagination, page: pagination.current_page }}
          onNextClick={() => getBookings(search, pagination.current_page + 1)}
          onPreviousClick={() => getBookings(search, pagination.current_page - 1)}
        />
      </div>
    </div>
  );
}

export function CardPlaceholder() {
  return (
    <Card className="space-y-2">
      <div className="w-1/3 bg-gray-200 animate-pulse rounded-lg h-4"></div>
      <div className="w-full bg-gray-200 animate-pulse rounded-lg h-3"></div>
      <div className="w-1/2 bg-gray-200 animate-pulse rounded-lg h-3"></div>
    </Card>
  );
}

