import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Switcher from 'src/components/shared/Switcher';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function SMSTemplates() {
  const { t } = useTranslation();
  return (
    <div className="p-6 space-y-6">
      <div className="space-y-1">
        <p className="text-lg font-semibold">{t('update-info')}</p>
        <p className="text-sm text-gray-500">{t('templates-content')}</p>
      </div>
      <DisplayItems />
    </div>
  );
}

function DisplayItems() {
  let render = true;
  const { t } = useTranslation();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!render) return;
    getItems();
    render = false;
  }, []);

  function getItems() {
    setIsLoading(true);
    prepareRequest({ url: 'sms_templates' }, (data) => {
      setItems(() => data.result?.sms_templates || []);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) return <LoadingComponent />;
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
      {/* <AddForm refetching={getItems} /> */}
      {items.map((item) => (
        <TemplateCard
          item={item}
          key={item.id}
          refetching={getItems}
        />
      ))}
    </div>
  );
}

function TemplateCard({ item, refetching }: { item: any; refetching: any }) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  function deleteItem() {
    setDisabled(true);
    prepareRequest({ url: 'sms_templates/delete/' + item.id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      refetching();
    }).finally(() => {
      setDisabled(false);
    });
  }
  function updateStatus() {
    setDisabled(true);
    prepareRequest({ url: 'sms_templates/update_is_active/' + item.id, method: 'post' }, (data) => {
      generateAlert(data.message, 'success');
      refetching();
    }).finally(() => {
      setDisabled(false);
    });
  }

  return (
    <Card className="min-h-full !p-6 flex flex-col gap-3">
      <div className="space-y-2 text-start flex-1">
        <div className="flex items-start gap-2">
          <p className="text-base font-semibold flex-1">{item.type}</p>
          <Switcher
            checked={!!item.is_active}
            onChange={updateStatus}
          />
        </div>
        <p className="text-sm text-gray-500 line-clamp-2">{item.body}</p>
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        <UpdateForm
          item={item}
          refetching={refetching}
        />
        <ButtonWithCallback
          options={{
            title: t('are-you-sure'),
            showCancelButton: true,
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            icon: 'question'
          }}
          callback={deleteItem}
          className="btn-with-icon !bg-red-100 !text-red-600"
          disabled={disabled}
        >
          <span>{t('delete')}</span>
        </ButtonWithCallback>
      </div>
    </Card>
  );
}

function UpdateForm({ refetching, item }: { refetching: any; item: any }) {
  const [initialValues, setInitialValues] = useState(item);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setErrors({});
    setDisabled(true);
    prepareRequest(
      {
        url: 'sms_templates/update',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        helper.resetForm();
        refetching();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  return (
    <>
      <button
        className="btn-with-icon !bg-gray-200 !text-gray-600"
        type="button"
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        <span>{t('edit')}</span>
      </button>
      <Modal
        visible={visible}
        title={t('add-new')}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-lg"
      >
        <form
          className="space-y-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.name}
                onChange={(e) => handleChange('name', e)}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.alt_name}
                onChange={(e) => handleChange('alt_name', e)}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>
            <div className="form-group col-span-full">
              <label className="form-label">{t('type')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.type}
                onChange={(e) => handleChange('type', e)}
              />
              {errors?.type ? <span className="form-error">{errors?.type}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.body}
                onChange={(e) => handleChange('body', e)}
              ></textarea>
              {errors?.body ? <span className="form-error">{errors?.body}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.alt_body}
                onChange={(e) => handleChange('alt_body', e)}
              ></textarea>
              {errors?.alt_body ? <span className="form-error">{errors?.alt_body}</span> : null}
            </div>
          </div>

          <div className="inline-flex gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary text-white"
              type="submit"
              disabled={disabled}
            >
              <span>{t('save-changes')}</span>
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

function AddForm({ refetching }: { refetching: any }) {
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setErrors({});
    setDisabled(true);
    prepareRequest(
      {
        url: 'sms_templates/add',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        helper.resetForm();
        refetching();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="text-black"
        onClick={() => setVisible(true)}
      >
        <Card className="py-10 px-8 flex items-center justify-center text-center flex-col gap-2 min-h-full">
          <Icon
            icon="solar:add-circle-bold-duotone"
            width="35"
            height="35"
            className="text-gray-500"
          />
          <span>{t('add-new')}</span>
        </Card>
      </button>
      <Modal
        visible={visible}
        title={t('add-new')}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-lg"
      >
        <form
          className="space-y-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.name}
                onChange={(e) => handleChange('name', e)}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.alt_name}
                onChange={(e) => handleChange('alt_name', e)}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>
            <div className="form-group col-span-full">
              <label className="form-label">{t('type')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.type}
                onChange={(e) => handleChange('type', e)}
              />
              {errors?.type ? <span className="form-error">{errors?.type}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.body}
                onChange={(e) => handleChange('body', e)}
              ></textarea>
              {errors?.body ? <span className="form-error">{errors?.body}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-description')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.alt_body}
                onChange={(e) => handleChange('alt_body', e)}
              ></textarea>
              {errors?.alt_body ? <span className="form-error">{errors?.alt_body}</span> : null}
            </div>
          </div>

          <div className="inline-flex gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary text-white"
              type="submit"
              disabled={disabled}
            >
              <span>{t('save-changes')}</span>
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
