import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { StepItemProps } from 'src/screens/account/setup';
import { RootState } from 'src/store';

export default function AddPage({ callback }: StepItemProps) {
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    getPages();
  }, []);

  function getPages() {
    prepareRequest(
      {
        url: 'pages'
      },
      (data) => {
        setPages(() => data.result?.pages);
      }
    );
  }

  return (
    <div className="space-y-6">
      <Tab.Group>
        <Tab.List>
          {pages.map((page) => (
            <Tab
              className="btn-with-icon transition-all ui-selected:!bg-primary ui-not-selected:!text-gray-600 ui-selected:!text-gray-600"
              key={page.id}
            >
              {page.title} - {page.alt_title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {pages.map((page) => (
            <Tab.Panel key={page.id}>
              <AddForm
                callback={callback}
                item={page}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

function AddForm({ item }: StepItemProps & { item: any }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({ ...item });
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'pages/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          window.location.reload();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="form-label">{t('title')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.title}
                  name={'title'}
                  onChange={handleChange}
                />
                <span className="form-error">{errors['title']}</span>
              </div>
              <div className="form-group">
                <label className="form-label">{t('alt_title')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.alt_title}
                  name={'alt_title'}
                  onChange={handleChange}
                />
                <span className="form-error">{errors['alt_title']}</span>
              </div>
              <div className="form-group col-span-full">
                <label className="form-label">{t('content')}</label>
                <Editor
                  value={values.content}
                  onChange={(e: any) => setFieldValue('content', e)}
                />
                <span className="form-error">{errors['content']}</span>
                <span className="form-error">{errors['id']}</span>
              </div>
              <div className="form-group col-span-full">
                <label className="form-label">{t('alt_content')}</label>
                <Editor
                  value={values.alt_content}
                  onChange={(e: any) => setFieldValue('alt_content', e)}
                />
                <span className="form-error">{errors['alt_content']}</span>
                <span className="form-error">{errors['id']}</span>
              </div>
            </div>
          </div>
        </Card>
        <div className="inline-flex items-center gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary shrink-0"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </div>
      </form>
    </div>
  );
}
