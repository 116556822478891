import { useNavigate } from 'react-router-dom';
import { ChangeLanguage } from './HeaderLayout';
import ProfileNavigation from './ProfileNavigation';
import { Icon } from '@iconify/react';

export default function FullScreenHeader({ title }: { title?: string }) {
  const navigate = useNavigate();

  return (
    <div className="w-full px-6 sticky top-0 bg-gray-50 z-10">
      <div className="xl:container">
        <div className="flex items-center gap-4 md:gap-8 h-20 w-full justify-between">
          <div className="flex-1 flex justify-start">
            <button
              className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center text-gray-600"
              onClick={() => navigate(-1)}
            >
              <Icon
                icon="humbleicons:arrow-left"
                width="20"
                className="block rtl:hidden"
              />
              <Icon
                icon="humbleicons:arrow-right"
                width="20"
                className="hidden rtl:block"
              />
            </button>
          </div>

          <div className="flex-1 flex justify-center">
            <p
              className="text-base font-semibold line-clamp-1 text-gray-800"
              data-title={title}
            >
              {title}
            </p>
          </div>

          <div className="flex-1 flex items-center justify-end gap-2">
            <ChangeLanguage />
            <div>
              <ProfileNavigation />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

