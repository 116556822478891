import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Subscription from 'src/components/account/Subscription';
import Footer from 'src/components/shared/Footer';
import Image from 'src/components/shared/Image';
import { RootState } from 'src/store';

export default function Packages() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/account/profile?tab=packages', { replace: true });
    }
  }, [user, pathname]);

  return (
    <div className="flex flex-col bg-white">
      <div className="flex-1 p-6 space-y-10">
        <Link
          to="/"
          className="table mx-auto"
        >
          <Image
            src="/logo.png"
            alt="website logo"
            className="w-full max-w-[15rem] object-contain"
          />
        </Link>

        <div className="container">
          <Subscription />
        </div>
      </div>
      <Footer />
    </div>
  );
}
