import { Icon } from '@iconify/react';
import { ChangeEvent, InputHTMLAttributes, useCallback, useState } from 'react';
import generateAlert from 'src/helper/generateAlert';

type ActionType = 'increase' | 'decrease';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: number | string;
  onChange: (value: IProps['value']) => void | any;
}

export default function Controllable({ value = 0, onChange, ...rest }: IProps) {
  const [counter, setCounter] = useState<number>(Number(value));
  const onChangeValue = useCallback(
    (type: ActionType) => {
      let result = 0;
      if (type === 'decrease') {
        if (counter <= (!!rest.min ? +rest.min : 0))
          return generateAlert('Min value should be more than 0', 'info');
        result = counter - 1;
      } else {
        result = !!rest.max ? (counter >= +rest.max ? +rest.max : counter + 1) : counter + 1;
      }

      setCounter(result);
      onChange(result);
    },
    [value, counter]
  );

  const onInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = ev.target;
      if (valueAsNumber < 0) {
        ev.target.value = '0';
        return generateAlert('Min value should be more than 0', 'info');
      }

      setCounter(valueAsNumber);
      onChange(valueAsNumber);
    },
    [value, counter]
  );
  return (
    <div className="grid grid-cols-3 gap-2  bg-gray-100 rounded-full p-1.5">
      <button
        type="button"
        className="btn-with-icon !p-1.5 !rounded-full bg-primary"
        onClick={() => onChangeValue('increase')}
        disabled={!!rest.max ? counter >= +rest.max : false}
      >
        <Icon
          icon="ic:baseline-plus"
          width="16"
        />
      </button>
      <input
        type="number"
        inputMode="numeric"
        onChange={onInputChange}
        className="w-full max-w-[2rem] text-center font-semibold disabled:opacity-100 text-sm !bg-transparent"
        placeholder="0"
        min={0}
        value={counter}
        defaultValue={counter}
        aria-controls="false"
        {...rest}
      />
      <button
        type="button"
        className="btn-with-icon !p-1.5 !rounded-full bg-primary"
        onClick={() => onChangeValue('decrease')}
        disabled={!!rest.min ? counter >= +rest.min || counter === 0 : false}
      >
        <Icon
          icon="ic:baseline-minus"
          width="16"
        />
      </button>
    </div>
  );
}
