import { Link } from 'react-router-dom';

interface Props {
  title: string;
  desc: string;
}
const NotConfigureCard = ({ title, desc }: Props) => {
  return (
    <div className="flex justify-center items-center p-6 h-auto">
      <div className="flex shadow-xl flex-col gap-5 p-10 border-2 rounded-xl">
        <div className="flex items-center justify-center">
          <img
            src="/images/system6.png"
            alt="hand icon"
            className="w-[100px] h-[100px] object-contain"
          />
        </div>

        <h2 className="text-xl text-txt text-center">{title}</h2>

        <p className="text-txt/80">{desc}</p>

        <Link
          className="shadow-lg font-bold text-center rounded-xl border-2 py-3 px-5 w-[60%] sm:w-[40%] mx-auto border-pink-300 text-black"
          to="/"
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default NotConfigureCard;



