import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import { GoogleAuthProvider, User, deleteUser, signInWithPopup } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import generateRandomWord from 'src/helper/generateRandomWord';
import { auth } from '../../firebase.config';
import Placeholder from '../shared/Placeholder';

const googleProvider = new GoogleAuthProvider();

interface ContinueWithSocialProps {
  google?: boolean;
  facebook?: boolean;
  apple?: boolean;
  register?: boolean;
}

export default function ContinueWithSocial({ register = false }: ContinueWithSocialProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function continueWithGoogle() {
    try {
      setDisabled(true);
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const { displayName, email, phoneNumber } = result.user;
      const randomName = generateRandomWord();

      const registerUserInfo = {
        name: displayName,
        email: email,
        mobile: phoneNumber,
        // center_name: displayName,
        // subdomain: randomName,
        register_method: 'google'
      };

      const loginUserInfo = {
        email: phoneNumber ? phoneNumber : email,
        register_method: 'google'
      };

      const { data } = await axiosInstance.post(
        register ? 'register' : 'login',
        register ? registerUserInfo : loginUserInfo
      );
      const accessToken = data.result.access_token;
      const user = data.result.profile;

      localStorage.setItem('@token', accessToken);

      // dispatch(storeToken(token));
      // dispatch(saveUserInfo(user));

      generateAlert(data.message, 'success');
      // navigate('/account/profile', { replace: true });
      window.location.reload();
    } catch (error: FirebaseError | any | AxiosError) {
      if (error instanceof FirebaseError) {
        const credentialError = GoogleAuthProvider.credentialFromError(error);
        generateAlert(error?.message, 'error');
        return;
      }

      if (error instanceof AxiosError) {
        const err = error.response?.data;
        generateAlert(err.message, 'error');
        return;
      }

      // delete authenticated user in case catch error
      if (auth.currentUser) {
        console.log(auth.currentUser);
        await deleteUser(auth.currentUser as User);
      }
      generateAlert(
        'There is something went wrong during creating new account, try again later or get in touch with our support through whatsapp',
        'error'
      );
    } finally {
      setDisabled(false);
    }
  }

  if (isLoading)
    return (
      <div className="space-y-2">
        <div className="btn-with-icon outline-btn w-full !p-3">
          <Placeholder
            width="24px"
            height="24px"
          />
          <Placeholder
            width="150px"
            height="16px"
          />
        </div>
      </div>
    );

  return (
    <div className="space-y-2">
      {/* {google && ( */}
      <button
        type="button"
        className="btn-with-icon outline-btn w-full !p-3"
        onClick={continueWithGoogle}
        disabled={disabled}
        aria-disabled={disabled}
        title={t('continue-with-google')}
        aria-label={t('continue-with-google')}
      >
        <Icon
          icon="logos:google-icon"
          width="1.2rem"
          height="1.2rem"
        />
        <span>{t('continue-with-google')}</span>
      </button>
      {/* )} */}
      {/* {facebook && (
        <button
          type="button"
          className="btn-with-icon outline-btn w-full !p-3"
          onClick={continueWithGoogle}
          disabled={disabled}
          aria-disabled={disabled}
          title={t('continue-with-facebook')}
          aria-label={t('continue-with-facebook')}
        >
          <Icon
            icon="logos:facebook"
            width="1.2rem"
            height="1.2rem"
          />
          <span>{t('continue-with-facebook')}</span>
        </button>
      )}
      {apple && (
        <button
          type="button"
          className="btn-with-icon outline-btn w-full !p-3"
          onClick={continueWithGoogle}
          disabled={disabled}
          aria-disabled={disabled}
          title={t('continue-with-apple')}
          aria-label={t('continue-with-apple')}
        >
          <Icon
            icon="logos:apple"
            width="1.2rem"
            height="1.2rem"
          />
          <span>{t('continue-with-apple')}</span>
        </button>
      )} */}
    </div>
  );
}

