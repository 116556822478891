import { AllHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card';
import Image from '../Image';
import Pagination from '../Pagination';
import SearchBox from '../SearchBox';

interface OptionalAttributes {
  loading?: boolean;
  isEmpty?: boolean;
  pagination?: any;
  onNextClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  onPreviousClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  searchProps?: AllHTMLAttributes<HTMLInputElement>;
  Actions?: FunctionComponent;
  title?: string;
  hasSearch?: boolean;
  noResultTitle?: string;
  noResultContent?: string;
  noResultImage?: string;
  noResultAction?: ReactNode;
}

interface TableInterface extends OptionalAttributes {
  RenderBody: FunctionComponent;
  RenderHead: FunctionComponent;
}

function Table({
  loading,
  pagination,
  searchProps,
  onNextClick,
  onPreviousClick,
  RenderBody,
  RenderHead,
  isEmpty,
  Actions,
  title,
  hasSearch = true,
  noResultTitle,
  noResultContent,
  noResultImage,
  noResultAction
}: TableInterface) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4 !p-0">
      {hasSearch && pagination && (
        <div className="flex gap-4 justify-between flex-wrap p-4 pb-0">
          <SearchBox
            loading={loading}
            onChange={searchProps?.onChange}
            onKeyDown={searchProps?.onKeyDown}
            defaultValue={searchProps?.defaultValue}
            value={searchProps?.value}
            placeholder={searchProps?.placeholder}
            className={searchProps?.className}
          />
          <div className="inline-flex gap-2 items-center flex-wrap">
            {Actions ? <Actions /> : null}
          </div>
        </div>
      )}

      <div className="grid">
        <div className="overflow-x-auto">
          <table className="styled-table">
            <thead className="bg-gray-100">
              <RenderHead />
            </thead>
            <tbody>
              <RenderBody />

              {isEmpty ? (
                <tr>
                  <td colSpan={100}>
                    <div className="flex items-center gap-8 flex-col">
                      <Image
                        src={noResultImage || '/images/no-result.svg'}
                        alt={noResultTitle || t('no-data')}
                        title={noResultTitle || t('no-data')}
                        width="250"
                        height="250"
                        className="object-contain object-center"
                      />
                      <p className="text-gray-600 shrink-0 text-center font-semibold">
                        {noResultTitle || t('no-data')}
                      </p>
                      {!!noResultContent && (
                        <p className="text-gray-500 text-sm shrink-0 text-center">
                          {noResultContent || t('no-data')}
                        </p>
                      )}
                      {noResultAction}
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      {pagination && pagination?.total_pages > 1 && (
        <Pagination
          pagination={pagination}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      )}
    </Card>
  );
}

export default Table;

