import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import Modal from './Modal';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import { AxiosError } from 'axios';

interface IProps {
  pathname: string;
  refetch: () => Promise<any>;
}

export default function Delete({ pathname, refetch }: IProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  async function handleDeletion() {
    try {
      setDisabled(true);
      const { data } = await axiosInstance.post(pathname);
      generateAlert(data.message, 'success');
      await refetch();
    } catch (error: AxiosError | any) {
      const err = error.response?.data;
      const message = err?.message;
      generateAlert(message, 'error');
    } finally {
      setDisabled(false);
    }
  }

  return <></>;

  return (
    <>
      <button
        type="button"
        className="btn-with-icon bg-red-500 text-white !text-xs"
        onClick={() => setVisible(true)}
      >
        <Icon
          icon="fluent:delete-12-regular"
          width="16"
        />
        <span>Delete</span>
      </button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <div className="space-y-4">
          <div className="space-y-2">
            <p className="text-lg font-bold">Are you sure?</p>
            <p className="text-sm text-gray-500">
              If you are sure of the deletion, you no longer have any authority to retrieve that
              item and delete anything related to it. If you still want to delete, click on the next
              delete button or cancel the process.
            </p>
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            <button
              type="button"
              className="btn-with-icon bg-red-500 text-white !text-xs"
              disabled={disabled}
              onClick={handleDeletion}
            >
              <span>Delete</span>
            </button>
            <button
              type="button"
              className="btn-with-icon outline-btn  text-gray-500 !text-xs"
              disabled={disabled}
              onClick={() => setVisible(false)}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
