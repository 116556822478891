import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import Modal from 'src/components/shared/Modal';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const Stocktaking: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('stock_counts', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.stock_counts;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('center')}</th>
              <th>{t('user')}</th>
              <th>{t('products')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.center?.name || 'N/A'}</td>
                    <td>{item.user?.name || 'N/A'}</td>
                    <td>
                      <ViewProducts items={item.items || []} />
                    </td>

                    <td>
                      <div className="inline-flex gap-2"></div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <Link
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                to="/settings/inventory/stocktaking/new"
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </Link>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </React.Fragment>
  );
};

function ViewProducts({ items }: { items: any[] }) {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        className="btn-with-icon bg-blue-600 !text-white shrink-0"
        onClick={() => setVisible(true)}
        disabled={!items.length}
      >
        ({items.length})<span>{t('view')}</span>
      </button>

      <Modal
        title={t('products')}
        size="!max-w-screen-md"
        handleClose={() => setVisible(false)}
        visible={visible}
      >
        <div className="grid">
          <div className="overflow-x-auto">
            <table className="styled-table">
              <thead className="bg-gray-100">
                <tr>
                  <th>{t('product')}</th>
                  <th>{t('price')}</th>
                  <th>{t('value')}</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      <div className="flex items-center gap-4">
                        <Image
                          src={item.image}
                          alt={item.name}
                          title={item.name}
                          width="40"
                          height="40"
                          className="shrink-0 w-10 h-10 rounded-full object-cover object-center"
                        />
                        <p className="!whitespace-normal !max-w-sm line-clamp-2 flex-1">
                          {item.name} - {item.alt_name}
                        </p>
                      </div>
                    </td>
                    <td>{CurrencyFormatter(item.price || 0)}</td>
                    <td>{item.value || 0}</td>
                  </tr>
                ))}

                {!items.length && (
                  <tr>
                    <td colSpan={100}>
                      <p className="text-center text-gray-600">{t('no-data')}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Stocktaking;

