import { t } from 'i18next';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Card from '../shared/Card';
import ItemList from '../shared/ItemList';
import PackageFeature from '../shared/PackageFeature';
import SvgProgressIcon from '../shared/SvgProgressIcon';

export default function SubscriptionUsageSummary() {
  const { user } = useSelector((state: RootState) => state.auth);
  const packageExpire = useMemo(() => {
    const endDate = new Date(user?.subscription?.end_date);
    const startDate = new Date(user?.subscription?.start_date);
    const today = new Date();
    const usedDays = Math.ceil(moment(endDate).diff(today, 'days', true));
    const packageDays = Math.ceil(moment(endDate).diff(startDate, 'days', true));
    const usedDaysPercent = Number(((usedDays / packageDays) * 100).toFixed(1));

    return {
      usedDays,
      packageDays,
      usedDaysPercent
    };
  }, [user]);

  const featuresUsed = useMemo(() => {
    const {
      centers_count = 0,
      center_users_count = 0,
      invoices_count = 0
    } = user?.subscription || {};
    const {
      centers_count: package_centers_count = 0,
      center_users_count: package_center_users_count = 0,
      invoices_count: package_invoices_count = 0
    } = user?.subscription?.package || {};

    const collectAll = package_centers_count + package_center_users_count + package_invoices_count;
    const collectUsed = centers_count + center_users_count + invoices_count;

    const multiplyUsedFromPackageFeatureValues = collectAll - collectUsed;
    const percentage = Math.max(0, Number(Math.ceil((collectUsed / collectAll) * 100).toFixed(2)));

    return {
      percentage: percentage >= 100 ? 100 : percentage,
      diff: multiplyUsedFromPackageFeatureValues,
      packageValues: collectAll,
      usedValues: collectUsed
    };
  }, [user]);
  return (
    <div className="flex-1 w-full lg:max-w-sm space-y-4">
      <Card className="!p-6 space-y-4">
        <SvgProgressIcon
          size="8rem"
          value={featuresUsed.percentage}
        />
        <p className="text-lg font-bold text-center">
          {user?.subscription?.package?.name} - {user?.subscription?.package?.alt_name}
        </p>
        <ul className="space-y-2">
          <ItemList>
            <PackageFeature
              title="centers-count"
              value={user?.subscription?.centers_count || 0}
              originValue={user?.subscription?.package?.centers_count || 0}
              icon="solar:buildings-linear"
            />
          </ItemList>
          <ItemList>
            <PackageFeature
              title="users-count"
              value={
                user?.subscription?.center_users_count || 0 * user?.subscription?.centers_count || 0
              }
              originValue={user?.subscription?.package?.center_users_count || 0}
              icon="mdi:account-tie-outline"
            />
          </ItemList>
          <ItemList>
            <PackageFeature
              title="invoices-count"
              value={user?.subscription?.invoices_count || 0}
              originValue={user?.subscription?.package?.invoices_count || 0}
              icon="tabler:file-invoice"
            />
          </ItemList>
          <ItemList>
            <PackageFeature
              title="package-date"
              value={packageExpire.usedDays || 0}
              originValue={packageExpire.packageDays || 0}
              icon="solar:alarm-linear"
              prefix={t('days')}
            />
          </ItemList>
        </ul>
      </Card>
    </div>
  );
}
