import { Tab } from '@headlessui/react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UpdatePayments, { UpdatePaymentsForm } from 'src/components/bookins/payments/UpdatePayments';
import PrintPDF from 'src/components/invoices/PrintPDF';
import Avatar from 'src/components/shared/Avatar';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Card from 'src/components/shared/Card';
import DisplayPayments from 'src/components/shared/DisplayPaymentsForm';
import Dropdown from 'src/components/shared/Dropdown';
import { downloadFile } from 'src/components/shared/ExportOptions';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import SendByEmail from 'src/components/shared/SendByEmail';
import SharedTime from 'src/components/shared/SharedTime';
import Title from 'src/components/shared/Title';
import { ItemType } from 'src/components/shared/items/AddForm';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';
import { RootState } from 'src/store';

type Status = 'created' | 'completed' | 'closed' | 'cancelled';
type ItemStatus = 'created' | 'completed' | 'cancelled' | 'running' | 'pending' | 'refunded';

function useHooks() {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [booking, setBooking] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getBooking();
  }, []);

  const getBooking = () => {
    setIsLoading(true);
    prepareRequest({ url: 'bookings/' + id }, (data, error) => {
      // console.log(error);
      setBooking(() => data.result?.booking || {});
      // if(error)
    })
      .catch(() => {
        navigate('/bookings', { replace: true });
      })
      .finally(() => setIsLoading(false));
  };

  const markPaid = () => {
    setDisabled(true);
    prepareRequest({ method: 'post', url: 'bookings/mark_paid/' + id }, (data) => {
      generateAlert(data.message, 'success');
      getBooking();
    }).finally(() => {
      setDisabled(false);
    });
  };

  const changeStatus = (status: Omit<Status, 'created'>) => {
    setDisabled(true);
    prepareRequest(
      { method: 'post', url: 'bookings/update_status', data: { id, status } },
      (data) => {
        generateAlert(data.message, 'success');
        getBooking();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const changeItemStatus = (booking_item_id: string, status: ItemStatus) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/update_booking_item_status',
        data: { booking_item_id, status }
      },
      (data) => {
        generateAlert(data.message, 'success');
        getBooking();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const markAsCompleted = () => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/mark_as_completed',
        data: { booking_id: id }
      },
      (data) => {
        generateAlert(data.message, 'success');
        getBooking();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const exportPdf = () => {
    setDisabled(true);
    prepareRequest(
      {
        url: '/bookings/' + id + '/export_pdf',
        method: 'get',
        responseType: 'blob'
      },
      (data) => {
        downloadFile('pdf', data);
      }
    ).finally(() => setDisabled(false));
  };

  const getRemainingAmount = useMemo(() => {
    let result = 0;
    const total = booking?.total || 0;
    const paid_amount = booking?.paid_amount || 0;

    if (paid_amount < total || paid_amount > total) {
      result = total - paid_amount;
    } else {
      result = 0;
    }
    return result;
  }, [booking]);

  return {
    isLoading,
    booking,
    markPaid,
    disabled,
    changeStatus,
    changeItemStatus,
    getBooking,
    bookingId: id,
    getRemainingAmount,
    exportPdf,
    markAsCompleted
  };
}

type PaidStatus = 'paid' | 'unpaid' | 'partial-paid';

export const paidStatus = (total: number, paid: number): PaidStatus => {
  let status: PaidStatus;
  if (paid === total) {
    status = 'paid';
  } else if (paid > 0 && paid < total) {
    status = 'partial-paid';
  } else {
    status = 'unpaid';
  }
  return status;
};

export default function BookingId() {
  const {
    isLoading,
    booking,
    markPaid,
    disabled,
    changeStatus,
    changeItemStatus,
    getBooking,
    bookingId,
    getRemainingAmount,
    exportPdf,
    markAsCompleted
  } = useHooks();
  const { t } = useTranslation();
  const tabs = ['items', 'basic-information', 'history'];

  const { user } = useSelector((state: RootState) => state.auth);
  const [editItemVisible, setEditItemVisible] = useState<Record<number, boolean>>({});
  const [addPaymentsVisible, setAddPaymentsVisible] = useState<boolean>(false);
  const [emailVisible, setEmailVisible] = useState<boolean>(false);
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [updateEmployeeVisible, setUpdateEmployeeVisible] = useState<boolean>(false);
  const [issuingInvoiceVisible, setIssuingInvoiceVisible] = useState<boolean>(false);
  const [notesVisible, setNotesVisible] = useState<boolean>(false);

  const filterItems = useCallback(
    (type: ItemType | ItemType[]) => {
      const items = booking?.items || [];
      const mapItems = new Array().concat(
        ...items.map((e: any) => {
          if (['offer', 'package', 'package_offer'].includes(e.item_type)) {
            const { items: data, ...others } = e;
            if (!data.length)
              return {
                ...others
              };
            return data.map((item: any) => ({
              ...item,
              item: others
            }));
          }
          return e;
        })
      );

      return (
        mapItems?.filter((e: any) => {
          if (Array.isArray(type)) {
            return type.includes(e.item_type);
          }
          return e.item_type === type;
        }) || []
      );
    },
    [booking]
  );

  if (isLoading) return <LoadingComponent />;

  console.log(booking);

  return (
    <div className="flex flex-col min-h-screen">
      <AppBar
        center
        title={[
          t(booking?.status === 'invoiced' ? 'invoice-details' : 'reservation-details'),
          '-',
          '#' + booking?.id,
          '( ' + booking?.client?.name + ' )'
        ].join(' ')}
        actions={
          <Dropdown
            button={
              <Icon
                icon="ion:menu"
                width="18"
              />
            }
            position="top-right"
          >
            <ul className="divide-y divide-gray-200 p-3">
              {user.user_type === 'admin' ? (
                <>
                  <ItemList className="py-2">
                    <ButtonWithCallback
                      type="button"
                      disabled={disabled}
                      options={{
                        title: t('are-you-sure'),
                        text: t('confirmations.change-status'),
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('close'),
                        showCancelButton: true,
                        icon: 'info'
                      }}
                      callback={() => changeStatus('cancelled')}
                      className="btn-with-icon !p-0 !text-gray-600"
                    >
                      <Icon
                        icon="mi:ban"
                        width="18"
                      />
                      <span>{t('cancel-reservation')}</span>
                    </ButtonWithCallback>
                  </ItemList>
                  <ItemList className="py-2">
                    <ButtonWithCallback
                      type="button"
                      disabled={disabled}
                      options={{
                        title: t('are-you-sure'),
                        text: t('confirmations.change-status'),
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('close'),
                        showCancelButton: true,
                        icon: 'info'
                      }}
                      callback={() => changeStatus('closed')}
                      className="btn-with-icon !p-0 !text-gray-600"
                    >
                      <Icon
                        icon="mi:ban"
                        width="18"
                      />
                      <span>{t('close-reservation')}</span>
                    </ButtonWithCallback>
                  </ItemList>
                </>
              ) : null}
              {booking?.status === 'created' ? (
                <ItemList className="py-2">
                  <ButtonWithCallback
                    type="button"
                    disabled={disabled}
                    options={{
                      title: t('are-you-sure'),
                      text: t('confirmations.mark-completed'),
                      confirmButtonText: t('yes'),
                      cancelButtonText: t('close'),
                      showCancelButton: true,
                      icon: 'info'
                    }}
                    callback={() => markAsCompleted()}
                    className="btn-with-icon !p-0 !text-gray-600"
                  >
                    <Icon
                      icon="material-symbols:check"
                      width="18"
                    />
                    <span>{t('mark-as-completed')}</span>
                  </ButtonWithCallback>
                </ItemList>
              ) : null}
              {['completed'].includes(booking?.status) ? (
                <ItemList className="py-2">
                  <ButtonWithCallback
                    type="button"
                    disabled={disabled}
                    options={{
                      title: t('are-you-sure'),
                      text: t('confirmations.change-status'),
                      confirmButtonText: t('yes'),
                      cancelButtonText: t('close'),
                      showCancelButton: true,
                      icon: 'info'
                    }}
                    callback={() => changeStatus('closed')}
                    className="btn-with-icon !p-0 !text-gray-600"
                  >
                    <Icon
                      icon="mi:ban"
                      width="18"
                    />
                    <span>{t('close-reservation')}</span>
                  </ButtonWithCallback>
                </ItemList>
              ) : null}

              {!['cancelled', 'closed', 'invoices'].includes(booking?.status) ? (
                <ItemList className="py-2">
                  <button
                    className="btn-with-icon !p-0 !text-gray-600"
                    type="button"
                    onClick={() => setCancelVisible(true)}
                    disabled={disabled}
                  >
                    <Icon
                      icon="charm:git-branch"
                      width="18"
                    />
                    <span>{t('cancel-items')}</span>
                  </button>
                </ItemList>
              ) : null}

              {booking?.total !== booking?.paid_amount ? (
                <ItemList className="py-2">
                  <button
                    className="btn-with-icon !p-0 !text-gray-600"
                    onClick={() => setIssuingInvoiceVisible(true)}
                  >
                    <Icon
                      icon="ph:file-pdf-duotone"
                      width="18"
                    />
                    <span>{t('issuing-invoice')}</span>
                  </button>
                </ItemList>
              ) : null}

              <ItemList className="py-2">
                <PrintPDF
                  item={booking}
                  trigger={
                    <button className="btn-with-icon !p-0 !text-gray-600">
                      <Icon
                        icon="ph:file-pdf-duotone"
                        width="18"
                      />
                      <span>
                        {t('print-invoice')} ({t('thermal')})
                      </span>
                    </button>
                  }
                />
              </ItemList>

              <ItemList className="py-2">
                <button
                  className="btn-with-icon !p-0 !text-gray-600"
                  disabled={disabled}
                  onClick={exportPdf}
                >
                  <Icon
                    icon="ph:file-pdf-duotone"
                    width="18"
                  />
                  <span>{t('exports.pdf')}</span>
                </button>
              </ItemList>
            </ul>
          </Dropdown>
        }
      />
      <div className="flex-1">
        <div className="space-y-4 p-6 xl:container mx-auto flex-1">
          <div className="flex items-start flex-col lg:flex-row gap-4">
            <div className="flex-1 w-full space-y-4">
              <Tab.Group>
                <Tab.List className="flex overflow-x-auto">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab}
                      className="btn-with-icon transition-all ui-selected:!bg-primary ui-not-selected:!text-gray-600 ui-selected:!text-white"
                    >
                      {t(tab)}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panel>
                  <div className="space-y-4">
                    <Title title={t('services')} />

                    <div className="grid">
                      <Table
                        RenderHead={() => {
                          return (
                            <tr>
                              <th>{t('name')}</th>
                              <th>{t('status')}</th>
                              <th>{t('expert')}</th>
                              <th>{t('call-center')}</th>
                              <th>{t('price')}</th>
                              <th>{t('execution-time')}</th>
                              <th></th>
                            </tr>
                          );
                        }}
                        RenderBody={() => {
                          return (
                            <>
                              {filterItems(['service', 'package_offer']).map(
                                (service: any, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      {service.code}
                                      <br />
                                      {service.name || 'N/A'}
                                      <br />
                                      {service.item ? (
                                        <span className="text-red-500 font-semibold">
                                          {service.item?.name || 'N/A'}
                                        </span>
                                      ) : null}
                                    </td>
                                    <td>{t('statuses.' + service.status) || 'N/A'}</td>
                                    {service.item_type !== 'package_offer' ? (
                                      <>
                                        <td>{service.employee?.name || 'N/A'}</td>
                                        <td>
                                          {service.marketer?.name || 'N/A'}
                                          <br />
                                          {service.marketer?.code || 'N/A'}
                                        </td>
                                      </>
                                    ) : (
                                      <td colSpan={2}>
                                        <hr className="border-gray-500" />
                                      </td>
                                    )}
                                    <td>{CurrencyFormatter(service.price || 0)}</td>
                                    <td>
                                      <SharedTime
                                        date={service?.completed_at}
                                        format="DD-MM-yyyy hh:mmA"
                                      />
                                    </td>
                                    <td>
                                      <div className="flex items-center gap-2">
                                        {service.status !== 'completed' &&
                                        service.item_type !== 'package_offer' ? (
                                          <button
                                            type="button"
                                            className="edit-btn"
                                            disabled={disabled}
                                            onClick={() =>
                                              setEditItemVisible({
                                                [service.id]: true
                                              })
                                            }
                                          >
                                            <Icon
                                              icon="mingcute:edit-3-line"
                                              width="18"
                                            />
                                            <span>{t('edit')}</span>
                                          </button>
                                        ) : null}

                                        {['created', 'pending'].includes(service.status) ? (
                                          <>
                                            <ButtonWithCallback
                                              type="button"
                                              className="!p-2 btn-with-icon bg-teal-500"
                                              disabled={disabled}
                                              callback={() =>
                                                changeItemStatus(service.id, 'running')
                                              }
                                              options={{
                                                title: t('are-you-sure'),
                                                text: t('confirmations.change-status'),
                                                confirmButtonText: t('yes'),
                                                cancelButtonText: t('close'),
                                                showCancelButton: true,
                                                icon: 'info'
                                              }}
                                            >
                                              <Icon
                                                icon="ri:play-fill"
                                                width="18"
                                              />
                                            </ButtonWithCallback>
                                          </>
                                        ) : null}

                                        {['running'].includes(service.status) ? (
                                          <>
                                            <ButtonWithCallback
                                              type="button"
                                              className="!p-2 btn-with-icon bg-teal-500"
                                              disabled={disabled}
                                              callback={() =>
                                                changeItemStatus(service.id, 'completed')
                                              }
                                              options={{
                                                title: t('are-you-sure'),
                                                text: t('confirmations.change-status'),
                                                confirmButtonText: t('yes'),
                                                cancelButtonText: t('close'),
                                                showCancelButton: true,
                                                icon: 'info'
                                              }}
                                            >
                                              <Icon
                                                icon="material-symbols:check"
                                                width="18"
                                              />
                                            </ButtonWithCallback>
                                          </>
                                        ) : null}
                                      </div>
                                      <Modal
                                        visible={!!editItemVisible?.[service.id]}
                                        title={service.name}
                                      >
                                        <UpdateServiceForm
                                          id={service.id}
                                          refetch={getBooking}
                                          handleClose={setEditItemVisible}
                                          employee={service.employee?.id}
                                          marketer={service.marketer?.code}
                                        />
                                      </Modal>
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          );
                        }}
                        isEmpty={!filterItems(['service', 'package_offer'])?.length}
                      />
                    </div>

                    <Title title={t('products')} />
                    <div className="grid">
                      <Table
                        RenderHead={() => {
                          return (
                            <tr>
                              <th>{t('name')}</th>
                              <th>{t('price')}</th>
                            </tr>
                          );
                        }}
                        RenderBody={() => {
                          return (
                            <>
                              {filterItems('product')?.map((service: any, index: number) => (
                                <tr key={index}>
                                  <td>
                                    {service.code}
                                    <br />
                                    {service.name || 'N/A'}
                                    <br />
                                    {service.item ? (
                                      <span className="text-red-500 font-semibold">
                                        {service.item?.name || 'N/A'}
                                      </span>
                                    ) : null}
                                  </td>

                                  <td>{CurrencyFormatter(service.price || 0)}</td>
                                </tr>
                              ))}
                            </>
                          );
                        }}
                        isEmpty={!filterItems('product')?.length}
                      />
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="space-y-4">
                    <div className="flex items-center gap-3 flex-wap">
                      <div className="flex-1">
                        <Title title={t('call-center')} />
                      </div>
                      <button
                        className="btn-with-icon !bg-blue-600 !text-xs"
                        type="button"
                        onClick={() => setUpdateEmployeeVisible(true)}
                      >
                        <Icon
                          icon="mingcute:edit-3-line"
                          width="16"
                        />
                        <span>{t('edit')}</span>
                      </button>
                    </div>
                    <Card>
                      <div className="flex flex-wrap gap-4">
                        <Avatar icon="mdi:support" />
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('name')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.marketer?.name || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('mobile')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.marketer?.mobile || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('email')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.marketer?.email || 'N/A'}
                          />
                        </div>
                      </div>
                    </Card>

                    <Title title={t('cashier-details')} />
                    <Card>
                      <div className="flex flex-wrap gap-4">
                        <Avatar icon="streamline:money-cashier-shop-shopping-pay-payment-cashier-store-cash-register-machine" />
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('name')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.cashier?.name || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('mobile')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.cashier?.mobile || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('email')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.cashier?.email || 'N/A'}
                          />
                        </div>
                      </div>
                    </Card>
                    <Title title={t('client-details')} />
                    <Card>
                      <div className="flex flex-wrap gap-4">
                        <Avatar />
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('name')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.client?.name || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('code')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.client?.code || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('mobile')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.client?.mobile || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('email')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.client?.email || 'N/A'}
                          />
                        </div>
                      </div>
                    </Card>
                    <Title title={t('center-details')} />
                    <Card>
                      <div className="flex flex-wrap gap-4">
                        <Avatar icon="fluent:location-20-filled" />
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('name')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.center?.name || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('mobile')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.center?.mobile || 'N/A'}
                          />
                        </div>
                        <div className="space-y-0.5 flex-1">
                          <Title title={t('email')} />
                          <Title
                            className="!text-black font-semibold"
                            title={booking?.center?.email || 'N/A'}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="space-y-4">
                    <div className="grid">
                      <Table
                        RenderHead={() => {
                          return (
                            <tr>
                              <th>{t('employee')}</th>
                              <th>{t('details')}</th>
                              <th>{t('created-date')}</th>
                            </tr>
                          );
                        }}
                        RenderBody={() => {
                          return (
                            <>
                              {booking?.logs?.map((log: any, index: number) => (
                                <tr key={index}>
                                  <td>{log.user?.name || 'N/A'}</td>
                                  <td>{log.label || '-'}</td>
                                  <td>
                                    <SharedTime date={log.created_at} />
                                  </td>
                                </tr>
                              ))}
                            </>
                          );
                        }}
                        isEmpty={!booking?.logs?.length}
                      />
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Group>
            </div>
            <div className="flex-1 w-full lg:max-w-sm space-y-4">
              <Title title={t('status-payments')} />
              <Card>
                <ul className="divide-y divide-gray-200">
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('booking-time')} />
                      <p className="!text-gray-800 font-semibold text-sm">
                        {moment(booking?.created_at).format('hh:mmA')}
                        <br />
                        {moment(booking?.created_at).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </ItemList>
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('reservation-status')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={t('statuses.' + booking?.status)}
                      />
                    </div>
                  </ItemList>
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('payment-status')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={t(booking?.payment_status)}
                      />
                    </div>
                  </ItemList>

                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('paid-amount')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={CurrencyFormatter(booking?.paid_amount || 0)}
                      />
                    </div>
                  </ItemList>
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('discount')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={CurrencyFormatter(booking?.extra_discount || 0)}
                      />
                    </div>
                  </ItemList>
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('vat')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={CurrencyFormatter(booking?.vat || 0)}
                      />
                    </div>
                  </ItemList>
                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('subtotal')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={CurrencyFormatter(booking?.sub_total || 0)}
                      />
                    </div>
                  </ItemList>

                  <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t('total')} />
                      <Title
                        className="!text-gray-800 font-semibold"
                        title={CurrencyFormatter(booking?.total || 0)}
                      />
                    </div>
                  </ItemList>
                  {/* <ItemList className="py-3 relative">
                    <div className="grid grid-cols-2 gap-2">
                      <Title title={t("due-amount")} />
                      <Title
                        className="!text-red-600 font-semibold"
                        title={CurrencyFormatter(getRemainingAmount)}
                      />
                    </div>
                  </ItemList> */}
                </ul>
              </Card>
              <div className="flex items-center justify-between gap-4">
                <Title title={t('payments')} />
                <UpdatePayments
                  id={bookingId}
                  item={booking}
                  refetch={getBooking}
                />
              </div>
              <Card>
                <ul className="divide-y divide-gray-200">
                  {booking?.payments?.map((item: any, index: number) => (
                    <ItemList
                      key={index}
                      className="py-3 relative"
                    >
                      <PaymentCard item={item} />
                    </ItemList>
                  ))}
                  {!booking?.payments?.length ? (
                    <ItemList className="py-3 relative">
                      <p className="text-sm text-center text-gray-600">{t('no-data')}</p>
                    </ItemList>
                  ) : null}
                </ul>
              </Card>
              <div className="flex items-center justify-between gap-4">
                <Title title={t('notes')} />
                <button
                  className="btn-with-icon !text-blue-600 !p-0"
                  type="button"
                  onClick={() => setNotesVisible(true)}
                >
                  <Icon
                    icon="iconoir:edit"
                    width="16"
                  />
                  <span>{t('edit')}</span>
                </button>
              </div>
              <Card>
                <ul className="divide-y divide-gray-200">
                  <ItemList className="py-3 relative">
                    <p className={`text-sm text-gray-600 ${!booking.notes ? 'text-center' : ''}`}>
                      {booking.notes ? booking.notes : t('no-data')}
                    </p>
                  </ItemList>
                </ul>
              </Card>
            </div>
          </div>
        </div>
        <Modal
          visible={addPaymentsVisible}
          title={t('add-payments')}
          handleClose={setAddPaymentsVisible}
        >
          <AddPayments
            refetch={getBooking}
            handleClose={setAddPaymentsVisible}
            id={bookingId}
            total={booking?.total}
          />
        </Modal>
        <Modal
          visible={cancelVisible}
          title={t('refund-items')}
          handleClose={setCancelVisible}
        >
          <CancelItems
            refetch={getBooking}
            handleClose={setCancelVisible}
            id={bookingId}
            items={booking?.items}
          />
        </Modal>
        <Modal
          visible={emailVisible}
          handleClose={setEmailVisible}
          title={t('exports.email')}
        >
          <SendByEmail
            handleClose={setEmailVisible}
            pathname={'bookings/' + bookingId + '/send_pdf'}
          />
        </Modal>
        <Modal
          visible={updateEmployeeVisible}
          handleClose={setUpdateEmployeeVisible}
          title={t('call-center')}
        >
          <UpdateCallCenterForm
            refetch={getBooking}
            id={bookingId}
            handleClose={setUpdateEmployeeVisible}
          />
        </Modal>
        <Modal
          visible={issuingInvoiceVisible}
          handleClose={() => setIssuingInvoiceVisible(false)}
          title={t('payments')}
        >
          <UpdatePaymentsForm
            id={bookingId}
            item={booking}
            refetch={getBooking}
            handleClose={setIssuingInvoiceVisible}
          />
        </Modal>
        <Modal
          visible={notesVisible}
          handleClose={() => setNotesVisible(false)}
          title={t('notes')}
        >
          <UpdateNotes
            id={bookingId}
            notes={booking.notes}
            refetch={getBooking}
            handleClose={setNotesVisible}
          />
        </Modal>
      </div>
    </div>
  );
}

function PaymentCard({ item }: any) {
  const { id }: any = useParams();

  return (
    <div className="flex items-center gap-4">
      <Image
        className="w-auto max-w-[2rem] object-contain shrink-0"
        src={'/images/' + item.payment_option + '.png'}
      />
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between gap-2">
          <Title
            title={CurrencyFormatter(item.amount || 0)}
            className="!font-semibold !text-gray-800"
          />
        </div>

        <SharedTime date={item.created_at} />
      </div>
    </div>
  );
}

function UpdateServiceForm({ id, handleClose, refetch, employee, marketer }: any) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<any>({
    employee_id: employee,
    marketer_code: marketer
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [employees, setEmployees] = useState<any[]>([]);

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = (search_key?: string) =>
    prepareRequest(
      {
        url: 'users',
        params: { is_active: 1, user_type: 'employee', page: 1 }
      },
      (data) => {
        setEmployees(data.result?.users?.data || []);
      }
    );

  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/update_employee',
        data: {
          ...values,
          booking_item_id: id
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        handleClose(false);
        helper.resetForm();
        generateAlert(data.message, 'success');
        await refetch();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };

  const {
    formik: { values, handleChange, setFieldValue, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className="form-group">
        <label className="form-label">{t('expert')}</label>
        <Select
          options={employees}
          value={values?.employee_id}
          onSelect={(value) => setFieldValue('employee_id', value)}
          optionTxt="name"
          optionValue="id"
          type="single"
          onSearchChange={(ev) => getEmployees(ev.target.value)}
        />
        {errors.employee_id && <p className="form-error">{errors.employee_id}</p>}
      </div>
      <div className="form-group">
        <label className="form-label">{t('execution-time')}</label>
        <input
          type="datetime-local"
          id="booking_time"
          className="form-input form-outline"
          value={values?.booking_time}
          onChange={handleChange}
          min={new Date().toISOString()}
        />
        {errors.booking_time && <p className="form-error">{errors.booking_time}</p>}
      </div>

      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={resetForm}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

function CancelItems({ id, refetch, handleClose, items }: any) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<any>({
    booking_item_ids: [],
    booking_id: id
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/cancel',
        data: {
          ...values
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        handleClose(false);
        helper.resetForm();
        generateAlert(data.message, 'success');
        await refetch();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };

  const {
    formik: { values, handleChange, setFieldValue, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  const amount = useMemo(() => {
    let total = 0;

    const filterServices = items.filter((e: any) =>
      values.booking_item_ids.map(Number).includes(e.id)
    );

    for (const item of filterServices) {
      total += item.price || 0;
    }
    // return items.filter((e: any) => ['pending'].includes(e.status))
    return total;
  }, [values.booking_item_ids, items]);

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <ul className="divide-y divide-gray-200">
        {items.map((item: any, index: number) => (
          <ItemList
            className="py-3 relative"
            key={index}
          >
            <input
              type="checkbox"
              className="form-checkbox form-outline absolute top-1/2 -translate-y-1/2 right-0 rtl:right-auto rtl:left-0 peer"
              id={item.id}
              name="booking_item_ids"
              value={item.id}
              onChange={handleChange}
            />
            <label
              htmlFor={item.id}
              className="w-full flex items-center gap-4 cursor-pointer"
            >
              <Image
                className="w-10 h-10 rounded-full object-cover shrink-0"
                src={item.image}
              />
              <div className="space-y-0.5 text-start">
                <p className="text-sm font-semibold text-gray-800">{item.name}</p>
                <p className="text-xs font-semibold text-gray-500">
                  {CurrencyFormatter(item.price || 0)}
                </p>
              </div>
            </label>
            {errors?.['booking_item_id.' + index] ? (
              <p className="form-error">{errors?.['booking_item_id.' + index]}</p>
            ) : null}
          </ItemList>
        ))}
        <ItemList className="py-3">
          <p className="text-center text-gray-600">
            <b>{t('amount')}: </b>
            {CurrencyFormatter(amount)}
          </p>
        </ItemList>
      </ul>
      {errors?.booking_item_ids ? <p className="form-error">{errors?.booking_item_ids}</p> : null}
      {errors?.booking_id ? <p className="form-error">{errors?.booking_id}</p> : null}

      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={resetForm}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

function UpdateNotes({ id, refetch, handleClose, notes }: any) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<any>({
    notes,
    booking_id: id
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/update_notes',
        data: {
          ...values
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        handleClose(false);
        helper.resetForm();
        generateAlert(data.message, 'success');
        await refetch();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };

  const {
    formik: { values, handleChange, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className="form-group">
        <label className="form-label">{t('notes')}</label>

        <textarea
          className="form-input form-outline"
          onChange={handleChange}
          name="notes"
          value={values.notes}
          placeholder="..."
        ></textarea>
        {errors.notes && <p className="form-error">{errors.notes}</p>}
      </div>

      <button
        className="btn-with-icon bg-primary text-white"
        type="submit"
        disabled={disabled}
      >
        <span>{t('save-changes')}</span>
      </button>
    </form>
  );
}

function AddPayments({ id, refetch, handleClose, total }: any) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<any>({
    payments: [],
    booking_id: id
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/add_payments',
        data: {
          ...values
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        handleClose(false);
        helper.resetForm();
        generateAlert(data.message, 'success');
        await refetch();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };

  const {
    formik: { values, handleChange, setFieldValue, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <DisplayPayments
        payments={values.payments}
        onChange={setFieldValue}
        errors={errors}
        total={total}
      />
      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={resetForm}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

function UpdateCallCenterForm({ id, handleClose, refetch }: any) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<any>({
    booking_id: id,
    marketer_id: undefined
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [marketers, setMarketers] = useState<any[]>([]);

  useEffect(() => {
    getMarketers();
  }, []);
  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/update_employee',
        data: {
          ...values
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        handleClose(false);
        helper.resetForm();
        generateAlert(data.message, 'success');
        await refetch();
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };
  async function getMarketers(search?: string) {
    try {
      const { data } = await axiosInstance.get('users', {
        params: {
          user_type: 'marketer',
          is_active: 1,
          page: 1,
          search_key: search
        }
      });
      const users = data.result?.users?.data || [];

      setMarketers(
        users.map((e: any) => ({
          ...e,
          label: [e.name, '( ' + e.code + ' )'].join(' ')
        }))
      );
    } catch (error) {}
  }

  const {
    formik: { values, handleChange, setFieldValue, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className="form-group">
        <label className="form-label">{t('call-center')}</label>
        <Select
          options={marketers}
          value={values.marketer_id}
          onSelect={(value) => setFieldValue('marketer_id', value)}
          optionTxt="label"
          optionValue="id"
          onSearchChange={(ev) => getMarketers(ev.target.value)}
        />
        {errors.marketer_id && <p className="form-error">{errors.marketer_id}</p>}
      </div>
      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={resetForm}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

