import { Icon } from '@iconify/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import SvgProgressIcon from './SvgProgressIcon';

export default function CompleteAccountStepsBanner() {
  const { t } = useTranslation();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);

  const percentageIcon = useMemo(() => {
    let total = 0;

    const { centers_count, subdomain, package_offers_count, products_count, services_count } =
      user?.summary || {};

    total += !!centers_count ? 100 / 3 : 0;
    total += !!subdomain ? 100 / 3 : 0;
    total += !!(package_offers_count || products_count || services_count) ? 100 / 3 : 0;

    if (total === 100) {
      setIsComplete(true);
    }

    return (
      <SvgProgressIcon
        size="8rem"
        value={Number(total.toFixed(0))}
      />
    );
  }, [user]);

  if (isComplete) {
    return null;
  }

  return (
    <div className="mx-auto w-full max-w-screen-xl">
      <div className="w-full rounded-2xl bg-primary flex items-center gap-10 relative overflow-hidden text-gray-800 p-6 md:p-8 shadow-2xl shadow-primary/50">
        <div className="flex-1 self-start">
          <h2 className="text-2xl font-bold">{t('complete-steps')}</h2>
          <p className="text-base text-gray-600">{t('complete-steps-content')}</p>
          <ul className="mt-4 space-y-1">
            <li>
              <div className="inline-flex items-center gap-3">
                <Icon
                  icon={
                    !!user?.summary?.centers_count
                      ? 'solar:check-circle-bold'
                      : 'solar:danger-circle-linear'
                  }
                  className={!!user?.summary?.centers_count ? 'text-teal-600' : 'text-white'}
                  width="24"
                  height="24"
                />
                <p className="text-base text-gray-600">{t('add-centers')}</p>
              </div>
            </li>
            <li>
              <div className="inline-flex items-center gap-3">
                <Icon
                  icon={
                    !!user?.summary?.subdomain
                      ? 'solar:check-circle-bold'
                      : 'solar:danger-circle-linear'
                  }
                  className={!!user?.summary?.subdomain ? 'text-teal-600' : 'text-white'}
                  width="24"
                  height="24"
                />
                <p className="text-base text-gray-600">{t('add-subdomain')}</p>
              </div>
            </li>
            <li>
              <div className="inline-flex items-center gap-3">
                <Icon
                  icon={
                    !!(
                      user?.summary?.package_offers_count ||
                      user?.summary?.products_count ||
                      user?.summary?.services_count
                    )
                      ? 'solar:check-circle-bold'
                      : 'solar:danger-circle-linear'
                  }
                  className={
                    !!(
                      user?.summary?.package_offers_count ||
                      user?.summary?.products_count ||
                      user?.summary?.services_count
                    )
                      ? 'text-teal-600'
                      : 'text-white'
                  }
                  width="24"
                  height="24"
                />
                <p className="text-base text-gray-600">{t('add-items')}</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="shrink-0 hidden sm:block text-blue-900">{percentageIcon}</div>
        <p className="text-black/10 text-5xl text-center absolute left-8 rtl:left-auto rtl:right-8 -bottom-1">
          {t('complete-steps')}
        </p>
      </div>
    </div>
  );
}

