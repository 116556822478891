// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAQ0xTBAt2pY2hzy1E4_rqayQ5zTOd1K9Y',
  authDomain: 'salonat-eced6.firebaseapp.com',
  projectId: 'salonat-eced6',
  storageBucket: 'salonat-eced6.appspot.com',
  messagingSenderId: '873493158390',
  appId: '1:873493158390:web:197fc6035776ed86f6cfd7',
  measurementId: 'G-2R3K2CXYPS'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);

export default firebaseApp;
