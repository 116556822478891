import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

const Processing: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('processed_items', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.processed_items;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('product')}</th>
              <th>{t('wastage')}</th>
              <th>{t('wastage-value')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.product?.name}</td>
                    <td>{item.wastage?.name}</td>
                    <td>{item.value || 0}</td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </button>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
        size="!max-w-screen-md"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  item_id: string | undefined;
  wastages: any[];
  to_items: any[];
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    item_id: undefined,
    wastages: [
      {
        id: undefined,
        value: undefined
      }
    ],
    to_items: [
      {
        id: undefined,
        value: undefined
      }
    ]
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<any[]>([]);
  const [wastages, setWastages] = React.useState<any[]>([]);

  useEffect(() => {
    getWastages();
    getProducts();
  }, []);

  function getProducts(q?: string) {
    prepareRequest(
      {
        url: 'items',
        params: {
          is_active: 1,
          page: 1,
          item_type: 'product',
          search_key: q
        }
      },
      (data) => {
        setProducts(() => data.result?.items?.data || []);
      }
    );
  }

  function getWastages() {
    prepareRequest(
      {
        url: 'wastages',
        params: {
          is_active: 1
        }
      },
      (data) => {
        setWastages(() => data.result?.wastages || []);
      }
    );
  }

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'processed_items/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          closeModal(false);
          reFetching();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('product')}</label>
        <Select
          type="single"
          options={products}
          optionTxt="name"
          optionValue="id"
          onSelect={(value) => setFieldValue('item_id', value)}
          onSearchChange={(ev) => getProducts(ev.target.value)}
          value={values.item_id}
        />
        {errors?.item_id ? <span className="form-error">{errors?.item_id}</span> : null}
      </div>

      {values.to_items.map((_e: any, index: number) => {
        return (
          <DisplayProcessingItem
            key={index}
            {...{
              setFieldValue,
              handleChange,
              values,
              errors,
              index
            }}
          />
        );
      })}
      {values.wastages.map((_e: any, index: number) => {
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('wastage')}</label>
              <Select
                type="single"
                options={wastages}
                optionTxt="name"
                optionValue="id"
                onSelect={(value) => setFieldValue('wastages.' + index + '.id', value)}
                value={values.wastages[index]['id']}
              />
              {errors?.['wastages.' + index + '.id'] ? (
                <span className="form-error">{errors?.['wastages.' + index + '.id']}</span>
              ) : null}
            </div>

            <div className="form-group">
              <label className="form-label">{t('wastage-value')}</label>
              <div className="flex items-center gap-3">
                <input
                  type="number"
                  placeholder="0"
                  min={0}
                  step="any"
                  className="form-input form-outline"
                  value={values.wastages[index]['value']}
                  name={'wastages.' + index + '.value'}
                  onChange={handleChange}
                />
                {index != values.wastages?.length - 1 ? (
                  <button
                    className="btn-with-icon bg-red-100 !text-red-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        'wastages',
                        values.wastages.filter((_e: any, idx: number) => index !== idx)
                      );
                    }}
                  >
                    <Icon
                      icon="solar:trash-bin-minimalistic-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                ) : (
                  <button
                    className="btn-with-icon bg-blue-100 !text-blue-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue('wastages', [
                        ...values.wastages,
                        {
                          id: undefined,
                          value: undefined
                        }
                      ]);
                    }}
                    disabled={!(values.wastages[index]['id'] || values.wastages[index]['value'])}
                  >
                    <Icon
                      icon="solar:add-circle-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                )}
              </div>
              {errors?.['wastages.' + index + '.value'] ? (
                <span className="form-error">{errors?.['wastages.' + index + '.value']}</span>
              ) : null}
            </div>
          </div>
        );
      })}

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

function DisplayProcessingItem({ setFieldValue, handleChange, values, errors, index }: any) {
  const { t } = useTranslation();
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts(q?: string) {
    prepareRequest(
      {
        url: 'items',
        params: {
          is_active: 1,
          page: 1,
          item_type: 'product',
          search_key: q
        }
      },
      (data) => {
        const items = data.result?.items?.data || [];
        const ids = [values?.item_id, values?.to_items?.map((item: any) => item.id)].filter(
          (v) => v
        );
        const filterItems = items.filter((item: any) => !ids.includes(item.id));

        setProducts(() => filterItems);
      }
    );
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="form-group">
        <label className="form-label">{t('product')}</label>
        <Select
          type="single"
          options={products}
          optionTxt="name"
          optionValue="id"
          onSelect={(value) => setFieldValue('to_items.' + index + '.id', value)}
          value={values?.to_items[index]['id']}
        />
        {errors?.['to_items.' + index + '.id'] ? (
          <span className="form-error">{errors?.['to_items.' + index + '.id']}</span>
        ) : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('value')}</label>
        <div className="flex items-center gap-3">
          <input
            type="number"
            placeholder="0"
            min={0}
            step="any"
            className="form-input form-outline"
            value={values?.to_items[index]['value']}
            name={'to_items.' + index + '.value'}
            onChange={handleChange}
          />
          {index != values?.to_items?.length - 1 ? (
            <button
              className="btn-with-icon bg-red-100 !text-red-600 h-full"
              type="button"
              onClick={() => {
                setFieldValue(
                  'to_items',
                  values?.to_items.filter((_e: any, idx: number) => index !== idx)
                );
              }}
            >
              <Icon
                icon="solar:trash-bin-minimalistic-outline"
                width="20"
                height="20"
              />
            </button>
          ) : (
            <button
              className="btn-with-icon bg-blue-100 !text-blue-600 h-full"
              type="button"
              onClick={() => {
                setFieldValue('to_items', [
                  ...values?.to_items,
                  {
                    id: undefined,
                    value: undefined
                  }
                ]);
              }}
              disabled={!(values?.to_items[index]['id'] || values.to_items[index]['value'])}
            >
              <Icon
                icon="solar:add-circle-outline"
                width="20"
                height="20"
              />
            </button>
          )}
        </div>
        {errors?.['to_items.' + index + '.value'] ? (
          <span className="form-error">{errors?.['to_items.' + index + '.value']}</span>
        ) : null}
      </div>
    </div>
  );
}

export default Processing;

