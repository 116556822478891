import { ReactNode } from 'react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import { EffectCards, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from '../shared/Image';
import { Link } from 'react-router-dom';
export default function AuthWrapper({
  children,
  isSingleApp
}: {
  children?: ReactNode;
  isSingleApp?: boolean;
}) {
  return (
    <div className="flex-1 py-8 px-6">
      <div className="xl:container">
        <div className="flex items-center gap-6">
          <div className={`w-full ${!isSingleApp && 'lg:w-1/2'}`}>{children}</div>

          {!isSingleApp && (
            <div className="hidden lg:block lg:w-[70%] relative">
              <p className="text-center text-txt mb-2">ألقِ نظرة على آخر التحديثات</p>

              <Swiper
                effect="cards"
                grabCursor={true}
                modules={[EffectCards, Pagination]}
                centeredSlides
                className="w-[70%] max-w-md mx-auto auth-swiper"
                pagination={{ clickable: true }}
              >
                {Array.from({ length: 4 })
                  .fill(0)
                  .map((_e, index: number) => (
                    <SwiperSlide
                      key={index}
                      className="w-full h-[50vh] max-h-[50rem]"
                    >
                      <div className="shadow-2xl shadow-gray-800/20 transition">
                        <Image
                          src="https://images.unsplash.com/photo-1577058109956-67adf6edc586?w=1024&auto=format"
                          alt="girl-paying-her-latte-with-smartphone-by-contactless-pay-pass-technology"
                          title="girl-paying-her-latte-with-smartphone-by-contactless-pay-pass-technology"
                          width={1024}
                          height={1080}
                          className="w-full  h-[50vh] max-h-[50rem] object-cover object-center"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>

              <p className="text-txt text-center p-2">
                مستويات التسعير إضافة جديدة تخفّض أسعار الشحن للتجار. دائمًا أفضل الأسعار.
              </p>

              <div className="flex items-center justify-center text-txt mt-10">
                <Link
                  to="/tutorials/"
                  className="block group border-b-2 border-txt"
                >
                  مساعدة
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

