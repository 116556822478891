import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { routes } from 'src/helper/routes';
import { RootState } from 'src/store';

export const PERMISSIONS: Record<Partial<UserType>, string[]> = {
  admin: [
    'dashboard',
    'pos',
    'bookings',
    'invoices',
    'reports',
    'logs',
    'settings',
    'marketers',
    'cashiers',
    'clients',
    'experts',
    'admins',
    'salaries',
    'centers',
    'categories',
    'items',
    'emails',
    'website',
    'features',
    'calendar',
    'expenses'
  ],

  cashier: ['dashboard', 'bookings', 'pos', 'profile', 'calendar'],
  marketer: ['dashboard', 'bookings', 'pos', 'profile', 'calendar'],
  employee: [],
  client: [],
  supplier: []
};

export default function usePermissions() {
  const { user } = useSelector((state: RootState) => state.auth);

  const hasPermission = useCallback(
    (value: string, type: UserType) => PERMISSIONS[type]?.includes(value),
    [PERMISSIONS]
  );

  const filterRoutes = useMemo(() => {
    return routes.filter((route: any) =>
      user?.user_type == 'owner'
        ? route
        : PERMISSIONS[user?.user_type as UserType]?.includes(route.permission) ||
          route.path === '*' ||
          !route.permission
    );
  }, [user]);

  return { filterRoutes, hasPermission };
}
