import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AppSelect from 'src/components/shared/AppSelect';
import Card from 'src/components/shared/Card';
import CardPlaceholder from 'src/components/shared/CardPlaceholder';
import Controllable from 'src/components/shared/Controllable';
import DisplayPayments from 'src/components/shared/DisplayPaymentsForm';
import ItemList from 'src/components/shared/ItemList';
import Modal from 'src/components/shared/Modal';
import SearchBox from 'src/components/shared/SearchBox';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function NewPos() {
  const { t } = useTranslation();
  const submitterBtn = useRef<HTMLInputElement>(null);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [completeVisible, setCompleteVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const [search, setSearch] = useSearchParams({
    page: '1',
    item_type: 'service',
    per_page: '50'
  });

  const { formik } = useForm({
    initialValues: {
      payments: [],
      client_id: undefined,
      booking_time: undefined,
      items: [],
      client: undefined
    },
    submitHandler(values, formikHelpers) {
      const pickedData = pick(values, ['client_id', 'items', 'payments', 'booking_time']);
      const items = new Array().concat(
        ...pickedData.items.map((item: any) => Array.from({ length: item.qty }).fill(item))
      );

      const data = {
        ...pickedData,
        items: items.map((item: any) => {
          const { id: item_id, ...rest } = pick(item, ['id', 'variant_id', 'option_ids']);
          return {
            item_id,
            ...rest
          };
        }),
        booking_time: moment(pickedData.booking_time).format('yyyy-MM-DD HH:mm')
      };
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'bookings/new',
          method: 'post',
          data
        },
        (response, error) => {
          if (error) return setErrors(() => error);
          generateAlert(response.message, 'success');
          window.location.reload();
        }
      ).finally(() => {
        setDisabled(false);
      });
    }
  });

  useEffect(() => {
    getItems();
    getCategories();
  }, [search]);

  function saveChanges() {
    submitterBtn.current?.click();
  }

  function getItems(params?: any) {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'items',
        params: search
      },
      (data) => {
        const result = data.result?.items;
        const arr = result.data || [];
        const mapItems = arr.map((item: any) => ({
          ...item,
          price: item.variants?.length ? item.variants?.[0]?.price || 0 : item.price
        }));
        setItems(() => mapItems);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  function getCategories() {
    prepareRequest(
      {
        url: 'categories'
      },
      (data) => {
        const result = data.result?.categories;

        setCategories(() => result);
      }
    );
  }

  function clearAllQueries() {
    for (const [key] of search.entries()) {
      setSearch((params) => {
        params.delete(key);
        return params;
      });
    }

    setSearch((params) => {
      params.set('page', '1');
      params.set('item_type', 'service');
      return params;
    });
  }

  function getClients(ev: ChangeEvent<HTMLInputElement>) {
    const { value } = ev.target;
    prepareRequest(
      {
        url: 'users',
        params: {
          user_type: 'client',
          page: 1,
          search_key: value
        }
      },
      (data) => {
        setClients(() => data.result.users?.data || []);
      }
    );
  }

  const totalPrice = useMemo(() => {
    let price = 0;
    formik.values.items?.map((item: any) => {
      let total = 0;
      if (!!(item.variant_id || item.option_ids?.length)) {
        const variant = item.variants.find((e: any) => e.id == item.variant_id);
        total = variant?.price;

        const options =
          item.options.filter((opt: any) => item.option_ids?.some((op: string) => op == opt.id)) ||
          [];
        options.forEach((opt: any) => {
          total += opt.price || 0;
        });
      } else {
        total = item.price;
      }

      price += total * item.qty;
    });
    return price;
  }, [formik.values]);

  const Client = useMemo(() => {
    if (!formik.values.client_id) return;
    const client = clients.find((e: any) => e.id == formik.values.client_id);
    formik.setFieldValue('client', client);
    return `${client?.name} - ${client?.mobile}`;
  }, [formik.values.client_id]);

  return (
    <>
      <form
        className="min-h-screen hidden xl:flex"
        onSubmit={formik.handleSubmit}
      >
        <div className="w-full max-w-md bg-gray-50 min-h-full border-e border-e-gray-200">
          <div className="sticky top-0 flex flex-col min-h-screen max-h-screen divide-y divide-gray-200">
            <div className="space-y-4 p-6">
              <div className="form-group">
                <div className="flex justify-between gap-4">
                  <p className="form-label">{t('booking-time')}</p>
                  {!formik.values.booking_time ? (
                    <p className="form-label !text-blue-600">({t('asap')})</p>
                  ) : null}
                </div>
                <input
                  type="datetime-local"
                  name="booking_time"
                  id="booking_time"
                  className="form-input form-outline"
                  value={formik.values.booking_time}
                  onChange={formik.handleChange}
                  min={new Date().toISOString()}
                />
                <p className="form-error">{errors['booking_time']}</p>
              </div>
            </div>
            <ul className="flex-1 overflow-y-auto py-6 divide-y divide-gray-200">
              {formik.values?.items?.map((item: any) => (
                <CardView
                  key={item.id}
                  item={item}
                  formik={formik}
                  errors={errors}
                />
              ))}
            </ul>
            <div className="px-6 py-4">
              <div className="flex gap-4 justify-between">
                <p className="form-label">{t('total')}</p>
                <p className="form-label !text-black !font-semibold">
                  {CurrencyFormatter(totalPrice)}
                </p>
              </div>
            </div>
            <div className="p-6">
              <button
                className="btn-with-icon !w-full !p-3 !bg-primary !rounded-full"
                type="button"
                onClick={() => setCompleteVisible(true)}
                disabled={disabled || !totalPrice}
              >
                <span>{t('complete-reservation')}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full min-h-full flex-1">
          <div className="w-full pt-8 px-6 sticky top-0 space-y-4 bg-white border-b border-b-gray-200 z-10">
            <p className="text-xl text-center font-bold">{t('pos')}</p>
            <p className="text-center text-gray-600">{t('pos-content')}</p>
            <div className="flex gap-3">
              {search ? (
                <button
                  className="btn-with-icon !bg-gray-200 !rounded-full !text-gray-600 !text-xs self-center"
                  onClick={clearAllQueries}
                  type="button"
                >
                  <span>{t('clear')}</span>
                </button>
              ) : null}

              <SearchBox
                className="flex-1 shrink-0"
                value={search.get('search_key') as string}
                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  setSearch((params) => {
                    params.set('search_key', ev.target.value);
                    return params;
                  })
                }
              />
              <select
                name="type"
                id="type"
                className="form-select shrink-0 max-w-fit form-outline"
                value={search.get('item_type') as string}
                onChange={(ev: ChangeEvent<HTMLSelectElement>) =>
                  setSearch((params) => {
                    params.set('item_type', ev.target.value);
                    return params;
                  })
                }
              >
                <option value="service">{t('services')}</option>
                <option value="product">{t('products')}</option>
                <option value="package_offer">{t('packages-offers')}</option>
              </select>
            </div>
            <div className="w-full overflow-x-auto flex-1">
              <ul className="flex">
                {categories.map((cat) => (
                  <li key={cat.id}>
                    <button
                      type="button"
                      className={`pb-3 px-4 text-sm transition  ${
                        cat.id == search.get('category_id')
                          ? 'border-b-2 border-primary'
                          : 'text-gray-500'
                      }`}
                      onClick={() =>
                        setSearch((params) => {
                          params.set('category_id', cat.id);
                          return params;
                        })
                      }
                    >
                      <span>{cat.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="grid grid-wrapper gap-4 p-6">
            <div className="col-span-full">
              <div className="flex justify-between gap-4">
                <p className="form-label">{t('items')}</p>
                {/* <p className="form-label">{t('show')}</p> */}
              </div>
            </div>

            {isLoading ? (
              <>
                {Array.from({ length: 8 }).map((_e, index) => (
                  <CardPlaceholder key={index} />
                ))}
              </>
            ) : (
              <>
                {items.length ? (
                  items.map((item) => (
                    <ItemCard
                      key={item.id}
                      item={item}
                      formik={formik}
                    />
                  ))
                ) : (
                  <p className="text-center text-gray-600 text-sm col-span-full">{t('no-data')}</p>
                )}
              </>
            )}
          </div>
        </div>

        <Modal
          visible={completeVisible}
          title={`${t('complete-reservation')} - ${CurrencyFormatter(totalPrice)}`}
          handleClose={() => setCompleteVisible(false)}
        >
          <div className="space-y-4">
            <div className="form-group">
              <p className="form-label">{t('client')}</p>
              <AppSelect
                options={clients}
                value="id"
                values={formik.values.client_id}
                onChange={getClients}
                onSelect={formik.handleChange}
                optionName="client_id"
                placeholder={t('user-search')}
                RenderOption={({ option }) => (
                  <>
                    <p className="font-semibold text-black text-sm">{option.name}</p>
                    <p className="text-gray-500 text-sm">{option.mobile}</p>
                  </>
                )}
              />
              {formik.values.client_id ? (
                <button
                  className="btn-with-icon bg-gray-100 !text-gray-500 !text-xs"
                  type="button"
                  onClick={() => formik.setFieldValue('client_id', undefined)}
                >
                  <span>{Client}</span>
                  <Icon
                    icon="mdi:remove"
                    width="18"
                    height="18"
                  />
                </button>
              ) : null}
              <p className="form-error">{errors['client_id']}</p>
            </div>
            <DisplayPayments
              payments={formik.values.payments}
              total={totalPrice}
              onChange={formik.setFieldValue}
              errors={errors}
            />
            <button
              className="btn-with-icon !w-full !p-3 !bg-primary !rounded-full"
              type="button"
              disabled={!totalPrice || disabled}
              onClick={saveChanges}
            >
              <span>{t('save-changes')}</span>
            </button>
          </div>
        </Modal>
        <input
          type="submit"
          hidden
          ref={submitterBtn}
        />
      </form>
      <div className="px-6 py-10 container xl:hidden text-center">
        <Card className="!p-6 space-y-4">
          <span className="rounded-full p-4 bg-gray-100 text-gray-500 mx-auto flex relative max-w-fit">
            <Icon
              icon="tabler:devices-off"
              width="32"
              height="32"
            />
          </span>
          <div className="space-y-2">
            <p className="font-bold text-lg">{t('not-allowed')}</p>
            <p className="text-gray-600 text-sm">{t('pos-screen-size')}</p>
          </div>
        </Card>
      </div>
    </>
  );
}

// type Keys = 'name' | 'alt_name' | 'id' | 'items' | 'variants' | 'options' | 'price';
function ItemCard({ item, formik }: { item: any; formik: any }) {
  const { t } = useTranslation();
  const items = useMemo(() => formik.values?.items || [], [formik.values]);
  const existed = useMemo(() => items?.find((e: any) => e.id == item.id), [items]);

  const index = useMemo(() => {
    const itemIndex = items?.findIndex((e: any) => e.id == item.id);
    return itemIndex;
  }, [items]);

  const [visible, setVisible] = useState<boolean>(false);
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
  const [value, setValue] = useState<any>({
    ...pick(item, ['id', 'qty', 'variant_id', 'price', 'name', 'options', 'variants']),
    ...pick(existed, ['option_ids'])
  });

  function addToList(data: any) {
    if (!existed) {
      formik.setFieldValue('items', [...items, { ...data, qty: 1 }]);
      generateAlert(`${item.name} Added`, 'success');
      return;
    }

    const mapItems = items.map((e: any) => {
      return {
        ...e,
        qty: e.id == item.id ? e.qty + 1 : e.qty
      };
    });

    formik.setFieldValue('items', mapItems);
    generateAlert(`${item.name} Updated`, 'success');
  }

  function updateItemVariant(ev: ChangeEvent<HTMLInputElement>) {
    const { value: variant_id } = ev.target;
    let obj;
    obj = {
      qty: 1,
      ...(existed ? existed : value),
      variant_id
    };
    formik.setFieldValue('items', [...items, obj]);
    generateAlert(`${item.name} Added`, 'success');
  }

  function updateItemOptions(ev: ChangeEvent<HTMLInputElement>) {
    const { value: option_id, checked } = ev.target;
    let obj: any;

    if (checked) {
      obj = {
        qty: 1,

        ...(existed ? existed : value),
        ...(existed
          ? { option_ids: [...(existed.option_ids || []), option_id] }
          : { option_ids: [option_id] })
      };
    } else {
      obj = {
        qty: 1,

        ...(existed ? existed : value),
        ...(existed
          ? { option_ids: [...(existed.option_ids || []).filter((e: string) => e !== option_id)] }
          : { option_ids: [] })
      };
    }

    const mapItems = items.map((ev: any) => {
      return ev.id == item.id ? obj : ev;
    });

    formik.setFieldValue('items', mapItems);
    generateAlert(`${item.name} options updated`, 'success');
  }

  return (
    <>
      <Card className="flex items-center gap-4">
        <div className="flex-1">
          <p className="font-semibold ">{CurrencyFormatter(item.price || 0)}</p>
          <div className="flex items-center gap-2">
            {item.items?.length ? (
              <button
                className="text-blue-600 relative"
                type="button"
                onClick={() => setDetailsVisible(true)}
              >
                <span className="w-full h-full rounded-full bg-blue-600 opacity-50 animate-ping absolute inset-0"></span>
                <Icon
                  icon="material-symbols:info-outline"
                  width="18"
                  height="18"
                  className="relative"
                />
              </button>
            ) : null}
            <span className="font-semibold text-sm text-gray-600">{item.name}</span>
          </div>
        </div>
        <button
          className="btn-with-icon !rounded-full bg-blue-600 !p-2"
          type="button"
          onClick={() =>
            !!(item.variants?.length || item.options?.length) ? setVisible(true) : addToList(value)
          }
        >
          <Icon
            icon="ic:baseline-plus"
            width="18"
            height="18"
          />
        </button>
      </Card>
      <Modal
        visible={visible}
        title={`${t('select-variants')} - ${item.name}`}
        handleClose={() => setVisible(false)}
      >
        <div className="space-y-4">
          {item.variants?.length ? (
            <>
              <p className="text-sm text-gray-500">{t('variants')}</p>
              <ul className="flex flex-wrap gap-2">
                {item.variants?.map((variant: any) => (
                  <li key={variant.id}>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        name=""
                        id={'radio-id-' + variant.id}
                        className="hidden peer"
                        value={variant.id}
                        onChange={updateItemVariant}
                        checked={existed?.variant_id == variant.id}
                        hidden
                      />
                      <label
                        htmlFor={'radio-id-' + variant.id}
                        className="btn-with-icon outline-btn peer-checked:bg-primary cursor-pointer peer-checked:border-white peer-checked:text-white transition"
                      >
                        {variant.name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
          {item.options?.length ? (
            <>
              <p className="text-sm text-gray-500">{t('options')}</p>
              <ul className="flex flex-wrap gap-2">
                {item.options?.map((option: any) => (
                  <li key={option.id}>
                    <div className="flex gap-2">
                      <input
                        type="checkbox"
                        name=""
                        id={'checkbox-id-' + option.id}
                        className="peer"
                        hidden
                        disabled={index < 0}
                        value={option.id}
                        onChange={updateItemOptions}
                        checked={existed?.option_ids?.some((e: string) => e == option.id)}
                      />
                      <label
                        htmlFor={'checkbox-id-' + option.id}
                        className="btn-with-icon outline-btn peer-checked:bg-primary cursor-pointer peer-checked:border-white peer-checked:text-white transition peer-disabled:cursor-not-allowed"
                      >
                        {option.name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </Modal>
      <Modal
        visible={detailsVisible}
        title={`${t('details')} - ${item.name}`}
        handleClose={() => setDetailsVisible(false)}
      >
        <ul className="divide-y divide-gray-200">
          {item.items?.map((_item: any) => (
            <ItemList
              className="py-4"
              key={_item.id}
            >
              <div className="flex justify-between gap-4">
                <p className="font-semibold">{_item.name}</p>
                <p className="font-semibold ">{CurrencyFormatter(_item.price || 0)}</p>
              </div>
            </ItemList>
          ))}
        </ul>
      </Modal>
    </>
  );
}

function CardView({ item, formik, errors }: { item: any; formik: any; errors: any }) {
  const index = useMemo(() => {
    const items = formik.values?.items || [];
    const itemIndex = items?.findIndex((e: any) => e.id == item.id);
    return itemIndex;
  }, [formik.values]);

  const price = useMemo(() => {
    let total = 0;
    if (!!(item.variant_id || item.option_ids?.length)) {
      const variant = item.variants.find((e: any) => e.id == item.variant_id);
      total = variant?.price;

      const options =
        item.options.filter((opt: any) => item.option_ids?.some((op: string) => op == opt.id)) ||
        [];
      options.forEach((opt: any) => {
        total += opt.price || 0;
      });
    } else {
      total = item.price;
    }

    return total;
  }, [formik.values]);

  function removeItem() {
    const filterItems = formik.values?.items?.filter((e: any) => e.id !== item.id);
    formik.setFieldValue('items', filterItems);
    generateAlert(`${item.name} removed`, 'success');
  }

  return (
    <ItemList className="py-4 px-6">
      <div className="flex gap-4">
        <button
          className="text-red-500"
          type="button"
          onClick={removeItem}
        >
          <Icon
            icon="dashicons:remove"
            width="18"
            height="18"
          />
        </button>
        <div className="flex-1">
          <p className="font-semibold">
            <span>{item.qty}x</span> <span>{CurrencyFormatter(price)}</span>
          </p>
          <p className="text-sm line-clamp-1">{item.name}</p>
          <span className="from-error">{errors['item.' + index + '.item_id']}</span>
          <span className="from-error">{errors['item.' + index + '.variant_id']}</span>
          <span className="from-error">{errors['item.' + index + '.option_ids']}</span>
        </div>
        <div className="shrink-0">
          <Controllable
            value={item.qty}
            onChange={(val) => {
              if (!val) {
                removeItem();
                generateAlert('When quantity equal 0 the item will be removed.', 'info');
                return;
              }
              formik.setFieldValue(`items.${index}.qty`, val);
            }}
          />
        </div>
      </div>
    </ItemList>
  );
}
