import { Icon } from '@iconify/react';
import React from 'react';

export default function StatusTracking({ statuses }: { statuses: any[] }) {
  return (
    <div>
      <ul className="flex items-start max-w-sm mx-auto">
        {statuses.map((status: any, i: number) => (
          <li
            className="flex-[fit-content] whitespace-nowrap flex flex-col gap-2 items-center justify-center"
            data-type="status-track"
            key={i}
          >
            <div
              className="w-full relative flex items-center justify-center "
              data-type="status-track-slide"
            >
              <span
                className={[
                  'w-6 h-6 rounded-full flex items-center justify-center z-[1]',
                  status.created_at ? 'bg-teal-600 text-white' : 'bg-gray-200 text-gray-500'
                ].join(' ')}
              >
                <Icon
                  icon={status.created_at ? 'uil:check' : 'bx:stop'}
                  width="16"
                />
              </span>
            </div>
            <div className="text-center space-y-0.5 px-2 ">
              <p className="text-xs font-semibold text-gray-600 capitalize">{status.label}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
