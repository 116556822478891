import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import { RootState } from 'src/store';
import LoadingComponent from '../shared/LoadingComponent';
import PackageCard from '../shared/PackageCard';
import Table from '../shared/tables/Table';

export default function Subscription() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [packages, setPackages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getPackages();
  }, []);

  function getPackages() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'packages'
      },
      (data) => {
        setPackages(() => data?.result?.packages || []);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="flex-1 space-y-4">
      <p className="text-lg font-bold">{t('available-packages')}</p>
      <ul className="grid grid-wrapper gap-4">
        {packages?.map((item) => (
          <li
            key={item.id}
            className="h-full"
          >
            <PackageCard {...item} />
          </li>
        ))}
      </ul>
      <p className="text-lg font-bold">{t('price-comparison')}</p>
      <Table
        loading={isLoading}
        RenderHead={() => (
          <tr>
            <th>{t('name')}</th>
            <th>{t('monthly-price')}</th>
            <th>{t('yearly-price')}</th>
            <th>{t('extra-center-fees')}</th>
            <th>{t('centers-count')}</th>
            <th>{t('users-count')}</th>
            <th>{t('invoices-count')}</th>
            <th>{t('permissions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {packages.map((item) => (
              <tr key={item.id}>
                <td>
                  {item.name} - {item.alt_name} <br />
                  {item.desc}
                </td>
                <td>{CurrencyFormatter(item.monthly_price)}</td>
                <td>{CurrencyFormatter(item.yearly_price)}</td>
                <td>{CurrencyFormatter(item.extra_center_fees)}</td>
                <td>{item.centers_count || 0}</td>
                <td>{item.center_users_count || 0}</td>
                <td>{item.invoices_count || 0}</td>
                <td>
                  <div className="inline-flex gap-2 w-96 flex-wrap">
                    {item.permissions?.sort()?.map((permission: string, index: number) => (
                      <span
                        key={index}
                        className="leading-5 text-xs rounded bg-gray-100 text-gray-600 font-medium inline-block py-0.5 px-1"
                      >
                        {t(permission)}
                      </span>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
      />
    </div>
  );
}
