import Card from './Card';

export default function CardPlaceholder() {
  return (
    <Card className="space-y-2">
      <div className="w-1/3 bg-gray-200 animate-pulse rounded-lg h-4"></div>
      <div className="w-full bg-gray-200 animate-pulse rounded-lg h-3"></div>
      <div className="w-1/2 bg-gray-200 animate-pulse rounded-lg h-3"></div>
    </Card>
  );
}
