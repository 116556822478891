import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from 'src/components/shared/Alert';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import MapPreview from 'src/components/shared/MapPreview';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import { useAlert } from 'src/hooks/alerts';
import { RootState } from 'src/store';

const FAQTypes: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [mapVisible, setMapVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);
  const { user } = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('centers', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.centers;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const DisplayAddress = React.useCallback(
    (item: any): string => {
      const concatLocations: any[] | null | undefined = new Array()
        .concat(item?.region, item?.city, item?.district)
        ?.filter((e) => e);

      return concatLocations?.map((e) => e?.name || e?.alt_name)?.join(', ');
    },
    [responses]
  );

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('mobile')}</th>
              <th>{t('email')}</th>
              <th>{t('cr-no')}</th>
              <th>{t('vat-no')}</th>
              <th>{t('bank-name')}</th>
              <th>IBAN</th>
              <th>{t('address')}</th>
              <th>{t('status')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);

                return (
                  <tr key={index}>
                    <td>{item.name || item.alt_name || '-'}</td>
                    <td>{item.mobile || '-'}</td>
                    <td>{item.email || '-'}</td>
                    <td>{item.cr_no || '-'}</td>
                    <td>{item.vat_no || '-'}</td>
                    <td>{item.bank_name || '-'}</td>
                    <td>{item.iban || '-'}</td>

                    <td>{item.address || DisplayAddress(item) || '-'}</td>

                    <td>
                      <ChangeStatus
                        active={!!item.is_active}
                        refetch={GetItems}
                        pathname={'centers/update_is_active/' + item.id}
                      />
                    </td>

                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <Link
                          to={'/settings/centers/' + item.id}
                          className="edit-btn"
                        >
                          {t('edit')}
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <Link
                to="/settings/centers/add"
                className="btn-with-icon bg-blue-600 !text-white  shrink-0"
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </Link>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));

            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        title={editItem?.name || editItem?.alt_name}
        visible={mapVisible}
        handleClose={() => setMapVisible(false)}
      >
        {editItem?.lat && editItem?.lng ? (
          <MapPreview
            lat={+editItem?.lat}
            lng={+editItem?.lng}
          />
        ) : (
          <Alert
            content={t('no-data')}
            type="info"
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default FAQTypes;

