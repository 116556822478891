import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrintPDF from 'src/components/invoices/PrintPDF';
import Modal from 'src/components/shared/Modal';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function UpdatePayments({ id, refetch, item, btnTitle }: any) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <button
        className="btn-with-icon !text-blue-600 !p-0"
        type="button"
        onClick={() => setVisible(true)}
      >
        <Icon
          icon="iconoir:edit"
          width="16"
        />
        <span>{btnTitle ? btnTitle : t('edit')}</span>
      </button>

      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('payments')}
      >
        <UpdatePaymentsForm
          id={id}
          item={item}
          refetch={refetch}
          handleClose={setVisible}
        />
      </Modal>
    </>
  );
}

export function UpdatePaymentsForm({ id, refetch, handleClose, item }: any) {
  const { t } = useTranslation();
  const QRRef = useRef<HTMLButtonElement>(null);

  const [initialValues, setInitialValues] = useState<any>({
    payments: [
      {
        payment_option: 'cash',
        amount: item?.payments?.find((e: any) => e.payment_option === 'cash')?.amount
      },
      {
        payment_option: 'mada',
        amount: item?.payments?.find((e: any) => e.payment_option === 'mada')?.amount
      },
      {
        payment_option: 'visa',
        amount: item?.payments?.find((e: any) => e.payment_option === 'visa')?.amount
      },
      {
        payment_option: 'bank_transfer',
        amount: item?.payments?.find((e: any) => e.payment_option === 'bank_transfer')?.amount
      }
    ],
    booking_id: id
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [booking, setBooking] = useState<any>();
  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    const filterPayments = values.payments.filter((e: any) => e.amount);
    prepareRequest(
      {
        method: 'post',
        url: 'bookings/update_payments',
        data: {
          ...values,
          payments: filterPayments
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        helper.resetForm();
        generateAlert(data.message, 'success');
        setBooking(() => data.result.booking);
        
        setTimeout(() => {
          QRRef.current?.click();
        }, 100);
        // await refetch();
        // handleClose(false);
      }
    ).finally(() => {
      setDisabled(false);
    });
  };

  const resetForm = () => {
    handleClose(false);
  };

  const {
    formik: { values, handleChange, setFieldValue, handleReset, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <>
      <form
        className="space-y-4"
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {values.payments.map((payment: any, index: number) => (
          <div
            className="form-group"
            key={payment.id}
          >
            <label className="form-label">{t(payment.payment_option)}</label>
            <input
              type="number"
              className="form-input form-outline"
              placeholder={t('amount')}
              value={payment.amount}
              onChange={handleChange}
              name={'payments.' + index + '.amount'}
              step="any"
            />
            {errors?.['payments.' + index + '.amount'] && (
              <p className="form-error">{errors?.['payments.' + index + '.amount']}</p>
            )}
            {errors?.['payments.' + index + '.payment_option'] && (
              <p className="form-error">{errors?.['payments.' + index + '.payment_option']}</p>
            )}
            {errors?.['payments.' + index + '.id'] && (
              <p className="form-error">{errors?.['payments.' + index + '.id']}</p>
            )}
          </div>
        ))}
        <div className="flex gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary text-white"
            type="submit"
          >
            {disabled ? (
              <Icon
                icon="svg-spinners:3-dots-fade"
                width={20}
              />
            ) : (
              <span>{t('submit')}</span>
            )}
          </button>
          <button
            className="btn-with-icon outline-btn"
            type="reset"
            onClick={resetForm}
          >
            <span>{t('cancel')}</span>
          </button>
        </div>
      </form>
      {booking && (
        <div className="fixed bottom-[calc(200vh)]">
          <PrintPDF
            item={booking}
            trigger={
              <button
                type="button"
                ref={QRRef}
              ></button>
            }
          />
        </div>
      )}
    </>
  );
}
