import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarEvents from 'src/components/shared/Calendar';
import Card from 'src/components/shared/Card';
import ItemList from 'src/components/shared/ItemList';
import Modal from 'src/components/shared/Modal';
import prepareRequest from 'src/helper/prepareRequest';
import { v4 as uuid } from 'uuid';

export default function Calendar() {
  let render = true;
  const { t } = useTranslation();
  const [events, setEvents] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [eventVisible, setEventVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [restEvents, setRestEvents] = useState<any[]>([]);

  // useEffect(() => {
  //   if (!render) return;
  //   getEvents();
  //   render = false;
  // }, []);

  function getEvents(params?: any) {
    // setIsLoading(true);
    prepareRequest({ url: 'calendar', params }, (data) => {
      const calendar = (data.result?.calendar || []).map((e: any) => ({ ...e, id: uuid() }));
      const color = {
        completed: 'green',
        running: 'orange',
        pending: 'gray',
        cancelled: 'red'
      } as any;
      const result = calendar.map((e: any) => ({
        date: e.time,
        id: e.id,
        title: e.employee?.name || e.name,
        color: color[e.status || 'pending']
      }));

      setRestEvents(() => calendar);
      setEvents(() => result);
    });
  }

  // if (isLoading) return <LoadingComponent />;

  return (
    <div className="p-6">
      <CalendarEvents
        events={events}
        eventClassNames="cursor-pointer text-start p-1.5 rounded m-0.5"
        dayHeaderClassNames="!p-3 text-sm text-center bg-gray-100"
        eventClick={(arg) => {
          const selected = restEvents.find((ev) => ev.id == arg.event.id);
          setSelectedEvent(() => selected);
          setEventVisible(() => true);
        }}
        datesSet={({ startStr, endStr }) => {
          getEvents({
            from: startStr.substring(0, 10),
            to: endStr.substring(0, 10)
          });
        }}
      />
      <Modal
        visible={eventVisible}
        handleClose={() => setEventVisible(false)}
        title={selectedEvent?.name}
        size="max-w-screen-sm"
      >
        <EventView event={selectedEvent} />
      </Modal>
    </div>
  );
}

function EventView({ event }: { event: any }) {
  const { t } = useTranslation();
  return (
    <div className="space-y-4">
      <p className="form-label">{t('client-details')}</p>
      <Card>
        <ul className="divide-y divide-gray-200">
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('name')}:</p>
              <p className="form-label !text-black !font-semibold">
                {event?.client?.name || event?.client_name || '-'}
              </p>
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('mobile')}:</p>
              <p className="form-label !text-black !font-semibold">
                {event?.client?.mobile || event?.client_mobile || '-'}
              </p>
            </div>
          </ItemList>
        </ul>
      </Card>
      <p className="form-label">{t('employee')}</p>
      <Card>
        <ul className="divide-y divide-gray-200">
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('name')}:</p>
              <p className="form-label !text-black !font-semibold">
                {event?.employee?.name || '-'}
              </p>
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('mobile')}:</p>
              <p className="form-label !text-black !font-semibold">
                {event?.employee?.mobile || '-'}
              </p>
            </div>
          </ItemList>
        </ul>
      </Card>
      <p className="form-label">{t('service')}</p>
      <Card>
        <ul className="divide-y divide-gray-200">
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('name')}:</p>
              <p className="form-label !text-black !font-semibold">{event?.name || '-'}</p>
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('status')}:</p>
              <p className="form-label !text-black !font-semibold">
                {t(`statuses.${event.status}`)}
              </p>
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-3">
              <p className="form-label">{t('date')}:</p>
              <p className="form-label !text-black !font-semibold">
                {moment(event?.time).format('ll, hh:mm:ssA') || '-'}
              </p>
            </div>
          </ItemList>
        </ul>
      </Card>
    </div>
  );
}
