import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Delete from 'src/components/shared/Delete';
import Image from 'src/components/shared/Image';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import AddFormBody from 'src/components/shared/items/AddForm';
import UpdateFormBody from 'src/components/shared/items/UpdateForm';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

export default function index() {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: '',
    category_id: searchParams.get('category')
  });
  const [filter, setFilter] = React.useState<any>({});

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
      console.log(searchParams.values().next(), searchParams.entries().next());
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, [
        'page',
        'is_active',
        'category_id',
        'item_type',
        'search_key'
      ]);

      const { data } = await axiosInstance.get('items', {
        params: { is_active: is_active, ...paginate, item_type: 'service' }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.items;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('image')}</th>
              <th>{t('name')}</th>
              <th>{t('category')}</th>
              <th>{t('price')}</th>
              <th>{t('cost')}</th>
              <th>{t('status')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="flex">
                        <Image
                          src={item.image}
                          className="w-10 h-10 rounded-full shrink-0"
                        />
                      </div>
                    </td>
                    <td>{item.name || item.alt_name || '-'}</td>
                    <td>{item.category?.name || '-'}</td>
                    <td>{CurrencyFormatter(item.price || 0)}</td>
                    <td>{CurrencyFormatter(item.cost || 0)}</td>
                    <td>
                      <ChangeStatus
                        active={!!item.is_active}
                        refetch={GetItems}
                        pathname={'items/update_is_active/' + item.id}
                      />
                    </td>
                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <Link
                          to={'/settings/services/' + item.id}
                          className="edit-btn"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          {t('edit')}
                        </Link>
                        <Delete
                          refetch={GetItems}
                          pathname={'items/delete/' + item.id}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <Link
                to="/settings/services/add"
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </Link>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
        size="max-w-screen-md"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
          item_type="service"
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title={editItem?.name}
        size="max-w-screen-md"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={{
            ...editItem,
            category_id: editItem?.category?.id,
            item_ids: editItem?.items?.map((e: any) => e.id)
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

function DisplayFilter({ formik: { values, handleChange } }: any) {
  const { t } = useTranslation();
  return (
    <>
      <div className="form-group">
        <p className="form-label">{t('price')} (SAR)</p>
        <input
          type="number"
          name="price"
          value={values?.price}
          onChange={handleChange}
          className="form-input form-outline"
          placeholder="0.00"
          step="any"
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('cost')} (SAR)</p>
        <input
          type="number"
          name="price"
          value={values?.price}
          onChange={handleChange}
          className="form-input form-outline"
          placeholder="0.00"
          step="any"
        />
      </div>
      <div className="form-group ">
        <p className="form-label">{t('duration')}</p>
        <input
          type="number"
          name="duration"
          value={values?.duration}
          onChange={handleChange}
          className="form-input form-outline"
          placeholder="00"
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('from')}</p>
        <input
          type="date"
          name="from"
          value={values?.from}
          onChange={handleChange}
          className="form-input form-outline"
        />
      </div>
      <div className="form-group">
        <p className="form-label">{t('to')}</p>
        <input
          type="date"
          name="to"
          value={values?.to}
          min={values?.from}
          onChange={handleChange}
          className="form-input form-outline"
        />
      </div>
    </>
  );
}

