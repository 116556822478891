import { Icon } from '@iconify/react';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import Card from './Card';

type DatePickerType = 'daily' | 'weekly' | 'monthly' | 'yearly';
type ResultDate = {
  from: Date;
  to: Date;
};

type Control = 'increment' | 'decrement';
type MomentUnit = moment.unitOfTime.DurationConstructor;
type IProps = {
  type: DatePickerType;
  onChange: (result: ResultDate) => any;
};

export default function HorizontalDatePicker({ type: selectedType = 'monthly', onChange }: IProps) {
  const unites: Record<DatePickerType, MomentUnit> = {
    daily: 'day',
    weekly: 'week',
    monthly: 'month',
    yearly: 'year'
  };
  const format = 'll';
  const type = useRef<DatePickerType>(selectedType);
  const genFormat = (date: Date) => moment(date).format(format);
  const [finalResult, setFinalResult] = useState<any>({});

  useEffect(() => {
    controlHandler();
  }, []);

  function controlHandler(control?: Control, date: Date = new Date()) {
    const result = generateDate(date, control);

    setFinalResult(() => result as ResultDate);

    onChange(result as ResultDate);
  }

  function generateDate(date: Date, control?: Control) {
    let result: Partial<ResultDate> = {},
      from: any,
      to: any;

    const amount = control ? (control === 'increment' ? 1 : -1) : 0;

    result = genMoment(date, amount, unites[type.current]) as ResultDate;

    return result;
  }

  function genMoment(date: Date, amount: number, unit: MomentUnit) {
    let result;
    const newMoment = moment(date).add(amount, unit).clone();
    if (type.current === 'daily') {
      result = {
        from: newMoment.startOf('day').toDate(),
        to: newMoment.endOf('day').toDate()
      };
    } else if (type.current === 'monthly') {
      result = {
        from: newMoment.startOf('month').toDate(),
        to: newMoment.endOf('month').toDate()
      };
    } else if (type.current === 'yearly') {
      result = {
        from: newMoment.startOf('year').toDate(),
        to: newMoment.endOf('year').toDate()
      };
    }

    return result;
  }

  const DATE_MOMENT = useMemo(() => {
    return (
      <p className="flex-1 text-center text-sm font-medium text-gray-500 flex items-center justify-center gap-2">
        <span className="shrink-0">{genFormat(finalResult.from)}</span>
        <span className="shrink-0">:</span>
        <span className="shrink-0">{genFormat(finalResult.to)}</span>
      </p>
    );
  }, [finalResult]);

  return (
    <div>
      <Card className="w-full flex items-center gap-3 !p-3">
        <button
          className="text-gray-600 shrink-0"
          type="button"
          onClick={() => controlHandler('decrement', finalResult.from)}
        >
          <Icon
            icon="fa:angle-right"
            width="16"
            height="16"
            className="hidden rtl:block"
          />
          <Icon
            icon="fa:angle-left"
            width="16"
            height="16"
            className="block rtl:hidden"
          />
        </button>
        {DATE_MOMENT}
        <button
          className="text-gray-600 shrink-0"
          type="button"
          onClick={() => controlHandler('increment', finalResult.to)}
        >
          <Icon
            icon="fa:angle-left"
            width="16"
            height="16"
            className="hidden rtl:block"
          />
          <Icon
            icon="fa:angle-right"
            width="16"
            height="16"
            className="block rtl:hidden"
          />
        </button>
      </Card>
    </div>
  );
}
