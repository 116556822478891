import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MoveToBottom } from 'src/animations';
import generateAlert from 'src/helper/generateAlert';
import generateFileSize from 'src/helper/generateFileSize';
import { v4 as uuid } from 'uuid';

type IAttachment = {
  name: string;
  size: string;
  file: File;
  id: string;
  uri: string;
};

type IProps = {
  multiple?: boolean;
  onChange: (file: File) => any;
  type?: string;
  disabled: boolean;
};

export const maxSize = 24 * 1000 * 1024;
export default function ImportForm({
  multiple = false,
  onChange,
  type = 'image/*',
  disabled = false
}: IProps) {
  const { t } = useTranslation();
  const uploaderRef = useRef<HTMLInputElement | null>(null);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);

  function onUploaderChange(ev: ChangeEvent<HTMLInputElement>) {
    const result: IAttachment[] = [];
    const { files } = ev.target;
    if (!files?.length) return generateAlert('No files selected', 'info');

    for (const file of files) {
      const object = generateFile(file);
      const size = Math.round(file.size / 1024);
      const maxSizeString = generateFileSize(maxSize);
      if (size >= maxSize) {
        generateAlert(
          'File ( ' + file.name + ' ) size is ' + object.size + ' and max size is ' + maxSizeString,
          'info'
        );
        continue;
      }
      result.push(object);
      onChange(file);
    }
    setAttachments((e) => (multiple ? [...e, ...result] : result));
    (uploaderRef.current as HTMLInputElement).value = '';
  }

  function generateFile(file: File) {
    const { name, size } = file;
    const uri = URL.createObjectURL(file);
    return {
      name,
      uri,
      size: generateFileSize(size),
      file,
      id: uuid()
    };
  }

  function removeFile(id: string) {
    const files = attachments.filter((file) => file.id !== id);
    setAttachments(() => files);
  }

  return (
    <div className="space-y-4">
      <input
        type="file"
        name="attachment-uploader"
        id="attachment-uploader"
        hidden
        ref={uploaderRef}
        onChange={onUploaderChange}
        multiple={multiple}
        accept={type}
        disabled={disabled}
        className="peer"
      />
      <label
        htmlFor="attachment-uploader"
        className="w-full p-10 rounded-md border-2 border-dashed border-gray-200 block cursor-pointer peer-disabled:cursor-not-allowed"
      >
        <div className="flex items-center justify-center text-center gap-3 flex-col">
          {/* <div className="flex items-center justify-center text-center gap-3"></div> */}
          <Icon
            icon="solar:cloud-upload-bold-duotone"
            width="45"
          />
          <p className="text-sm text-gray-500">{t('import-content')}</p>
        </div>
      </label>
      <ul className="space-y-3">
        {attachments.map((file: IAttachment, index: number) => (
          <motion.li
            key={file.id}
            initial="hidden"
            variants={MoveToBottom}
            animate="visible"
          >
            <Link
              to={file.uri}
              target="_blank"
              className="flex items-center justify-between gap-3  rounded-md bg-gray-100 py-2 px-3"
            >
              <Icon
                icon="iconamoon:attachment-fill"
                width="20"
                className="shrink-0 text-gray-400"
              />
              <div className="flex-1">
                <p className="text-sm text-gray-500">{file.name}</p>
                <p className="text-sm text-gray-800 font-semibold">{file.size}</p>
              </div>
              <button
                type="button"
                className="shrink-0"
                onClick={(e: any) => {
                  e.preventDefault();
                  removeFile(file.id);
                }}
              >
                <Icon
                  icon="fluent:delete-12-filled"
                  width="20"
                  className="text-red-500"
                />
              </button>
            </Link>
          </motion.li>
        ))}
      </ul>
    </div>
  );
}
