import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import Mobile from 'src/components/shared/Mobile';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

const ContactInfo = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'centers/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group">
                <label className="form-label">{t('email')}</label>
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.email}
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.email}</p>
              </div>

              <div className="form-group">
                <label className="form-label">{t('mobile')}</label>
                <Mobile
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.mobile}
                  name="mobile"
                  onChange={handleChange}
                  required
                />
                <p className="form-error">{errors?.mobile}</p>
              </div>

              <div className="form-group">
                <label className="form-label">{t('whatsapp')}</label>
                <Mobile
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  required
                />
                <p className="form-error">{errors?.whatsapp}</p>
              </div>

              <div className="form-group">
                <label className="form-label">{t('facebook')}</label>
                <input
                  type="url"
                  name="facebook"
                  autoComplete="off"
                  placeholder={t('link') + ' ' + t('facebook')}
                  className="form-input form-outline"
                  value={values.facebook}
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.facebook}</p>
              </div>

              <div className="form-group">
                <label className="form-label">{t('twitter')}</label>
                <input
                  type="url"
                  name="twitter"
                  autoComplete="off"
                  placeholder={t('link') + ' ' + t('twitter')}
                  className="form-input form-outline"
                  value={values.twitter}
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.twitter}</p>
              </div>

              <div className="form-group">
                <label className="form-label">{t('instagram')}</label>
                <input
                  type="url"
                  name="instagram"
                  autoComplete="off"
                  placeholder={t('link') + ' ' + t('instagram')}
                  className="form-input form-outline"
                  value={values.instagram}
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.instagram}</p>
              </div>
            </div>
          </div>
        </Card>

        <button
          className="btn-with-icon bg-primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </form>
    </div>
  );
};

export default ContactInfo;














