import { Icon } from '@iconify/react';
import React from 'react';
import { MoveToBottom, MoveToTop } from '../../animations';
import { motion } from 'framer-motion';

const SingleMessage: React.FC = () => {
  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="flex items-center justify-between flex-wrap">
          <p className="text-lg font-semibold text-gray-500 shrink-0">Messages</p>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button className="btn-with-icon !text-gray-500 shrink-0">
              <span>
                <Icon icon="majesticons:plus" width={18} />
              </span>
              <span>Send message</span>
            </button>
          </div>
        </div>
        <ul className="hidden  grid-wrapper gap-4">
          {Array.from({ length: 6 })
            .fill(0)
            .map((_, index: string | number) => (
              <li key={index}>
                <a
                  href="#"
                  className="p-6 rounded-xl bg-white ring-1 ring-gray-200 space-y-1 block">
                  <motion.p
                    animate="visible"
                    initial="hidden"
                    variants={MoveToTop}
                    className="text-lg font-semibold text-gray-800">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </motion.p>
                  <motion.p
                    animate="visible"
                    initial="hidden"
                    variants={MoveToBottom}
                    className="text-sm font-medium text-gray-500 line-clamp-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid harum ea
                    voluptatibus deserunt nisi odit? Nulla, non? Deleniti excepturi inventore error
                    voluptates, consequatur aperiam suscipit, incidunt impedit minima vel quod.
                  </motion.p>
                  <div className="flex gap-2 flex-wrap !my-4">
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="inline-flex gap-2 items-center text-xs font-semibold text-teal-500">
                      <span className="shrink-0 inline w-1.5 h-1.5 rounded-full bg-teal-500"></span>
                      <span>Open</span>
                    </motion.p>
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="inline-flex gap-2 items-center text-xs font-semibold text-red-500">
                      <span className="shrink-0 inline w-1.5 h-1.5 rounded-full bg-red-500"></span>
                      <span>Low</span>
                    </motion.p>
                  </div>
                  <div className="flex gap-2 flex-wrap justify-between pt-4 border-t border-t-gray-200">
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="text-xs font-medium text-primary">
                      Sender name
                    </motion.p>
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="text-xs font-medium text-gray-500">
                      {new Date().toISOString()}
                    </motion.p>
                  </div>
                </a>
              </li>
            ))}
        </ul>
        {/*  */}
        <figure className="p-4 rounded-xl bg-white ring-1 ring-gray-200 space-y-1 block">
          <blockquote className="flex flex-col">
            <header className="w-full pb-4 border-b border-b-gray-200">
              <div className="max-w-screen-lg mx-auto flex items-center gap-3 justify-between">
                <div className="grid shrink-0">
                  <motion.p
                    animate="visible"
                    initial="hidden"
                    variants={MoveToTop}
                    className="text-base font-semibold text-gray-800 shrink-0 line-clamp-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </motion.p>
                </div>
                <div className="w-auto bg-gray-100 inline-flex items-center p-3 rounded-lg h-11">
                  <span className="shrink-0 inline text-gray-500">
                    <Icon icon="ri:search-line" width={20} />
                  </span>
                  <input
                    type="text"
                    className="form-input bg-transparent text-sm border-none py-0"
                    placeholder="What are you looking for?"
                    autoComplete="off"
                  />
                </div>
              </div>
            </header>
            <div className="max-w-screen-lg mx-auto flex items-center gap-3 justify-between flex-1 overflow-y-auto">
              <div className="py-20">
                <Icon width={40} icon="tabler:message-off" className="mx-auto text-center" />
                <motion.p
                  animate="visible"
                  initial="hidden"
                  variants={MoveToTop}
                  className="text-base font-semibold text-gray-800 text-center my-3">
                  Room is empty
                </motion.p>
                <motion.p
                  animate="visible"
                  initial="hidden"
                  variants={MoveToBottom}
                  className="text-sm font-medium text-gray-500 text-center">
                  There are no messages sent yet. You can send a message to the messenger to discuss
                  his inquiries about what he is looking for
                </motion.p>
              </div>
            </div>
            <footer className="w-full pt-4 border-t border-t-gray-200">
              <div className="max-w-screen-lg mx-auto space-y-3">
                <textarea
                  placeholder="Type your message here.."
                  className="form-textarea text-sm font-medium"></textarea>
                <div className="flex gap-2 shrink-0 flex-wrap justify-end">
                  <button className="btn-with-icon outline-btn shrink-0">
                    <span>
                      <Icon icon="majesticons:attachment-line" width={18} />
                    </span>
                    <span>Attachments</span>
                  </button>
                  <button className="btn-with-icon bg-primary shrink-0">
                    <span>Send message</span>
                    <span>
                      <Icon icon="carbon:send" width={18} />
                    </span>
                  </button>
                </div>
                <div className="border-t border-t-gray-200 pt-4 space-y-3">
                  <p className="text-sm  text-gray-500">Uploaded attachments</p>
                  <ul className="flex items-center gap-3 flex-wrap">
                    {Array.from({ length: 3 })
                      .fill(0)
                      .map((_, index: string | number) => (
                        <li className="shrink-0" key={index}>
                          <a href="#" className="inline-flex gap-3 bg-gray-100 rounded-xl p-3">
                            <span className="shrink-0 text-gray-500 self-center">
                              <Icon icon="uim:image-v" width={25} />
                            </span>
                            <div className="flex-1 shrink-0">
                              <motion.p
                                animate="visible"
                                initial="hidden"
                                variants={MoveToTop}
                                className="text-sm font-semibold text-gray-800  line-clamp-1">
                                Attachment name
                              </motion.p>
                              <motion.p
                                animate="visible"
                                initial="hidden"
                                variants={MoveToBottom}
                                className="text-xs font-medium text-gray-500">
                                24kb
                              </motion.p>
                            </div>
                            <span className="shrink-0 self-center">
                              <button className="w-8 h-8 rounded-full bg-red-500/10 text-red-500 flex items-center justify-center">
                                <span className="shrink-0">
                                  <Icon icon="mdi:trash-can-empty" width={18} />
                                </span>
                              </button>
                            </span>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </footer>
          </blockquote>
        </figure>
      </div>
    </React.Fragment>
  );
};

export default SingleMessage;
