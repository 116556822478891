import { Icon } from '@iconify/react';
import { AllHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import ItemList from './ItemList';
import SearchBox from './SearchBox';
import SlideUpDown from './animations/SlideUpDown';

interface IProps extends AllHTMLAttributes<HTMLInputElement> {
  multiple?: boolean;
  options: any[];
  value: string;
  values: any | any[];
  onSelect: AllHTMLAttributes<HTMLInputElement>['onChange'];
  optionName: AllHTMLAttributes<HTMLInputElement>['name'];
  RenderOption: (props: { option: any }) => JSX.Element;
}

export default function AppSelect({
  multiple,
  options,
  value,
  values,
  onSelect,
  RenderOption,
  optionName,
  ...rest
}: IProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const isSelected = useCallback(
    (str: any) => {
      if (multiple) {
        return values?.some((e: any) => e[value] == str);
      } else {
        return values == str;
      }
    },
    [values, multiple]
  );

  useEffect(() => {
    setVisible(false);
  }, [options]);

  return (
    <div className="form-group">
      <SearchBox
        className="w-full"
        {...rest}
      >
        {!!options.length ? (
          <button
            type="button"
            className="text-xs text-red-500"
            onClick={() => setVisible(!visible)}
          >
            <span>{visible ? t('close') : t('show')}</span>
          </button>
        ) : null}
      </SearchBox>
      <SlideUpDown visible={visible}>
        <Card className="!p-0 overflow-hidden">
          <ul className="divide-y divide-gray-200 overflow-y-auto max-h-96 py-3">
            {options.map((option) => (
              <ItemList
                key={option.id}
                className="py-3 group"
              >
                <input
                  type={multiple ? 'checkbox' : 'radio'}
                  name={multiple ? `option-id-${option.id}` : optionName}
                  id={`option-id-${option.id}`}
                  onChange={onSelect}
                  value={option.id}
                  defaultChecked={isSelected(option[value])}
                  hidden
                  className="peer hidden"
                />
                <label
                  htmlFor={`option-id-${option.id}`}
                  className="cursor-pointer w-full block hover:bg-gray-50 -my-3 p-3 group-first:mt-auto group-first:pt-0 group-last:mb-auto group-last:pb-0"
                >
                  <div className="flex items-center gap-4">
                    <div className="flex-1">
                      <RenderOption option={option} />
                    </div>
                    <Icon
                      icon="material-symbols:check"
                      width="18"
                      height="18"
                      className={isSelected(option[value]) ? 'text-teal-600 shrink-0' : 'hidden'}
                    />
                  </div>
                </label>
              </ItemList>
            ))}
          </ul>
        </Card>
      </SlideUpDown>
    </div>
  );
}
