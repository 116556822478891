import { Popover } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import prepareRequest from 'src/helper/prepareRequest';
import ItemList from './ItemList';
import Modal from './Modal';
import SendByEmail from './SendByEmail';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';

export default function ExportOptions({
  excelPathname,
  cvsPathname,
  pdfPathname,
  pathname,
  params
}: {
  excelPathname?: string;
  cvsPathname?: string;
  pdfPathname?: string;
  pathname?: string;
  params?: string;
}) {
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [emailVisible, setEmailVisible] = useState<boolean>(false);

  const exportExcel = () => {
    if (!excelPathname)
      return generateAlert("This feature isn't available at that moment, try again later.", 'info');
    setDisabled(true);
    prepareRequest({ url: excelPathname, method: 'get', responseType: 'blob', params }, (data) => {
      downloadFile('xlsx', data);
    }).finally(() => setDisabled(false));
  };

  const exportCsv = () => {
    if (!cvsPathname)
      return generateAlert("This feature isn't available at that moment, try again later.", 'info');
    setDisabled(true);
    prepareRequest({ url: cvsPathname, method: 'get', responseType: 'blob', params }, (data) => {
      downloadFile('csv', data);
    }).finally(() => setDisabled(false));
  };

  const exportPDF = () => {
    if (!pdfPathname)
      return generateAlert("This feature isn't available at that moment, try again later.", 'info');
    setDisabled(true);
    prepareRequest({ url: pdfPathname, method: 'get', responseType: 'blob', params }, (data) => {
      downloadFile('pdf', data);
    }).finally(() => setDisabled(false));
  };

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="btn-with-icon outline-btn !text-gray-600 shrink-0">
          <Icon
            icon="uil:file-export"
            width={18}
          />
          <span>{t('export')}</span>
        </Popover.Button>

        <Popover.Panel className="absolute z-10 top-full mt-2 right-0 rtl:right-auto rtl:left-0 ring-1 ring-slate-200 bg-white rounded-lg w-max">
          <ul className="divide-y divide-slate-200 p-3">
            <ItemList className="py-2">
              <button
                type="button"
                className="btn-with-icon !text-gray-600 !p-0"
                disabled={disabled}
                onClick={exportExcel}
              >
                <Icon
                  icon="bi:filetype-xlsx"
                  width={18}
                />
                <span>{t('exports.xlsx')}</span>
              </button>
            </ItemList>
            <ItemList className="py-2">
              <button
                type="button"
                className="btn-with-icon !text-gray-600 !p-0"
                disabled={disabled}
                onClick={exportCsv}
              >
                <Icon
                  icon="ph:file-csv"
                  width={18}
                />
                <span>{t('exports.csv')}</span>
              </button>
            </ItemList>
            <ItemList className="py-2">
              <button
                type="button"
                className="btn-with-icon !text-gray-600 !p-0"
                disabled={disabled}
                onClick={exportPDF}
              >
                <Icon
                  icon="ph:file-pdf"
                  width={18}
                />
                <span>{t('exports.pdf')}</span>
              </button>
            </ItemList>
            <ItemList className="py-2">
              <button
                type="button"
                className="btn-with-icon !text-gray-600 !p-0"
                disabled={disabled}
                onClick={() => setEmailVisible(true)}
              >
                <Icon
                  icon="mdi:email-send-outline"
                  width={18}
                />
                <span>{t('exports.email')}</span>
              </button>
            </ItemList>
          </ul>
        </Popover.Panel>
      </Popover>
      <Modal
        visible={emailVisible}
        handleClose={setEmailVisible}
        title={t('exports.email')}
      >
        <SendByEmail
          handleClose={setEmailVisible}
          pathname={pathname}
        />
      </Modal>
    </>
  );
}

export function downloadFile(type: string, data: any) {
  // const blob = new Blob([data]);
  const link = document.createElement('a');
  const fileURL = URL.createObjectURL(data);
  link.href = fileURL;
  link.download = new Date().getTime() + '.' + type;
  link.click();
  URL.revokeObjectURL(fileURL);
}
