import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

export default function SlideUpDown({
  visible = false,
  children
}: {
  visible: boolean;
  children: React.ReactNode;
}) {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{
            height: 0,
            opacity: 0
          }}
          animate={{
            height: 'auto',
            opacity: 1,
            transition: {
              height: {
                duration: 0.2
              },
              opacity: {
                duration: 0.25,
                delay: 0.15
              }
            }
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.2
              },
              opacity: {
                duration: 0.25
              }
            }
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
