import moment from 'moment';
import { ReactElement, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import ReactToPrint, { ITriggerProps } from 'react-to-print';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateQRCode from 'src/helper/generateQRCode';
import { RootState } from 'src/store';
import Card from '../shared/Card';
import DisplayTwoRow from '../shared/DisplayTwoRow';
import Image from '../shared/Image';

type Props = {
  disabled: boolean;
  onIssuingInvoice: () => void;
};
interface IProps {
  item: any;
  trigger: ReactElement<ITriggerProps<any>>;
}

export default function PrintPDF({ item, trigger }: Required<IProps>) {
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);

  const getPaymentAmount = useCallback(
    (payment_option: string | undefined, payments: any[]) => {
      const payment = payments?.find((e: any) => e.payment_option === payment_option);
      const excludedCash = payments?.filter((e: any) => e.payment_option !== 'cash');
      const total = excludedCash?.reduce((a, b) => (a.amount || 0) + (b.amount || 0), 0);

      return payment_option ? (payment ? payment.amount : 0) : total;
    },
    [item]
  );

  const format = 'l hh:mmA';

  const generateBookingTime = useMemo(() => {
    let result;
    const time = item?.booking_time;
    if (!time) {
      result = moment(new Date()).format(format);
    } else {
      result = moment(new Date(time)).format(format);
    }

    return result;
  }, [item]);

  const onImageLoad = useCallback(() => {
    console.log('loaded..');
  }, []);

  return (
    <div>
      <div
        className="w-[8cm] h-auto mx-auto space-y-4 p-2 fixed print:relative bottom-[calc(200vh)] z-50 print:bottom-auto print:my-4"
        ref={invoiceRef}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        {user.center?.image && (
          <Image
            className="max-w-full mx-auto max-h-24 object-contain"
            width={150}
            height={96}
            src={user.center?.image || '/logo.png'}
            onLoad={onImageLoad}
          />
        )}
        <p className="text-xs text-center">
          {user.center?.name} - {user.center?.alt_name}
        </p>

        <Card className="space-y-3 !pb-6">
          <div className="grid grid-cols-2 gap-1">
            <DisplayTwoRow
              title={t('vat-no')}
              value={user.center?.vat_no || '-'}
            />
            <DisplayTwoRow
              title={t('mobile')}
              value={user.center?.mobile?.replace(/\s/, '') || '-'}
            />

            <DisplayTwoRow
              title={t('booking-no')}
              value={'#' + item?.id}
            />

            <DisplayTwoRow
              title={t('booking-time')}
              value={generateBookingTime}
            />
            <DisplayTwoRow
              title={t('print-time')}
              value={moment(new Date()).format(format)}
            />
          </div>
          <hr className="border-px border-dashed border-gray-600" />
          <p className="text-xs text-start  font-semibold">{t('client-details')}</p>
          <hr className="border-px border-dashed border-gray-600" />

          <div className="grid grid-cols-2 gap-2 ">
            <DisplayTwoRow
              title={t('name')}
              value={item?.client?.name || '-'}
            />

            <DisplayTwoRow
              title={t('mobile')}
              value={item?.client?.mobile?.replace(/\s/, '') || '-'}
            />
          </div>
          <hr className="border-px border-dashed border-gray-600" />
          <p className="text-xs text-start font-semibold">{t('invoice-items')}</p>

          <hr className="border-px border-dashed border-gray-600" />

          <div className="grid grid-cols-2 gap-2">
            {item?.items?.map((service: any) => (
              <>
                <p className="text-xs text-start text-gray-600">
                  {[service.code ? '[' + service.code + ']' : null, service.name].join(' ')}
                  <br />
                  {!!service?.items?.length ? (
                    <>[ {service?.items?.map((e: any) => e.name).join(' , ')} ]</>
                  ) : null}
                </p>
                <p className="text-xs text-end font-semibold text-black">
                  {CurrencyFormatter(service.price)}
                </p>
              </>
            ))}
          </div>

          <hr className="border-px border-dashed border-gray-600" />

          <div className="grid grid-cols-2 gap-2">
            <DisplayTwoRow
              title={t('subtotal')}
              value={CurrencyFormatter(item?.sub_total || 0)}
            />
            <DisplayTwoRow
              title={t('discount')}
              value={CurrencyFormatter(item?.discount || 0)}
            />
            <DisplayTwoRow
              title={t('vat')}
              value={CurrencyFormatter(item?.vat || 0)}
            />
            <DisplayTwoRow
              title={t('total')}
              value={CurrencyFormatter(item?.total || 0)}
            />
          </div>
          <hr className="border-px border-dashed border-gray-600" />

          <div className="grid grid-cols-2 gap-2">
            <DisplayTwoRow
              title={t('paid-amount')}
              value={CurrencyFormatter(item?.paid_amount || 0)}
            />
            <DisplayTwoRow
              title={t('paid-card')}
              value={CurrencyFormatter(getPaymentAmount(undefined, item?.payments))}
            />
          </div>
          <div className="!my-4 space-y-2">
            <p className="text-xs text-center">{t('thanks-for-visiting')}</p>
            <p className="text-xs text-center">{t('paid-content-footer')}</p>
          </div>
          <div className="pb-3">
            <div
              style={{ height: 'auto', margin: 'auto', maxWidth: '5.5cm', width: '100%' }}
              className="qr-code-wrapper"
            >
              <QRCode
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={generateQRCode(
                  (item?.client?.name || '').toString(),
                  (item?.center?.vat_no || '').toString(),
                  (item?.total || '').toString(),
                  (item?.vat || '').toString()
                )}
                viewBox="0 0 256 256"
                size={256}
              />
            </div>
          </div>
          {item.notes && (
            <p className="text-xs text-center border border-gray-200 rounded p-3">{item.notes}</p>
          )}
        </Card>
        <div className="pt-2">
          <p className="text-xs text-center">Powered by sa.salon</p>
          <p className="text-xs text-center">Developed with love by ia6g.com</p>
        </div>
      </div>
      <ReactToPrint
        trigger={() => trigger}
        content={() => invoiceRef.current}
        onAfterPrint={() => console.log('Invoice printed..')}
      />
    </div>
  );
}
