import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { RootState } from 'src/store';
import DisplayTwoRow from './DisplayTwoRow';
import Image from './Image';
import ItemList from './ItemList';
import Title from './Title';
import SlideUpDown from './animations/SlideUpDown';

type PaymentOption = 'cash' | 'mada' | 'bank_transfer';

type PaymentItem = {
  payment_option: PaymentOption;
  amount: number | undefined;
  ref_no: string | undefined;
};

interface IProps {
  payments: PaymentItem[];
  onChange: any;
  errors: any;
  total: number;
}
export default function DisplayPayments({ payments, onChange, errors, total }: IProps) {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);

  const cards = [
    {
      logo: '/images/cash.png',
      title: t('paid-with-cash'),
      hint: 'cash',
      key: 'cash'
    },
    {
      logo: '/images/mada.png',
      title: t('paid-with-mada'),
      hint: 'mada',
      key: 'mada'
    },
    {
      logo: '/images/visa.png',
      title: t('paid-with-visa'),
      hint: 'visa',
      key: 'visa'
    },

    {
      logo: '/images/bank_transfer.png',
      title: t('paid-with-bank-transfer'),
      hint: 'bank',
      key: 'bank_transfer'
    }
  ];
  const isSelected = useCallback(
    (value: string) => {
      return payments.some((e: PaymentItem) => e?.payment_option === value);
    },
    [payments]
  );

  const onPaymentChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = ev.target;
      const hasData = !!payments.length;
      const payment = {
        payment_option: value,
        amount: undefined,
        ref_no: undefined
      };
      const concat = [...payments, payment];

      const filter = payments.filter((e: PaymentItem) => e?.payment_option !== value);
      if (checked) return onChange('payments', concat);
      onChange('payments', filter);
    },
    [payments, total]
  );

  const getPayment = useCallback(
    (payment_option: PaymentOption) => {
      const payment = payments.find((e: PaymentItem) => e?.payment_option == payment_option);

      return payment;
    },
    [payments]
  );

  const getRemainingAmount = useMemo(() => {
    const amount = total;
    let paymentsAmount = 0;

    for (const payment of payments) {
      paymentsAmount += Number(payment?.amount || 0);
    }

    return amount - paymentsAmount;
  }, [payments, total]);

  const onPaymentBlur = useCallback(
    (type: PaymentOption) => {
      let dueAmount = 0;
      let totalAmount = 0;

      const remaining = getRemainingAmount;

      const payment = payments.find((e: PaymentItem) => e?.payment_option == type);
      const index = payments.findIndex((e: PaymentItem) => e?.payment_option == type);
      const filterPayments = payments.filter((e: PaymentItem) => e?.payment_option !== type);
      if (!payment) return;

      const amount = Number(payment.amount || 0);

      for (const item of payments) {
        totalAmount += Number(item.amount || 0);
      }

      if (!amount) return;
      if (amount <= 0) return onChange('payments', filterPayments);

      if (totalAmount > total) {
        dueAmount = amount + remaining;

        onChange('payments[' + index + '].amount', dueAmount);
      }
    },
    [payments, total]
  );

  const onAmountChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = ev.target;
      const index = payments.findIndex((e: PaymentItem) => e.payment_option == name);

      onChange('payments[' + index + '].amount', +value);
    },
    [payments]
  );

  const onRefChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = ev.target;
      const index = payments.findIndex((e: PaymentItem) => e.payment_option == name);

      onChange('payments[' + index + '].ref_no', value);
    },
    [payments]
  );

  const getPaymentError = useCallback(
    (type: PaymentOption) => {
      const index = payments.findIndex((e: PaymentItem) => e?.payment_option == type);
      return errors['payments.' + index + '.'] || {};
    },
    [payments]
  );
  return (
    <>
      <div className="space-y-1">
        <Title
          title={t('payments')}
          className="text-gray-800 font-semibold"
        />
        <Title title={t('select-payment-content')} />
      </div>
      <ul className="divide-y divide-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <DisplayTwoRow
              title={t('amount-after-discount')}
              value={CurrencyFormatter(total)}
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <DisplayTwoRow
              title={t('required-amount')}
              value={CurrencyFormatter(getRemainingAmount >= 0 ? getRemainingAmount : 0)}
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <DisplayTwoRow
              title={t('remaining-due-amount')}
              value={CurrencyFormatter(
                (getRemainingAmount <= 0 ? getRemainingAmount * -1 : 0) || 0
              )}
            />
          </div>
        </ItemList>
        {isSelected('bank_transfer') ? (
          <>
            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <DisplayTwoRow
                  title={t('bank-name')}
                  value={user.center?.bank_name || '-'}
                />
              </div>
            </ItemList>

            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <DisplayTwoRow
                  title="IBAN"
                  value={user.center?.iban || '-'}
                />
              </div>
            </ItemList>
          </>
        ) : null}
      </ul>

      <ul className="space-y-2">
        {cards.map((payment: any, index: number) => (
          <li
            className="relative"
            key={index}
          >
            <input
              type="checkbox"
              id={payment.key}
              name="payments"
              onChange={onPaymentChange}
              value={payment.key}
              className="form-checkbox absolute top-1/2 -translate-y-1/2 right-4 rtl:left-4 rtl:right-auto z-[1] peer form-outline"
              checked={isSelected(payment.key)}
              disabled={getRemainingAmount <= 0 && !getPayment(payment.key)?.amount}
            />
            <label
              className="w-full bg-white border-2 border-gray-200 rounded-lg flex items-center gap-4 relative p-4 pe-12 transition peer-checked:border-blue-500  cursor-pointer peer-disabled:opacity-50 peer-disabled:cursor-not-allowed"
              htmlFor={payment.key}
            >
              <Image
                className="w-10 h-10 object-contain shrink-0"
                src={payment.logo}
              />
              <div className="flex-1 space-y-2">
                <p className="font-semibold text-sm text-gray-800">{payment.title}</p>
                <SlideUpDown visible={isSelected(payment.key)}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div>
                      <input
                        type="number"
                        className="form-input form-outline"
                        placeholder={CurrencyFormatter(getRemainingAmount || 0)}
                        value={getPayment(payment.key)?.amount}
                        data-payment-type={payment.key}
                        onChange={onAmountChange}
                        name={payment.key}
                        onBlur={() => onPaymentBlur(payment.key)}
                        step="any"
                      />
                      {getPaymentError(payment.key)?.amount && (
                        <p className="form-error">{getPaymentError(payment.key)?.amount}</p>
                      )}
                    </div>
                    {payment.key == 'bank_transfer' ? (
                      <div>
                        <input
                          type="number"
                          className="form-input form-outline"
                          onChange={onRefChange}
                          placeholder={t('reference-number')}
                          defaultValue={getPayment(payment.key)?.ref_no}
                          name={payment.key}
                        />
                        {getPaymentError(payment.key)?.ref_no && (
                          <p className="form-error">{getPaymentError(payment.key)?.ref_no}</p>
                        )}
                      </div>
                    ) : null}
                  </div>
                </SlideUpDown>
              </div>
            </label>
          </li>
        ))}
      </ul>
    </>
  );
}
