import { Disclosure } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { serialize } from 'object-to-formdata';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';
import Card from '../Card';
import InputFile from '../InputFile';
import Select from '../Select';
import { ItemType } from './AddForm';

export type Option = {
  name: string | undefined;
  alt_name: string | undefined;
  price: string | undefined;
  cost: string | undefined;
};

export type Variant = {
  name: string | undefined;
  alt_name: string | undefined;
  price: string | undefined;
  cost: string | undefined;
};

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  category_id: string | undefined;
  item_id: string | undefined;
  price: string | undefined;
  cost: string | undefined;
  item_type: ItemType;
  items: any[];
  centers: any[];
  variants: Variant[];
  options: Option[];
  [key: string]: any;
}

export default function FormWithVariants({ item_type }: { item_type: ItemType }) {
  let render = true;
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    image: undefined,
    category_id: undefined,
    cost: undefined,
    price: undefined,
    centers: [],
    item_id: undefined,
    item_type,
    items: [],
    variants: [],
    options: []
  } satisfies ItemsForm;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [centers, setCenters] = useState<any[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    const collectVariantAndOptionsWithCenters = values.centers.map((center: number) => {
      return {
        id: center,
        employee_id: values?.['employee_id_' + center]
        // variant: values.variant,
        // options: values.options
      };
    });
    const result = {
      ...values,
      centers: collectVariantAndOptionsWithCenters
    };

    const body = serialize(result, {
      indices: true,
      nullsAsUndefineds: true,
      booleansAsIntegers: true
    });

    Object.keys(values).forEach((key: string) => {
      if (/^employee_id_\d+$/gi.test(key)) {
        console.log(key);
        body.delete(key);
      }
    });

    setDisabled(true);
    setErrors({});
    prepareRequest(
      {
        url: 'items/add',
        method: 'post',
        data: body
      },
      (response, error) => {
        if (error) {
          if (error['centers.0.id']) {
            generateAlert(t('no-centers'), 'error');
          }
          setErrors(error);

          return;
        }

        generateAlert(response.message, 'success');
        helper.resetForm();
      }
    ).finally(() => setDisabled(false));
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  useEffect(() => {
    getCategories();
    getCenters();
  }, []);

  const getCategories = (value?: string) => {
    prepareRequest(
      {
        url: 'categories',
        params: {
          is_active: 1,
          search_key: value,
          page: 1
        }
      },
      (data) => {
        setCategories(data.result?.categories?.data || []);
      }
    );
  };

  const getCenters = (value?: string) => {
    prepareRequest(
      {
        url: 'centers',
        params: {
          is_active: 1,
          search_key: value,
          page: 1
        }
      },
      (data) => {
        setCenters(data.result?.centers?.data || []);
      }
    );
  };

  function addNewOption() {
    const newOptions = [
      ...formik.values.options,
      {
        name: undefined,
        alt_name: undefined,
        values: []
      }
    ];
    formik.setFieldValue('options', newOptions);
  }

  function addNewVariant() {
    const newOptions = [
      ...formik.values.variants,
      {
        name: undefined,
        alt_name: undefined,
        price: undefined,
        cost: undefined
      }
    ];
    formik.setFieldValue('variants', newOptions);
  }

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <Card className="!p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-group col-span-full">
            <label
              htmlFor="image"
              className="form-label"
            >
              {t('image')}
            </label>
            <InputFile
              defaultValue={formik.values.image}
              onValueChange={function (e: any): void {
                formik.setFieldValue('image', e);
              }}
              accept="image/*"
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>

          <div
            className={`form-group ${
              !!user?.global_settings?.enable_alt_name ? '' : 'col-span-full'
            }`}
          >
            <label className="form-label">{t('name')}</label>
            <input
              type="text"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.name}
              onChange={(e) => handleChange('name', e)}
            />
            {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
          </div>

          {!!user?.global_settings?.enable_alt_name ? (
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.alt_name}
                onChange={(e) => handleChange('alt_name', e)}
              />
              {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
            </div>
          ) : null}

          {!!user?.global_settings?.enable_desc ? (
            <>
              <div className="form-group">
                <label className="form-label">{t('description')}</label>
                <textarea
                  autoComplete="off"
                  placeholder="..."
                  className="form-textarea form-outline"
                  value={formik.values.desc}
                  onChange={(e) => handleChange('desc', e)}
                ></textarea>
                {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('alt-description')}</label>
                <textarea
                  autoComplete="off"
                  placeholder="..."
                  className="form-textarea form-outline"
                  value={formik.values.alt_desc}
                  onChange={(e) => handleChange('alt_desc', e)}
                ></textarea>
                {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
              </div>
            </>
          ) : null}

          <div className="form-group">
            <label className="form-label">
              {t('price')} ({t('sar')})
            </label>
            <input
              type="number"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.price}
              onChange={(e) => handleChange('price', e)}
            />
            {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
          </div>
          
          <div className="form-group">
            <label className="form-label">
              {t('cost')} ({t('sar')})
            </label>
            <input
              type="number"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.cost}
              onChange={(e) => handleChange('cost', e)}
            />
            {errors?.cost ? <span className="form-error">{errors?.cost}</span> : null}
          </div>

          <div className="form-group col-span-full">
            <label className="form-label">{t('category')}</label>
            <Select
              type={'single'}
              options={categories}
              value={formik.values.category_id}
              onSelect={function (value: any): any {
                return formik.setFieldValue('category_id', value);
              }}
              onSearchChange={(ev) => getCategories(ev.target.value)}
              optionTxt={'name'}
              optionValue={'id'}
            />

            {errors?.category_id ? <span className="form-error">{errors?.category_id}</span> : null}
          </div>

          {centers.length > 1 ? (
            <div className="form-group col-span-full">
              <label className="form-label">{t('centers')}</label>

              <Select
                type={'multi'}
                options={centers}
                value={formik.values.centers || centers[0]?.id}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('centers', value);
                }}
                onSearchChange={(ev) => getCenters(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.centers ? <span className="form-error">{errors?.centers}</span> : null}
            </div>
          ) : null}

          {formik.values.centers.map((center: number) => (
            <CenterEmployee
              key={center}
              id={center}
              onChange={formik.handleChange}
              onCustomValueChange={formik.setFieldValue}
              values={formik.values}
              errors={errors}
            />
          ))}
        </div>
      </Card>

      {/* <div className="flex items-center justify-between gap-4 col-span-full">
        <p className="form-label">{t('variants')}</p>
        <button
          type="button"
          className="btn-with-icon !p-0 !text-blue-600"
          onClick={addNewVariant}
        >
          <Icon
            icon="material-symbols:add"
            width="18"
            height="18"
          />
          <span>{t('add-new')}</span>
        </button>
      </div>

      <Card className="!p-8">
        <Variants
          onChange={formik.handleChange}
          onCustomValueChange={formik.setFieldValue}
          values={formik.values}
          errors={errors}
        />
      </Card> */}

      <div className="flex items-center justify-between gap-4 col-span-full">
        <p className="form-label">{t('options')}</p>
        <button
          type="button"
          className="btn-with-icon !p-0 !text-blue-600"
          onClick={addNewOption}
        >
          <Icon
            icon="material-symbols:add"
            width="18"
            height="18"
          />
          <span>{t('add-new')}</span>
        </button>
      </div>

      <Card className="!p-8">
        <Options
          onChange={formik.handleChange}
          onCustomValueChange={formik.setFieldValue}
          values={formik.values}
          errors={errors}
        />
      </Card>

      <button
        className="btn-with-icon bg-primary text-white"
        type="submit"
        disabled={disabled}
      >
        <span>{t('submit')}</span>
      </button>
    </form>
  );
}

export function Variants({ onChange, values, errors, onCustomValueChange }: any) {
  const { t } = useTranslation();

  function deleteSelectedOption(ev: MouseEvent<HTMLButtonElement>) {
    ev.preventDefault();
    return (index: number) => {
      const newOptions = [...values?.variants];
      const filterOptions = newOptions.filter((_e, idx: number) => idx !== index);
      onCustomValueChange('variants', filterOptions);
    };
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {values.variants?.length ? (
        <div className="col-span-full space-y-2">
          {values.variants?.map((variant: Variant, index: number) => (
            <Disclosure
              as="div"
              className="outline-btn !border-gray-200"
              key={index}
            >
              <Disclosure.Button
                type="button"
                className="btn-with-icon !text-start !text-gray-600 !w-full !justify-between  !bg-gray-100 !py-3 !px-6"
              >
                <span>{variant.name || t('name')}</span>
                <button
                  type="button"
                  className="btn-with-icon !p-0 !text-red-600"
                  onClick={(ev) => deleteSelectedOption(ev)(index)}
                >
                  <span>{t('delete')}</span>
                </button>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
                  <div className="form-group">
                    <label className="form-label">{t('name')}</label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="..."
                      className="form-input form-outline"
                      value={values.variants?.[index]?.name}
                      name={'variants.' + index + '.name'}
                      onChange={onChange}
                    />
                    <span className="form-error">{errors?.['variants.' + index + '.name']}</span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">{t('alt-name')}</label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="..."
                      className="form-input form-outline"
                      name={'variants.' + index + '.alt_name'}
                      value={values.variants?.[index]?.alt_name}
                      onChange={onChange}
                    />
                    <span className="form-error">
                      {errors?.['variants.' + index + '.alt_name']}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t('price')} ({t('sar')})
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="..."
                      className="form-input form-outline"
                      name={'variants.' + index + '.price'}
                      value={values.variants?.[index]?.price}
                      onChange={onChange}
                    />
                    <span className="form-error">{errors?.['variants.' + index + '.price']}</span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t('cost')} ({t('sar')})
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="..."
                      className="form-input form-outline"
                      name={'variants.' + index + '.cost'}
                      value={values.variants?.[index]?.cost}
                      onChange={onChange}
                    />
                    <span className="form-error">{errors?.['variants.' + index + '.cost']}</span>
                  </div>
                </div>
              </Disclosure.Panel>
            </Disclosure>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600 text-xsm col-span-full">{t('no-data')}</p>
      )}
    </div>
  );
}

export function Options({ onChange, values, errors, onCustomValueChange }: any) {
  const { t } = useTranslation();

  function deleteSelectedOption(ev: MouseEvent<HTMLButtonElement>) {
    ev.preventDefault();
    return (index: number) => {
      const newOptions = [...values?.options];
      const filterOptions = newOptions.filter((_e, idx: number) => idx !== index);
      onCustomValueChange('options', filterOptions);
    };
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {values.options?.length ? (
        <div className="col-span-full space-y-2">
          {values.options?.map((option: Option, index: number) => (
            <div
              className="flex items-end gap-4 p-4"
              key={index}
            >
              <div className="form-group flex-1">
                <label className="form-label">{t('name')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={option?.name}
                  name={'options.' + index + '.name'}
                  onChange={onChange}
                />
                <span className="form-error">{errors?.['options.' + index + '.name']}</span>
              </div>

              <div className="form-group flex-1">
                <label className="form-label">
                  {t('price')} ({t('sar')}){' '}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  name={'options.' + index + '.price'}
                  value={option?.price}
                  onChange={onChange}
                />
                <span className="form-error">{errors?.['options.' + index + '.price']}</span>
              </div>

              <div className="form-group flex-1">
                <label className="form-label">
                  {t('cost')} ({t('sar')}){' '}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  name={'options.' + index + '.cost'}
                  value={option?.cost}
                  onChange={onChange}
                />
                <span className="form-error">{errors?.['options.' + index + '.cost']}</span>
              </div>

              <div>
                <button
                  type="button"
                  className="form-input !text-red-600"
                  onClick={(ev) => deleteSelectedOption(ev)(index)}
                >
                  <span>{t('delete')}</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600 text-xsm col-span-full">{t('no-data')}</p>
      )}
    </div>
  );
}

export function CenterEmployee({ onChange, values, errors, onCustomValueChange, id }: any) {
  const key = 'employee_id_' + id;
  const centerIndex = values.centers.findIndex((e: number) => id == e);
  const { t } = useTranslation();
  const [center, setCenter] = useState<any>({});
  const [employees, setEmployees] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getEmployees();
    getCenter();
  }, []);

  function getCenter() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'centers/' + id,
        method: 'get'
      },
      (data) => setCenter(() => data.result?.center || {})
    ).finally(() => setIsLoading(false));
  }

  function getEmployees(value?: string) {
    prepareRequest(
      {
        url: 'users',
        params: {
          is_active: 1,
          search_key: value,
          page: 1,
          user_type: 'employee'
        }
      },
      (data) => {
        setEmployees(data.result?.users?.data || []);
      }
    );
  }

  if (isLoading) return <></>;

  return (
    <div className="form-group">
      <label className="form-label">
        {t('default-expert')} - ( {center.name} )
      </label>
      <Select
        type={'single'}
        options={employees}
        value={values?.[key]}
        onSelect={function (value: any): any {
          return onCustomValueChange(key, value);
        }}
        onSearchChange={(ev) => getEmployees(ev.target.value)}
        optionTxt={'name'}
        optionValue={'id'}
      />

      <span className="form-error">{errors?.['centers.' + centerIndex + '.id']}</span>
      <span className="form-error">{errors?.['centers.' + centerIndex + '.employee_id']}</span>
    </div>
  );
}

