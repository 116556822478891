import { useSelector } from 'react-redux';
import AuthWrapper from 'src/components/account/AuthWrapper';
import EmailLogin from 'src/components/account/login/EmailLogin';
import OtpLogin from 'src/components/account/login/OtpLogin';
import { RootState } from 'src/store';

const Login = () => {
  const { isSingleApp } = useSelector((state: RootState) => state.auth);

  return (
    <AuthWrapper isSingleApp={isSingleApp}>
      {isSingleApp ? <EmailLogin /> : <OtpLogin />}
    </AuthWrapper>
  );
};

export default Login;

