import React from 'react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.sa';

export default function Mobile(props: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <Cleave
      options={{
        phone: true,
        phoneRegionCode: 'sa'
      }}
      placeholder="05 000 0000"
      dir="ltr"
      {...props}
    />
  );
}
