import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import ExportOptions from 'src/components/shared/ExportOptions';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from 'src/components/shared/MiniDashboardSingleCard';
import Select from 'src/components/shared/Select';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';

const { RangePicker } = DatePicker;

const Invoices: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();

  const globalCards: ItemInterface[] = [
    {
      icon: 'fluent-emoji-high-contrast:money-bag',
      color: '',
      title: t('total-amount'),
      key: 'total_amount',
      value: 0
    },
    {
      icon: 'iconoir:hand-cash',
      color: '',
      title: t('cash-amount'),
      key: 'total_cash_amount',
      value: 0
    },
    {
      icon: 'solar:card-linear',
      color: '',
      title: t('mada-amount'),
      key: 'total_mada_amount',
      value: 0
    },
    {
      icon: 'solar:card-linear',
      color: '',
      title: t('visa-amount'),
      key: 'total_visa_amount',
      value: 0
    },
    {
      icon: 'majesticons:money-plus-line',
      color: '',
      title: t('vat'),
      key: 'total_vat_amount',
      value: 0
    },
    {
      icon: 'ph:bank',
      color: '',
      title: t('bank-transfer-amount'),
      key: 'total_bank_transfer_amount',
      value: 0
    }
  ];

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [responses, setResponses] = React.useState<any[]>([]);
  const [cards, setCards] = React.useState<any[]>(globalCards);

  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    from: moment(new Date(new Date().setDate(new Date().getDate() - 29))).format('YYYY-MM-DD'),
    to: moment(new Date()).format('YYYY-MM-DD')
  });
  const [params, setParams] = useSearchParams({
    page: '1'
  });

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, [params]);

  const GetItems = useCallback(
    function () {
      setIsLoading(true);
      setIsUpdating(true);

      prepareRequest(
        {
          url: 'bookings',
          params
        },
        (data) => {
          const { data: items, pagination: responsePaginate } = data?.result?.bookings;
          setPagination((values: any) => responsePaginate);

          setResponses(items);
          const summary = data?.result?.summary;
          const mapCards = cards.map((e) => {
            const value = summary[e.key] || 0;
            return {
              ...e,
              value: CurrencyFormatter(value || 0)
            };
          });
          setCards(mapCards);
        }
      ).finally(() => {
        setIsLoading(false);
        setIsUpdating(false);
      });
    },
    [params]
  );

  const getPaymentAmount = React.useCallback(
    (payment_option: string | undefined, payments: any[]) => {
      const payment = payments.find((e: any) => e.payment_option === payment_option);
      const excludedCash = payments.filter((e: any) => e.payment_option !== 'cash');
      const total = excludedCash.reduce((a, b) => (a.amount || 0) + (b.amount || 0), 0);

      return payment_option ? (payment ? payment.amount : 0) : total;
    },
    [responses]
  );

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>#</th>
              <th>{t('client')}</th>
              <th>{t('cashier')}</th>
              <th>{t('call-center')}</th>
              <th>{t('type')}</th>

              <th>{t('card-amount')}</th>
              <th>{t('cash-amount')}</th>
              <th>{t('vat')}</th>
              <th>{t('total')}</th>
              <th>{t('created-date')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        className="text-blue-600"
                        to={'/invoices/' + item.id}
                      >
                        {item.id || '-'}
                      </Link>
                    </td>
                    <td>{item?.client?.name || '-'}</td>
                    <td>{item?.cashier?.name || '-'}</td>
                    <td>{item?.marketer?.name || '-'}</td>
                    <td>{t(item.invoice_type) || '-'}</td>
                    <td>
                      {CurrencyFormatter(eval(`${getPaymentAmount(undefined, item.payments)}`))}
                    </td>
                    <td>
                      {CurrencyFormatter(eval(`${getPaymentAmount(undefined, item.payments)}`))}
                    </td>
                    <td>{CurrencyFormatter(item?.vat || 0)}</td>
                    <td className={item?.invoice_type == 'refunded' ? 'text-red-500' : undefined}>
                      {CurrencyFormatter(
                        eval(`${item?.invoice_type == 'refunded' ? '-' : ''}${item?.total || 0}`)
                      )}
                    </td>
                    <td>
                      <SharedTime date={item.invoice_date} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="bookings/export_excel"
                cvsPathname="bookings/export_csv"
                pathname="bookings/send_excel"
                pdfPathname="bookings/export_pdf"
                params={params.toString()}
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
            // GetItems();
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
          // GetItems();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
          // GetItems();
        }}
        loading={isLoading || isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <div className="grid grid-wrapper gap-3">
          <FilterComponent />
        </div>
        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>

        {MEMO_TABLE}
      </div>
    </React.Fragment>
  );
};

function FilterComponent({ refetch }: any) {
  let render = true;
  const { t } = useTranslation();

  const [cashiers, setCashiers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [marketers, setMarketers] = useState<any[]>([]);

  const [params, setParams] = useSearchParams({
    page: '1'
  });

  useEffect(() => {
    if (!render) return;
    Promise.all([getClients(), getCashiers(), getMarketers()]);
  }, []);

  const getClients = (search_key?: string) =>
    prepareRequest(
      { url: 'users', params: { is_active: 1, user_type: 'client', search_key, page: 1 } },
      (data) => {
        const users = data.result?.users?.data || [];
        setClients(() =>
          users.map((e: any) => ({
            ...e,
            name: [e.name, e.mobile].join(' - ')
          }))
        );
      }
    );

  const getCashiers = (search_key?: string) =>
    prepareRequest(
      { url: 'users', params: { is_active: 1, user_type: 'cashier', search_key, page: 1 } },
      (data) => {
        const users = data.result?.users?.data || [];
        setCashiers(users);
      }
    );

  const getMarketers = (search_key?: string) =>
    prepareRequest(
      { url: 'users', params: { is_active: 1, user_type: 'marketer', search_key, page: 1 } },
      (data) => {
        const users = data.result?.users?.data || [];
        setMarketers(users);
      }
    );

  return (
    <>
      <div className="form-group">
        <p className="form-label">{t('client')}</p>
        <Select
          value={params.get('client_id') as string}
          options={clients}
          optionTxt="name"
          optionValue="id"
          type="single"
          onSelect={(value) => {
            setParams((param) => {
              if (!value || value === '') {
                param.delete('client_id');
              } else param.set('client_id', value as any);
              param.set('page', '1');
              return param;
            });
            // refetch();
          }}
          onSearchChange={(ev) => getClients(ev.target.value)}
        />
      </div>

      <div className="form-group">
        <p className="form-label">{t('cashier')}</p>
        <Select
          value={params.get('cashier_id') as string}
          options={cashiers}
          optionTxt="name"
          optionValue="id"
          type="single"
          onSelect={(value) => {
            setParams((param) => {
              if (!value || value === '') {
                param.delete('cashier_id');
              } else param.set('cashier_id', value as any);
              param.set('page', '1');
              return param;
            });
            // refetch();
          }}
          onSearchChange={(ev) => getClients(ev.target.value)}
        />
      </div>

      <div className="form-group">
        <p className="form-label">{t('call-center')}</p>
        <Select
          value={params.get('marketer_id') as string}
          options={marketers}
          optionTxt="name"
          optionValue="id"
          type="single"
          onSelect={(value) => {
            setParams((param) => {
              if (!value || value === '') {
                param.delete('marketer_id');
              } else param.set('marketer_id', value as any);
              param.set('page', '1');
              return param;
            });
            // refetch();
          }}
          onSearchChange={(ev) => getMarketers(ev.target.value)}
        />
      </div>

      <div className="form-group">
        <p className="form-label">{t('payments')}</p>
        <select
          className="form-select form-outline"
          value={params.get('payment_option') as string}
          defaultValue=""
          name="payment_option"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setParams((param) => {
              param.set('payment_option', (e.target as any).value);
              param.set('page', '1');
              return param;
            });
            // refetch();
          }}
        >
          <option
            value=""
            disabled
          >
            {t('select')}
          </option>
          <option value="cash">{t('cash')}</option>
          <option value="card">{t('card')}</option>
          <option value="bank_transfer">{t('bank_transfer')}</option>
        </select>
      </div>

      <div className="custom-date-picker space-y-2">
        <p className="form-label">{t('date')}</p>

        <RangePicker
          defaultValue={[
            dayjs(params.get('from') || moment(new Date()).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
            dayjs(params.get('to') || moment(new Date()).format('YYYY-MM-DD'), 'YYYY-MM-DD')
          ]}
          className="text-center"
          onChange={(date, dateString) => {
            setParams((param) => {
              if (!dateString[0] || !dateString[1]) {
                param.delete('from');
                param.delete('to');
              } else {
                param.set('from', dateString[0]);
                param.set('to', dateString[1]);
              }
              param.set('page', '1');
              return param;
            });
          }}
        />
      </div>
    </>
  );
}
export default Invoices;

