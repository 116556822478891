import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export default function Footer() {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <footer className="border-t border-t-slate-200 p-6">
      <div className="w-full flex items-center justify-between gap-4 flex-wrap">
        <p className="text-sm font-medium text-slate-500">
          ©2023 - {new Date().getFullYear()} {t('copy-rights')}
        </p>
      </div>
    </footer>
  );
}
