import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import Card from './Card';

interface PackageFeatureProps {
  title: string;
  originValue: number;
  value: number;
  icon: string;
  prefix?: string;
}

export default function PackageFeature({
  title,
  originValue,
  value,
  icon,
  prefix
}: PackageFeatureProps) {
  const { t } = useTranslation();
  const percentage = Math.ceil((value / originValue) * 100);
  return (
    <Card className="p-4 !bg-gray space-y-3">
      <div className="flex items-center gap-3">
        <div>
          <Icon
            icon={icon}
            width="1.5rem"
            height="1.5rem"
            className="text-black"
          />
        </div>
        <div className="flex-1 flex gap-2 justify-between">
          <p className="font-medium text-gray-500 text-sm">{t(title)}</p>
          <h2 className="text-sm font-extrabold">
            {value}/{originValue} {prefix}
          </h2>
        </div>
      </div>
      <div className="w-full p-px bg-gray-100 h-2 rounded-full">
        <div
          className="h-full bg-gradient-t-r rtl:bg-gradient-to-l from-blue-600 to-blue-800 rounded-full"
          style={{
            width: (percentage >= 100 ? 100 : percentage) + '%'
          }}
        ></div>
      </div>
    </Card>
  );
}
