import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Delete from 'src/components/shared/Delete';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import useForm from 'src/hooks/useForm';

const CostCenters: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('cost_center_groups', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.cost_center_groups;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('alt-name')}</th>
              <th>{t('status')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>
                    <td>{item.alt_name || 'N/A'}</td>
                    <td>
                      <ChangeStatus
                        pathname={'cost_center_groups/update_is_active/' + item.id}
                        refetch={GetItems}
                        active={!!item.is_active}
                      />
                    </td>
                    {/* <td >
                      <Link
                        to={{
                          pathname: '/settings/sub-CostCenters',
                          search: new URLSearchParams({
                            category: item.id
                          }).toString()
                        }}
                        className="btn-with-icon outline-btn max-w-max"
                      >
                        <Icon
                          icon="ri:eye-line"
                          width={18}
                        />
                        <span>View sub CostCenters</span>
                      </Link>
                    </td> */}
                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          {t('edit')}
                        </button>

                        <Delete
                          pathname={'cost_center_groups/delete/' + item.id}
                          refetch={GetItems}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </button>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
        size="!max-w-screen-md"
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        size="!max-w-screen-md"
        title={editItem?.name}
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={editItem}
        />
      </Modal>
    </React.Fragment>
  );
};

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  cost_centers: Omit<FormInterface, 'cost_centers'>[];
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    name: undefined,
    alt_name: undefined,
    cost_centers: [
      {
        name: undefined,
        alt_name: undefined
      }
    ]
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('cost_center_groups/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { handleChange, handleSubmit, setFieldValue, values }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.name}
          name="name"
          onChange={handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('alt-name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.alt_name}
          name="alt_name"
          onChange={handleChange}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>

      <>
        {values.cost_centers.map((center: any, index: number) => (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={values.cost_centers[index]['name']}
                name={'cost_centers.' + index + '.name'}
                onChange={handleChange}
              />
              {errors?.['cost_centers.' + index + '.name'] ? (
                <span className="form-error">{errors?.['cost_centers.' + index + '.name']}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <div className="flex items-center gap-3">
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline flex-1"
                  value={values.cost_centers[index]['alt_name']}
                  name={'cost_centers.' + index + '.alt_name'}
                  onChange={handleChange}
                />
                {index != values.cost_centers?.length - 1 ? (
                  <button
                    className="btn-with-icon bg-red-100 !text-red-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        'cost_centers',
                        values.cost_centers.filter((_e: any, idx: number) => index !== idx)
                      );
                    }}
                  >
                    <Icon
                      icon="solar:trash-bin-minimalistic-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                ) : (
                  <button
                    className="btn-with-icon bg-blue-100 !text-blue-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue('cost_centers', [
                        ...values.cost_centers,
                        {
                          name: undefined,
                          alt_name: undefined
                        }
                      ]);
                    }}
                    disabled={
                      !(
                        values.cost_centers[index]['name'] || values.cost_centers[index]['alt_name']
                      )
                    }
                  >
                    <Icon
                      icon="solar:add-circle-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                )}
              </div>
              {errors?.['cost_centers.' + index + '.alt_name'] ? (
                <span className="form-error">
                  {errors?.['cost_centers.' + index + '.alt_name']}
                </span>
              ) : null}
            </div>
          </div>
        ))}
      </>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('cost_center_groups/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.name}
          name="name"
          onChange={handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('alt-name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.alt_name}
          name="alt_name"
          onChange={handleChange}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>

      <>
        {values.cost_centers.map((center: any, index: number) => (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={values.cost_centers[index]['name']}
                name={'cost_centers.' + index + '.name'}
                onChange={handleChange}
              />
              {errors?.['cost_centers.' + index + '.name'] ? (
                <span className="form-error">{errors?.['cost_centers.' + index + '.name']}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt-name')}</label>
              <div className="flex items-center gap-3">
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline flex-1"
                  value={values.cost_centers[index]['alt_name']}
                  name={'cost_centers.' + index + '.alt_name'}
                  onChange={handleChange}
                />
                {index != values.cost_centers?.length - 1 ? (
                  <button
                    className="btn-with-icon bg-red-100 !text-red-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        'cost_centers',
                        values.cost_centers.filter((_e: any, idx: number) => index !== idx)
                      );
                    }}
                  >
                    <Icon
                      icon="solar:trash-bin-minimalistic-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                ) : (
                  <button
                    className="btn-with-icon bg-blue-100 !text-blue-600 h-full"
                    type="button"
                    onClick={() => {
                      setFieldValue('cost_centers', [
                        ...values.cost_centers,
                        {
                          name: undefined,
                          alt_name: undefined
                        }
                      ]);
                    }}
                    disabled={
                      !(
                        values.cost_centers[index]['name'] || values.cost_centers[index]['alt_name']
                      )
                    }
                  >
                    <Icon
                      icon="solar:add-circle-outline"
                      width="20"
                      height="20"
                    />
                  </button>
                )}
              </div>
              {errors?.['cost_centers.' + index + '.alt_name'] ? (
                <span className="form-error">
                  {errors?.['cost_centers.' + index + '.alt_name']}
                </span>
              ) : null}
            </div>
          </div>
        ))}
      </>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

export default CostCenters;

