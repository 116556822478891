export default function generateRandomWord(length: number = 8): string {
  const vowels = 'aeiou';
  const consonants = 'bcdfghjklmnpqrstvwxyz';
  let randomWord = '';

  for (let i = 0; i < length; i++) {
    // Alternate between consonants and vowels
    if (i % 2 === 0) {
      const randomConsonant = consonants[Math.floor(Math.random() * consonants.length)];
      randomWord += randomConsonant;
    } else {
      const randomVowel = vowels[Math.floor(Math.random() * vowels.length)];
      randomWord += randomVowel;
    }
  }

  return randomWord;
}
