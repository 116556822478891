import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from './shared/Card';
import Image from './shared/Image';

interface TutorialCard {
  title: string;
  alt_title: string;
  id: string;
  tutorial_category: any;
  image: string;
}

export default function TutorialCard({
  title,
  alt_title,
  id,
  tutorial_category,
  image
}: TutorialCard) {
  const { t, i18n } = useTranslation();
  return (
    <Link
      to={'/tutorials/' + id}
      className="block group"
    >
      <Card className="h-full flex flex-col !p-0 overflow-hidden relative">
        {!!tutorial_category && (
          <span className="absolute top-0 m-3 rounded-full line-clamp-1 py-1.5 px-2 text-xs bg-blue-700 text-white border border-white">
            {tutorial_category.name} - {tutorial_category.alt_name}
          </span>
        )}
        <div className="flex-1">
          <picture>
            <Image
              src={image}
              width="250"
              height="250"
              alt={i18n.language == 'ar' ? alt_title : title}
              title={i18n.language == 'ar' ? alt_title : title}
              className="w-full h-full"
            />
          </picture>
        </div>
        <div className="flex-1 space-y-2 p-4">
          <h2 className="font-bold line-clamp-1">{i18n.language == 'ar' ? alt_title : title}</h2>
          <p className="flex items-center gap-1 max-w-fit ms-auto text-sm font-medium underline group-hover:no-underline text-primary">
            <span>{t('view-details')}</span>
            <Icon
              width="1.2rem"
              height="1.2rem"
              icon="solar:double-alt-arrow-right-line-duotone"
              className="rtl:rotate-180"
            />
          </p>
        </div>
      </Card>
    </Link>
  );
}
