import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Password from 'src/components/shared/Password';
import SharedTime from 'src/components/shared/SharedTime';
import Switcher from 'src/components/shared/Switcher';
import SlideUpDown from 'src/components/shared/animations/SlideUpDown';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import createScript from 'src/helper/createScript';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function SMSSettings() {
  const { t } = useTranslation();
  return (
    <div className="p-6 space-y-4">
      <UpdateSmsForm />
      <div
        className="space-y-1"
        id="packages"
      >
        <p className="text-lg font-semibold">{t('packages')}</p>
        <p className="text-sm text-gray-500">{t('sms-packages-content')}</p>
      </div>
      <SMSPackages />
    </div>
  );
}

function UpdateSmsForm() {
  let render = true;
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentGateway, setPaymentGateway] = useState<any>({});
  const [customVisible, setCustomVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!render) return;
    getUpdates();
    render = false;
  }, []);

  function getUpdates() {
    setIsLoading(true);
    prepareRequest({ url: 'settings/sms_gateway' }, (data) => {
      const result = data.result;
      console.log(result);

      setInitialValues(() => ({
        gateway: result.gateway,
        ...result.infinito_config
      }));
      setPaymentGateway(() => ({
        ...result
      }));
      setCustomVisible(() => !!result.gateway);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function submitHandler(values: any, helper: any) {
    setErrors({});
    setDisabled(true);
    prepareRequest(
      {
        url: 'settings/sms_gateway/update',
        method: 'post',
        data: {
          ...(values.gateway && {
            ...values
          }),
          gateway: values.gateway ? values.gateway : null
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        await getUpdates();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  if (isLoading) return <LoadingComponent />;
  return (
    <>
      <Card className="!p-6">
        <div className="flex gap-4 justify-between">
          <div className="space-y-1">
            <p className="text-lg font-semibold">{t('custom-sms-form-content')}</p>
            <p className="text-sm text-gray-500">{t('sms-settings-content')}</p>
          </div>
          <Switcher
            checked={customVisible}
            onChange={setCustomVisible}
          />
        </div>
      </Card>
      {customVisible ? (
        <SlideUpDown visible={customVisible}>
          <Card className="!p-6">
            <form
              className="space-y-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="form-group col-span-full">
                  <label className="form-label">{t('sms-type')}</label>
                  <select
                    name="gateway"
                    id="gateway"
                    className="form-select form-outline"
                    defaultValue=""
                    value={formik.values.gateway}
                    onChange={formik.handleChange}
                  >
                    <option value="">{t('select')}</option>
                    <option value="infinito">infinito</option>
                  </select>
                  {errors?.gateway ? <span className="form-error">{errors?.gateway}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('sender-name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.sender_name}
                    onChange={(e) => handleChange('sender_name', e)}
                  />
                  {errors?.sender_name ? (
                    <span className="form-error">{errors?.sender_name}</span>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="form-label">{t('client-id')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.client_id}
                    onChange={(e) => handleChange('client_id', e)}
                  />
                  {errors?.client_id ? (
                    <span className="form-error">{errors?.client_id}</span>
                  ) : null}
                </div>
                <div className="form-group col-span-full">
                  <label className="form-label">{t('password')}</label>
                  <Password
                    autoComplete="off"
                    className="form-input form-outline"
                    value={formik.values.client_password}
                    onChange={(e) => handleChange('client_password', e)}
                  />
                  {errors?.client_password ? (
                    <span className="form-error">{errors?.client_password}</span>
                  ) : null}
                </div>
              </div>

              <div className="inline-flex gap-3 flex-wrap">
                <button
                  className="btn-with-icon bg-primary text-white"
                  type="submit"
                  disabled={disabled}
                >
                  <span>{t('save-changes')}</span>
                </button>
              </div>
            </form>
          </Card>
        </SlideUpDown>
      ) : null}
      {!customVisible && !formik.values.gateway ? (
        <>
          <Card className="!p-6">
            <SMSSummary item={paymentGateway} />
          </Card>
          <SMSLogs />
        </>
      ) : null}
    </>
  );
}

function SMSPackages() {
  let render = true;
  const { t } = useTranslation();
  const [packages, setPackages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!render) return;
    getPackages();
    render = false;
  }, []);

  function getPackages() {
    setIsLoading(true);
    prepareRequest({ url: 'settings/sms_gateway/packages' }, (data) => {
      const result = data.result;
      setPackages(() => result.sms_packages || []);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="grid grid-wrapper gap-4">
      {packages.map((pack) => (
        <SMSCard
          item={pack}
          key={pack.id}
        />
      ))}
    </div>
  );
}

function SMSLogs() {
  let render = true;
  const { t } = useTranslation();
  const [logs, setLogs] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!render) return;
    getLogs();
    render = false;
  }, []);

  function getLogs(params?: any) {
    setIsLoading(true);
    prepareRequest({ url: 'settings/sms_gateway/logs', params: { page: 1, ...params } }, (data) => {
      const result = data.result;
      setLogs(() => result.sms_logs?.data || []);
      setPagination(() => result.sms_logs?.pagination || {});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      <Table
        title={t('sms-logs')}
        isEmpty={!logs.length}
        RenderHead={() => (
          <tr>
            <th>{t('sms-type')}</th>
            <th>{t('messages-count')}</th>
            <th>{t('details')}</th>
            <th>{t('date')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{log.gateway || '-'}</td>
                <td>{log.messages_count || 0}</td>
                <td>{log.extra || '-'}</td>
                <td>
                  <SharedTime date={log.created_at} />
                </td>
              </tr>
            ))}
          </>
        )}
        pagination={pagination}
        loading={isLoading}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            getLogs({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => getLogs({ page: pagination.page + 1 })}
        onPreviousClick={() => getLogs({ page: pagination.page - 1 })}
      />
    </>
  );
}

function SMSCard({ item }: { item: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Card className="min-h-full !p-0 overflow-hidden">
      <div className="w-full p-6 bg-primary text-center space-y-2 relative">
        <span className="absolute top-0 m-4 left-0 rtl:right-0 rtl:left-auto opacity-20">
          <Icon
            icon="tabler:message-2-dollar"
            width="100"
            height="100"
            className="text-white"
          />
        </span>
        <p className="font-semibold text-white">{item.name || 'N/A'}</p>
        <p className="text-lg font-bold text-white">{CurrencyFormatter(Number(item.price || 0))}</p>
        <p className="text-sm text-white">
          {t('per')} <span className="font-bold">{item.messages_count || 0}</span>{' '}
          <span>{t('sms')}</span>
        </p>
      </div>
      <div>
        <ButtonWithCallback
          options={{
            icon: 'question',
            title: t('are-you-sure'),
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            showCancelButton: true
          }}
          callback={() => setVisible(true)}
          className="font-semibold text-sm text-center w-full underline hover:no-underline text-gray-600 p-4 flex items-center justify-center gap-3"
        >
          <Icon
            icon="solar:dollar-bold"
            width="20"
            height="20"
          />
          <span>{t('buy-now')}</span>
        </ButtonWithCallback>
      </div>

      <Modal
        visible={visible}
        title={`${t('pay-for')} " ${item.name} "`}
        handleClose={() => setVisible(false)}
        size="max-w-screen-sm"
      >
        <SubscribeForm item={item} />
      </Modal>
    </Card>
  );
}

function SubscribeForm({ item }: { item: any }) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    payment_option: 'visa',
    sms_package_id: item.id
  });
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const [params, setParams] = useState<string>();

  useEffect(() => {
    if (!result) return;
    const script = createScript({
      src: result?.checkout_url
    });
    const content = createScript({
      content: 'var wpwlOptions = { style: "plain", validation: true}'
    });

    return () => {
      setResult(undefined);
      script.remove();
      content.remove();
    };
  }, [result]);

  const { formik } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'settings/sms_gateway/buy_package',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          setResult(() => ({
            ...data.result,
            package: JSON.stringify(item)
          }));
          const query = new URLSearchParams({
            payment: data.result?.payment_option as string,
            payment_option: data.result?.payment_option as string,
            type: 'sms' as string
          }).toString();
          setParams(query);
        }
      ).finally(() => {
        setDisabled(false);
      });
    }
  });

  return (
    <>
      {result ? (
        <form
          action={'/checkout/?' + params}
          className="paymentWidgets"
          data-brands={
            result?.payment_option === 'visa'
              ? 'VISA MASTER'
              : result?.payment_option === 'mada'
              ? 'MADA'
              : ''
          }
        ></form>
      ) : (
        <form
          className="space-y-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('pay-with')}</p>
            <select
              name="payment_option"
              id="payments"
              className="form-select form-outline"
              value={formik.values.payment_option}
              defaultValue={formik.values.payment_option}
              onChange={formik.handleChange}
            >
              <option value="visa">{t('visa')}</option>
              <option value="mada">{t('mada')}</option>
            </select>
            <p className="form-error">{errors?.['payment_option']}</p>
            <p className="form-error">{errors?.['sms_package_id']}</p>
          </div>
          <button
            className="btn-with-icon bg-primary text-white"
            type="submit"
            disabled={disabled}
          >
            <span>{t('submit')}</span>
          </button>
        </form>
      )}
    </>
  );
}

function SMSSummary({ item }: { item: any }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const remainingPercent = ((item.balance || 0) / (item.total_messages || 0)) * 100;

  return (
    <div className="space-y-4">
      <div className="flex justify-between gap-4">
        <p className="text-gray-500">{t('total-amount')}</p>
        <p className="font-bold">{CurrencyFormatter(item.total_amount || 0)}</p>
      </div>
      <div className="flex justify-between gap-4">
        <p className="text-gray-500 text-sm">
          {t('messages-count')} ({item.total_messages || 0})
        </p>
        <p className="text-gray-500 text-sm">
          {t('remaining-messages-count')} ({item.balance || 0})
        </p>
      </div>
      <div className="w-full p-0.5 h-3 rounded-full bg-gray-200">
        <span
          className="bg-teal-600 h-full rounded-full transition-all block relative"
          style={{
            width: remainingPercent + '%'
          }}
        >
          <span className="absolute top-1/2 -translate-y-1/2 right-0 rtl:right-auto rtl:left-0 w-4 h-4 rounded-full bg-white border border-teal-600">
            <span className="absolute w-full h-full bg-teal-600 opacity-50 animate-ping z-[-1] rounded-full"></span>
          </span>
        </span>
      </div>
      <p className="text-gray-500 text-sm pt-4 border-t border-t-gray-200">
        {t('messages-content')}{' '}
        <Link
          to={{
            pathname: pathname,
            hash: 'packages'
          }}
          className="text-primary font-semibold"
        >
          {t('buy-now')}
        </Link>
      </p>
    </div>
  );
}

