import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';

const AddAboutUs = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [contentValue, setContentValue] = useState<string>('');
  const [altContentValue, setAltContentValue] = useState<string>('');

  const handleSubmit = (e: any) => {};

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group col-span-full">
                <label className="form-label">{t('content')}</label>
                <Editor
                  value={contentValue}
                  onChange={(e: any) => setContentValue(e)}
                />
                <span className="form-error">{errors['content']}</span>
                <span className="form-error">{errors['id']}</span>
              </div>

              <div className="form-group col-span-full">
                <label className="form-label">{t('alt_content')}</label>
                <Editor
                  value={altContentValue}
                  onChange={(e: any) => setAltContentValue(e)}
                />
                <span className="form-error">{errors['alt_content']}</span>
                <span className="form-error">{errors['id']}</span>
              </div>
            </div>
          </div>
        </Card>
        <div className="inline-flex items-center gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary shrink-0"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAboutUs;

