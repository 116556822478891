import { Buffer } from 'buffer';

function getTLVForValue(num, value) {
  const tagNum = Buffer.from([num], 'utf-8');
  const tagValueLength = Buffer.from([value?.length], 'utf-8');
  const tagValue = Buffer.from(value, 'utf-8');
  const bufferArray = [tagNum, tagValueLength, tagValue];
  return Buffer.concat(bufferArray);
}

export default function generateQRCode(name, vatNumber, invoiceAmount, invoiceVat) {
  const seller = getTLVForValue('1', name);
  const vat = getTLVForValue('2', vatNumber);
  const timestamp = getTLVForValue('3', new Date().toISOString());
  const amount = getTLVForValue('4', invoiceAmount);
  const vatAmount = getTLVForValue('5', invoiceVat);

  const QRBufferConcat = [seller, vat, timestamp, amount, vatAmount];

  const base64 = Buffer.concat(QRBufferConcat).toString('base64');
  return base64;
}
