import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import ExportOptions from 'src/components/shared/ExportOptions';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import ReportsWrapper from '../../../components/reports/ReportsWrapper';

const index: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });
  const [params, setParams] = useSearchParams({ page: '1' });

  useEffect(() => {
    GetItems();
  }, [params]);

  function GetItems() {
    setIsUpdating(true);
    prepareRequest(
      {
        url: 'reports/clients',
        params
      },
      (data) => {
        const { data: items, pagination: responsePaginate } = data?.result?.clients;
        // console.log(data);
        setPagination(() => responsePaginate);
        setResponses(() => items);
      }
    ).finally(() => setIsUpdating(false));
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('mobile')}</th>
              <th>{t('completed-services')}</th>
              <th>{t('incomplete-services')}</th>
              <th>{t('cancelled-services')}</th>
              <th>{t('total')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || '-'}</td>
                    <td>{item.mobile || '-'}</td>

                    <td>
                      <div className="flex gap-2">
                        <p>({item.completed_services_count || '-'})</p>
                        <Link
                          to={{
                            pathname: '/items',
                            search: '?client_id=' + item.id + '&status=completed'
                          }}
                          className="underline text-blue-600"
                        >
                          {t('view')}
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <p>({item.incompleted_services_count || '-'})</p>
                        <Link
                          to={{
                            pathname: '/items',
                            search: '?client_id=' + item.id
                          }}
                          className="underline text-blue-600"
                        >
                          {t('view')}
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <p>({item.cancelled_services_count || '-'})</p>
                        <Link
                          to={{
                            pathname: '/items',
                            search: '?client_id=' + item.id + '&status=cancelled'
                          }}
                          className="underline text-blue-600"
                        >
                          {t('view')}
                        </Link>
                      </div>
                    </td>
                    <td>{CurrencyFormatter(item.payments_total || 0)}</td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="reports/clients/export_excel"
                cvsPathname="reports/clients/export_csv"
                pathname="reports/clients/send_excel"
                pdfPathname="reports/clients/export_pdf"
                params={params.toString()}
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        onNextClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page + 1 : 1).toString()
            );

            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set(
              'page',
              (pagination.current_page >= 1 ? pagination.current_page - 1 : 1).toString()
            );

            return param;
          });
        }}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="monthly"
          onChange={({ from, to }) => {
            setParams((param) => {
              param.set('from', moment(from).format('yyyy-MM-DD'));
              param.set('to', moment(to).format('yyyy-MM-DD'));
              param.set('page', '1');

              return param;
            });
          }}
        />
      </div>
      <div className="grid">{MEMO_TABLE}</div>
    </ReportsWrapper>
  );
};

export default index;
