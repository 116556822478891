import { Switch } from '@headlessui/react';
import { ComponentProps } from 'react';

export default function Switcher(props: ComponentProps<typeof Switch>) {
  return (
    <Switch
      className={`${
        props.checked ? 'bg-blue-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full shrink-0`}
      {...props}
    >
      <span
        className={`${
          props.checked ? 'translate-x-6 rtl:-translate-x-6' : 'translate-x-1 rtl:-translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
}
