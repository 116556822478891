import { pick } from 'lodash';
import { FocusEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import { Option, Variant } from 'src/components/shared/items/FormWithVariants';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';

export default function UpdateItems() {
  const { t } = useTranslation();
  return (
    <div>
      <AppBar title={t('update-items')} />
      <UpdateItemsForm />
    </div>
  );
}

type ItemType = {
  id: string;
  name: string;
  alt_name: string;
  variants: Variant[];
  options: Option[];
  price: number;
  cost: number;
};

const itemTypes: any[] = [
  {
    key: 'service',
    label: 'service'
  },
  {
    key: 'product',
    label: 'product'
  },
  {
    key: 'package_offer',
    label: 'packages-offers'
  },
  {
    key: 'options',
    label: 'options'
  }
];

interface UpdateItemsFormInterface {
  items: ItemType[];
  price_list_id: string | undefined;
}

function UpdateItemsForm() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [price, setPrice] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [initialValues, setInitialValues] = useState<UpdateItemsFormInterface>({
    items: [],
    price_list_id: id
  });
  const [pagination, setPagination] = useState<any>({});
  const [items, setItems] = useState<any[]>([]);
  const [modifyAble, setModifyAble] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState('service');

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      const data = pick(values, ['items', 'price_list_id']);
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'price_lists/update_items',
          method: 'post',
          data
        },
        (response, error) => {
          if (error) return setErrors(() => error);
          generateAlert(response.message, 'success');
          formikHelpers.resetForm();
          navigate('/settings/prices', {
            replace: true
          });
        }
      ).finally(() => setDisabled(false));
    }
  });

  useEffect(() => {
    getPrice();
    getItems();
  }, []);

  function getPrice() {
    prepareRequest(
      {
        url: 'price_lists/' + id,
        method: 'get'
      },
      (data) => {
        const priceItem = data.result?.price_list || {};
        setPrice(() => priceItem);
        setFieldValue('items', priceItem?.items);
        setFieldValue(
          'center_ids',
          priceItem?.centers?.map((e: any) => e.id)
        );
      }
    ).finally(() => setIsLoading(false));
  }

  function getItems(params?: any) {
    prepareRequest(
      {
        url: 'items',
        params: {
          price_list_id: id,
          item_type: selectedType,
          page: 1,
          ...params
        }
      },
      (data) => {
        const itemsResult: any[] = (data.result?.items?.data || [])?.map((item: any) => {
          const result = pick(item, [
            'variants',
            'options',
            'price',
            'cost',
            'id',
            'name',
            'alt_name'
          ]);
          const variants = result.variants?.map((variant: any) => ({
            ...variant,
            original_price: variant.price,
            original_cost: variant.cost
          }));
          const options = result.options?.map((option: any) => ({
            ...option,
            original_price: option.price,
            original_cost: option.cost
          }));

          return {
            ...result,
            original_price: result.price || 0,
            original_cost: result.cost || 0,
            variants,
            options
          };
        });
        const sort = itemsResult.sort((a: any, b: any) => {
          return a.options?.length > b.options?.length || a.variants?.length > b.variants?.length
            ? -1
            : 1;
        });
        setFieldValue('items', sort);
        setInitialValues((e) => ({
          ...e,
          items: sort
        }));
        setPagination((e: any) => ({
          ...e,
          ...data.result?.items?.pagination
        }));
      }
    );
  }

  function onItemsChange(value: any, products: any[]) {
    const item = pick(
      products.find((ev: any) => ev.id == value),
      ['name', 'alt_name', 'id', 'variants', 'options', 'price']
    );
    const clone = {
      ...item,
      variants: item?.variants?.map((variant: any) => ({
        ...variant,
        original_price: variant.price || 0
      })),
      options: item?.options?.map((variant: any) => ({
        ...variant,
        original_price: variant.price || 0
      }))
    };
    const includes = values.items?.find((e: ItemType) => e.id == value);
    if (includes) {
      generateAlert('( ' + includes.name + ' ) already in use');
      setFieldValue('item_id', undefined);
      return;
    }
    const concatItems = [...values.items, clone];
    setFieldValue('items', concatItems);
    setFieldValue('item_id', value);
  }

  function onPriceBlur(ev: FocusEvent<HTMLInputElement>) {
    const { valueAsNumber, dataset } = ev.target;
    const { itemId, variantId, optionId } = dataset;
    const { has_options } = pagination;
    const modifyItems = values.items.map((item: any) => {
      const existed = item.id == itemId;

      if (existed) {
        const options = item.options.map((option: any) => {
          return {
            ...option,
            price: option.id == optionId ? valueAsNumber : option.price
          };
        });
        const variants = item.variants.map((variant: any) => {
          return {
            ...variant,
            price: variant.id == variantId ? valueAsNumber : variant.price
          };
        });

        if (!has_options)
          return {
            ...item,
            ...(variantId ? { variants } : { price: valueAsNumber })
          };
        return {
          ...item,
          options
        };
      }
      return item;
    });

    setFieldValue('items', modifyItems);
  }
  if (isLoading) return <LoadingComponent />;

  return (
    <div className="space-y-4 p-6">
      <p className="text-center text-xl font-semibold">" {price.name} "</p>
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <ul className="flex items-center overflow-x-auto shrink-0 gap-2">
          {itemTypes.map((type: any, index: number) => (
            <li key={index}>
              <input
                type="radio"
                hidden
                className="peer"
                name="types"
                id={type.key}
                value={type.key}
                checked={type.key === selectedType}
                onChange={() => {
                  const changeOptions = {
                    ...(type.key !== 'options'
                      ? { item_type: type.key }
                      : { has_options: 1, item_type: undefined })
                  };
                  setSelectedType(() => type.key);
                  getItems(changeOptions);
                  setPagination((e: any) => ({
                    ...e,
                    ...changeOptions
                  }));
                }}
                disabled={modifyAble}
              />
              <label
                htmlFor={type.key}
                className="btn-with-icon bg-gray-100 !text-gray-500 peer-checked:bg-primary peer-checked:!text-white !rounded-full transition-all !px-4 cursor-pointer capitalize peer-disabled:opacity-70 peer-disabled:cursor-no-drop"
              >
                {t(type.label)}
              </label>
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-2 flex-wrap">
          <button
            className="btn-with-icon bg-blue-600 !text-white"
            type="button"
            onClick={() => setModifyAble(true)}
            disabled={modifyAble}
          >
            <span>{t('edit')}</span>
          </button>
          <button
            className="btn-with-icon bg-red-600"
            type="button"
            disabled={!modifyAble}
            onClick={() => setModifyAble(false)}
          >
            <span>{t('cancel')}</span>
          </button>
        </div>
      </div>
      <form
        className="space-y-4"
        onSubmit={handleSubmit}
      >
        <Table
          RenderHead={() => {
            return (
              <tr>
                <th>{t('name')}</th>
                <th>{t('price')}</th>
                <th>{t('cost')}</th>
                <th>
                  {t('edit')} - {t('price')}
                </th>
              </tr>
            );
          }}
          RenderBody={() => {
            return (
              <>
                {values.items.map((item: any, index: number) => {
                  return (
                    <>
                      {item.variants?.length && !pagination.has_options ? (
                        <>
                          {item.variants?.map((variant: any, variantIndex: number) => (
                            <tr key={item.id + variant.id}>
                              <td>
                                {item.name} - {variant.name}
                              </td>
                              <td>
                                <span
                                  className={
                                    variant.original_price !== variant.price
                                      ? 'text-gray-500 line-through'
                                      : undefined
                                  }
                                >
                                  {CurrencyFormatter(variant.original_price || 0)}
                                </span>{' '}
                                {variant.original_price !== variant.price ? (
                                  <span>{CurrencyFormatter(variant.price || 0)}</span>
                                ) : null}
                              </td>
                              <td>
                                <span>{CurrencyFormatter(variant.original_cost || 0)}</span>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-input form-outline text-center"
                                  name={'items.' + index + '.variants.' + variantIndex + '.price'}
                                  defaultValue={
                                    values.items[index]?.variants?.[variantIndex]?.price
                                  }
                                  data-item-id={item.id}
                                  data-variant-id={variant.id}
                                  onBlur={onPriceBlur}
                                  id={'item-price-' + item.id + '-variant-' + variant.id}
                                  disabled={!modifyAble}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : item.options?.length && pagination.has_options ? (
                        <>
                          {item.options?.map((option: any, optionIndex: number) => (
                            <tr key={item.id + option.id}>
                              <td>
                                {item.name} - {option.name}
                              </td>
                              <td>
                                <span
                                  className={
                                    option.original_price !== option.price
                                      ? 'text-gray-500 line-through'
                                      : undefined
                                  }
                                >
                                  {CurrencyFormatter(option.original_price || 0)}
                                </span>{' '}
                                {option.original_price !== option.price ? (
                                  <span>{CurrencyFormatter(option.price || 0)}</span>
                                ) : null}
                              </td>
                              <td>
                                <span>{CurrencyFormatter(option.original_cost || 0)}</span>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-input form-outline text-center"
                                  name={'items.' + index + '.options.' + optionIndex + '.price'}
                                  defaultValue={values.items[index]?.options?.[optionIndex]?.price}
                                  data-item-id={item.id}
                                  data-option-id={option.id}
                                  onBlur={onPriceBlur}
                                  id={'item-price-' + item.id + '-option-' + option.id}
                                  disabled={!modifyAble}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>
                            <span
                              className={
                                item.original_price !== item.price
                                  ? 'text-gray-500 line-through'
                                  : undefined
                              }
                            >
                              {CurrencyFormatter(item.original_price || 0)}
                            </span>{' '}
                            {item.original_price !== item.price ? (
                              <span>{CurrencyFormatter(item.price || 0)}</span>
                            ) : null}
                          </td>
                          <td>
                            <span>{CurrencyFormatter(item.original_cost || 0)}</span>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-input form-outline text-center"
                              name={'items.' + index + '.price'}
                              defaultValue={values.items[index]?.price}
                              data-item-id={item.id}
                              onBlur={onPriceBlur}
                              id={'item-price-' + item.id}
                              disabled={!modifyAble}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </>
            );
          }}
          loading={isLoading}
          isEmpty={!values.items.length}
          pagination={modifyAble ? undefined : pagination}
          searchProps={{
            onChange: (e) => {
              getItems({ search_key: (e.target as HTMLInputElement).value });
            }
          }}
          onNextClick={() => getItems({ page: pagination.page + 1 })}
          onPreviousClick={() => getItems({ page: pagination.page - 1 })}
        />
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
          disabled={!modifyAble || disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </form>
    </div>
  );
}

