import { Icon } from '@iconify/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';

export default function index() {
  const { t } = useTranslation();
  const url = new URL(window.location.href);
  const search = url.searchParams;
  const [result, setResult] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  async function checkPaymentStatus() {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.post('check_payment_status', {
        checkout_id: search.get('id'),
        payment_option: search.get('payment_option')
      });
      generateAlert(data.message, 'success');
      if (!data.result) {
        navigate('/', { replace: true });
        return;
      }
      setResult(() => data.result?.sms_package_order || {});
    } catch (error) {
      const message = 'Payment failed';
      generateAlert(message, 'error');
      navigate('/', { replace: true });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="p-6">
      <div className="mx-auto max-w-screen-sm my-8 space-y-4">
        <div className="space-y-2 text-center">
          <p className="text-4xl">🥳</p>
          <p className="text-xl font-bold">{t('done-successful')}</p>
        </div>
        <Card className="!p-6">
          <div className="grid grid-cols-2 gap-3">
            <p className="text-sm text-gray-500">{t('name')}:</p>
            <p className="text-sm font-semibold">{result.sms_package_name}</p>
            <p className="text-sm text-gray-500">{t('paid-amount')}:</p>
            <p className="text-sm font-semibold">{CurrencyFormatter(Number(result?.total) || 0)}</p>
            <p className="text-sm text-gray-500">{t('date')}:</p>
            <p className="text-sm font-semibold">
              {moment(new Date(result?.paid_at as string)).fromNow()}
            </p>
          </div>
        </Card>
        <Link
          to="/"
          className="btn-with-icon !py-3 !px-6 w-full max-w-xs bg-primary mx-auto !rounded-full"
        >
          <Icon
            icon="ri:home-5-line"
            width="20"
            height="20"
          />
          <span>{t('dashboard')}</span>
        </Link>
      </div>
    </div>
  );
}
// http://localhost:3000/checkout/?payment=visa&payment_option=visa&id=70C65048F72FE12ADB8C74353AF1979C.uat01-vm-tx03&resourcePath=%2Fv1%2Fcheckouts%2F70C65048F72FE12ADB8C74353AF1979C.uat01-vm-tx03%2Fpayment
// http://localhost:3000/checkout/?payment=visa&payment_option=visa&type=sms&id=778B02A6DEF2C16CC0872749509900DC.uat01-vm-tx02&resourcePath=%2Fv1%2Fcheckouts%2F778B02A6DEF2C16CC0872749509900DC.uat01-vm-tx02%2Fpayment
