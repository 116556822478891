import { Icon } from '@iconify/react';

export default function WhatsAppSupportBtn() {
  return (
    <a
      href="https://wa.me/+966545495924"
      className="fixed bottom-6 right-6 w-16 h-16 rounded-full shadow-xl shadow-gray-800/10 bg-teal-600 flex items-center justify-center text-white border-4 border-white z-50"
    >
      <Icon
        icon="fa-brands:whatsapp"
        width="24"
        height="24"
      />
    </a>
  );
}
