import { RouteObject } from 'react-router-dom';
import {
  AccountingTree,
  ActivityLogs,
  AddCenter,
  AddProduct,
  AddService,
  AdvancedGlobalSettings,
  Attendance,
  BookingDetails,
  Calendar,
  Cashiers,
  Categories,
  CenterSettings,
  // Packages,
  Centers,
  ChartsSummaryReports,
  Checkout,
  Clients,
  ClientsReports,
  CostCenters,
  EmailTemplates,
  Employees,
  EmployeesReports,
  ErrorPage,
  Expenses,
  Faqs,
  Home,
  InvoiceDetails,
  Invoices,
  JournalEntries,
  Login,
  Management,
  Marketers,
  Messages,
  NewJournalEntry,
  NewPos,
  NewPurchaseOrder,
  NewStocktaking,
  NotificationTemplates,
  Notifications,
  Offers,
  POS,
  PackagesOffersReports,
  PackagesPlans,
  Pages,
  PaymentGateway,
  Prices,
  Processing,
  Products,
  ProductsReports,
  Profile,
  PurchaseOrderDetails,
  PurchaseOrders,
  Register,
  Reports,
  ReservationItems,
  SMSSettings,
  SMSTemplates,
  Salaries,
  Services,
  ServicesReports,
  SessionsReports,
  Settings,
  Setup,
  SingleMessage,
  Stocktaking,
  Suppliers,
  Transactions,
  Tutorials,
  UpdateCenter,
  UpdateItems,
  UpdateJournalEntry,
  UpdateProduct,
  UpdateService,
  Users,
  Wastages,
  WastagesManagement,
  DesignReceipt,
  ForgotPassword
} from 'src/screens';
import TutorialDetails from 'src/screens/tutorials/id';
import { Reservations, SalariesReports } from '../screens';

type Route = RouteObject & {
  permission?: string | string[];
  breadcrumb?: any;
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  },
  {
    path: '/packages',
    element: <PackagesPlans />
  },
  {
    path: '/tutorials',
    element: <Tutorials />
  },
  {
    path: '/tutorials/:id',
    element: <TutorialDetails />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/forgot-password',
    element: <ForgotPassword />
  },
  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/account/setup',
    element: <Setup />,
    permission: 'setup'
  },
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },

  {
    path: '/invoices',
    element: <Invoices />,
    permission: 'invoices'
  },
  {
    path: '/invoices/:id',
    element: <InvoiceDetails />,
    permission: 'invoices'
  },
  {
    path: '/bookings',
    element: <Reservations />,
    permission: 'bookings'
  },
  {
    path: '/items',
    element: <ReservationItems />,
    permission: 'booking-items'
  },

  {
    path: '/bookings/:id',
    element: <BookingDetails />,
    permission: 'bookings'
  },

  {
    path: '/notifications',
    element: <Notifications />,
    permission: 'notifications'
  },
  {
    path: '/notifications/templates',
    element: <NotificationTemplates />,
    permission: 'notifications'
  },
  {
    path: '/messages',
    element: <Messages />,
    permission: 'messages'
  },
  {
    path: '/activity-logs',
    element: <ActivityLogs />,
    permission: 'logs'
  },
  {
    path: '/messages/:id',
    element: <SingleMessage />,
    permission: 'messages'
  },
  {
    path: '/settings',
    element: <Settings />,
    permission: 'settings'
  },

  {
    path: '/settings/cashiers',
    element: <Cashiers />,
    permission: 'cashiers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/call-centers',
    element: <Marketers />,
    permission: 'marketers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/experts',
    element: <Employees />,
    permission: 'employees',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/suppliers',
    element: <Suppliers />,
    permission: 'suppliers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/clients',
    element: <Clients />,
    permission: 'clients',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers',
    element: <Centers />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers/add',
    element: <AddCenter />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/centers/:id',
    element: <UpdateCenter />,
    permission: 'centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/salaries',
    element: <Salaries />,
    permission: 'salaries',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/management',
    element: <Management />,
    permission: 'admins',
    breadcrumb: 'settings'
  },

  {
    path: '/account/profile',
    element: <Profile />,
    permission: 'profile'
  },
  {
    path: '/settings/categories',
    element: <Categories />,
    permission: 'categories',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/email-configurations',
    element: <EmailTemplates />,
    permission: 'email_configurations',
    breadcrumb: 'settings'
  },

  {
    path: '/settings/employees',
    element: <Users />,
    permission: 'users'
  },
  {
    path: '/settings/services',
    element: <Services />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/services/add',
    element: <AddService />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/services/:id',
    element: <UpdateService />,
    permission: 'services',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products',
    element: <Products />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/products',
    element: <Products />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products/add',
    element: <AddProduct />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/products/:id',
    element: <UpdateProduct />,
    permission: 'products',
    breadcrumb: 'settings'
  },
  // {
  //   path: '/settings/inventory',
  //   element: <Products />,
  //   permission: 'inventory',
  //   breadcrumb: 'settings'
  // },
  {
    path: '/settings/inventory/stocktaking',
    element: <Stocktaking />,
    permission: 'inventory',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/inventory/stocktaking/new',
    element: <NewStocktaking />,
    permission: 'inventory',
    breadcrumb: 'stocktaking'
  },
  {
    path: '/settings/prices',
    element: <Prices />,
    permission: 'prices',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/prices/:id',
    element: <UpdateItems />,
    permission: 'prices',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/inventory/purchase-orders',
    element: <PurchaseOrders />,
    permission: 'products',
    breadcrumb: 'inventory'
  },
  {
    path: '/settings/inventory/purchase-orders/new',
    element: <NewPurchaseOrder />,
    permission: 'products',
    breadcrumb: 'purchase-orders'
  },
  {
    path: '/settings/wastages',
    element: <Wastages />,
    permission: 'wastages',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/accounting-tree',
    element: <AccountingTree />,
    permission: 'accounting-tree',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/cost-centers',
    element: <CostCenters />,
    permission: 'cost-centers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/accounting-tree/:account/transactions',
    element: <Transactions />,
    permission: 'transactions',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/journal-entries',
    element: <JournalEntries />,
    permission: 'journal-entries',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/journal-entries/new',
    element: <NewJournalEntry />,
    permission: 'journal-entries',
    breadcrumb: 'journal-entries'
  },
  {
    path: '/settings/journal-entries/:id',
    element: <UpdateJournalEntry />,
    permission: 'journal-entries',
    breadcrumb: 'journal-entries'
  },
  {
    path: '/settings/wastages-management',
    element: <WastagesManagement />,
    permission: 'wastages-management',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/processing',
    element: <Processing />,
    permission: 'processing',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/inventory/purchase-orders/:id',
    element: <PurchaseOrderDetails />,
    permission: 'products',
    breadcrumb: 'purchase-orders'
  },

  {
    path: '/settings/packages-offers',
    element: <Offers />,
    permission: 'packages-offers',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/expenses',
    element: <Expenses />,
    permission: 'expenses',
    breadcrumb: 'expenses'
  },
  {
    path: '/calendar',
    element: <Calendar />,
    permission: 'calendar'
  },
  {
    path: '/pos',
    element: <POS />,
    permission: 'pos'
  },
  {
    path: '/pos/new',
    element: <NewPos />,
    permission: 'pos'
  },
  {
    path: '/reports',
    element: <Reports />,
    permission: 'reports'
  },
  {
    path: '/reports/charts',
    element: <ChartsSummaryReports />,
    permission: 'reports'
  },
  // {
  //   path: '/reports/incomes',
  //   element: <IncomesReports />,
  //   permission: 'reports'
  // },
  {
    path: '/reports/sessions',
    element: <SessionsReports />,
    permission: 'reports'
  },
  {
    path: '/reports/products',
    element: <ProductsReports />,
    permission: 'reports'
  },
  {
    path: '/reports/services',
    element: <ServicesReports />,
    permission: 'reports'
  },
  {
    path: '/reports/packages-offers',
    element: <PackagesOffersReports />,
    permission: 'reports'
  },
  {
    path: '/reports/clients',
    element: <ClientsReports />,
    permission: 'reports'
  },
  {
    path: '/reports/employees',
    element: <EmployeesReports />,
    permission: 'reports'
  },
  {
    path: '/reports/salaries',
    element: <SalariesReports />,
    permission: 'reports'
  },
  {
    path: '/settings/advanced',
    element: <AdvancedGlobalSettings />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/receipt-design',
    element: <DesignReceipt />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/center',
    element: <CenterSettings />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/sms',
    element: <SMSSettings />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/payments',
    element: <PaymentGateway />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/faqs',
    element: <Faqs />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/pages',
    element: <Pages />,
    permission: 'settings'
  },
  {
    path: '/settings/advanced/sms-templates',
    element: <SMSTemplates />,
    permission: 'sms'
  },
  {
    path: '/settings/advanced/email-templates',
    element: <EmailTemplates />,
    permission: 'email-templates'
  },
  {
    path: '/settings/attendance',
    element: <Attendance />,
    permission: 'attendance'
  },
  {
    path: '/checkout',
    element: <Checkout />
  },

  ...globalRoutes
];

const names = [
  'dashboard',
  'centers',
  'center_owners',
  'invoices',
  'offers',
  'reports',
  'messages',
  'notifications',
  'users',
  'admins',
  'categories',
  'roles',
  'sub_categories',
  'items',
  'features',
  'email_templates',
  'email_notifications',
  'regions',
  'cities',
  'districts',
  'settings'
];

export { authenticationRoutes, globalRoutes, names, routes };



