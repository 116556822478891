import { ChangeEvent, FocusEvent, KeyboardEvent, useState } from 'react';

export default function VerifyOTP({
  onChange,
  time = 60,
  disabled,
}: {
  onChange: (value: string) => void;
  time?: number;
  disabled?: boolean;
}) {
  const [otp, setOtp] = useState(['', '', '', '']);

  const handleChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (index < 3 && value !== '') {
      document.getElementById(`digit${index + 2}`)?.focus();
    }

    setOtp(newOtp);
    onChange?.(newOtp.join(''));
  };

  return (
    <div className="flex items-center gap-3">
      {otp.map((_e, index: number) => (
        <input
          key={index}
          type="text"
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (!/\d/gi.test(event.currentTarget.value)) {
              event.currentTarget.value = '';
            }
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (!/\d/gi.test(event.currentTarget.value)) {
              event.currentTarget.value = '';
            }
          }}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            if (!/\d/gi.test(event.currentTarget.value)) {
              event.currentTarget.value = '';
            }
          }}
          maxLength={1}
          minLength={1}
          id={`digit${index + 1}`}
          autoComplete="off"
          autoFocus={index === 0}
          autoCorrect="false"
          required
          className="form-input form-outline text-center w-12 h-12 max-w-full"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChange(index, event.target.value)
          }
          disabled={disabled}
        />
      ))}
    </div>
  );
}

