import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import TutorialCard from 'src/components/TutorialCard';
import Card from 'src/components/shared/Card';
import Placeholder from 'src/components/shared/Placeholder';
import SearchBox from 'src/components/shared/SearchBox';
import prepareRequest from 'src/helper/prepareRequest';

export default function Tutorials() {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams({ is_active: '1' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, [params]);

  function getItems() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'tutorials',
        params
      },
      (data) => {
        setItems(() => data?.result?.tutorials || []);
        // setItems(() => data?.result?.tutorials || []);
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <div className="flex-1">
      <div className="w-full bg-slate-100 py-14 px-6">
        <h3 className="text-2xl font-bold text-gray-800 text-center">{t('tutorials')}</h3>
      </div>
      <div className="w-full max-w-screen-sm -mt-6 mb-4 mx-auto">
        <SearchBox
          defaultValue={params.get('search_key') as string}
          className="w-full"
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', (ev.target as any).value);
              return param;
            });
          }}
        />
      </div>
      <div className="xl:container">
        <div className="space-y-4">
          {isLoading ? (
            <div className="p-6 grid grid-wrapper gap-4">
              {Array.from({ length: 5 })
                .fill(0)
                .map((_e, index: number) => (
                  <Card className="flex flex-col gap-4 !border-gray-100">
                    <Placeholder height="12rem" />
                    <div className="space-y-2">
                      <Placeholder
                        height="1rem"
                        width="50%"
                      />
                      <Placeholder height="0.6rem" />
                      <Placeholder
                        height="0.6rem"
                        width="60%"
                      />
                    </div>
                  </Card>
                ))}
            </div>
          ) : (
            <div className="p-6 grid grid-wrapper gap-4">
              {items.map((item: any, index: number) => (
                <TutorialCard
                  key={item.id}
                  {...item}
                />
              ))}
              {!items.length && (
                <p className="col-span-full text-center text-gray-600 font-bold">{t('no-data')}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
