import React from 'react';
import { motion } from 'framer-motion';
import { MoveToBottom, MoveToTop } from 'src/animations';
import { Icon } from '@iconify/react';
import axiosInstance from 'src/helper/AxiosInstance';
import { pick } from 'lodash';
import Alert from 'src/components/shared/Alert';
import SharedTime from 'src/components/shared/SharedTime';
import Card from 'src/components/shared/Card';
import Pagination from 'src/components/shared/Pagination';
import { useTranslation } from 'react-i18next';

const ActivityLogs: React.FC = () => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('logs', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.logs;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        {isLoading ? (
          <ul className="space-y-3">
            {Array.from({ length: 3 })
              .fill(0)
              .map((_, index: string | number) => (
                <li key={index}>
                  <a
                    href="#"
                    className="p-6 rounded-xl bg-white ring-1 ring-gray-200 s block"
                  >
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToTop}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-3 mb-4"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-full  bg-gray-200 animate-pulse rounded p-3 mb-2"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/3 bg-gray-200 animate-pulse rounded p-3"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-2 mt-4"
                    ></motion.div>
                  </a>
                </li>
              ))}
          </ul>
        ) : (
          <>
            <ul className="space-y-3">
              {responses?.length ? (
                responses.map((item: any, index: string | number) => (
                  <li key={index}>
                    <Card className="space-y-4">
                      <p>{item.description}</p>
                      <div className="flex items-center flex-wrap gap-3 border-t border-t-gray-200 pt-4">
                        <p className="inline-flex gap-2 items-center text-sm text-gray-500">
                          <Icon
                            icon="solar:user-circle-outline"
                            width="18"
                          />
                          <span>{item.causer?.name || 'N/A'}</span>
                        </p>
                        <SharedTime
                          date={item.created_at}
                          format="DD-MM-YYYY hh:mmA"
                        />
                      </div>
                    </Card>
                  </li>
                ))
              ) : (
                <Alert
                  type="default"
                  content={t('no-data')}
                />
              )}
            </ul>
            <div>
              <Pagination
                pagination={pagination}
                onNextClick={() => GetItems({ page: (pagination.page || 1) + 1 })}
                onPreviousClick={() => GetItems({ page: (pagination.page || 1) - 1 })}
              />
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActivityLogs;
