import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import generateAlert from 'src/helper/generateAlert';
import generateRandomWord from 'src/helper/generateRandomWord';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

export default function index() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    enable_cashier_print: 0,
    enable_alt_name: 0,
    enable_desc: 0
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getSettings();
  }, []);

  function getSettings() {
    setIsLoading(true);
    prepareRequest({ url: 'settings' }, (data) => {
      setInitialValues(() => data.result || {});
    }).finally(() => setIsLoading(false));
  }

  const {
    formik: { values, setFieldValue, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'settings/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          // getSettings();
          window.location.reload();
        }
      ).finally(() => setDisabled(false));
    }
  });

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="p-6 space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <Card>
            <div className="space-y-4">
              <div className="form-group">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="form-checkbox form-outline rounded-full"
                    name="enable_cashier_print"
                    id="enable_cashier_print"
                    checked={!!values.enable_cashier_print}
                    onChange={(e) =>
                      setFieldValue('enable_cashier_print', e.target.checked ? 1 : 0)
                    }
                  />
                  <label
                    className="form-label"
                    htmlFor="enable_cashier_print"
                  >
                    {t('enable-cashier-print')}
                  </label>
                </div>
                {errors?.enable_cashier_print ? (
                  <span className="form-error">{errors?.enable_cashier_print}</span>
                ) : null}
              </div>
              <div className="form-group">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="form-checkbox form-outline rounded-full"
                    name="enable_alt_name"
                    id="enable_alt_name"
                    checked={!!values.enable_alt_name}
                    onChange={(e) => setFieldValue('enable_alt_name', e.target.checked ? 1 : 0)}
                  />
                  <label
                    className="form-label"
                    htmlFor="enable_alt_name"
                  >
                    {t('enable_alt_name')}
                  </label>
                </div>
                {errors?.enable_alt_name ? (
                  <span className="form-error">{errors?.enable_alt_name}</span>
                ) : null}
              </div>
              <div className="form-group">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="form-checkbox form-outline rounded-full"
                    name="enable_desc"
                    id="enable_desc"
                    checked={!!values.enable_desc}
                    onChange={(e) => setFieldValue('enable_desc', e.target.checked ? 1 : 0)}
                  />
                  <label
                    className="form-label"
                    htmlFor="enable_desc"
                  >
                    {t('enable_desc')}
                  </label>
                </div>
                {errors?.enable_desc ? (
                  <span className="form-error">{errors?.enable_desc}</span>
                ) : null}
              </div>
            </div>
          </Card>
          <button
            className="btn-with-icon bg-primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </form>
        <UpdateSubdomain />
      </div>
    </div>
  );
}

function UpdateSubdomain() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({
    subdomain: user?.summary.subdomain || generateRandomWord(8)
  });
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'settings/subdomain/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4"
    >
      <Card>
        <div className="space-y-4">
          <div className="form-group">
            <label
              htmlFor="center-subdomain"
              className="form-label"
            >
              {t('domain-name')}
            </label>
            <div
              className="flex items-center"
              dir="ltr"
            >
              <p className="form-input align-middle max-w-fit form-outline !rounded-e-none !bg-gray-50">
                https://
              </p>
              <input
                type="text"
                id="center-subdomain"
                className="form-input form-outline flex-1 !rounded-none !border-x-0"
                value={values.subdomain}
                onChange={handleChange}
                name="subdomain"
              />
              <p className="form-input align-middle max-w-fit form-outline !rounded-s-none !bg-gray-50">
                .salon.sa
              </p>
            </div>
            <p className="form-error">{errors?.['subdomain']}</p>
          </div>
        </div>
      </Card>
      <button
        className="btn-with-icon bg-primary"
        type="submit"
        disabled={disabled}
      >
        <span>{t('save-changes')}</span>
      </button>
    </form>
  );
}
