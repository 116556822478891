import { Icon } from '@iconify/react';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import StatusTracking from 'src/components/StatusTracking';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import Card from 'src/components/shared/Card';
import Controllable from 'src/components/shared/Controllable';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import Title from 'src/components/shared/Title';
import SlideUpDown from 'src/components/shared/animations/SlideUpDown';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';

type Status = 'approved' | 'cancelled' | 'closed' | 'received';

export default function id() {
  const { t } = useTranslation();
  const { id }: any = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [actualVisible, setActualVisible] = useState<boolean>(false);
  const [refundVisible, setRefundVisible] = useState<boolean>(false);
  const [order, setOrder] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  useEffect(() => {
    getSelectedOrder();
  }, []);

  function getSelectedOrder() {
    setIsLoading(true);
    prepareRequest(
      {
        method: 'get',
        url: 'orders/' + id
      },
      (data, error) => {
        if (error) return navigate('/settings/inventory/purchase-orders', { replace: true });
        setOrder(data.result);
      }
    ).finally(() => setIsLoading(false));
  }

  function markAsPaid() {
    setDisabled(true);
    prepareRequest(
      {
        url: 'orders/mark_as_paid',
        method: 'post',
        data: { id }
      },
      (data) => getSelectedOrder()
    ).finally(() => setDisabled(false));
  }

  function changeStatus(status: Status) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'orders/update_status',
        method: 'post',
        data: { id, status }
      },
      (data) => {
        generateAlert(data.message, 'success');
        getSelectedOrder();
      }
    ).finally(() => setDisabled(false));
  }

  function closeOrder() {
    setDisabled(true);
    prepareRequest(
      {
        url: 'orders/close/' + id,
        method: 'post'
      },
      (data) => {
        generateAlert(data.message, 'success');
        getSelectedOrder();
      }
    ).finally(() => setDisabled(false));
  }

  const onSelectedItem = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = ev.target;
      const concat = [...selectedIds, +value];
      const filter = selectedIds.filter((e: any) => e !== +value);

      setSelectedIds(() => (checked ? concat : filter));
    },
    [selectedIds]
  );

  const selectAll = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { checked } = ev.target;
      const ids = order.items?.map((e: any) => e.id);
      setSelectedIds(() => (checked ? ids : []));
    },
    [selectedIds]
  );

  const itemValues = useMemo(() => {
    const items = order.items?.filter((e: any) => selectedIds?.includes(e.id));
    return items;
  }, [selectedIds]);

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <AppBar
        center
        title={'#' + order.id}
      />
      <div className="w-full max-w-screen-lg mx-auto py-6 space-y-4">
        <Image
          src="/logo.png"
          className="w-full object-contain max-w-[10rem] mx-auto"
        />
        <div className="mx-auto">
          <StatusTracking statuses={order.tracking} />
        </div>
        <Card className="p-6">
          <ul className="divide-y divide-gray-200">
            <ItemList className="py-8">
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="space-y-2">
                  <div className="flex gap-2">
                    <Title
                      className="text-sm font-semibold"
                      title={t('name')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.center?.name || '-'}
                    />
                  </div>
                  <div className="flex gap-2">
                    <Title
                      className="text-sm font-semibold"
                      title={t('mobile')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.center?.mobile || '-'}
                    />
                  </div>
                  <div className="flex gap-2">
                    <Title
                      className="text-sm font-semibold"
                      title={t('cr-no')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.center?.cr_no || '-'}
                    />
                  </div>
                  <div className="flex gap-2">
                    <Title
                      className="text-sm font-semibold"
                      title={t('vat-no')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.center?.vat_no || '-'}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex gap-2 justify-end">
                    <Title
                      className="text-sm font-semibold"
                      title="#"
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.id}
                    />
                  </div>
                  <div className="flex gap-2 justify-end">
                    <Title
                      className="text-sm font-semibold"
                      title={t('name')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.supplier?.name || order.supplier_name || '-'}
                    />
                  </div>
                  <div className="flex gap-2 justify-end">
                    <Title
                      className="text-sm font-semibold"
                      title={t('mobile')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={order.supplier?.mobile || order.supplier_mobile || '-'}
                    />
                  </div>
                  <div className="flex gap-2 justify-end">
                    <Title
                      className="text-sm font-semibold"
                      title={t('created-date')}
                    />
                    <Title
                      className="text-sm font-semibold !text-black"
                      title={moment(order.created_at).format('lll')}
                    />
                  </div>
                </div>
              </div>
            </ItemList>
            <ItemList className="pt-4 pb-8 space-y-4">
              <div className="flex items-center gap-3 flex-wrap">
                <SlideUpDown visible={order.status === 'created' && !!selectedIds?.length}>
                  <button
                    type="button"
                    className="btn-with-icon !bg-blue-500 !rounded-full !px-4"
                    onClick={() => setActualVisible(true)}
                  >
                    <Icon
                      icon="ph:scales"
                      width="18"
                    />
                    <span>{t('set-actual-values')}</span>
                  </button>
                </SlideUpDown>
                <SlideUpDown visible={order.status === 'approved' && !!selectedIds?.length}>
                  <button
                    type="button"
                    className="btn-with-icon !bg-red-500 !rounded-full !px-4"
                    onClick={() => setRefundVisible(true)}
                  >
                    <Icon
                      icon="fluent:branch-24-regular"
                      width="18"
                    />
                    <span>{t('refund-items')}</span>
                  </button>
                </SlideUpDown>
              </div>

              <Table
                RenderHead={() => (
                  <tr>
                    <th>{order.status == 'crea'}</th>
                    <th>{t('name')}</th>
                    <th>{t('price')}</th>
                    <th>{t('quantity')}</th>
                    <th>{t('total')}</th>
                  </tr>
                )}
                RenderBody={() => (
                  <>
                    {order.items?.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            name={'check-' + item.id}
                            id={'check-' + item.id}
                            className="form-checkbox"
                            value={item.id}
                            checked={selectedIds?.includes(item.id)}
                            onChange={onSelectedItem}
                          />
                        </td>
                        <td>{item.name || '-'}</td>
                        <td>{CurrencyFormatter(item.price || 0)}</td>
                        <td>{item.qty || 0}</td>
                        <td>{CurrencyFormatter((item.price || 0) * (item.qty || 0))}</td>
                      </tr>
                    ))}
                  </>
                )}
                isEmpty={!order.items?.length}
              />
            </ItemList>
            <ItemList className="py-8">
              <div className="w-full grid grid-cols-2 gap-3">
                <Title
                  className="text-sm font-semibold"
                  title={t('subtotal')}
                />
                <Title
                  className="text-sm font-semibold !text-black !text-end"
                  title={CurrencyFormatter(order.sub_total || 0)}
                />
                <Title
                  className="text-sm font-semibold"
                  title={t('discount')}
                />
                <Title
                  className="text-sm font-semibold !text-black !text-end"
                  title={CurrencyFormatter(order.coupon_discount || 0)}
                />
                <Title
                  className="text-sm font-semibold"
                  title={t('vat')}
                />
                <Title
                  className="text-sm font-semibold !text-black !text-end"
                  title={CurrencyFormatter(order.vat || 0)}
                />
                <Title
                  className="text-sm font-semibold"
                  title={t('total')}
                />
                <Title
                  className="text-sm font-semibold !text-black !text-end"
                  title={CurrencyFormatter(order.total || 0)}
                />
              </div>
            </ItemList>
            <ItemList className="py-6">
              <div className="flex items-center gap-3 flex-wrap">
                {order.status === 'created' ? (
                  <>
                    <ButtonWithCallback
                      className="btn-with-icon  !bg-teal-500 !rounded-full !px-4"
                      options={{
                        title: t('are-you-sure'),
                        text: t('confirmations.change-status'),
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        showCancelButton: true
                      }}
                      disabled={disabled}
                      callback={() => changeStatus('approved')}
                    >
                      <Icon
                        icon="material-symbols:check"
                        width="18"
                      />
                      <span>{t('approve')}</span>
                    </ButtonWithCallback>
                    <ButtonWithCallback
                      className="btn-with-icon  !bg-red-500 !rounded-full !px-4"
                      options={{
                        title: t('are-you-sure'),
                        text: t('confirmations.change-status'),
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        showCancelButton: true
                      }}
                      disabled={disabled}
                      callback={() => changeStatus('cancelled')}
                    >
                      <Icon
                        icon="mdi:ban"
                        width="18"
                      />
                      <span>{t('cancel')}</span>
                    </ButtonWithCallback>
                  </>
                ) : null}

                {order.status === 'approved' ? (
                  <>
                    <ButtonWithCallback
                      className="btn-with-icon  !bg-teal-500 !rounded-full !px-4"
                      options={{
                        title: t('are-you-sure'),
                        text: t('confirmations.change-status'),
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        showCancelButton: true
                      }}
                      disabled={disabled}
                      callback={() => changeStatus('received')}
                    >
                      <Icon
                        icon="material-symbols:check"
                        width="18"
                      />
                      <span>{t('receive')}</span>
                    </ButtonWithCallback>
                    {!order.is_paid && (
                      <ButtonWithCallback
                        className="btn-with-icon  !bg-teal-500 !rounded-full !px-4"
                        options={{
                          title: t('are-you-sure'),
                          text: t('confirmations.change-status'),
                          confirmButtonText: t('yes'),
                          cancelButtonText: t('no'),
                          showCancelButton: true
                        }}
                        disabled={disabled}
                        callback={() => markAsPaid()}
                      >
                        <Icon
                          icon="streamline:money-cash-coins-stack-accounting-billing-payment-stack-cash-coins-currency-money-finance"
                          width="18"
                        />
                        <span>{t('mark-as-paid')}</span>
                      </ButtonWithCallback>
                    )}
                  </>
                ) : null}

                {['received'].includes(order.status) ? (
                  <ButtonWithCallback
                    className="btn-with-icon  !bg-red-500 !rounded-full !px-4"
                    options={{
                      title: t('are-you-sure'),
                      text: t('confirmations.change-status'),
                      confirmButtonText: t('yes'),
                      cancelButtonText: t('no'),
                      showCancelButton: true
                    }}
                    disabled={disabled}
                    callback={closeOrder}
                  >
                    <Icon
                      icon="material-symbols:close"
                      width="18"
                    />
                    <span>{t('close')}</span>
                  </ButtonWithCallback>
                ) : null}
              </div>
            </ItemList>
          </ul>
        </Card>
      </div>
      <Modal
        title={t('set-actual-values')}
        visible={actualVisible}
        handleClose={setActualVisible}
        size="max-w-screen-md"
      >
        <SetActualValues
          items={itemValues}
          refetch={getSelectedOrder}
          orderId={id}
        />
      </Modal>
      <Modal
        title={t('refund-items')}
        visible={refundVisible}
        handleClose={setRefundVisible}
        size="max-w-screen-sm"
      >
        <RefundItems
          items={itemValues}
          refetch={getSelectedOrder}
          orderId={id}
          centerId={order.center?.id}
        />
      </Modal>
    </>
  );
}

function RefundItems({ items, refetch, orderId, centerId }: any) {
  const { t } = useTranslation();
  const initialValues = {
    items: items.map((item: any) => ({
      ...item,
      origin_qty: item.qty
    })),
    order_type: 'refund',
    order_id: orderId,
    center_id: centerId
  };
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const {
    formik: { values, handleSubmit, setFieldValue, handleChange }
  } = useForm({ initialValues, submitHandler });

  function submitHandler(...params: any[]) {
    const [form] = params || [];

    const body = {
      ...form,
      items: form.items?.map((e: any) => ({
        id: e.id,
        qty: e.qty,
        price: e.price
      }))
    };

    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'orders/new',
        data: body
      },
      (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        refetch();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="grid">
        <div className="overflow-x-auto">
          <table className="styled-table">
            <thead className="bg-gray-100">
              <tr>
                <th></th>
                <th>{t('quantity')}</th>
              </tr>
            </thead>
            <tbody>
              {values.items?.map((item: any, index: number) => (
                <tr key={item.id}>
                  <td>
                    <div className="space-y-0.5">
                      <p className="text-sm line-clamp-1">{item.name || '-'}</p>
                      <p className="text-sm text-gray-500">
                        {item.qty > 1 ? (
                          <span>{CurrencyFormatter((item.price || 0) * (item.qty || 0))}</span>
                        ) : null}{' '}
                        <span className={item.qty > 1 ? 'line-through' : ''}>
                          {CurrencyFormatter(item.price || 0)}
                        </span>
                      </p>
                      <p className="form-error">{errors?.['items.' + index + '.id']}</p>
                      <p className="form-error">{errors?.['items.' + index + '.price']}</p>
                      <p className="form-error">{errors?.['items.' + index + '.qty']}</p>
                    </div>
                  </td>

                  <td>
                    <div className="shrink-0 max-w-fit">
                      <Controllable
                        value={item.qty}
                        max={item.origin_qty}
                        onChange={(v) => setFieldValue('items.' + index + '.qty', +v)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <button
          className="btn-with-icon !bg-primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </div>
    </form>
  );
}

function SetActualValues({ items, refetch, orderId }: any) {
  const { t } = useTranslation();
  const initialValues = {
    items,
    order_id: orderId
  };
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const {
    formik: { values, handleSubmit, setFieldValue, handleChange }
  } = useForm({ initialValues, submitHandler });

  function submitHandler(...params: any[]) {
    const [form] = params || [];

    const body = {
      ...form,
      items: form.items?.map((e: any) => ({
        id: e.id,
        qty: e.qty,
        price: e.price
      }))
    };

    setDisabled(true);
    prepareRequest(
      {
        method: 'post',
        url: 'orders/set_actual_values',
        data: body
      },
      (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        refetch();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="grid">
        <div className="overflow-x-auto">
          <table className="styled-table">
            <thead className="bg-gray-100">
              <tr>
                <th></th>
                <th>{t('price')}</th>
                <th>{t('quantity')}</th>
              </tr>
            </thead>
            <tbody>
              {values.items?.map((item: any, index: number) => (
                <tr key={item.id}>
                  <td>
                    <div className="space-y-0.5">
                      <p className="text-sm line-clamp-1">{item.name || '-'}</p>
                      <p className="text-sm text-gray-500">
                        {item.qty > 1 ? (
                          <span>{CurrencyFormatter((item.price || 0) * (item.qty || 0))}</span>
                        ) : null}{' '}
                        <span className={item.qty > 1 ? 'line-through' : ''}>
                          {CurrencyFormatter(item.price || 0)}
                        </span>
                      </p>
                      <p className="form-error">{errors?.['items.' + index + '.id']}</p>
                      <p className="form-error">{errors?.['items.' + index + '.price']}</p>
                      <p className="form-error">{errors?.['items.' + index + '.qty']}</p>
                    </div>
                  </td>
                  <td>
                    <div className="shrink-0 max-w-fit">
                      <input
                        type="number"
                        step="any"
                        className="form-input text-center form-outline"
                        value={item.price}
                        name={'items.' + index + '.price'}
                        onChange={handleChange}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="shrink-0 max-w-fit">
                      <Controllable
                        value={item.qty}
                        onChange={(v) => setFieldValue('items.' + index + '.qty', +v)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <button
          className="btn-with-icon !bg-primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </div>
    </form>
  );
}
