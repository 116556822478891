import { useEffect, useRef, useState } from 'react';

interface SvgProgressIcon {
  size: string;
  value: number;
}

export default function SvgProgressIcon({ size, value }: SvgProgressIcon) {
  const ref = useRef<SVGSVGElement>(null);
  const [result, setResult] = useState<any>({});

  const options = useEffect(() => {
    // get icon values
    const element = ref.current;
    const width = element?.getBoundingClientRect().width || 0;

    const radius = width / 2;
    const strokeDasharray = 2 * Math.PI * radius;
    const strokeDashoffset = strokeDasharray - (strokeDasharray * value) / 100;

    setResult({
      strokeDasharray,
      strokeDashoffset
    });
  }, []);

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
      ref={ref}
    >
      <circle
        cx="50%"
        cy="50%"
        r="40%"
        stroke="#e4e4e4"
        strokeWidth="7%"
        fill="transparent"
      />

      <circle
        cx="50%"
        cy="50%"
        r="40%"
        stroke="currentColor"
        strokeWidth="7%"
        fill="transparent"
        strokeDasharray={`0 ${result?.strokeDasharray}`}
        strokeDashoffset={result?.strokeDashoffset}
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dasharray"
          // values="0; 2 * 3.14159 * 40%"
          values={`0; ${result?.strokeDasharray}`}
          dur="1s"
          fill="freeze"
        />
      </circle>

      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="1em"
        fill="#111"
      >
        {value}%
      </text>
    </svg>
  );
}
