import { Icon } from '@iconify/react';
import { serialize } from 'object-to-formdata';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';
import { RootState } from 'src/store';
import Card from '../Card';
import InputFile from '../InputFile';
import LoadingComponent from '../LoadingComponent';
import Select from '../Select';
import { ItemType } from './AddForm';
import { CenterEmployee, Options, Variants } from './FormWithVariants';

type Option = {
  name: string | undefined;
  alt_name: string | undefined;
  price: string | undefined;
};

type Variant = {
  name: string | undefined;
  alt_name: string | undefined;
  price: string | undefined;
};

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  category_id: string | undefined;
  item_id: string | undefined;
  price: string | undefined;
  cost: string | undefined;
  item_type: ItemType;
  items: any[];
  centers: any[];
  variants: Variant[];
  options: Option[];
  [key: string]: any;
}

export default function UpdateFormWithVariants() {
  let render = true;
  const { t } = useTranslation();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [centers, setCenters] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getCategories(), getCenters(), getItem()])
      .finally(() => setIsLoading(false))
      .catch(() => navigate(-1));
  }, []);

  function getItem() {
    return prepareRequest(
      {
        url: 'items/' + id,
        method: 'get'
      },
      (data) => {
        const item = data.result?.item || {};
        const result = {
          ...item
        };

        const centers = item.centers?.map((center: any) => {
          result['employee_id_' + center.id] = center.employee?.id;
          return center.id;
        });

        result.centers = centers;

        setInitialValues(() => result);
      }
    );
  }

  const submitHandler = useCallback(async function (values: any, helper: any) {
    const collectVariantAndOptionsWithCenters = values.centers.map((center: number) => {
      return {
        id: center,
        employee_id: values?.['employee_id_' + center]
        // variant: values.variant,
        // options: values.options
      };
    });
    const result = {
      ...values,
      centers: collectVariantAndOptionsWithCenters
    };

    const body = serialize(result, {
      indices: true,
      nullsAsUndefineds: true,
      booleansAsIntegers: true
    });

    Object.keys(values).forEach((key: string) => {
      if (/^employee_id_\d+$/gi.test(key)) {
        body.delete(key);
      } else if (key === 'image') {
        if (!(values[key] instanceof File)) body.delete(key);
      }
    });

    setDisabled(true);
    setErrors({});
    prepareRequest(
      {
        url: 'items/update',
        method: 'post',
        data: body
      },
      (response, error) => {
        if (error) return setErrors(() => error);
        generateAlert(response.message, 'success');
        navigate('/settings/services');
      }
    ).finally(() => setDisabled(false));
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const getCategories = (value?: string) => {
    prepareRequest(
      {
        url: 'categories',
        params: {
          is_active: 1,
          search_key: value,
          page: 1
        }
      },
      (data) => {
        setCategories(data.result?.categories?.data || []);
      }
    );
  };

  const getCenters = (value?: string) => {
    prepareRequest(
      {
        url: 'centers',
        params: {
          is_active: 1,
          search_key: value,
          page: 1
        }
      },
      (data) => {
        setCenters(data.result?.centers?.data || []);
      }
    );
  };

  function addNewOption() {
    const newOptions = [
      ...formik.values.options,
      {
        name: undefined,
        alt_name: undefined,
        values: []
      }
    ];
    formik.setFieldValue('options', newOptions);
  }

  function addNewVariant() {
    const newOptions = [
      ...formik.values.variants,
      {
        name: undefined,
        alt_name: undefined,
        price: undefined,
        cost: undefined
      }
    ];
    formik.setFieldValue('variants', newOptions);
  }

  if (isLoading) return <LoadingComponent />;
  return (
    <>
      <AppBar title={JSON.parse(JSON.stringify(formik.values?.name || t('edit')))} />
      <div className="p-6">
        <form
          className="space-y-4"
          onSubmit={formik.handleSubmit}
        >
          <Card className="!p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group col-span-full">
                <label
                  htmlFor="image"
                  className="form-label"
                >
                  {t('image')}
                </label>
                <InputFile
                  defaultValue={formik.values.image}
                  onValueChange={function (e: any): void {
                    formik.setFieldValue('image', e);
                  }}
                  accept="image/*"
                />
                {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
              </div>
              <div
                className={`form-group ${
                  !!user?.global_settings?.enable_alt_name ? '' : 'col-span-full'
                }`}
              >
                <label className="form-label">{t('name')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.name}
                  onChange={(e) => handleChange('name', e)}
                />
                {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
              </div>
              {!!user?.global_settings?.enable_alt_name ? (
                <div className="form-group">
                  <label className="form-label">{t('alt-name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.alt_name}
                    onChange={(e) => handleChange('alt_name', e)}
                  />
                  {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
                </div>
              ) : null}
              {!!user?.global_settings?.enable_desc ? (
                <>
                  <div className="form-group">
                    <label className="form-label">{t('description')}</label>
                    <textarea
                      autoComplete="off"
                      placeholder="..."
                      className="form-textarea form-outline"
                      value={formik.values.desc}
                      onChange={(e) => handleChange('desc', e)}
                    ></textarea>
                    {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
                  </div>
                  <div className="form-group">
                    <label className="form-label">{t('alt-description')}</label>
                    <textarea
                      autoComplete="off"
                      placeholder="..."
                      className="form-textarea form-outline"
                      value={formik.values.alt_desc}
                      onChange={(e) => handleChange('alt_desc', e)}
                    ></textarea>
                    {errors?.alt_desc ? (
                      <span className="form-error">{errors?.alt_desc}</span>
                    ) : null}
                  </div>
                </>
              ) : null}
              <div className="form-group">
                <label className="form-label">
                  {t('price')} ({t('sar')})
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.price}
                  onChange={(e) => handleChange('price', e)}
                />
                {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t('cost')} ({t('sar')})
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.cost}
                  onChange={(e) => handleChange('cost', e)}
                />
                {errors?.cost ? <span className="form-error">{errors?.cost}</span> : null}
              </div>

              <div className="form-group col-span-full">
                <label className="form-label">{t('category')}</label>
                <Select
                  type={'single'}
                  options={categories}
                  value={formik.values.category_id}
                  onSelect={function (value: any): any {
                    return formik.setFieldValue('category_id', value);
                  }}
                  onSearchChange={(ev) => getCategories(ev.target.value)}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                {errors?.category_id ? (
                  <span className="form-error">{errors?.category_id}</span>
                ) : null}
              </div>

              {centers.length > 1 ? (
                <div className="form-group col-span-full">
                  <label className="form-label">{t('centers')}</label>
                  <Select
                    type={'multi'}
                    options={centers}
                    value={formik.values.centers}
                    onSelect={function (value: any): any {
                      return formik.setFieldValue('centers', value);
                    }}
                    onSearchChange={(ev) => getCenters(ev.target.value)}
                    optionTxt={'name'}
                    optionValue={'id'}
                  />

                  {errors?.centers ? <span className="form-error">{errors?.centers}</span> : null}
                </div>
              ) : null}

              {formik.values.centers?.map((center: number) => (
                <CenterEmployee
                  key={center}
                  id={center}
                  onChange={formik.handleChange}
                  onCustomValueChange={formik.setFieldValue}
                  values={formik.values}
                  errors={errors}
                />
              ))}
            </div>
          </Card>

          {/* <div className="flex items-center justify-between gap-4 col-span-full">
            <p className="form-label">{t('variants')}</p>
            <button
              type="button"
              className="btn-with-icon !p-0 !text-blue-600"
              onClick={addNewVariant}
            >
              <Icon
                icon="material-symbols:add"
                width="18"
                height="18"
              />
              <span>{t('add-new')}</span>
            </button>
          </div>
          <Card className="!p-8">
            <Variants
              onChange={formik.handleChange}
              onCustomValueChange={formik.setFieldValue}
              values={formik.values}
              errors={errors}
            />
          </Card> */}

          <div className="flex items-center justify-between gap-4 col-span-full">
            <p className="form-label">{t('options')}</p>
            <button
              type="button"
              className="btn-with-icon !p-0 !text-blue-600"
              onClick={addNewOption}
            >
              <Icon
                icon="material-symbols:add"
                width="18"
                height="18"
              />
              <span>{t('add-new')}</span>
            </button>
          </div>
          
          <Card className="!p-8">
            <Options
              onChange={formik.handleChange}
              onCustomValueChange={formik.setFieldValue}
              values={formik.values}
              errors={errors}
            />
          </Card>

          <button
            className="btn-with-icon bg-primary text-white"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </form>
      </div>
    </>
  );
}

