import { Icon } from '@iconify/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Controllable from 'src/components/shared/Controllable';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import Pagination from 'src/components/shared/Pagination';
import SearchBox from 'src/components/shared/SearchBox';
import Title from 'src/components/shared/Title';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { CardPlaceholder } from 'src/screens/pos';
import { RootState } from 'src/store';

function useHooks() {
  const initialValues = {
    items: []
  };
  const { user } = useSelector((state: RootState) => state.auth);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const navigate = useNavigate();
  const {
    formik: { values, setFieldValue, handleSubmit, handleChange }
  } = useForm({ initialValues, submitHandler });

  function submitHandler(...params: any[]) {
    const [body, helpers] = params;
    const formData = {
      ...body,
      items: body?.items?.map((e: any) => ({
        id: e.id,
        price: e.price,
        qty: e.qty
      })),
      center_id: user.center?.id
    };
    setDisabled(true);
    prepareRequest(
      {
        url: 'orders/new',
        method: 'post',
        data: formData
      },
      (data, error) => {
        if (error) return setErrors(() => error);
        generateAlert(data.message, 'success');
        navigate('/settings/inventory/purchase-orders', { replace: true });
      }
    ).finally(() => setDisabled(false));
  }

  const handleItems = useCallback(
    (item: any) => {
      const { id, price, ...others } = item || {};
      const object = {
        id,
        price,
        qty: 1,
        item
      };
      const existed = values.items.find((e: any) => e.id == id);
      const group = [...values.items, object];
      const filterItems = values.items.filter((e: any) => e.id !== id);

      if (existed) {
        setFieldValue('items', filterItems);
        generateAlert('( ' + item.name + ' ) removed!', 'success');
      } else {
        setFieldValue('items', group);
        generateAlert('( ' + item.name + ' ) added successfully', 'success');
      }
    },
    [values.items]
  );

  const isSelected = useCallback(
    (id: string) => values.items.some((e: any) => e.id == id),
    [values.items]
  );

  const getTotal = useMemo(() => {
    let total = 0;
    let vat = 0;
    let subtotal = 0;
    for (const item of values.items || []) {
      total += Number(item.price || 0) * Number(item.qty || 1);
    }

    subtotal = parseFloat((total / 1.15).toFixed(2));
    vat = parseFloat((total - subtotal).toFixed(2));

    return { total: parseFloat(total.toFixed(2)), vat, subtotal };
  }, [values.items]);

  return {
    values,
    setFieldValue,
    handleItems,
    isSelected,
    getTotal,
    handleChange,
    handleSubmit,
    disabled,
    errors
  };
}

export default function index() {
  let render = true;
  const { t } = useTranslation();
  const [products, setProducts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const selectedProducts = useRef<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any[]>([]);

  const {
    values,
    setFieldValue,
    handleItems,
    isSelected,
    getTotal,
    handleChange,
    handleSubmit,
    disabled,
    errors
  } = useHooks();

  useEffect(() => {
    if (values.supplier_id) GetProducts();
  }, [values.supplier_id]);

  useEffect(() => {
    getSuppliers();
  }, []);

  async function GetProducts(params?: any) {
    setIsLoading(true);
    const { page = 1, search_key }: any = params || {};
    prepareRequest(
      {
        url: 'items',
        params: {
          item_type: 'product',
          page,
          search_key,
          supplier_id: values.supplier_id
        }
      },
      (data) => {
        const items = data.result?.items;
        setProducts(items.data || []);
        setPagination(items.pagination || []);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  }

  async function getSuppliers(q?: string) {
    prepareRequest(
      {
        url: 'users',
        params: {
          page: 1,
          search_key: q,
          is_active: 1,
          user_type: 'supplier'
        }
      },
      (data) => {
        const users = data.result?.users;
        setSuppliers(() => users.data || []);
      }
    );
  }

  const DISPLAY_COMPONENTS = useMemo(() => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <div className="flex gap-4 flex-1">
              <div className="w-full flex-1 max-w-sm">
                <Card className="flex flex-col !p-0">
                  <div className="p-4 border-b border-b-gray-100">
                    <SearchBox
                      onChange={(ev) => getSuppliers((ev.target as any).value)}
                      className="w-full"
                      placeholder={t('supplier-name')}
                    />
                  </div>
                  <ul className="divide-y divide-gray-100 p-4 flex-1 shrink-0 max-h-96 overflow-y-auto">
                    {!suppliers.length && (
                      <ItemList className="py-3 text-center text-gray-600 text-sm">
                        {t('no-data')}
                      </ItemList>
                    )}

                    {suppliers.map((supplier) => (
                      <ItemList
                        className="py-3"
                        key={supplier.id}
                      >
                        <div className="flex gap-3 items-center">
                          <input
                            type="radio"
                            name="supplier_id"
                            className="form-radio shrink-0 border-gray-300 bg-gray-50 transition-all duration-200 w-5 h-5"
                            value={supplier.id}
                            defaultChecked={supplier.id == values.supplier_id}
                            onChange={handleChange}
                            id={'supplier-' + supplier.id}
                          />
                          <label
                            htmlFor={'supplier-' + supplier.id}
                            className="flex-1 block cursor-pointer"
                          >
                            <p className="text-sm">{supplier.name || '-'}</p>
                            <p className="text-sm text-gray-500">{supplier.mobile || '-'}</p>
                          </label>
                        </div>
                      </ItemList>
                    ))}
                  </ul>
                </Card>
              </div>
              <div className="flex-1 w-full space-y-4">
                <div className="flex items-center justify-between gap-4 flex-wrap">
                  <SearchBox
                    placeholder={t('searches.products')}
                    onChange={(ev) =>
                      GetProducts({ page: 1, search_key: (ev.target as any).value })
                    }
                  />
                </div>
                <div className="grid grid-wrapper gap-4">
                  {isLoading ? (
                    <>
                      {Array.from({ length: 4 }).map((_e, idx: number) => (
                        <CardPlaceholder key={idx} />
                      ))}
                    </>
                  ) : (
                    <>
                      {products.map((item: any, index: number) => (
                        <DisplayItem
                          item={item}
                          key={item.id}
                          onClick={() => handleItems(item)}
                          selected={isSelected(item.id)}
                        />
                      ))}
                      {!products.length && (
                        <p className="text-center text-gray-600 text-sm">{t('no-data')}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Pagination
                pagination={pagination}
                onNextClick={() => GetProducts({ page: pagination?.current_page + 1 })}
                onPreviousClick={() => GetProducts({ page: pagination?.current_page - 1 })}
              />
            </div>
            <div className="w-full sticky bottom-0 z-10 bg-white py-4 px-6">
              <button
                className="btn-with-icon !bg-primary !rounded-full !p-4 w-full max-w-xs mx-auto"
                type="button"
                onClick={() => setActiveTab((index: number) => index + 1)}
                disabled={!values.items.length}
              >
                <span>{t('next')}</span>
              </button>
            </div>
          </>
        );
      case 1:
        return (
          <CartDetails
            items={values.items}
            onChange={setFieldValue}
            total={getTotal}
            activeIndex={activeTab}
            changeIndex={setActiveTab}
            errors={errors}
          />
        );
      default:
        return (
          <Checkout
            values={values}
            total={getTotal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            disabled={disabled}
            errors={errors}
          />
        );
    }
  }, [activeTab, products, pagination, values, errors, suppliers]);

  return (
    <>
      <div className="space-y-4 flex-1">
        <div className="space-y-4 py-4 px-6">
          <div className="w-full border-b border-b-gray-200">
            <Steps
              activeIndex={activeTab}
              onChange={setActiveTab}
              disabled={disabled}
            />
          </div>
          {DISPLAY_COMPONENTS}
        </div>
      </div>
    </>
  );
}

function Steps({
  activeIndex = 0,
  onChange,
  disabled
}: {
  activeIndex?: number;
  onChange?: (index: number) => any;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  const steps = [
    {
      title: 'add-products',
      icon: 'bi:bag-plus'
    },
    {
      title: 'cart-details',
      icon: 'solar:cart-outline'
    },
    {
      title: 'checkout',
      icon: 'iconoir:hand-cash'
    }
  ];

  const isActive = (index: number) => {
    return activeIndex == index ? '!text-primary font-semibold' : '!text-gray-500';
  };

  const isDisabled = (index: number) => index > activeIndex;
  return (
    <div className="justify-center grid">
      <ul className="flex items-center overflow-x-auto gap-4 pb-3 whitespace-nowrap">
        {steps.map((step: any, index: number) => (
          <li key={index}>
            <button
              className={['btn-with-icon transition-all', isActive(index)].join(' ')}
              type="button"
              onClick={() => onChange?.(index)}
              disabled={isDisabled(index) || disabled}
            >
              <Icon
                icon={step.icon}
                width="18"
              />
              <span>{t(step.title)}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

function DisplayItem({ item, onClick, selected }: { item: any; onClick: any; selected: boolean }) {
  return (
    <Card>
      <div className="flex items-center gap-3">
        <Image
          src={item.image}
          className="w-12 h-12 rounded-full object-cover shrink-0"
        />
        <div className="space-y-1 flex-1">
          <p className="text-sm text-gray-800 line-clamp-2">{item.name || '-'}</p>
          <p className="text-sm text-gray-500 font-bold">{CurrencyFormatter(item.price)}</p>
        </div>
        <button
          className={[
            'btn-with-icon !rounded-full !p-2 shrink-0',
            selected ? '!bg-red-500' : '!bg-primary'
          ].join(' ')}
          type="button"
          onClick={onClick}
        >
          <Icon
            icon={selected ? 'fluent:delete-12-regular' : 'ic:baseline-plus'}
            width="18"
          />
        </button>
      </div>
    </Card>
  );
}

function CartDetails({ items, onChange, total, activeIndex, changeIndex, errors }: any) {
  const { t } = useTranslation();

  const index = (id: string) => items.findIndex((e: any) => e.id == id);
  const getValues = useCallback(
    (id: string) => {
      const { price = 0, qty = 1 } = items[index(id)];
      return { price, qty };
    },
    [items]
  );

  const onValueChange = useCallback(
    (id: string, key: string) => {
      return (value: any) => {
        const idx = index(id);
        onChange('items.' + idx + '.' + key, Number(value));
      };
    },
    [items]
  );

  const removeItem = useCallback(
    (id: string) => {
      const filter = items.filter((e: any) => e.id !== id);
      onChange('items', filter);
    },
    [items]
  );

  return (
    <>
      <div className="grid">
        <div className="flex items-start gap-4">
          <div className="flex-1">
            <Table
              RenderHead={() => (
                <tr>
                  <th>{t('image')}</th>
                  <th>{t('name')}</th>
                  <th>{t('price')}</th>
                  <th>{t('quantity')}</th>
                  <th>{t('actions')}</th>
                </tr>
              )}
              RenderBody={() => {
                return (
                  <>
                    {items.map((item: any, index: number) => (
                      <tr key={item.id}>
                        <td>
                          <Image
                            src={item.id}
                            className="w-10 h-10 rounded-full object-cover shrink-0"
                          />
                        </td>
                        <td>
                          <p className="max-w-xs line-clamp-1">{item.item?.name}</p>
                          <p className="text-gray-500">
                            {CurrencyFormatter(item.item?.price || 0)}
                          </p>
                          <p className="form-error">{errors?.['items.' + index + '.id']}</p>
                        </td>
                        <td>
                          <div className="max-w-fit">
                            <Controllable
                              value={getValues(item.id).price}
                              onChange={onValueChange(item.id, 'price')}
                              step="any"
                            />
                            <p className="form-error">{errors?.['items.' + index + '.price']}</p>
                          </div>
                        </td>
                        <td>
                          <div className="max-w-fit">
                            <Controllable
                              value={getValues(item.id).qty}
                              onChange={onValueChange(item.id, 'qty')}
                            />
                            <p className="form-error">{errors?.['items.' + index + '.qty']}</p>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn-with-icon !bg-red-500"
                            type="button"
                            onClick={() => removeItem(item.id)}
                          >
                            <Icon
                              icon="fluent:delete-12-regular"
                              width="18"
                            />
                            <span>{t('delete')}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                    {!items.length ? (
                      <tr>
                        <td
                          colSpan={100}
                          className="text-center"
                        >
                          {t('no-data')}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td colSpan={2}></td>
                      <td>{t('subtotal')}</td>
                      <td>{CurrencyFormatter(total.subtotal || 0)}</td>
                      <td colSpan={100}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                      <td>{t('vat')}</td>
                      <td>{CurrencyFormatter(total.vat || 0)}</td>
                      <td colSpan={100}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                      <td>{t('total')}</td>
                      <td>{CurrencyFormatter(total.total || 0)}</td>
                      <td colSpan={100}></td>
                    </tr>
                    <tr>
                      <td colSpan={3}></td>
                      <td>
                        <button
                          className="btn-with-icon !rounded-full !bg-primary !py-3 !px-6"
                          type="button"
                          onClick={() => changeIndex(activeIndex + 1)}
                        >
                          <Icon
                            icon="iconoir:hand-cash"
                            width="18"
                          />
                          <span>{t('checkout')}</span>
                        </button>
                      </td>
                      <td colSpan={100}></td>
                    </tr>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function Checkout({ values, handleChange, total, handleSubmit, disabled, errors }: any) {
  const { t } = useTranslation();
  return (
    <>
      <form
        className="mx-auto w-full max-w-screen-sm space-y-4"
        onSubmit={handleSubmit}
      >
        <p className="text-center text-primary font-bold">{t('checkout')}</p>

        <Card>
          <ul className="divide-y divide-gray-200">
            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <Title title={t('products')} />
                <Title
                  className="!text-black font-semibold"
                  title={values.items?.length || 0}
                />
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <Title title={t('subtotal')} />
                <Title
                  className="!text-black font-semibold"
                  title={CurrencyFormatter(total.subtotal || 0)}
                />
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <Title title={t('vat')} />
                <Title
                  className="!text-black font-semibold"
                  title={CurrencyFormatter(total.vat || 0)}
                />
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="grid grid-cols-2 gap-2">
                <Title title={t('total')} />
                <Title
                  className="!text-black font-semibold"
                  title={CurrencyFormatter(total.total || 0)}
                />
              </div>
            </ItemList>
          </ul>
        </Card>
        <button
          className="btn-with-icon w-full !rounded-full !bg-primary !p-3"
          type="submit"
          disabled={disabled}
        >
          <Icon
            icon="iconoir:hand-cash"
            width="18"
          />
          <span>{t('save-changes')}</span>
        </button>
      </form>
    </>
  );
}
