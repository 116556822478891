import { useEffect, useState } from 'react';

import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';

import { Icon } from '@iconify/react';
import { serialize } from 'object-to-formdata';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ImportForm from 'src/components/shared/ImportForm';
import Modal from 'src/components/shared/Modal';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';

export default function Attendance() {
  let render = true;
  const { t } = useTranslation();
  const [attendance, setAttendance] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!render) return;
    getAttendance();
    render = false;
  }, []);

  function getAttendance(params?: any) {
    setIsLoading(true);
    prepareRequest({ url: 'attendance_logs', params: { page: 1, ...params } }, (data) => {
      const result = data.result;
      setAttendance(() => result.attendance_logs?.data || []);
      setPagination(() => result.attendance_logs?.pagination || {});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className="p-6">
      <Table
        isEmpty={!attendance.length}
        RenderHead={() => (
          <tr>
            <th>{t('employee')}</th>
            <th>{t('check-in')}</th>
            <th>{t('check-out')}</th>
            <th>{t('date')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {attendance.map((item) => (
              <tr key={item.id}>
                <td>{item.employee?.name || '-'}</td>
                <td>{item.check_in || '-'}</td>
                <td>{item.check_out || '-'}</td>
                <td>
                  <SharedTime date={item.date} />
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => {
          return (
            <>
              <ImportAttendanceTemplate refetch={getAttendance} />
            </>
          );
        }}
        pagination={pagination}
        loading={isLoading}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));

            getAttendance({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => getAttendance({ page: pagination.page + 1 })}
        onPreviousClick={() => getAttendance({ page: pagination.page - 1 })}
      />
    </div>
  );
}

function ImportAttendanceTemplate({ refetch }: { refetch: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);

  async function uploadTemplate(file: File) {
    setDisabled(true);
    setErrors(() => {});

    prepareRequest(
      {
        url: 'attendance_logs/import',
        method: 'post',
        data: serialize({ file }),
        onUploadProgress: ({ total = 0, loaded = 0 }) => {
          const percent = Math.round((loaded * 100) / total);
          setProgress(() => (percent === 100 ? 0 : percent));
        }
      },
      (data, error) => {
        if (error) return setErrors(() => error);
        generateAlert(data.message, 'success');
        setVisible(false);
        refetch();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <>
      <button
        type="button"
        className="btn-with-icon bg-blue-600 !text-white"
        onClick={() => setVisible(true)}
      >
        <Icon
          icon="tabler:file-import"
          width="18"
          height="18"
        />
        <span>{t('import')}</span>
      </button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('import')}
      >
        <>
          <ImportForm
            onChange={uploadTemplate}
            type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            disabled={disabled}
          />
          {/* <p className="form-error">{errors.toString()}</p> */}
          <p className="text-center text-gray-600 text-sm bg-gray-100 rounded p-3 relative overflow-hidden">
            <span
              className="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full bg-teal-600 opacity-20 transition w-1/2"
              style={{
                width: progress + '%'
              }}
            ></span>
            <span className="z-[1] relative">
              {t('import-attendance-template-note')}{' '}
              <Link
                to="/attendance_logs_template.xlsx"
                download="/attendance_logs_template.xlsx"
                target="_blank"
                className="text-blue-600 underline hover:no-underline"
              >
                {t('download')}
              </Link>
            </span>
          </p>
        </>
      </Modal>
    </>
  );
}

