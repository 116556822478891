import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ExportOptions, { downloadFile } from 'src/components/shared/ExportOptions';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import ReportsWrapper from '../../../components/reports/ReportsWrapper';

const index: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    GetItems();
  }, [params]);

  function GetItems() {
    setIsUpdating(true);
    prepareRequest(
      {
        url: 'reports/sessions',
        params
      },
      (data) => {
        const sessions = data?.result?.sessions || [];

        setResponses(() => sessions);
      }
    ).finally(() => setIsUpdating(false));
  }

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('date')}</th>
              <th>{t('services')}</th>
              <th>{t('products')}</th>

              <th>{t('total')}</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      <SharedTime
                        date={item.date}
                        format="dddd, ll"
                      />
                    </td>

                    <td>
                      {CurrencyFormatter(item.services?.total || 0)} - ({item.services?.count || 0})
                    </td>
                    <td>
                      {CurrencyFormatter(item.products?.total || 0)} - ({item.products?.count || 0})
                    </td>

                    <td>{CurrencyFormatter(item.total_payments || 0)}</td>
                    <td>
                      <CloseSession date={item.date} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="reports/sessions/export_excel"
                cvsPathname="reports/sessions/export_csv"
                pathname="reports/sessions/send_excel"
                pdfPathname="reports/sessions/export_pdf"
                params={params.toString()}
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        searchProps={{
          onChange: (e) => {
            setParams((param) => {
              param.set('search_key', (e.target as any).value);

              param.set('page', '1');
              return param;
            });
          }
        }}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating]);

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="monthly"
          onChange={({ from, to }) => {
            setParams((param) => {
              param.set('from', moment(from).format('yyyy-MM-DD'));
              param.set('to', moment(to).format('yyyy-MM-DD'));
              param.set('page', '1');

              return param;
            });
          }}
        />
      </div>
      <div className="grid">{MEMO_TABLE}</div>
    </ReportsWrapper>
  );
};

function CloseSession({ date }: { date: string }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({
    initialValues: {
      with_booking_items: ''
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'bookings/close_session',
          method: 'post',
          responseType: 'blob',
          params: {
            ...values,
            date
          }
        },
        (data, error) => {
          downloadFile('pdf', data);
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <>
      <button
        className="btn-with-icon !text-gray-600 !bg-gray-100"
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('close-session')}</span>
      </button>
      <Modal
        title={t('close-session')}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <select
              className="form-select form-outline"
              defaultValue=""
              name="with_booking_items"
              onChange={handleChange}
              value={values.with_booking_items}
            >
              <option
                value=""
                disabled
              >
                {t('select')}
              </option>
              <option value="1">{t('with-items')}</option>
              <option value="0">{t('without-items')}</option>
            </select>
          </div>
          <button
            className="btn-with-icon  !bg-primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </button>
        </form>
      </Modal>
    </>
  );
}

export default index;
