import { Icon } from '@iconify/react';
import { pick } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ItemList from 'src/components/shared/ItemList';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const JournalEntries: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('journal_entries', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.journal_entries;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('center')}</th>
              <th>{t('type')}</th>
              <th>{t('total')}</th>
              <th>{t('date')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.center?.name || 'N/A'}</td>
                    <td>{item.ref_type}</td>
                    <td>{CurrencyFormatter(item.total_amount || 0)}</td>
                    <td>
                      <SharedTime date={item.date} />
                    </td>

                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="actions">
                        <Link
                          to={`/settings/journal-entries/${item.id}`}
                          className="edit-btn"
                        >
                          <span>{t('edit')}</span>
                        </Link>
                        <ViewJournalData item={item} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <Link
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                to="/settings/journal-entries/new"
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </Link>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </React.Fragment>
  );
};

function ViewJournalData({ item }: { item: any }) {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        type="button"
        className="edit-btn"
        onClick={() => setVisible(true)}
      >
        <Icon
          icon="solar:eye-outline"
          width="20"
          height="20"
        />
      </button>
      <Modal
        title={item.date}
        visible={visible}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-md"
      >
        <ul className="divide-y divide-gray-100">
          <ItemList className="py-3">
            <p className="text-gray-600 text-sm">{t('date')}</p>
            <p className="text-gray-600 text-sm">{<SharedTime date={item.date} />}</p>
          </ItemList>
          <ItemList className="py-3">
            <p className="text-gray-600 text-sm">{t('center')}</p>
            <p className="text-gray-600 text-sm">{item.center?.name}</p>
          </ItemList>
          <ItemList className="py-3">
            <p className="text-gray-600 text-sm">{t('notes')}</p>
            <p className="text-gray-600 text-sm">{item.notes || '-'}</p>
          </ItemList>
          <ItemList className="py-3">
            <p className="text-gray-600 text-sm">{t('transactions')}</p>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid">
              <div className="overflow-x-auto">
                <table className="styled-table">
                  <thead className="bg-gray-100">
                    <tr>
                      <th>{t('account')}</th>
                      <th>{t('debit')}</th>
                      <th>{t('credit')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.transactions.map((transaction: any) => (
                      <tr key={transaction.id}>
                        <td>{transaction.account?.name}</td>
                        <td>{CurrencyFormatter(transaction.debit || 0)}</td>
                        <td>{CurrencyFormatter(transaction.credit || 0)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ItemList>
        </ul>
      </Modal>
    </>
  );
}

export default JournalEntries;

