import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/shared/Modal';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

const UpdateFAQS = ({ refetching, item }: { refetching: any; item: any }) => {
  const [initialValues, setInitialValues] = useState(item);
  const [errors, setErrors] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setErrors({});
    setDisabled(true);
    prepareRequest(
      {
        url: 'faqs/update',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        helper.resetForm();
        refetching();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  return (
    <>
      <button
        className="btn-with-icon !bg-gray-200 !text-gray-600"
        type="button"
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        <span>{t('edit')}</span>
      </button>

      <Modal
        visible={visible}
        title={t('add-new')}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-lg"
      >
        <form
          className="space-y-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-group">
              <label className="form-label">{t('question')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.question}
                onChange={(e) => handleChange('question', e)}
              />
              {errors?.question ? <span className="form-error">{errors?.question}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt_question')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.alt_question}
                onChange={(e) => handleChange('alt_question', e)}
              />
              {errors?.alt_question ? (
                <span className="form-error">{errors?.alt_question}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('answer')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.answer}
                onChange={(e) => handleChange('answer', e)}
              ></textarea>
              {errors?.answer ? <span className="form-error">{errors?.answer}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('alt_answer')}</label>
              <textarea
                autoComplete="off"
                placeholder="..."
                className="form-textarea form-outline"
                value={formik.values.alt_answer}
                onChange={(e) => handleChange('alt_answer', e)}
              ></textarea>
              {errors?.alt_answer ? <span className="form-error">{errors?.alt_answer}</span> : null}
            </div>
          </div>

          <div className="inline-flex gap-3 flex-wrap">
            <button
              className="btn-with-icon bg-primary text-white"
              type="submit"
              disabled={disabled}
            >
              <span>{t('save-changes')}</span>
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdateFAQS;

