type Params = {
  content?: string;
  src?: string;
  position?: string;
};

export default function createScript({ src = '', content = '', position = 'body' }: Params) {
  const script = document.createElement('script') as HTMLScriptElement;
  const parent = document.querySelector(position) as HTMLElement;
  script.src = src;
  script.type = 'text/javascript';
  script.text = content;
  parent.appendChild(script);
  return script;
}
