import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import Mobile from 'src/components/shared/Mobile';
import Select from 'src/components/shared/Select';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

export default function AddCenter() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [cities, setCities] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  useEffect(() => {
    getRegions();
  }, []);

  function getCities(region: string) {
    prepareRequest(
      {
        url: 'cities',
        params: {
          is_active: 1,
          region_id: region
        }
      },
      (data) => {
        setCities(() => data.result?.cities || []);
        setDistricts([]);
      }
    );
  }

  function getRegions() {
    prepareRequest(
      {
        url: 'regions',
        params: {
          is_active: 1
        }
      },
      (data) => {
        setRegions(() => data.result?.regions || []);
        setCities([]);
        setDistricts([]);
      }
    );
  }

  function getDistricts(city: string) {
    prepareRequest(
      {
        url: 'districts',
        params: {
          is_active: 1,
          city_id: city
        }
      },
      (data) => {
        setDistricts(() => data.result?.districts || []);
      }
    );
  }

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'centers/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card className="!p-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="form-label"
                >
                  {t('name')}
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-input form-outline"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  required
                />

                <p className="form-error">{errors?.['name']}</p>
              </div>
              <div className="form-group">
                <label
                  htmlFor="alt_name"
                  className="form-label"
                >
                  {t('alt-name')}
                </label>
                <input
                  type="text"
                  id="alt_name"
                  className="form-input form-outline flex-1 !rounded-none !border-x-0"
                  value={values.alt_name}
                  onChange={handleChange}
                  name="alt_name"
                />

                <p className="form-error">{errors?.['alt_name']}</p>
              </div>
              <div className="form-group">
                <label className="form-label">{t('cr-no')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.cr_no}
                  name="cr_no"
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.cr_no}</p>
              </div>

              {/* <div className="form-group">
                <label className="form-label">{t('vat-no')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.vat_no}
                  name="vat_no"
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.vat_no}</p>
              </div> */}

              <div className="form-group">
                <label className="form-label">{t('email')}</label>
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.email}
                  onChange={handleChange}
                />
                <p className="form-error">{errors?.email}</p>
              </div> 

              <div className="form-group">
                <label className="form-label">{t('mobile')}</label>
                <Mobile
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={values.mobile}
                  name="mobile"
                  onChange={handleChange}
                  required
                />
                <p className="form-error">{errors?.mobile}</p>
              </div>
              
              <div className="form-group">
                <label className="form-label">{t('region')}</label>
                <Select
                  type="single"
                  options={regions}
                  value={values.region_id}
                  onSelect={function (value: any): void {
                    setFieldValue('region_id', value);
                    getCities(value);
                  }}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                <span className="form-error">{errors?.region_id}</span>
              </div>
              <div className="form-group">
                <label className="form-label">{t('city')}</label>
                <Select
                  type="single"
                  options={cities}
                  value={values.city_id}
                  onSelect={function (value: any): void {
                    setFieldValue('city_id', value);
                    getDistricts(value);
                  }}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                <span className="form-error">{errors?.city_id}</span>
              </div>
              <div className="form-group">
                <label className="form-label">{t('district')}</label>
                <Select
                  type="single"
                  options={districts}
                  value={values.district_id}
                  onSelect={function (value: any): void {
                    setFieldValue('district_id', value);
                  }}
                  optionTxt={'name'}
                  optionValue={'id'}
                />

                <span className="form-error">{errors?.district_id}</span>
              </div>
            </div>
          </div>
        </Card>

        <button
          className="btn-with-icon bg-primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </form>
    </div>
  );
}

