import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import ChangeStatus from '../shared/ChangeStatus';
import Modal from '../shared/Modal';
import SharedTime from '../shared/SharedTime';
import Switcher from '../shared/Switcher';

export default function Account({
  refetch,
  item,
  padding
}: {
  refetch: any;
  item: any;
  padding?: number;
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  return (
    <>
      <tr>
        <td
          style={{
            paddingInlineStart: padding ? padding + 'px' : 'auto'
          }}
        >
          {!!item.sub_accounts?.length && (
            <button
              type="button"
              className="w-8 h-8 shrink-0"
              onClick={() => setVisible(!visible)}
            >
              <Icon
                icon="solar:alt-arrow-left-linear"
                width="20"
                height="20"
                className={`${visible ? '-rotate-90' : 'rtl:rotate-180'}`}
              />
            </button>
          )}
        </td>
        <td>{item.name || 'N/A'}</td>
        <td>{item.alt_name || 'N/A'}</td>
        <td>
          <ChangeStatus
            pathname={'accounts/update_is_active/' + item.id}
            refetch={refetch}
            active={!!item.is_active}
          />
        </td>

        <td>
          <SharedTime date={item.updated_at} />
        </td>
        <td>
          <div className="inline-flex gap-2">
            <button
              type="button"
              className="edit-btn"
              onClick={() => setEditVisible(true)}
            >
              {t('edit')}
            </button>
            <Link
              to={`/settings/accounting-tree/${item.id}/transactions`}
              className="edit-btn"
            >
              {t('transactions')}
            </Link>
          </div>
        </td>
      </tr>
      {!!item.sub_accounts?.length &&
        visible &&
        item.sub_accounts?.map((account: any, idx: number) => (
          <Account
            refetch={refetch}
            item={account}
            key={account.id}
            padding={padding ? 2 * padding : 16}
          />
        ))}
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditVisible(false);
        }}
        title={item?.name}
        size="!max-w-screen-md"
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={refetch}
          schema={item}
        />
      </Modal>
    </>
  );
}

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState(schema);
  const [errors, setErrors] = useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    formik: { handleChange, setFieldValue, values, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'accounts/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          closeModal(false);
          reFetching();
        }
      ).finally(() => {
        setDisabled(false);
      });
    }
  });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-group">
          <label className="form-label">{t('name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.name}
            name="name"
            onChange={handleChange}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.alt_name}
            name="alt_name"
            onChange={handleChange}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('description')}</label>
          <textarea
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.desc}
            name="desc"
            onChange={handleChange}
          ></textarea>
          {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-description')}</label>
          <textarea
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.alt_desc}
            name="alt_desc"
            onChange={handleChange}
          ></textarea>
          {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
        </div>
        <div className="form-group col-span-full">
          <label className="form-label">{t('type')}</label>
          <select
            name="type"
            className="form-select form-outline"
            defaultValue={values.type}
          >
            <option value="">-- {t('select')} -- </option>
            <option value="debit">{t('debit')}</option>
            <option value="credit">{t('credit')}</option>
          </select>
          {errors?.type ? <span className="form-error">{errors?.type}</span> : null}
        </div>
        <div className="form-group col-span-full">
          <label className="form-label">{t('detailed-account')}</label>
          <Switcher
            checked={!!values.is_details}
            onChange={(checked: boolean) => setFieldValue('is_details', checked ? 1 : 0)}
          />
          {errors?.type ? <span className="form-error">{errors?.type}</span> : null}
        </div>
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};
