import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import Select from 'src/components/shared/Select';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';

interface Item {
  id: string | undefined;
  value: number | undefined;
  name: string | undefined;
  alt_name: string | undefined;
  image: string | undefined;
  price: number | undefined;
}

interface StocktakingValues {
  center_id: string | undefined;
  items: Item[];
}

export default function NewStocktaking() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<StocktakingValues | any>({});
  const [centers, setCenters] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCenters();
    getProducts();
  }, []);

  function getCenters(q?: string) {
    prepareRequest(
      {
        url: 'centers',
        params: {
          is_active: 1,
          page: 1,
          search_key: q
        }
      },
      (data) => {
        setCenters(() => data.result?.centers?.data);
      }
    );
  }

  function getProducts() {
    prepareRequest(
      {
        url: 'items',
        params: {
          is_active: 1,
          item_type: 'product'
        }
      },
      (data) => {
        const items = data.result?.items || [];
        // setProducts(() => items);
        setFieldValue('items', items);
      }
    );
  }

  const initialValues = {
    center_id: undefined,
    items: []
  } satisfies StocktakingValues;

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm<StocktakingValues>({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      const stockValues = {
        ...values,
        items: values.items.filter((item: Item) => (item?.value || 0) >= 1)
      };
      prepareRequest(
        { url: 'stock_counts/add', method: 'post', data: stockValues },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          navigate('/settings/inventory/stocktaking', { replace: true });
        }
      ).finally(() => {
        setDisabled(false);
      });
    }
  });

  return (
    <>
      <AppBar
        title={t('add-new')}
        center
      />
      <div className="flex-1 min-h-screen">
        <div className="p-6">
          <div className="container">
            <form
              className="space-y-4"
              onSubmit={handleSubmit}
            >
              <Card className="!p-6 space-y-4">
                <div className="form-group">
                  <p className="form-label">{t('center')}</p>
                  <Select
                    type="single"
                    options={centers}
                    optionTxt="name"
                    optionValue="id"
                    onSelect={(value) => setFieldValue('center_id', value)}
                    onSearchChange={(ev) => getCenters(ev.target.value)}
                    value={values.center_id}
                  />
                  <p className="form-error">{errors.center_id}</p>
                </div>
              </Card>

              <Card className="!p-0 overflow-hidden">
                <div className="grid">
                  <div className="overflow-x-auto">
                    <table className="styled-table">
                      <thead className="bg-gray-100">
                        <tr>
                          <th>{t('product')}</th>
                          <th>{t('price')}</th>
                          <th>{t('value')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.items.map((item: Item, index: number) => (
                          <tr key={item.id}>
                            <td>
                              <div className="flex items-center gap-4">
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  title={item.name}
                                  width="40"
                                  height="40"
                                  className="shrink-0 w-10 h-10 rounded-full object-cover object-center"
                                />
                                <p className="!whitespace-normal !max-w-sm line-clamp-2 flex-1">
                                  {item.name} - {item.alt_name}
                                </p>
                              </div>
                            </td>
                            <td>{CurrencyFormatter(item.price || 0)}</td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  name={'items.' + index + '.value'}
                                  className="form-input form-outline"
                                  onChange={handleChange}
                                  value={values.items[index]['value']}
                                />
                                <p className="form-error">
                                  {errors?.['items.' + index + '.value']}
                                </p>
                                <p className="form-error">{errors?.['items.' + index + '.id']}</p>
                              </div>
                            </td>
                          </tr>
                        ))}

                        {!values.items.length && (
                          <tr>
                            <td colSpan={100}>
                              <p className="text-center text-gray-600">{t('no-data')}</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
              <button
                type="submit"
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                disabled={disabled}
              >
                {t('save-changes')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
