import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import { RootState } from 'src/store';
import { ChangeLanguage } from './HeaderLayout';
import ProfileNavigation from './ProfileNavigation';
import { Icon } from '@iconify/react';
import DemoAccount from 'src/components/account/DemoAccount';

export default function Header({ hasBack, title }: { hasBack?: boolean; title?: string }) {
  const { t } = useTranslation();
  const menu = useRef<HTMLElement | null>(null);
  const { user, isSingleApp } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  function toggleMenu() {
    const menuElement = menu.current;

    if (menuElement?.classList.contains('block')) {
      menuElement?.classList.replace('block', 'hidden');
    } else {
      menuElement?.classList.replace('hidden', 'block');
    }
  }

  return (
    <div className="w-full px-6 sticky top-0 bg-white z-10">
      <div className="xl:container">
        <div className="flex items-center gap-4 md:gap-8 h-20">
          <div className="lg:w-[300px]">
            <Link
              to="https://sa.salon"
              className="shrink-0"
              title="sa salon - صالونات السعودية"
            >
              <Image
                src="/logo.png"
                alt="website logo"
                width="250"
                height="50"
                className="w-full max-w-[9rem] object-contain shrink-0 object-center"
              />
            </Link>
          </div>

          {(hasBack || title) && (
            <div className="flex items-center gap-4">
              {hasBack && (
                <button
                  className="text-gray-600"
                  onClick={() => navigate(-1)}
                >
                  <Icon
                    icon="humbleicons:arrow-left"
                    width="20"
                    className="block rtl:hidden"
                  />
                  <Icon
                    icon="humbleicons:arrow-right"
                    width="20"
                    className="hidden rtl:block"
                  />
                </button>
              )}

              {title && (
                <p
                  className="text-base font-semibold line-clamp-1 text-gray-800"
                  data-title={title}
                >
                  {t(title) || ''}
                </p>
              )}
            </div>
          )}

          <div className="flex-1"></div>
          <nav
            className="hidden md:block absolute md:relative top-full md:top-0 w-full md:w-auto bg-white left-0 rtl:left-auto rtl:right-0 z-10"
            ref={menu}
          >
            <ul className="flex flex-col md:flex-row  md:items-center gap-4 p-6 md:p-0">
              {!user && !isSingleApp ? (
                <>
                  <li>
                    <DemoAccount />
                  </li>

                  <li>
                    <Link
                      to="https://sa.salon"
                      className="flex gap-1 items-center text-lg font-medium text-gray-600"
                    >
                      <Icon
                        icon="akar-icons:globe"
                        className="w-5 h-5"
                      />

                      <span>{t('website')}</span>
                    </Link>
                  </li>
                </>
              ) : user ? (
                <>
                  <li>
                    <Link
                      to="/"
                      className="text-base font-medium text-gray-600"
                    >
                      <span>{t('home')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tutorials"
                      className="text-base font-medium text-gray-600"
                    >
                      <span>{t('tutorials')}</span>
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </nav>
          <ChangeLanguage />
          <div>{!!user && <ProfileNavigation />}</div>
          <button
            type="button"
            className="space-y-1 w-8 h-8 md:hidden flex flex-col items-center justify-center"
            onClick={toggleMenu}
          >
            <span className="h-0.5 block w-6 bg-gray-600"></span>
            <span className="h-0.5 block w-6 bg-gray-600"></span>
            <span className="h-0.5 block w-6 bg-gray-600"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

