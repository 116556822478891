import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import usePermissions from 'src/hooks/usePermissions';
import { RootState } from 'src/store';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../../components/shared/MiniDashboardSingleCard';

const Settings: FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { hasPermission } = usePermissions();
  const cards: ItemInterface[] = [
    {
      icon: 'mdi:account-tie-outline',
      color: 'text-gray-500',
      value: t('employees'),
      url: '/settings/employees'
    },
    {
      icon: 'mi:users',
      color: 'text-gray-500',
      value: t('clients'),
      url: '/settings/clients'
    },
    // {
    //   icon: 'mi:users',
    //   color: 'text-gray-500',
    //   value: t('suppliers'),
    //   url: '/settings/suppliers'
    // },

    // {
    //   icon: 'mdi:fingerprint',
    //   color: 'text-gray-500',
    //   value: t('attendance'),
    //   url: '/settings/attendance'
    // },
    {
      icon: 'solar:dollar-outline',
      color: 'text-gray-500',
      value: t('salaries'),
      url: '/settings/salaries'
    },
    {
      icon: 'carbon:receipt',
      color: 'text-gray-500',
      value: t('expenses'),
      url: '/settings/expenses'
    },

    {
      icon: 'fe:layer',
      color: 'text-gray-500',
      value: t('categories'),
      url: '/settings/categories'
    },
    {
      icon: 'solar:clipboard-list-linear',
      color: 'text-gray-500',
      value: t('prices'),
      url: '/settings/prices'
    },

    {
      icon: 'iconoir:grid-add',
      color: 'text-gray-500',
      value: t('services'),
      url: '/settings/services'
    },
    {
      icon: 'solar:tag-outline',
      color: 'text-gray-500',
      value: t('packages-offers'),
      url: '/settings/packages-offers'
    },
    {
      icon: 'fluent-mdl2:product-variant',
      color: 'text-gray-500',
      value: t('products'),
      url: '/settings/products'
    }
  ];

  const advancedSettingsCards: ItemInterface[] = [
    // {
    //   icon: 'ri:settings-line',

    //   color: 'text-gray-500',
    //   value: t('global-settings'),
    //   url: '/settings/advanced'
    // },
    // {
    //   icon: 'fluent:edit-settings-24-regular',
    //   color: 'text-gray-500',
    //   value: t('center-settings'),
    //   url: '/settings/advanced/center'
    // },
    // {
    //   icon: 'streamline:money-cash-bill-3-accounting-billing-payment-finance-cash-currency-money-bill',
    //   color: 'text-gray-500',
    //   value: t('payment-settings'),
    //   url: '/settings/advanced/payments'
    // },
    // {
    //   icon: 'tabler:message-cog',
    //   color: 'text-gray-500',
    //   value: t('sms-settings'),
    //   url: '/settings/advanced/sms'
    // },
    // {
    //   icon: 'solar:documents-minimalistic-linear',
    //   color: 'text-gray-500',
    //   value: t('sms-templates'),
    //   url: '/settings/advanced/sms-templates'
    // },
    // {
    //   icon: 'ic:outline-alternate-email',
    //   color: 'text-gray-500',
    //   value: t('email-templates'),
    //   url: '/settings/advanced/email-templates'
    // },
    // {
    //   icon: 'solar:mouse-line-duotone',
    //   color: 'text-gray-500',
    //   value: t('logs'),
    //   url: '/settings/advanced/logs'
    // },
    // {
    //   icon: 'fa6-regular:circle-question',
    //   color: 'text-gray-500',
    //   value: t('faqs'),
    //   url: '/settings/advanced/faqs'
    // },
    // {
    //   icon: 'icon-park-outline:page',
    //   color: 'text-gray-500',
    //   value: t('pages'),
    //   url: '/settings/advanced/pages'
    // },
    {
      value: t('website-settings'),
      url: '/account/setup',
      color: 'text-gray-500',
      icon: 'solar:server-square-update-outline'
    },
    {
      value: t('receipt-design'),
      url: '/settings/advanced/receipt-design',
      color: 'text-gray-500',
      icon: 'material-symbols-light:receipt-outline'
    }
  ];

  const inventoryCards: ItemInterface[] = [
    {
      icon: 'fluent-mdl2:product-variant',
      color: 'text-gray-500',
      value: t('stock-counts'),
      url: '/settings/inventory/stocktaking'
    },
    {
      icon: 'solar:cart-linear',
      color: 'text-gray-500',
      value: t('purchases'),
      url: '/settings/inventory/purchase-orders'
    },
    {
      icon: 'solar:settings-minimalistic-linear',
      color: 'text-gray-500',
      value: t('wastages-management'),
      url: '/settings/wastages-management'
    },
    {
      icon: 'solar:trash-bin-minimalistic-linear',
      color: 'text-gray-500',
      value: t('wastages'),
      url: '/settings/wastages'
    },
    {
      icon: 'clarity:process-on-vm-line',
      color: 'text-gray-500',
      value: t('processing'),
      url: '/settings/processing'
    }
    // {
    //   icon: 'streamline:interface-arrows-data-transfer-diagonal-square-arrow-square-data-diagonal-internet-transfer-network',
    //   color: 'text-gray-500',
    //   value: t('transfers'),
    //   url: '/settings/transfers'
    // },
    // {
    //   icon: 'icon-park-outline:return',
    //   color: 'text-gray-500',
    //   value: t('refunds'),
    //   url: '/settings/refunds'
    // }
  ];

  const accountingCards: ItemInterface[] = [
    {
      icon: 'solar:archive-down-linear',
      color: 'text-gray-500',
      value: t('accounting-tree'),
      url: '/settings/accounting-tree'
    },
    {
      icon: 'solar:document-add-linear',
      color: 'text-gray-500',
      value: t('journal-entries'),
      url: '/settings/journal-entries'
    },
    {
      icon: 'solar:branching-paths-down-outline',
      color: 'text-gray-500',
      value: t('cost-centers'),
      url: '/settings/cost-centers'
    }
  ];

  return (
    <Fragment>
      <div className="p-6 space-y-4">
        {/* <MiniDashboardSingleCard
          item={{
            value: t('setup'),
            url: '/account/setup',
            icon: 'solar:server-square-update-outline',
            color: 'text-yellow-500'
          }}
        /> */}

        {/* <p className="text-sm font-medium text-gray-500">{t('center-details')}</p> */}
        {/* <CenterConfiguration /> */}

        <p className="text-sm font-medium text-gray-500">{t('settings')}</p>
        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        <p className="text-sm font-medium text-gray-500">{t('advanced-settings')}</p>
        <div className="grid grid-wrapper gap-3">
          {advancedSettingsCards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        {/* <p className="text-sm font-medium text-gray-500">{t('inventory')}</p>
        <div className="grid grid-wrapper gap-3">
          {inventoryCards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        <p className="text-sm font-medium text-gray-500">{t('accounting')}</p>
        <div className="grid grid-wrapper gap-3">
          {accountingCards.map((item, i) => (
            <div
              key={i}
              className="relative"
            >
              {!!user?.subscription?.package?.is_default && (
                <span className="px-3 py-1.5 rounded-full leading-none text-sm bg-yellow-500 text-gray-800 font-medium absolute -top-3 right-6 rtl:left-6 rtl:right-auto w-fit">
                  {t('upgrade')}
                </span>
              )}
              <MiniDashboardSingleCard
                item={{
                  ...item,
                  url: !!user?.subscription?.package?.is_default ? '#' : item.url
                }}
              />
            </div>
          ))}
        </div> */}
      </div>
    </Fragment>
  );
};

function CenterConfiguration() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  return (
    <Card>
      <Link
        to={
          ['owner', 'admin'].includes(user.user_type) ? '/settings/centers/' + user.center?.id : '#'
        }
        className="hidden grid-cols-1 sm:grid-cols-2 gap-3"
      >
        <div className="col-span-full">
          <Image
            className="w-12 h-12 rounded-full object-cover"
            src={user.center?.logo}
          />
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('email')}</p>
          <p className="font-semibold text-gray-700">{user.center?.email || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('mobile')}</p>
          <p className="font-semibold text-gray-700">{user.center?.mobile || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('address')}</p>
          <p className="font-semibold text-gray-700">{user.center?.address || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('vat-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.vat_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('cr-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.cr_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('bank-name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.bank_name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">IBAN</p>
          <p className="font-semibold text-gray-700">{user.center?.iban || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('accept-partial-payments')}</p>
          <p className="font-semibold text-gray-700">
            {user.center?.accept_partial_payments ? t('yes') : t('no')}
          </p>
        </div>
      </Link>
    </Card>
  );
}

export default Settings;




