import { Popover } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import useLogout from 'src/hooks/useLogout';
import { RootState } from 'src/store';

export default function ProfileNavigation() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const { handleLogged } = useLogout();

  return (
    <div className="w-full flex items-center justify-between gap-4">
      <div className="flex-1 flex gap-4 items-center">
        <Image
          src={user?.image}
          className="shrink-0 w-10 h-10 rounded-full object-cover object-center shadow-sm shadow-gray-800/20"
        />
        <div className="flex-1 space-y-0.5 hidden sm:block max-w-[8rem]">
          <div className="grid">
            <p className="text-sm font-bold text-gray-800 line-clamp-1">
              {user?.name || 'UNKNOWN'}
            </p>
          </div>
          <div className="grid">
            <p className="text-xs text-gray-500 line-clamp-1 [direction:ltr]">
              {user?.email || 'UNKNOWN'}
            </p>
          </div>
        </div>
      </div>
      <Popover className="relative">
        <Popover.Button
          className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center text-gray-600 shrink-0"
          type="button"
        >
          <Icon
            icon="solar:alt-arrow-down-linear"
            width="20"
            height="20"
          />
        </Popover.Button>

        <Popover.Panel className="absolute z-10  ring-1 ring-slate-200 bg-white rounded-lg w-max top-full mt-5 right-0 rtl:right-auto rtl:left-0 p-3">
          <ul className="divide-y divide-gray-100">
            <ItemList className="py-1.5">
              <Link to="/account/profile">
                <div className="inline-flex items-center gap-3 text-sm">
                  <Icon
                    icon="solar:shield-user-bold-duotone"
                    width="24"
                    height="24"
                    className="text-gray-800"
                  />
                  <div>
                    <p className="truncate text-gray-600">{t('profile')}</p>
                  </div>
                </div>
              </Link>
            </ItemList>
            <ItemList className="py-1.5">
              <Link to="/calendar">
                <div className="inline-flex items-center gap-3 text-sm">
                  <Icon
                    icon="solar:calendar-bold-duotone"
                    width="24"
                    height="24"
                    className="text-gray-800"
                  />
                  <div>
                    <p className="truncate text-gray-600">{t('calendar')}</p>
                  </div>
                </div>
              </Link>
            </ItemList>
            <ItemList className="py-1.5">
              <Link to="/settings">
                <div className="inline-flex items-center gap-3 text-sm">
                  <Icon
                    icon="ri:settings-line"
                    width="24"
                    height="24"
                    className="text-gray-800"
                  />
                  <div>
                    <p className="truncate text-gray-600">{t('settings')}</p>
                  </div>
                </div>
              </Link>
            </ItemList>
            <ItemList className="py-1.5">
              <button
                type="button"
                onClick={handleLogged}
              >
                <div className="inline-flex items-center gap-3 text-sm">
                  <Icon
                    icon="solar:power-linear"
                    width="24"
                    height="24"
                    className="text-red-800"
                  />
                  <div>
                    <p className="truncate text-red-600">{t('logout')}</p>
                  </div>
                </div>
              </button>
            </ItemList>
          </ul>
        </Popover.Panel>
      </Popover>
    </div>
  );
}
