import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import SharedTime from 'src/components/shared/SharedTime';
import prepareRequest from 'src/helper/prepareRequest';

export default function TutorialDetails() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});

  useEffect(() => {
    getItem();
  }, []);

  function getItem() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'tutorials/' + id
      },
      (data) => {
        setItem(() => data.result?.tutorial || {});
      }
    ).finally(() => setIsLoading(false));
  }

  if (isLoading) return <LoadingComponent />;
  return (
    <div className="flex-1">
      <div className="w-full bg-slate-100 pt-14 pb-20 px-6">
        <h3 className="text-2xl font-bold text-gray-800 text-center">
          {item.title} - {item.alt_title}
        </h3>
        <SharedTime date={item.created_at} />
      </div>
      <div className="w-full max-w-screen-lg px-6 mx-auto">
        <picture>
          <Image
            src={item.image}
            width="1024"
            height="400"
            alt={i18n.language == 'ar' ? item.alt_title : item.title}
            title={i18n.language == 'ar' ? item.alt_title : item.title}
            className="w-full h-full rounded-lg -mt-8"
          />
        </picture>
      </div>
      <div
        className="prose p-6 max-w-screen-lg mx-auto"
        dangerouslySetInnerHTML={{ __html: i18n.language == 'ar' ? item.alt_body : item.body }}
      ></div>
    </div>
  );
}
