import React from 'react';

export default function DisplayTwoRow({ title, value }: { title: string; value: string }) {
  return (
    <>
      <p className="text-xs text-start text-gray-600">{title}</p>
      <p className="text-xs text-end font-semibold text-black">{value}</p>
    </>
  );
}
