import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import ContinueWithSocial from 'src/components/account/ContinueWithSocial';
import VerifyOTP from 'src/components/account/VerifyOTP';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';

interface AuthInterface {
  email?: string;
  otp?: string;
}

const OtpLogin: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    email: undefined,
    otp: undefined
  } satisfies AuthInterface;
  const [initialValues, setInitialValues] = useState<AuthInterface>(globalValues);
  const [errors, setErrors] = useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hasOtp, setHasOtp] = useState<boolean>(false);
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'login',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(error);

          const otp = data.result.need_otp;
          const time = data.result.remain_seconds;
          const token = data.result.access_token;

          if (otp) {
            setHasOtp(true);
            setRemainingTime(time || 0);
            return;
          }

          localStorage.setItem('@token', token);
          window.location.reload();
          // dispatch(storeToken(token));
          // dispatch(saveUserInfo(user));
          // navigate(data.result.go_settings ? '/settings' : '/', { replace: true });
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  useEffect(() => {
    if (values?.otp?.length === 4) {
      handleSubmit();
    }
  }, [values.otp]);

  return (
    <div className="flex-1">
      <div className="max-w-screen-sm p-8 m-auto space-y-6">
        {hasOtp ? (
          <p className="text-xl text-black font-medium text-center">{t('otp')}</p>
        ) : (
          <>
            <p className="text-xl text-black font-medium text-center">{t('welcome')} 👋</p>
            <ContinueWithSocial />
            <p className="flex items-center gap-4 !my-4">
              <span className="flex-1 h-px bg-gray-200"></span>
              <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
              <span className="flex-1 h-px bg-gray-200"></span>
            </p>{' '}
          </>
        )}

        <form
          className="space-y-3"
          onSubmit={handleSubmit}
        >
          {hasOtp ? (
            <div className="form-group">
              <p
                className="text-gray-600 text-sm mb-3"
                dangerouslySetInnerHTML={{
                  __html: t('verify-content').replace(
                    '_email_',
                    () => `<span class="text-black font-bold">${values.email || ''}</span>`
                  )
                }}
              ></p>
              <VerifyOTP
                onChange={(value) => setFieldValue('otp', value)}
                disabled={disabled}
              />

              <div className="flex justify-between items-center">
                <button
                  type="button"
                  title={t('resend-otp')}
                  aria-label={t('resend-otp')}
                  className="text-blue-600 text-sm block underline hover:no-underline disabled:text-gray-500"
                  disabled={resendDisabled}
                  onClick={() => handleSubmit()}
                >
                  {t('resend-otp')} (
                  <CountUp
                    start={remainingTime}
                    duration={1000}
                    end={0}
                    onStart={() => setResendDisabled(true)}
                    onEnd={() => setResendDisabled(false)}
                  />
                  )
                </button>

                <button
                  type="button"
                  title={t('edit-email')}
                  aria-label={t('edit-email')}
                  className="text-blue-600 text-sm block underline hover:no-underline"
                  onClick={() => {
                    setHasOtp(false);
                    setFieldValue('otp', '');
                  }}
                >
                  {t('edit-email')}
                </button>
              </div>

              {errors?.otp ? <span className="form-error">{errors?.otp}</span> : null}
            </div>
          ) : (
            <div className="form-group">
              <label
                htmlFor="email"
                className="form-label"
              >
                {t('email')}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                className="form-input form-outline"
                autoComplete="off"
                autoCapitalize="false"
                autoCorrect="true"
                value={values.email}
                required
                onChange={({ target }: { target: HTMLInputElement }) =>
                  setFieldValue('email', target.value)
                }
              />
              {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
            </div>
          )}

          {!hasOtp && (
            <div className="!mt-8 space-y-2">
              <button
                className="btn-with-icon bg-primary w-full !p-4"
                type="submit"
                disabled={disabled}
              >
                {disabled ? (
                  <Icon
                    icon="svg-spinners:3-dots-fade"
                    width={20}
                  />
                ) : (
                  <span>{hasOtp ? t('verify') : t('get-otp')}</span>
                )}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default OtpLogin;


