import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/components/shared/Alert';
import Editor from 'src/components/shared/Editor';
import InputFile from 'src/components/shared/InputFile';
import Mobile from 'src/components/shared/Mobile';
import Select from 'src/components/shared/Select';
import TomComponent from 'src/components/shared/TomComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import useForm from 'src/hooks/useForm';
// import GoogleMap from '../../components/shared/GoogleMap';

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
  info: string | undefined;
  alt_info: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  region_id: string | undefined;
  city_id: string | undefined;
  district_id: string | undefined;
  owner_id: string | undefined;
  package_id: string | undefined;
  address: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
  accept_partial_payments: number | undefined;
  feature_ids: string[] | undefined;
}

export default function Create() {
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    info: undefined,
    alt_info: undefined,
    email: undefined,
    mobile: undefined,
    region_id: undefined,
    city_id: undefined,
    district_id: undefined,
    owner_id: undefined,
    package_id: undefined,
    address: undefined,
    lat: undefined,
    lng: undefined,
    feature_ids: [],
    accept_partial_payments: 0
  } satisfies FormInterface;
  let rerender = true;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [features, setFeatures] = React.useState<any[]>([]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [districts, setDistricts] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (rerender) {
      Promise.all([GetFeatures(), GetRegions(), GetCities(), GetDistricts()]).finally(() =>
        setIsLoading(false)
      );
      rerender = false;
    }
  }, []);

  const GetFeatures = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('features', { params: { is_active: 1 } });
      setFeatures(data?.result?.features);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetRegions = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('regions', { params: { is_active: 1 } });
      setRegions(data?.result?.regions);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetCities = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('cities', { params: { is_active: 1 } });
      setCities(data?.result?.cities);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const GetDistricts = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('districts', { params: { is_active: 1 } });
      setDistricts(data?.result?.districts);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('centers/add', values);
      helper.resetForm();
      generateAlert(data.message, 'success');
      goBack();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
          generateAlert(err?.message, 'error');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
      generateAlert(error.message, 'error');
    } finally {
      setDisabled(false);
    }
  }, []);

  const goBack = React.useCallback(function () {
    navigate('/settings/centers', { replace: true });
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  const filterCities = React.useMemo(() => {
    formik.setFieldValue('city_id', undefined);
    formik.setFieldValue('district_id', undefined);
    return cities.filter((e: any) =>
      formik.values.region_id ? formik.values.region_id == e.region?.id : e
    );
  }, [formik.values.region_id, cities]);

  const filterDistricts = React.useMemo(() => {
    formik.setFieldValue('district_id', undefined);
    return districts.filter((e: any) =>
      formik.values.city_id ? formik.values.city_id == e.region?.id : e
    );
  }, [formik.values.city_id, districts]);

  return (
    <React.Fragment>
      <div className="p-6">
        {errors ? (
          <div className="mb-4">
            <Alert
              title="Error"
              type="error"
              content={errors}
            />
          </div>
        ) : null}
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start"
          onSubmit={formik.handleSubmit}
        >
          <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
            <figcaption className="px-6 py-4 border-b border-b-gray-200">
              <p className="text-sm font-semibold text-gray-500">{t('basic-information')}</p>
            </figcaption>
            <blockquote className="space-y-4 p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="form-group col-span-full">
                  <label className="form-label">{t('logo')}</label>
                  <InputFile
                    defaultValue={formik.values.logo}
                    onValueChange={function (e: any): void {
                      formik.setFieldValue('logo', e);
                    }}
                    accept="image/*"
                  />
                  {errors?.logo ? <span className="form-error">{errors?.logo}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.name}
                    onChange={(e) => handleChange('name', e)}
                  />
                  {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('alt-name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.alt_name}
                    onChange={(e) => handleChange('alt_name', e)}
                  />
                  {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="form-group">
                  <label className="form-label">{t('cr-no')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.cr_no}
                    onChange={(e) => handleChange('cr_no', e)}
                  />
                  {errors?.cr_no ? <span className="form-error">{errors?.cr_no}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('vat-no')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.vat_no}
                    onChange={(e) => handleChange('vat_no', e)}
                  />
                  {errors?.vat_no ? <span className="form-error">{errors?.vat_no}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('bank-name')}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.bank_name}
                    onChange={(e) => handleChange('bank_name', e)}
                  />
                  {errors?.bank_name ? (
                    <span className="form-error">{errors?.bank_name}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="form-label">IBAN</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="..."
                    className="form-input form-outline"
                    value={formik.values.iban}
                    onChange={(e) => handleChange('iban', e)}
                  />
                  {errors?.iban ? <span className="form-error">{errors?.iban}</span> : null}
                </div>
              </div>
              <div className="form-group">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="form-checkbox form-outline rounded-full"
                    true-value={1}
                    false-value={0}
                    id="accept_partial_payments"
                    value={formik.values.accept_partial_payments}
                    onChange={(e) => handleChange('accept_partial_payments', e)}
                  />
                  <label
                    className="form-label"
                    htmlFor="accept_partial_payments"
                  >
                    {t('accept-partial-payments')}
                  </label>
                </div>
                {errors?.accept_partial_payments ? (
                  <span className="form-error">{errors?.accept_partial_payments}</span>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('email')}</label>
                <input
                  type="email"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.email}
                  onChange={(e) => handleChange('email', e)}
                />
                {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
              </div>

              <div className="form-group">
                <label className="form-label">{t('mobile')}</label>
                <Mobile
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.mobile}
                  onChange={(e) => handleChange('mobile', e)}
                />
                {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
              </div>

              <div className="form-group">
                <label className="form-label">{t('description')}</label>

                <Editor
                  onChange={(value) => formik.setFieldValue('info', value)}
                  value={formik.values.info}
                />
                {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
              </div>
              <div className="form-group">
                <label className="form-label">{t('alt-description')}</label>
                <Editor
                  onChange={(value) => formik.setFieldValue('alt_info', value)}
                  value={formik.values.alt_info}
                />
                {errors?.alt_info ? <span className="form-error">{errors?.alt_info}</span> : null}
              </div>
            </blockquote>
          </figure>
          <div className="space-y-4">
            <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
              <figcaption className="px-6 py-4 border-b border-b-gray-200">
                <p className="text-sm font-semibold text-gray-500">{t('location')}</p>
              </figcaption>
              <blockquote className="space-y-4 p-6">
                <div className="form-group">
                  <label className="form-label">{t('region')}</label>
                  <Select
                    type={'single'}
                    options={regions}
                    value={formik.values.region_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('region_id', value);
                    }}
                    optionTxt={'name'}
                    optionValue={'id'}
                  />

                  {errors?.region_id ? (
                    <span className="form-error">{errors?.region_id}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('city')}</label>
                  <Select
                    type={'single'}
                    options={filterCities}
                    value={formik.values.city_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('city_id', value);
                    }}
                    optionTxt={'name'}
                    optionValue={'id'}
                  />

                  {errors?.city_id ? <span className="form-error">{errors?.city_id}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('district')}</label>
                  <Select
                    type={'single'}
                    options={filterDistricts}
                    value={formik.values.district_id}
                    onSelect={function (value: any): void {
                      formik.setFieldValue('district_id', value);
                    }}
                    optionTxt={'name'}
                    optionValue={'id'}
                  />

                  {errors?.district_id ? (
                    <span className="form-error">{errors?.district_id}</span>
                  ) : null}
                </div>
              </blockquote>
            </figure>
            {/* <figure className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5">
            <blockquote className="space-y-4 p-6">
              <GoogleMap
                lat={formik.values.lat}
                lng={formik.values.lng}
                onChange={(val: any) => {
                  console.log(val);
                }}
              />
            </blockquote>
          </figure> */}
            <div>
              <div className="inline-flex gap-3 flex-wrap">
                <button
                  className="btn-with-icon bg-primary"
                  type="submit"
                  disabled={disabled}
                >
                  {disabled ? (
                    <Icon
                      icon="svg-spinners:3-dots-fade"
                      width={20}
                    />
                  ) : (
                    <span>{t('submit')}</span>
                  )}
                </button>
                <button
                  className="btn-with-icon !text-gray-600"
                  type="reset"
                  disabled={disabled}
                  onClick={goBack}
                >
                  <span>{t('cancel')}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
