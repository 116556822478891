import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'src/components/shared/Card';
import generateAlert from 'src/helper/generateAlert';
import generateRandomWord from 'src/helper/generateRandomWord';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';
import SubdomainPreview from './SubdomainPreview';
import { Icon } from '@iconify/react';

export default function Subdomain() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [initialValues, setInitialValues] = useState<any>({
    subdomain: user?.summary.subdomain || generateRandomWord(8)
  });
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { values, setFieldValue, handleChange, handleSubmit }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'settings/subdomain/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <div className="space-y-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <Card>
          <div className="space-y-4">
            <div className="form-group">
              <p className="mb-4 text-xl font-semibold">{t('domain-name')}</p>
              <div className="">
                <span></span>
              </div>
              {/* */}

              <div className="w-full flex items-center gap-3">
                <div
                  className="w-8 h-8 rounded-full flex items-center justify-center text-teal-600 bg-gray-100 shrink-0 border border-gray-200"
                  title="secure"
                  aria-label="secure"
                >
                  <Icon
                    icon="solar:lock-keyhole-minimalistic-bold"
                    width="1.1rem"
                    height="1.1rem"
                  />
                </div>

                <p className="text-gray-500 whitespace-nowrap text-lg">
                  https://
                  <span
                    className={`text-gray-800 font-semibold min-w-[3ch] inline-block ${
                      errors?.['subdomain'] ? 'border border-red-500' : ''
                    }`}
                    contentEditable
                    suppressContentEditableWarning
                    onInput={(e) => setFieldValue('subdomain', e.currentTarget.textContent)}
                    onBlur={(e) => setFieldValue('subdomain', e.currentTarget.textContent)}
                    onFocus={(e) => (e.currentTarget.textContent = values.subdomain)}
                    defaultValue={values.subdomain}
                    id="center-subdomain"
                    style={{
                      direction: 'ltr'
                    }}
                  >
                    {initialValues.subdomain}
                  </span>
                  .sa.salon
                </p>
              </div>

              <p className="form-error">{errors?.['subdomain']}</p>
            </div>
          </div>
        </Card>

        <button
          className="btn-with-icon bg-primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
      </form>
    </div>
  );
}

