import { Icon } from '@iconify/react';
import React from 'react';
import { MoveToBottom, MoveToTop } from '../../animations';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';
import axiosInstance from 'src/helper/AxiosInstance';
import Alert from 'src/components/shared/Alert';
import Modal from 'src/components/shared/Modal';
import Image from 'src/components/shared/Image';
import SharedTime from 'src/components/shared/SharedTime';

const Messages: React.FC = () => {
  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [viewVisible, setViewVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('contacts', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.contacts;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(Array.from({ length: 10 }).fill(0));
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const ShowCurrentMessage = React.useCallback(function (item: any) {
    setViewVisible(true);
  }, []);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="flex items-center justify-between flex-wrap">
          <p className="text-lg font-semibold text-gray-500 shrink-0">Messages</p>
        </div>

        {isLoading ? (
          <ul className="grid grid-wrapper gap-4">
            {Array.from({ length: 3 })
              .fill(0)
              .map((_, index: string | number) => (
                <li key={index}>
                  <a
                    href="#"
                    className="p-6 rounded-xl bg-white ring-1 ring-gray-200 s block"
                  >
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToTop}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-3 mb-4"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-full  bg-gray-200 animate-pulse rounded p-3 mb-2"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/3 bg-gray-200 animate-pulse rounded p-3"
                    ></motion.div>
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="w-1/2 bg-gray-200 animate-pulse rounded p-2 mt-4"
                    ></motion.div>
                  </a>
                </li>
              ))}
          </ul>
        ) : (
          <ul className={responses?.length ? 'grid grid-wrapper gap-4' : ''}>
            {responses?.length ? (
              responses.map((item: any, index: string | number) => (
                <li key={index}>
                  <button
                    className="p-6 rounded-xl bg-white ring-1 ring-gray-200 space-y-1 block text-start"
                    onClick={() => ShowCurrentMessage(item)}
                  >
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToTop}
                      className="text-base font-semibold text-gray-800"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </motion.p>
                    <motion.p
                      animate="visible"
                      initial="hidden"
                      variants={MoveToBottom}
                      className="text-sm font-medium text-gray-500 line-clamp-2"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid harum ea
                      voluptatibus deserunt nisi odit? Nulla, non? Deleniti excepturi inventore
                      error voluptates, consequatur aperiam suscipit, incidunt impedit minima vel
                      quod.
                    </motion.p>
                    <div className="flex gap-2 flex-wrap !my-4">
                      <motion.p
                        animate="visible"
                        initial="hidden"
                        variants={MoveToBottom}
                        className="inline-flex gap-2 items-center text-xs font-semibold text-teal-500"
                      >
                        <span className="shrink-0 inline w-1.5 h-1.5 rounded-full bg-teal-500"></span>
                        <span>Open</span>
                      </motion.p>
                      <motion.p
                        animate="visible"
                        initial="hidden"
                        variants={MoveToBottom}
                        className="inline-flex gap-2 items-center text-xs font-semibold text-red-500"
                      >
                        <span className="shrink-0 inline w-1.5 h-1.5 rounded-full bg-red-500"></span>
                        <span>Low</span>
                      </motion.p>
                    </div>
                    <div className="flex gap-2 flex-wrap justify-between pt-4 border-t border-t-gray-200">
                      <motion.p
                        animate="visible"
                        initial="hidden"
                        variants={MoveToBottom}
                        className="text-xs font-medium text-primary"
                      >
                        Sender name
                      </motion.p>
                      <motion.p
                        animate="visible"
                        initial="hidden"
                        variants={MoveToBottom}
                        className="text-xs font-medium text-gray-500"
                      >
                        {new Date().toISOString()}
                      </motion.p>
                    </div>
                  </button>
                </li>
              ))
            ) : (
              <Alert
                type="default"
                title="Empty result"
                content="It seems that there are no contacts available yet"
              />
            )}
          </ul>
        )}
        <div className="p-4 flex items-center gap-4 flex-wrap">
          <p className="text-gray-500 flex-1 shrink-0 text-sm">
            Showing {pagination?.page || 0} to {pagination?.total_pages || 0} of{' '}
            {pagination?.total || 0} results
          </p>
          <div className="inline-flex flex-wrap gap-2">
            <button
              className="btn-with-icon outline-btn shrink-0"
              disabled={pagination?.current_page === 1}
              onClick={() => GetItems({ page: pagination.page + 1 })}
            >
              <span>Previous</span>
            </button>

            <button
              className="btn-with-icon outline-btn shrink-0"
              disabled={pagination?.current_page === pagination?.total_pages}
              onClick={() => GetItems({ page: pagination.page - 1 })}
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="View contact"
        visible={viewVisible}
        handleClose={() => setViewVisible(false)}
      >
        <React.Fragment>
          <ul className="divide-y divide-gray-200 border-t border-t-gray-200">
            <li className="py-4">
              <div className="inline-flex gap-5 items-center">
                <Image className="shrink-0 w-10 h-10 rounded-full p-0.5 border border-gray-200" />
                <div className="flex-1 space-y-0.5">
                  <p className="text-gray-600 text-sm font-medium">Username</p>
                  <p className="text-gray-500 text-xs break-all">example@example.com</p>
                </div>
              </div>
            </li>
            <li className="py-4">
              <div className="flex-1 space-y-1">
                <p className="text-gray-500 text-xs break-all">Subject</p>
                <p className="text-gray-600 text-sm font-medium">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident, tempore.
                </p>
              </div>
            </li>
            <li className="py-4">
              <div className="flex-1 space-y-1">
                <p className="text-gray-500 text-xs break-all">Description</p>
                <p className="text-gray-600 text-sm font-medium">
                  Lorem, Lorem ipsum dolor sit amet consectetur adipisicing elit. Est labore, maxime
                  obcaecati illo tempora repellat tenetur atque repudiandae saepe sapiente
                  repellendus magni unde recusandae voluptates similique perspiciatis corporis
                  pariatur architecto.
                </p>
              </div>
            </li>
            <li className="py-4">
              <div className="inline-flex gap-2 flex-wrap">
                <div className="btn-with-icon bg-gray-200 !text-gray-600">
                  <Icon
                    icon="ri:eye-line"
                    width={15}
                  />
                  <span>Read at {new Date().toISOString().substring(0, 10)}</span>
                </div>
                <div className="btn-with-icon bg-gray-200">
                  <SharedTime date={new Date()} />
                </div>
              </div>
            </li>
          </ul>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default Messages;
