import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';
import Controllable from '../Controllable';
import InputFile from '../InputFile';
import ItemList from '../ItemList';
import Select from '../Select';

export type ItemType = 'service' | 'product' | 'package_offer';
type IType = {
  key: ItemType;
  label: string;
};

export const itemTypes: Array<IType> = [
  {
    key: 'service',
    label: 'service'
  },
  {
    key: 'product',
    label: 'product'
  },
  {
    key: 'package_offer',
    label: 'packages-offers'
  }
];

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  category_id: string | undefined;
  item_id: string | undefined;
  item_type: ItemType;
  items: any[];
}

export default function AddFormBody({
  closeModal,
  reFetching,
  item_type
}: {
  closeModal: any;
  reFetching: any;
  item_type: ItemType;
}) {
  let render = true;
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    image: undefined,
    category_id: undefined,
    item_id: undefined,
    item_type,
    items: []
  } satisfies ItemsForm;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const [centers, setCenters] = useState<any[]>([]);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const items = new Array().concat(
        ...values.items?.map((item: any) => {
          const { qty, id, price } = item;

          if (qty > 1) {
            return Array.from({ length: qty }).fill({
              id,
              price
            });
          }

          return {
            id,
            price
          };
        })
      );

      const centers = values.centers?.map((center: string) => ({ id: center }));

      const fd = serialize(
        {
          ...values,
          items,
          centers
        },
        {
          indices: true
        }
      );
      const { data } = await axiosInstance.post('items/add', fd);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  useEffect(() => {
    getCategories();
    getCenters();
  }, []);

  const getCategories = (value?: string) => {
    prepareRequest(
      {
        url: 'categories',
        params: {
          is_active: 1,
          search_key: value
        }
      },
      (data) => {
        setCategories(data.result?.categories || []);
      }
    );
  };

  const getCenters = (value?: string) => {
    prepareRequest(
      {
        url: 'centers',
        params: {
          is_active: 1,
          search_key: value
        }
      },
      (data) => {
        setCenters(data.result?.centers || []);
      }
    );
  };
  const costPrice = useMemo(() => {
    let cost = 0;
    let price = 0;
    const { item_type, items } = formik.values;
    if (['service', 'product'].includes(item_type)) return;

    for (const item of items) {
      cost += Number(item.cost || 0) * (item.qty || 0);
      price += Number(item.price || 0) * (item.qty || 0);
    }
    return {
      cost: CurrencyFormatter(cost),
      price: CurrencyFormatter(price)
    };
  }, [formik.values]);

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          className={[
            'space-y-4',
            formik.values.item_type !== 'package_offer' ? 'col-span-full' : ''
          ].join(' ')}
        >
          <div className="form-group">
            <label
              htmlFor="image"
              className="form-label"
            >
              {t('image')}
            </label>
            <InputFile
              defaultValue={formik.values.image}
              onValueChange={function (e: any): void {
                formik.setFieldValue('image', e);
              }}
              accept="image/*"
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              className={`form-group ${
                !!user?.global_settings?.enable_alt_name ? '' : 'col-span-full'
              }`}
            >
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.name}
                onChange={(e) => handleChange('name', e)}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            {!!user?.global_settings?.enable_alt_name ? (
              <div className="form-group">
                <label className="form-label">{t('alt-name')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="..."
                  className="form-input form-outline"
                  value={formik.values.alt_name}
                  onChange={(e) => handleChange('alt_name', e)}
                />
                {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
              </div>
            ) : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {!!user?.global_settings?.enable_desc ? (
              <>
                <div className="form-group">
                  <label className="form-label">{t('description')}</label>
                  <textarea
                    autoComplete="off"
                    placeholder="..."
                    className="form-textarea form-outline"
                    value={formik.values.desc}
                    onChange={(e) => handleChange('desc', e)}
                  ></textarea>
                  {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
                </div>
                <div className="form-group">
                  <label className="form-label">{t('alt-description')}</label>
                  <textarea
                    autoComplete="off"
                    placeholder="..."
                    className="form-textarea form-outline"
                    value={formik.values.alt_desc}
                    onChange={(e) => handleChange('alt_desc', e)}
                  ></textarea>
                  {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
                </div>
              </>
            ) : null}
            {/* <div
              className={[
                'form-group',
                formik.values.item_type == 'package_offer' ? 'col-span-full' : ''
              ].join(' ')}
            >
              <label className="form-label">
                {t('price')} ({t('sar')})
              </label>
              <input
                type="number"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.price}
                onChange={(e) => handleChange('price', e)}
              />
              {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
            </div> */}
            {formik.values.item_type == 'package_offer' ? (
              <div className="form-group">
                <label className="form-label">
                  {t('sale-price')} ({t('sar')})
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder={costPrice?.price}
                  className="form-input form-outline"
                  disabled={!['service', 'product'].includes(formik.values.item_type)}
                  readOnly={!['service', 'product'].includes(formik.values.item_type)}
                />
              </div>
            ) : null}
            <div className="form-group">
              <label className="form-label">
                {t('cost-price')} ({t('sar')})
              </label>
              <input
                type="number"
                autoComplete="off"
                placeholder={costPrice?.cost}
                className="form-input form-outline"
                value={formik.values.cost}
                onChange={(e) => handleChange('cost', e)}
                disabled={!['service', 'product'].includes(formik.values.item_type)}
                readOnly={!['service', 'product'].includes(formik.values.item_type)}
              />
              {errors?.cost ? <span className="form-error">{errors?.cost}</span> : null}
            </div>
          </div>
          <div className="form-group col-span-full">
            <label className="form-label">{t('centers')}</label>
            <Select
              type={'multi'}
              options={centers}
              value={formik.values.centers}
              onSelect={function (value: any): any {
                return formik.setFieldValue('centers', value);
              }}
              optionTxt={'name'}
              optionValue={'id'}
            />

            {errors?.centers ? <span className="form-error">{errors?.centers}</span> : null}
          </div>
          {['service', 'product'].includes(formik.values.item_type) ? (
            <div className="form-group">
              <label className="form-label">{t('category')}</label>
              <Select
                type={'single'}
                options={categories}
                value={formik.values.category_id}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('category_id', value);
                }}
                onSearchChange={(ev) => getCategories(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.category_id ? (
                <span className="form-error">{errors?.category_id}</span>
              ) : null}
            </div>
          ) : null}
          {formik.values.item_type === 'package_offer' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <p className="form-label">{t('start-date')}</p>
                <input
                  type="date"
                  name="start_date"
                  value={formik.values.start_date}
                  onChange={(e) => handleChange('start_date', e)}
                  className="form-input form-outline"
                />
                {errors?.start_date ? (
                  <span className="form-error">{errors?.start_date}</span>
                ) : null}
              </div>
              <div className="form-group">
                <p className="form-label">{t('end-date')}</p>
                <input
                  type="date"
                  name="end_date"
                  value={formik.values.end_date}
                  onChange={(e) => handleChange('end_date', e)}
                  className="form-input form-outline"
                />
                {errors?.end_date ? <span className="form-error">{errors?.end_date}</span> : null}
              </div>
            </div>
          )}
        </div>

        {['package_offer'].includes(formik.values.item_type) ? (
          <DisplayOfferItems
            formik={formik}
            errors={errors}
          />
        ) : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

export const DisplayOfferItems = memo(({ formik, errors }: any) => {
  const { t } = useTranslation();
  const ref = useRef<any[]>([]);
  const selectedItems = useRef<any[]>(formik.values?.items || []);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = (value?: string) => {
    prepareRequest(
      {
        url: 'items',
        params: {
          is_active: 1,
          item_type: ['service', 'product'],
          page: 1,
          search_key: value
        }
      },
      (data) => {
        const result = data.result?.items?.data || [];
        setItems(() => result);
        return (ref.current = result);
      }
    );
  };

  const onDelete = (id: string) => {
    const result = selectedItems.current.filter((item: any) => item.id !== id);
    selectedItems.current = result;
    formik.setFieldValue('items', result);
  };

  const onValueChange = (id: string, value: any, price?: number) => {
    const concatItems = [...selectedItems.current];
    const mapItems = concatItems.map((item: any) => {
      const isSame = item.id == id;
      if (!isSame) return item;
      return {
        ...item,
        qty: value,
        price
      };
    });
    selectedItems.current = mapItems;
    formik.setFieldValue('items', mapItems);
  };

  const onItemIdChange = (id: string) => {
    const isExisted = selectedItems.current.find((e: any) => e.id == id);
    const item = ref.current?.find((e: any) => e.id == id);
    const joinItem = [...selectedItems.current, { ...item, qty: 1 }];
    const updateItems = selectedItems.current.map((e: any) => {
      const isSame = e.id == id;
      if (!isSame) return e;
      return {
        ...e,
        qty: e.qty + 1
      };
    });
    const result = isExisted ? updateItems : joinItem;

    selectedItems.current = result;
    formik.setFieldValue('items', result);
  };

  return (
    <div className="space-y-4">
      <div className="form-group">
        <label className="form-label">{t('items')}</label>
        <Select
          type="single"
          options={items}
          value={formik.values.item_id}
          onSelect={(value: any) => {
            formik.setFieldValue('item_id', value);
            // console.log(items);
            onItemIdChange(value);
          }}
          onSearchChange={(ev) => getItems(ev.target.value)}
          optionTxt={'name'}
          optionValue={'id'}
        />

        {errors?.item_ids ? <span className="form-error">{errors?.item_ids}</span> : null}
      </div>

      {selectedItems.current.length ? (
        <ul className="divide-y divide-gray-200">
          {selectedItems.current.map((item: any) => (
            <DisplayItem
              items={selectedItems.current}
              onChange={onValueChange}
              onDelete={onDelete}
              key={item.id}
              item={item}
            />
          ))}
        </ul>
      ) : (
        <p className="text-sm text-gray-600 text-center">{t('no-data')}</p>
      )}
    </div>
  );
});

const DisplayItem = memo(({ item, onChange, onDelete }: any) => {
  return (
    <ItemList className="py-3">
      <div className="grid">
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="text-red-500 shrink-0"
            onClick={() => onDelete(item.id)}
          >
            <Icon
              icon="fluent:delete-12-regular"
              width="18"
            />
          </button>
          <div className="flex-1 space-y-1">
            <div className="grid">
              <p className="text-sm font-semibold text-gray-600 line-clamp-1">{item.name}</p>
            </div>
            <div className="flex items-center gap-3">
              <input
                type="number"
                className="form-input form-outline"
                value={item.price}
                onChange={(e) => onChange(item.id, item.qty, e.target.valueAsNumber)}
              />
              <div className="w-fit shrink-0">
                <Controllable
                  value={item.qty}
                  onChange={(value) => onChange(item.id, value, item.price)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ItemList>
  );
});
