import { pick } from 'lodash';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const Transactions: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { account } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });

  useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = useCallback(async function (params?: any) {
    try {
      setIsLoading(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('accounts/' + account + '/transactions', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.transactions;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const MEMO_TABLE = useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('account')}</th>
              <th>{t('type')}</th>
              <th>{t('debit')}</th>
              <th>{t('credit')}</th>
              <th>{t('balance')}</th>
              <th>{t('date')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.account?.name || 'N/A'}</td>
                    <td>{t(item.account?.type) || 'N/A'}</td>
                    <td>{CurrencyFormatter(item.debit || 0)}</td>
                    <td>{CurrencyFormatter(item.credit || 0)}</td>
                    <td>{CurrencyFormatter(item.balance || 0)}</td>

                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));
            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading}
      />
    );
  }, [responses, isLoading, pagination]);

  return (
    <Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
    </Fragment>
  );
};

export default Transactions;

