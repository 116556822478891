export interface TitleInterface {
  [key: string]: string;
}

export const titles: TitleInterface = {
  '/': 'home',
  '/account/login': 'Login',
  '/centers': 'centers',
  '/bookings': 'reservations',
  '/bookings/items': 'services',
  '/settings/clients': 'clients',
  '/invoices': 'invoices',
  '/reports': 'reports',
  '/calendar': 'calendar',
  '/reports/products': 'products',
  '/reports/services': 'services',
  '/reports/packages-offers': 'packages-offers',
  '/reports/clients': 'clients',
  '/reports/employees': 'employees',
  '/activity-logs': 'logs',
  '/settings/offers': 'offers',
  '/account/profile': 'profile',
  '/account/setup': 'setup',
  '/settings': 'settings',
  '/settings/cashiers': 'cashiers',
  '/settings/call-centers': 'call-centers',
  '/settings/management': 'management',
  '/settings/employees': 'experts',
  '/settings/centers': 'centers',
  '/settings/suppliers': 'suppliers',
  '/settings/centers/add': 'centers',
  '/settings/centers/:id': 'centers',
  '/settings/salaries': 'salaries',
  '/settings/expenses': 'expenses',
  '/settings/services': 'services',
  '/settings/categories': 'categories',
  '/settings/products': 'products',
  '/settings/products/add': 'products',
  '/settings/services/add': 'services',
  '/settings/inventory': 'inventory',
  '/settings/inventory/stocktaking': 'stock-counts',
  '/settings/processing': 'processing',
  '/settings/prices': 'prices',
  '/settings/transactions': 'transactions',
  '/settings/accounting-tree': 'accounting-tree',
  '/settings/journal-entries': 'journal-entries',
  '/settings/cost-centers': 'cost-centers',
  '/settings/journal-entries/new': 'add-new',
  '/settings/inventory/purchase-orders': 'purchase-orders',
  '/settings/wastages': 'wastages',
  '/settings/wastages-management': 'wastages-management',
  '/settings/inventory/purchase-orders/new': 'new-purchase',
  '/settings/packages-offers': 'packages-offers',
  '/messages': 'messages',
  '/settings/email-configurations': 'email-configurations',
  '/settings/website-configurations': 'website-configurations',
  '/settings/advanced': 'global-settings',
  '/settings/advanced/center': 'center-settings',
  '/settings/advanced/payments': 'payments-gateway',
  '/settings/advanced/sms': 'sms-settings',
  '/settings/advanced/faqs': 'faqs',
  '/settings/advanced/logs': 'logs',
  '/settings/advanced/sms-templates': 'sms-templates',
  '/settings/advanced/email-templates': 'email-templates',
  '/settings/advanced/pages': 'pages',
  '/settings/advanced/receipt-design': 'receipt-design',
  '/pos': 'pos',
  '/settings/roles': 'Roles & Permissions',
  '/messages/*': 'messages',
  '*': 'page-not-found'
};

