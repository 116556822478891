import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';
import Card from './Card';
import ItemList from './ItemList';
import Modal from './Modal';

interface PackageCardProps {
  name: string;
  alt_name: string;
  desc: string;
  alt_desc: string;
  yearly_price: number;
  monthly_price: number;
  centers_count: number;
  center_users_count: number;
  extra_center_fees: number;
  invoices_count: number;
  id: any;
  image: any;
}

export default function PackageCard({
  name,
  alt_name,
  desc,
  alt_desc,
  yearly_price,
  monthly_price,
  centers_count,
  extra_center_fees,
  center_users_count,
  invoices_count,
  id
}: PackageCardProps) {
  const { t } = useTranslation();

  return (
    <>
      <Card className="!p-0 overflow-hidden h-full flex flex-col">
        <div className="w-full px-5 py-8 bg-blue-600 text-center space-y-1">
          <p className="text-sm text-white">
            {name} - {alt_name}
          </p>
          <h3 className="text-center font-extra-bold text-white text-lg">
            {monthly_price ? CurrencyFormatter(monthly_price) : t('free')}{' '}
            {!!monthly_price && <span className="text-sm">/ {t('monthly')}</span>}
          </h3>
        </div>
        <div className="p-5 flex-1">
          <ul className="divide-y divide-gray-100">
            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('centers-count')}</p>
                <p className="text-black font-bold">{centers_count}</p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('users-count')}</p>
                <p className="text-black font-bold">{center_users_count}</p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('invoices-count')}</p>
                <p className="text-black font-bold">{invoices_count}</p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('monthly-price')}</p>
                <p className="text-black font-bold">{CurrencyFormatter(monthly_price)}</p>
              </div>
            </ItemList>
            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('yearly-price')}</p>
                <p className="text-black font-bold">{CurrencyFormatter(yearly_price)}</p>
              </div>
            </ItemList>

            <ItemList className="py-3">
              <div className="flex items-center gap-3 justify-between text-sm">
                <p className="font-medium">{t('extra-center-fees')}</p>
                <p className="text-black font-bold">{CurrencyFormatter(extra_center_fees)}</p>
              </div>
            </ItemList>
          </ul>
        </div>
        <div className="p-5">
          <SubscribeModal
            id={id}
            name={name}
            alt_name={alt_name}
          />
        </div>
      </Card>
    </>
  );
}

function SubscribeModal({ id, name, alt_name }: { id: string; name: string; alt_name: string }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [paymentForm, setPaymentForm] = useState<any>({});
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  function subscribeToCurrentPackage(values: any) {
    setDisabled(true);
    setErrors({});
    prepareRequest(
      {
        url: 'packages/subscribe',
        method: 'post',
        data: values
      },
      (data, error) => {
        if (!!error) return setErrors(error);
        generateAlert(data.message, 'success');
        const { checkout_id, checkout_url, payment_option } = data?.result || {};

        if (checkout_url) {
          setPaymentForm({
            checkout_id,
            checkout_url,
            payment_option
          });

          //  display payment form iframe in current page;
          const script = document.createElement('script');
          const body = document.querySelector('body');
          script.src = checkout_url;
          body?.append(script);

          return;
        }

        setVisible(false);
      }
    ).finally(() => {
      setDisabled(false);
    });
  }

  const {
    formik: { setFieldValue, values, handleSubmit, handleChange, resetForm }
  } = useForm({ initialValues: { package_id: id }, submitHandler: subscribeToCurrentPackage });

  useEffect(() => {
    if (visible) {
      setPaymentForm({});
      resetForm();
    }
  }, [visible]);

  return (
    <>
      <button
        className="btn-with-icon !bg-primary w-full max-w-xs mx-auto !p-3"
        type="button"
        disabled={disabled}
        onClick={() => (user ? setVisible(true) : navigate('/account/login', { replace: true }))}
      >
        <Icon
          icon="fe:check"
          width="1.2rem"
          height="1.2rem"
        />
        <span>{t('subscribe')}</span>
      </button>
      <Modal
        visible={visible}
        title={`${t('subscribe')} (${name} - ${alt_name})`}
        handleClose={() => setVisible(false)}
      >
        {!!paymentForm?.checkout_id ? (
          <form
            action={
              '/checkout/?payment=' +
              paymentForm?.payment_option +
              '&payment_option=' +
              paymentForm?.payment_option
            }
            className="paymentWidgets"
            data-brands={
              paymentForm?.payment_option === 'visa'
                ? 'VISA MASTER'
                : paymentForm?.payment_option === 'mada'
                ? 'MADA'
                : ''
            }
          ></form>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="space-y-4"
          >
            <div className="form-group">
              <label
                htmlFor="payment_option"
                className="form-label"
              >
                {t('subscribe-type')}
              </label>
              <div className="flex items-center gap-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="form-radio form-outline"
                    value="monthly"
                    id="monthly"
                    name="period_type"
                    checked={values.period_type === 'monthly'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-label"
                    htmlFor="monthly"
                  >
                    {t('monthly')}
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="form-radio form-outline"
                    value="yearly"
                    id="yearly"
                    name="period_type"
                    checked={values.period_type === 'yearly'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-label"
                    htmlFor="yearly"
                  >
                    {t('yearly')}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="payment_option"
                className="form-label"
              >
                {t('payment-option')}
              </label>
              <div className="flex items-center gap-4 flex-wrap">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="form-radio form-outline"
                    value="visa"
                    id="visa"
                    name="payment_option"
                    checked={values.payment_option === 'visa'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-label"
                    htmlFor="visa"
                  >
                    {t('visa')}
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="form-radio form-outline"
                    value="mada"
                    id="mada"
                    name="payment_option"
                    checked={values.payment_option === 'mada'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-label"
                    htmlFor="mada"
                  >
                    {t('mada')}
                  </label>
                </div>
              </div>
            </div>
            <button
              className="btn-with-icon !bg-primary w-full max-w-xs mx-auto !p-3"
              type="submit"
              disabled={disabled}
            >
              <span>{t('subscribe')}</span>
            </button>
          </form>
        )}
      </Modal>
    </>
  );
}
