import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Password from 'src/components/shared/Password';

interface AuthInterface {
  email_mobile?: string;
}

const EmailFormBody = ({
  setResetPasswordEmail,
  setActiveTab,
  reset_password_email
}: {
  setResetPasswordEmail: (email: string) => void;
  setActiveTab: (tab: string) => void;
  reset_password_email: null | string;
}) => {
  const globalValues = {
    email_mobile: reset_password_email || ''
  } satisfies AuthInterface;

  const { t } = useTranslation();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        { url: 'reset_password/send', method: 'post', data: values },
        (data, error) => {
          if (error) return setErrors(() => error);

          helper.resetForm();
          generateAlert(data.message, 'success');
          setResetPasswordEmail(values.email_mobile as string);
          setActiveTab('reset');
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="w-full space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="mb-6 space-y-4 text-center">
        <p className="text-3xl font-bold">{t('auth.forgot-password.title')}</p>
        <p className="text-gray-500">{t('auth.forgot-password.content')}</p>
      </div>
      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          defaultValue={reset_password_email || ''}
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>

      <div className="!mt-8 space-y-2">
        <button
          className="btn-with-icon bg-primary !text-white w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          <span>{t('next')}</span>
        </button>
        <p className="flex items-center gap-4 !my-4">
          <span className="flex-1 h-px bg-gray-200"></span>
          <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
          <span className="flex-1 h-px bg-gray-200"></span>
        </p>
        <p className="text-gray-600 text-center text-sm">
          <Link
            className="font-medium text-primary hover:underline transition"
            to="/account/login"
          >
            <span>{t('sign-in')}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

const ResetFormBody = ({
  reset_password_email,
  setActiveTab
}: {
  reset_password_email: null | string;
  setActiveTab: (tab: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const globalValues = {} satisfies AuthInterface;
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [verified, setVerified] = React.useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        {
          url: 'reset_password/save',
          method: 'post',
          data: { ...values, email_mobile: reset_password_email }
        },
        (data, error) => {
          if (error) return setErrors(() => error);

          // helper.resetForm();
          generateAlert(data.message, 'success');
          if (data.result?.need_password) {
            setVerified(true);
          } else {
            navigate('/account/login', { replace: true });
          }
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="w-full space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="mb-6 space-y-4 text-center">
        <p className="text-3xl font-bold">
          {verified ? t('auth.reset-password.title') : t('auth.verify-code.title')}
        </p>
        <p className="text-gray-500">
          {verified
            ? t('auth.reset-password.content')
            : t('auth.verify-code.content').replace(
                '{{EMAIL}}',
                reset_password_email as string
              )}{' '}
          {!verified && (
            <button
              className="text-primary font-medium hover:underline"
              onClick={() => {
                setActiveTab('email');
              }}
              type="button"
            >
              {t('edit-email')}
            </button>
          )}
        </p>
      </div>

      {verified ? (
        <>
          <div className="form-group">
            <label
              htmlFor="new-password"
              className="form-label"
            >
              {t('new-password')}
            </label>
            <Password
              name="password"
              id="new-password"
              placeholder="••••••"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('password', target.value)
              }
            />
            {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
          </div>
          <div className="form-group">
            <label
              htmlFor="confirm-password"
              className="form-label"
            >
              {t('confirm-password')}
            </label>
            <Password
              name="password_confirmation"
              id="confirm-password"
              placeholder="••••••"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('password_confirmation', target.value)
              }
            />
            {errors?.password_confirmation ? (
              <span className="form-error">{errors?.password_confirmation}</span>
            ) : null}
          </div>
        </>
      ) : (
        <div className="form-group">
          <label
            htmlFor="verify-code"
            className="form-label"
          >
            {t('verify-code')}
          </label>
          <input
            name="text"
            id="code"
            placeholder="••••••"
            className="form-input form-outline !bg-gray-50"
            autoComplete="off"
            autoCapitalize="false"
            autoCorrect="true"
            onChange={({ target }: { target: HTMLInputElement }) =>
              setFieldValue('code', target.value)
            }
          />
          {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
        </div>
      )}

      <div className="!mt-8 space-y-2">
        <button
          className="btn-with-icon bg-primary !text-white w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </button>
        <p className="flex items-center gap-4 !my-4">
          <span className="flex-1 h-px bg-gray-200"></span>
          <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
          <span className="flex-1 h-px bg-gray-200"></span>
        </p>
        <p className="text-gray-600 text-center text-sm">
          <Link
            className="font-medium text-primary hover:underline transition"
            to="/account/login"
          >
            <span>{t('sign-in')}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

const ForgotPassword: React.FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState('email');
  const [reset_password_email, setResetPasswordEmail] = useState<null | string>(null);

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1 py-20 px-8">
        <Card className="max-w-xl mx-auto space-y-4 p-8">
          {activeTab === 'reset' && reset_password_email ? (
            <ResetFormBody
              reset_password_email={reset_password_email}
              setActiveTab={setActiveTab}
            />
          ) : (
            <EmailFormBody
              setResetPasswordEmail={setResetPasswordEmail}
              setActiveTab={setActiveTab}
              reset_password_email={reset_password_email}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;

