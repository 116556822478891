import React from 'react';

const Receipt = ({ itemContent, logo }: { itemContent: any; logo?: string }) => {
  const {
    font_size,
    show_logo,
    commercial_number,
    center_name,
    center_location,
    invoice_number,
    client_name,
    client_number,
    item_price,
    item_qty,
    discount,
    vat,
    total_include_vat,
    total_exclude_vat,
    qr_code,
    show_comment,
    show_notes,
    notes,
    comment
  } = itemContent;

  return (
    <div
      className={`shrink-0 border border-gray-200 bg-white rounded-md px-4 py-10 top-[6rem] w-full lg:sticky lg:w-[350px]`}
    >
      <div className="flex flex-col gap-0.5 items-center justify-center pb-3 border-b border-gray-200">
        {show_logo == 1 && (
          <div className="w-full mb-2 -mt-2">
            <img
              src={logo ? logo : '/images/placeholder.png'}
              alt="logo"
              className={`w-full h-20 object-contain`}
              loading="lazy"
            />
          </div>
        )}

        {invoice_number == 1 && (
          <p
            className={`font-bold mb-2`}
            style={{
              fontSize: Number(font_size) + 4
            }}
          >
            110
          </p>
        )}

        {center_name == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>اسم المركز</ItemP>

            <ItemP fontSize={font_size}>مركز الجمال</ItemP>
          </div>
        )}

        {center_location == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>عنوان المركز</ItemP>

            <ItemP fontSize={font_size}>الرياض - حي العليا</ItemP>
          </div>
        )}

        {commercial_number == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>رقم السجل التجاري</ItemP>

            <ItemP fontSize={font_size}>#1234567890</ItemP>
          </div>
        )}
      </div>

      <div className="py-4 border-b border-gray-200 space-y-3">
        <div className="w-full flex gap-3">
          <ItemP fontSize={font_size}>مستحضرات التجميل</ItemP>

          <div className={`flex-1 flex gap-3 ${item_price + item_qty == 0 ? 'hidden' : ''}`}>
            {item_price == 1 && <ItemP fontSize={font_size}>20 ريال</ItemP>}

            {item_qty == 1 && <ItemP fontSize={font_size}>3 قطع</ItemP>}
          </div>
        </div>

        <div className="w-full flex gap-3">
          <ItemP fontSize={font_size}>مستحضرات التجميل</ItemP>

          <div className={`flex-1 flex gap-3 ${item_price + item_qty == 0 ? 'hidden' : ''}`}>
            {item_price == 1 && <ItemP fontSize={font_size}>20 ريال</ItemP>}

            {item_qty == 1 && <ItemP fontSize={font_size}>3 قطع</ItemP>}
          </div>
        </div>

        <div className="w-full flex gap-3">
          <ItemP fontSize={font_size}>مستحضرات التجميل</ItemP>

          <div className={`flex-1 flex gap-3 ${item_price + item_qty == 0 ? 'hidden' : ''}`}>
            {item_price == 1 && <ItemP fontSize={font_size}>20 ريال</ItemP>}

            {item_qty == 1 && <ItemP fontSize={font_size}>3 قطع</ItemP>}
          </div>
        </div>
      </div>

      <div
        className={`py-4 border-b border-gray-200 ${
          total_exclude_vat + total_include_vat + vat + discount == 0 ? 'hidden' : ''
        }`}
      >
        {total_exclude_vat == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>الاجمالي غير شامل الضريبة</ItemP>

            <ItemP fontSize={font_size}>400 ريال</ItemP>
          </div>
        )}

        {total_include_vat == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>الاجمالي شامل الضريبة</ItemP>

            <ItemP fontSize={font_size}>22.00 ريال</ItemP>
          </div>
        )}

        {vat == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>ضريبة القيمة المضافة</ItemP>

            <ItemP fontSize={font_size}>15.00 ريال</ItemP>
          </div>
        )}

        {discount == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>مبلغ الخصم</ItemP>

            <ItemP fontSize={font_size}>16.00 ريال</ItemP>
          </div>
        )}
      </div>

      <div
        className={`py-4 border-b border-gray-200 ${
          client_name + client_number == 0 ? 'hidden' : ''
        }`}
      >
        {client_name == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>اسم العميل</ItemP>

            <ItemP fontSize={font_size}>علي احمد علي</ItemP>
          </div>
        )}

        {client_number == 1 && (
          <div className="w-full flex gap-4">
            <ItemP fontSize={font_size}>رقم جوال العميل</ItemP>

            <ItemP fontSize={font_size}>+966 55 555 5555</ItemP>
          </div>
        )}
      </div>

      <div className="py-4 flex flex-col gap-1 items-center justify-center">
        <ItemP
          className="text-center"
          fontSize={font_size}
        >
          شكرا لزيارتكم
        </ItemP>

        <ItemP
          className="text-center font-semibold"
          fontSize={font_size}
        >
          رقم الهاتف : 0555555555
        </ItemP>
      </div>

      <div className={`py-4 flex flex-col gap-4 ${show_comment + show_notes == 0 ? 'hidden' : ''}`}>
        {show_comment == 1 && (
          <div>
            <ItemP
              fontSize={font_size}
              className="mb-1 text-gray-800 font-semibold"
            >
              التعليق:
            </ItemP>

            <ItemP fontSize={font_size}>{comment}</ItemP>
          </div>
        )}

        {show_notes == 1 && (
          <div>
            <ItemP
              fontSize={font_size}
              className="mb-1 text-gray-800 font-semibold"
            >
              ملاحظات:
            </ItemP>

            <ItemP fontSize={font_size}>{notes}</ItemP>
          </div>
        )}
      </div>

      {qr_code == 1 && (
        <div className="w-full">
          <img
            src="/images/bar-code.png"
            alt="qr-code"
            loading="lazy"
            className="w-full h-20 object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default Receipt;

const ItemP = ({
  children,
  className,
  fontSize
}: {
  children: React.ReactNode;
  className?: string;
  fontSize: number;
}) => {
  return (
    <p
      className={`flex-1 text-gray-700 ${className}`}
      style={{
        fontSize: fontSize + 'px',
        lineHeight: 1.5
      }}
    >
      {children}
    </p>
  );
};

