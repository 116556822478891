import { AllHTMLAttributes } from "react";

interface PlaceholderProps extends AllHTMLAttributes<HTMLDivElement> {
  height: string;
  width?: string;
}

export default function Placeholder({ height, width,className}: PlaceholderProps) {
  return (
    <div
      className={`bg-gray-200 rounded animate-pulse max-w-full ${className}`}
      style={{
        height,
        width: width || '100%'
      }}
    ></div>
  );
}
