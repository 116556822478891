import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Delete from 'src/components/shared/Delete';
import ItemList from 'src/components/shared/ItemList';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function index() {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [addVariableVisible, setAddVariableVisible] = React.useState<boolean>(false);
  const [viewVisible, setViewVisible] = React.useState<boolean>(false);
  const [addVariableItem, setAddVariableItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const { is_active, ...paginate } = pick(paginates, ['page', 'is_active', 'search_key']);

      const { data } = await axiosInstance.get('expenses', {
        params: { is_active: is_active, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.expenses;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('alt-name')}</th>
              <th>{t('amounts')}</th>
              <th>{t('latest-update')}</th>
              <th>{t('actions')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item.name || 'N/A'}</td>
                    <td>{item.alt_name || 'N/A'}</td>
                    <td>
                      {/* <button
                        className="btn-with-icon !bg-blue-500"
                        type="button"
                        onClick={() => {
                          setAddVariableVisible(true);
                          setAddVariableItem({
                            name: item.id,
                            expense_id: item.id
                          });
                        }}
                      >
                        {t('add-new')}
                      </button> */}
                      <div className="btn-with-icon !bg-gray-100 !text-gray-600 w-fit">
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => {
                            setViewVisible(true);
                            setAddVariableItem(item);
                          }}
                        >
                          <Icon
                            icon="majesticons:eye-line"
                            width="15"
                          />
                        </button>
                        <span>|</span>
                        <span>{CurrencyFormatter(item.total || 0)}</span>
                        <span>|</span>
                        <button
                          type="button"
                          className="btn-with-icon !rounded-full bg-blue-500 !text-white !p-1"
                          onClick={() => {
                            setAddVariableVisible(true);
                            setAddVariableItem({
                              name: item.name,
                              expense_id: item.id
                            });
                          }}
                        >
                          <Icon
                            icon="ic:baseline-plus"
                            width="15"
                          />
                        </button>
                      </div>
                    </td>
                    <td>
                      <SharedTime date={item.updated_at} />
                    </td>
                    <td>
                      <div className="inline-flex gap-2">
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setEditItem(item);
                            setEditVisible(true);
                          }}
                        >
                          {t('edit')}
                        </button>

                        <Delete
                          pathname={'expenses/delete/' + item.id}
                          refetch={GetItems}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                className="btn-with-icon bg-blue-600 !text-white shrink-0"
                onClick={() => setVisible(true)}
              >
                <span>
                  <Icon
                    icon="majesticons:plus"
                    width={18}
                  />
                </span>
                <span>{t('add-new')}</span>
              </button>
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) => {
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            }));

            GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">{MEMO_TABLE}</div>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
      >
        <AddFormBody
          closeModal={setVisible}
          reFetching={GetItems}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => {
          setEditItem({});
          setEditVisible(false);
        }}
        title={editItem?.name}
      >
        <UpdateFormBody
          closeModal={setEditVisible}
          reFetching={GetItems}
          schema={editItem}
        />
      </Modal>
      <Modal
        visible={addVariableVisible}
        handleClose={() => {
          setAddVariableItem({});
          setAddVariableVisible(false);
        }}
        title={addVariableItem?.name}
      >
        <AddVariable
          closeModal={setAddVariableVisible}
          reFetching={GetItems}
          schema={addVariableItem}
        />
      </Modal>
      <Modal
        visible={viewVisible}
        handleClose={() => {
          setViewVisible(false);
          setAddVariableItem({});
        }}
        title={addVariableItem?.name}
      >
        <DisplayVariables data={addVariableItem.variables} />
      </Modal>
    </React.Fragment>
  );
}

interface FormInterface {
  name: string | undefined;
  alt_name: string | undefined;
}

const AddFormBody = ({
  closeModal,
  reFetching
}: {
  closeModal: any;
  reFetching: any;
}): JSX.Element => {
  const { t } = useTranslation();

  const globalValues = {
    name: undefined,
    alt_name: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = React.useState(globalValues);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [centers, setCenters] = React.useState<any[]>([]);

  React.useEffect(() => {
    getCenters();
  }, []);

  const getCenters = (search_key?: string) =>
    prepareRequest({ url: 'centers', params: { is_active: 1, search_key, page: 1 } }, (data) => {
      setCenters(() => data.result?.centers?.data || []);
    });

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('expenses/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('alt-name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="form-group">
        <p className="form-label">{t('center')}</p>
        <Select
          value={formik.values.center_id}
          options={centers}
          optionTxt="name"
          optionValue="id"
          type="single"
          onSelect={(value) => formik.setFieldValue('center_id', value)}
          onSearchChange={(ev) => getCenters(ev.target.value)}
        />
        {errors?.center_id ? <span className="form-error">{errors?.center_id}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

const UpdateFormBody = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('expenses/update', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('alt-name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.alt_name}
          onChange={(e) => handleChange('alt_name', e)}
        />
        {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

const AddVariable = ({
  closeModal,
  reFetching,
  schema
}: {
  closeModal: any;
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const { data } = await axiosInstance.post('expenses/add_variable', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('amount')}</label>
        <input
          type="number"
          autoComplete="off"
          placeholder="0.00"
          className="form-input form-outline"
          value={formik.values.amount}
          onChange={(e) => handleChange('amount', e)}
        />
        {errors?.amount ? <span className="form-error">{errors?.amount}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('notes')}</label>
        <textarea
          autoComplete="off"
          placeholder="..."
          className="form-textarea form-outline"
          value={formik.values.notes}
          onChange={(e) => handleChange('notes', e)}
        ></textarea>
        {errors?.notes ? <span className="form-error">{errors?.notes}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
};

function DisplayVariables({ data = [] }: any) {
  const { t } = useTranslation();
  const getTotal = React.useMemo(() => {
    let total = 0;

    for (const item of data) {
      total += Number(item.amount || 0);
    }

    return total;
  }, [data]);
  return (
    <ul className="divide-y divide-gray-200">
      {data.map((item: any) => (
        <ItemList
          className="py-3"
          key={item.id}
        >
          <div className="flex items-start gap-4">
            <div className="space-y-2 flex-1">
              <p className="text-sm text-gray-600 py-2 px-3 max-w-fit bg-gray-100">
                {item.notes || t('no-data')}
              </p>

              <SharedTime date={item.created_at} />
            </div>
            <p className="font-semibold text-gray-800">{CurrencyFormatter(item.amount || 0)}</p>
          </div>
        </ItemList>
      ))}

      <ItemList className="py-3">
        <div className="flex items-center gap-4">
          <div className="space-y-0.5 flex-1"></div>
          <p className="font-semibold text-gray-800">{CurrencyFormatter(getTotal)}</p>
        </div>
      </ItemList>
    </ul>
  );
}

